/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'
import analytics from "../../../../analytics/analytics";

function SlideView (props) {
	let { image, link, headline, text } = props

	return (
		<div className={'m-slider__element'}>
			<img className={'m-slider__image'} src={image} />
			<div className={'m-slider__content'}>
				<a className={'m-slider__link'} href={link}>
					<h3 className={'m-slider__headline'}>{headline}</h3>
				</a>
				<p className={'m-slider__text'}>
					{text}
				</p>
				<a className={'m-slider__additional-link'} href={link}>Weiterlesen</a>

			</div>
		</div>
	)
}

SlideView.defaultProps = {
	image: 'https://via.placeholder.com/831x500',
	link: '',
	headline: '',
	text: ''
}

if (__DEV__) {
	SlideView.propTypes = {
		image: propTypes.string,
		link: propTypes.string,
		headline: propTypes.string,
		text: propTypes.string
	}
}

export default SlideView
