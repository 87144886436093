/* global __DEV__ */
import React, { Component, Fragment } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToReadingListController } from '../../utils/mappings'
import {
	removeFromReadingList,
	removeAllItemsFromReadingList,
	syncReadingList
} from '../../actions/reading-list.actions'
import ReadingListView from './views/reading-list.view'
import ReadingListEmptyView from './views/reading-list-empty.view'
import ReadingListItemView from './views/reading-list-item.view'
import { withPageContentSections } from '../pages/page-content.controller'
import routes, { advancedRoutes } from '../../utils/routes'
import { createModal } from '../../actions/modal.actions'
import i18n from '../../i18n'
import withTitle from '../header/withTitle.hoc'
import { compose } from 'redux/lib/redux'
import commonPropTypes from '../../utils/common-prop-types'
import analytics from '../../analytics/analytics'

class ReadingListController extends Component {
	constructor (props) {
		super(props)
		props.dispatch(syncReadingList())
	}

	handleRemove (type, id) {
		this.props.dispatch(removeFromReadingList(id, type))
	}

	handleClearReadingList () {
		this.props.dispatch(createModal(
			i18n.t('TXT_GENERAL_ATTENTION.label'),
			i18n.t('TXT_READING_LIST_CLEAR_QUESTION.label'),
			() => {
				this.props.dispatch(removeAllItemsFromReadingList())
				/* TODO: Maybe redirect to another page. */
				// Custom Tracking
				analytics.track('clear Readinglist')
			},
			() => { /* Nothing to do. */ },
			i18n.t('TXT_READING_LIST_CLEAR_QUESTION_YES.label'),
			i18n.t('TXT_READING_LIST_CLEAR_QUESTION_NO.label')
		))
	}

	render () {
		const { readingList, readingListCount } = this.props

		/* We do have an empty reading list. */
		if (Object.keys(readingList).length === 0) {
			return (
				<ReadingListEmptyView />
			)
		}

		return (
			<ReadingListView
				readingListLink={readingListCount > 0 ? routes.readingListSend.build() : ''}
				handleClearReadingList={readingListCount > 0 ? this.handleClearReadingList.bind(this) : null}
			>
				{/* Iteration over content types */}
				{Object.keys(readingList).map((type, keyIndex) => {
					return (
						<Fragment key={keyIndex}>
							{/* Iteration over elements in content type */}
							{Object.keys(readingList[type]).map((id, keyIndex) => {
								let item = readingList[type][id]
								let linkTo
								try {
									linkTo = advancedRoutes.buildEntityRoute(type, item)
								} catch (e) {
									linkTo = '#'
								}

								return (
									<ReadingListItemView
										key={keyIndex}
										itemType={type}
										itemId={id}
										item={item}
										linkTo={linkTo}
										handleRemove={this.handleRemove.bind(this, type, id)}
									/>
								)
							})}
						</Fragment>
					)
				})}
			</ReadingListView>
		)
	}
}

if (__DEV__) {
	ReadingListController.propTypes = {
		readingList: propTypes.object.isRequired,
		readingListCount: propTypes.number.isRequired,

		...commonPropTypes.mixinFromReduxConnect
	}
}

export default compose(
	connect(mapAppStateToReadingListController),
	withPageContentSections,
	withTitle(null, 'TXT_READING_LIST_LIST_TITLE.label')
)(ReadingListController)
