export default class Operation {

	/**
	 * @param {String} name
	 */
	constructor (name) {
		this.name = name
	}

	// Abstract method
	execute () {
	}
}
