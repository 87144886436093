/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToStakeholdersListController } from '../../utils/mappings'
import StakeholdersListView from './views/stakeholders-list.view'
import routes from '../../utils/routes'
import { withPageContentSections } from '../pages/page-content.controller'
import { compose } from 'redux/lib/redux'
import withTitle from '../header/withTitle.hoc'
import commonPropTypes from '../../utils/common-prop-types'
import { asSequence } from 'sequency'

class StakeholdersListController extends Component {
	static prepareItems (stakeholders) {
		let sOrganised = {}
		asSequence(Object.values(stakeholders))
			.onEach(s => { sOrganised[s.organization] = sOrganised[s.organization] || [] })
			.onEach(s => sOrganised[s.organization].push(s))
			.toArray()
		return sOrganised
	}

	render () {

		// Group stakeholders by organisation
		let stakeholdersOrganised = StakeholdersListController.prepareItems(this.props.stakeholders)

		return (
			<StakeholdersListView stakeholdersOrganised={ stakeholdersOrganised }/>
		)
	}
}

if (__DEV__) {
	StakeholdersListController.propTypes = {
		stakeholders: propTypes.object.isRequired,

		...commonPropTypes.mixinFromReduxConnect
	}
}

export default compose(
	connect(mapAppStateToStakeholdersListController),
	withPageContentSections,
	withTitle(null, 'TXT_STAKEHOLDER_LIST_TITLE.label')
)(StakeholdersListController)
