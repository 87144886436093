/* eslint-disable indent */
/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToGenericControllerComponent } from '../../utils/mappings'
import ContentElementGalleryView from './views/content-element-gallery.view'
import { asSequence } from 'sequency'

class ContentElementGalleryController extends Component {
	/**
	 * Gets the url of image with max resolution
	 * We can use it to determine what resolution to display
	 * @param images
	 * @return {*}
	 */
	resolutionHelper (images) {
		return asSequence(images)
			.maxBy(im => im.width)
			.url
	}

	prepareElements (elements) {
		return asSequence(elements)
			.map(el => {
					return {
						headline: el.title,
						text: el.caption,
						image: this.resolutionHelper(el.images)
					}
				}
			)
			.toArray()
	}

	render () {
		let elements = this.prepareElements(this.props.contentElement.elements)

		return (
			<ContentElementGalleryView
				elements={ elements }
			/>
		)
	}
}

if (__DEV__) {
	ContentElementGalleryController.propTypes = {
		contentElement: propTypes.object.isRequired
	}
}

export default connect(
	(state, ownProps) => mapAppStateToGenericControllerComponent(state, ownProps)
)(ContentElementGalleryController)
