/* global ga */
/* global XMLHttpRequest */
import appEnv from '../../utils/app-env'
import AnalyticsModuleBasic from './analytics-module-basic'
import analyticsSignalProcessorGoogle from '../signal-processors/analytics-signal-processor-google'

const STORAGE_AREA = 'analytics-module-google'

/**
 * Analytics module that pushes tracking data to Google Analytics.
 */
class AnalyticsModuleGoogle extends AnalyticsModuleBasic {
	/**
	 * Initialize module settings.
	 */
	constructor () {
		super()

		// Google Analytics only works with a tracking id.
		this.active = !!appEnv.analytics.google.trackingId

		// Use the Google Analytics signal processor.
		this.signalProcessor = analyticsSignalProcessorGoogle
	}

	/**
	 * Custom module initialization.
	 * Loads the ga script and initializes tracking options.
	 * Also triggers queue processing.
	 */
	initialize () {
		AnalyticsModuleGoogle.loadGoogleAnalytics()
		ga('create', appEnv.analytics.google.trackingId, 'auto')
		ga('set', 'anonymizeIp', true)
		AnalyticsModuleGoogle.modifyGoogleAnalyticsSendHitTask()

		// Mark module as initialized.
		// We can track data now.
		// The ga function queues signals until the actual google analytics
		// tracking script is loaded and initialized.
		this.initialized = true

		// Trigger queue processing.
		ga(this.process.bind(this))
	}

	/**
	 * Initializes the ga() function and loads the actual google analytics
	 * script.
	 */
	static loadGoogleAnalytics () {
		/* eslint-disable */
		(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;// noinspection CommaExpressionJS
			i[r]=i[r]||function(){
				(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();// noinspection CommaExpressionJS
			a=s.createElement(o),
				m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
		})(window,document,'script','https://www.google-analytics.com/analytics.js','ga')
		/* eslint-enable */
	}

	/**
	 * Modifies the ga tracker in a way that allows us to respond to failed
	 * tracking network requests. (e.g. offline mode)
	 */
	static modifyGoogleAnalyticsSendHitTask () {
		const trackingURI = 'https://www.google-analytics.com/collect'
		ga(function (tracker) {
			// Grab a reference to the default sendHitTask function.
			// var originalSendHitTask = tracker.get('sendHitTask')

			// Modifies sendHitTask to send the tracking request via
			// custom xhr. With that we can handle failed network
			// requests.
			tracker.set('sendHitTask', function (model) {
				let hitCallback	= model.get('hitCallback')
				let hitPayload	= model.get('hitPayload')
				let succeed		= typeof hitCallback === 'function'
					? function () { hitCallback(null, true) }
					: function () {}
				let fail		= typeof hitCallback === 'function'
					? hitCallback
					: (e) => { throw e }

				try {
					var xhr = new XMLHttpRequest()
					xhr.onreadystatechange = function () {
						if (xhr.readyState !== 4) {
							return
						}

						if (xhr.status >= 200 && xhr.status < 300) {
							succeed()
						} else {
							fail(new Error('tracking request failed'))
						}
					}
					xhr.open('GET', trackingURI + '?' + hitPayload, true)
					xhr.send()
				} catch (e) {
					fail(e)
				}
			})
		})
	}
}

const analyticsModuleGoogle = new AnalyticsModuleGoogle()
analyticsModuleGoogle.initQueue(true, STORAGE_AREA)

export default analyticsModuleGoogle
