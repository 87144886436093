/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'
import commonPropTypes from '../../../utils/common-prop-types'

function StakeholdersItemView (props) {
	// Assign all properties to variables for convenience
	const { children } = props
	return (
		<div className='o-detail-view'>
			{children}
		</div>
	)
}

if (__DEV__) {
	// Properties types validation
	StakeholdersItemView.propTypes = {
		stakeholderItem: propTypes.object,
		children: commonPropTypes.reactChildren
	}
}

// Export component
export default StakeholdersItemView
