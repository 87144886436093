import { DataLayerResponseSearchContent } from '../../../lib/DataLayerResponse'
import { CreateGlamusSearchModel } from '../lib/models'
import ResponseCodeCheckers from '../lib/response-code-checkers'
import {
	GLAMUS_CONTENT_TYPE_ARTICLES,
	GLAMUS_CONTENT_TYPE_PRESS_RELEASES
} from '../lib/constants'
import {
	APP_CONTENT_TYPE_ARTICLES,
	APP_CONTENT_TYPE_PRESS_RELEASES
} from '../../../../constants/names'

const getType = function (type) {
	switch (type) {
		case GLAMUS_CONTENT_TYPE_ARTICLES:
			return APP_CONTENT_TYPE_ARTICLES
			break

		case GLAMUS_CONTENT_TYPE_PRESS_RELEASES:
			return APP_CONTENT_TYPE_PRESS_RELEASES
			break

		default:
			alert('Unknown type ' + type)
			return type
			break
	}
}

const getSearch = function (searchResults) {
	let data = []

	searchResults.forEach((searchResult) => {
		let type = getType(searchResult.type)
		let id = searchResult.itemId

		let categoryIds = 'categoryIds' in searchResult ? searchResult.categoryIds : []
		let categoryId = categoryIds === null ? null : categoryIds[0]

		data.push({
			id: id,
			type: type,
			category: categoryId,
			title: searchResult.title
		})
	})

	return data
}

const searchContent = async function (searchTerm) {
	let data = []

	let success = true

	/* Fetch all data at once (recommended) */
	let modelSearch = CreateGlamusSearchModel(searchTerm)

	let responseSearch = null
	try {
		responseSearch = await modelSearch.request()

		console.error(responseSearch)

		if (responseSearch.status === 200) {
			data = getSearch(responseSearch.data)
		}

		if (ResponseCodeCheckers.isRequestFailed(responseSearch)) {
			success = false
		}
	} catch (e) {
		console.error(e)
		success = false
	}

	return new DataLayerResponseSearchContent(
		false,
		success,
		data
	)
}

const paAppSearchService = {
	searchContent
}

export default paAppSearchService
