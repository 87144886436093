/* global __DEV__ */
import React, { Fragment } from 'react'
import propTypes from 'prop-types'
import i18n from 'i18next'
import dataLayer from '../../../data-layer/dataLayer'

function SendArticleView (props) {
	// Assign all properties to variables for convenience
	const {
		emailTo,
		emailSubject,
		emailMessage,
		handleSendArticleEmailToChange,
		handleSendArticleEmailSubjectChange,
		handleSendArticleEmailMessageChange,
		handleSendArticleSubmit
	} = props

	return (
		<div className="o-article-send">

			<label className={'a-label'} htmlFor="emailTo">{i18n.t('TXT_ARTICLE_SEND_VIEW_RECEIVER.label')}</label>
			<input
				placeholder="name@domain.de"
				type="text"
				name="emailTo"
				id="emailTo"
				className={'a-input a-input--filled'}
				value={emailTo}
				minLength="1"
				onChange={ handleSendArticleEmailToChange.bind(this) }
			/>

			{
				dataLayer.articleSendHasSubject
					? (
						<Fragment>
							<label className={'a-label'} htmlFor="emailSubject">{i18n.t('TXT_ARTICLE_SEND_VIEW_SUBJECT.label')}</label>
							<input
								placeholder=""
								type="text"
								name="emailSubject"
								id="emailSubject"
								className={'a-input a-input--filled'}
								value={emailSubject}
								onChange={ handleSendArticleEmailSubjectChange.bind(this) }
							/>
						</Fragment>
					)
					: null
			}

			<label className={'a-label'} htmlFor="emailSubject">{i18n.t('TXT_ARTICLE_SEND_VIEW_MESSAGE.label')}</label>
			<textarea
				className="a-textarea a-textarea--filled"
				placeholder=""
				name="emailMessage"
				id="emailMessage"
				onChange={ handleSendArticleEmailMessageChange.bind(this) }
				value={emailMessage}
			/>

			<button className="a-button a-button--center" onClick={ handleSendArticleSubmit.bind(this) }>{i18n.t('TXT_GENERAL_SEND.label')}</button>
		</div>
	)
}

if (__DEV__) {
	// Properties types validation
	SendArticleView.propTypes = {
		emailTo: propTypes.string.isRequired,
		emailSubject: propTypes.string.isRequired,
		emailMessage: propTypes.string.isRequired,
		handleSendArticleEmailToChange: propTypes.func,
		handleSendArticleEmailSubjectChange: propTypes.func,
		handleSendArticleEmailMessageChange: propTypes.func,
		handleSendArticleSubmit: propTypes.func
	}
}

// Export component
export default SendArticleView
