import axios from 'axios'
import { getLocalAuthData } from './helpers'
import store from '../../../../store'
import appEnv from '../../../../utils/app-env'

class DATA_LAYER_GLAMUS_BASE_URL {
	/**
	 * Returns correct api endpoint with "pattern" replaced on "endpoint"
	 * @param {String} pattern
	 * @param {String} endpoint
	 * @return {string}
	 */
	static replace (pattern, endpoint) {
		let developmentMode = appEnv.devBuildOnly.developmentMode
		let urlBase = 'https://www.vfa.de/ws/applicationrest/cockpit/%s'
		let basicStateRef = store.getState().basic

		if (basicStateRef.cmsPreview && !basicStateRef.singleViewError) {
			urlBase = 'https://cms.vfa.de/ws/applicationrest/cockpit/%s'
		}

		if (developmentMode) {
			urlBase = 'https://api.pa.vfa.rsm-stage.de/ws/applicationrest/cockpit/%s'
		}

		return urlBase.replace(pattern, endpoint)
	}
}

const LOCAL_URL = false

/**
 * Assembles the complete api url using the endpoint, the query and the hash.
 *
 * @param url
 * @param query
 * @param hash
 * @returns {*}
 */
function createCompleteUrl (url, query = '', hash = null) {
	if (!LOCAL_URL) {
		let authData = getLocalAuthData()
		let tokenGlue = '?'

		if (query) {
			tokenGlue = '&'
			url += '?' + query
		}

		/* add authorization data, if data exists */
		if (hash) {
			url += String(tokenGlue + 'hash=%hash').replace(/%hash/, hash)
		} else if (authData !== null && authData.token) {
			url += String(tokenGlue + 'token=%token').replace(/%token/, authData.token)
		}

		return url
	}

	return url
		.replace(/vfa.de/, '127.0.0.1:3005')
		.replace(/\?id=/, '_id_') + '.json'
}

/**
 * Get the entire API via single calls. This means that all individual api requirements are summarized here.
 * This function builds and returns an obj array:
 * - obj.status
 * - obj.data.category
 * - obj.data.article
 * - obj.data.news
 * - obj.data.pressrelease
 * - obj.data.slider
 *
 * @returns {{request: (function(): {data: {}, status: number})}}
 * @constructor
 */
function CreateGlamusAllDataSingleModel () {
	return {
		request: async () => {
			let obj = {
				status: 200,
				data: {}
			}

			/* Fetch all categories */
			obj.data.category = []
			let categoryId2Index = {}
			let modelCategory = CreateGlamusCategoryDataModel()
			let responseCategory = null
			try {
				responseCategory = await modelCategory.request()

				if (responseCategory.status === 200) {
					obj.data.category = responseCategory.data

					for (let indexCategory in obj.data.category) {
						categoryId2Index[obj.data.category[indexCategory].categoryId] = parseInt(indexCategory)
					}
				}
			} catch (e) {
				console.error(e)
			}

			/* Fetch all category-article relationships (article ids) */
			let articles = {}
			for (let indexCategory in obj.data.category) {
				let category = obj.data.category[indexCategory]

				let modelCategoryTopic = CreateGlamusCategoryArticleDataModel(category.categoryId)
				let responseCategoryTopic = null
				try {
					responseCategoryTopic = await modelCategoryTopic.request()

					if (responseCategory.status === 200) {
						let indexCategoryData = categoryId2Index[category.categoryId]
						obj.data.category[indexCategoryData].article = []

						for (let indexArticle in responseCategoryTopic.data) {
							let article = responseCategoryTopic.data[indexArticle]
							articles[article.articleId] = article

							/* add article ids to categories */
							obj.data.category[indexCategoryData].article.push(article.articleId)
						}
					}
				} catch (e) {
					console.error(e)
				}
			}

			/* Fetch all articles (details) */
			obj.data.article = []
			for (let articleId in articles) {
				let modelArticle = CreateGlamusArticleDataModel(articleId)
				let responseArticle = null
				try {
					responseArticle = await modelArticle.request()

					if (responseArticle.status === 200) {
						obj.data.article.push(responseArticle.data)
					}
				} catch (e) {
					console.error(e)
				}
			}

			/* Fetch all news */
			obj.data.news = []
			let modelNews = CreateGlamusNewsDataModel()
			let responseNews = null
			try {
				/* get news list */
				responseNews = await modelNews.request()

				if (responseNews.status === 200) {
					for (let indexNews in responseNews.data) {
						let newsId = responseNews.data[indexNews].newsId
						let modelNewsDetail = CreateGlamusNewsDetailDataModel(newsId)
						let responseNewsDetail = null
						try {
							/* get news detail */
							responseNewsDetail = await modelNewsDetail.request()

							if (responseNewsDetail.status === 200) {
								obj.data.news.push(responseNewsDetail.data)
							}
						} catch (e) {
							console.error(e)
						}
					}
				}
			} catch (e) {
				console.error(e)
			}

			/* Fetch all press releases */
			obj.data.pressrelease = []
			let modelPressReleases = CreateGlamusPressReleaseDataModel()
			let responsePressReleases = null
			try {
				responsePressReleases = await modelPressReleases.request()

				if (responsePressReleases.status === 200) {
					for (let indexPressRelease in responsePressReleases.data) {
						let pressReleaseId = responsePressReleases.data[indexPressRelease].pressreleaseId
						let modelPressReleaseDetail = CreateGlamusPressReleaseDetailDataModel(pressReleaseId)
						let responsePressReleaseDetail = null
						try {
							responsePressReleaseDetail = await modelPressReleaseDetail.request()
							if (responsePressReleaseDetail.status === 200) {
								obj.data.pressrelease.push(responsePressReleaseDetail.data)
							}
						} catch (e) {
							console.error(e)
						}
					}
				}
			} catch (e) {
				console.error(e)
			}

			/* Fetch all slider (news) elements */
			obj.data.slider = []
			let modelSlider = CreateGlamusSliderDataModel()
			let responseSlider = null
			try {
				responseSlider = await modelSlider.request()

				if (responseSlider.status === 200) {
					obj.data.slider = responseSlider.data
				}
			} catch (e) {
				console.error(e)
			}

			return obj
		}
	}
}

/**
 * Get a article single view item via hash call and build and return the obj array:
 * - obj.status
 * - obj.data.article
 *
 * TODO: This function is no longer necessary and can be deleted. Use the
 *       function CreateGlamusArticleDataModel directly instead of
 *       CreateGlamusPreviewArticleDataModel.
 *
 * @returns {{request: (function(): {data: {}, status: number})}}
 * @constructor
 */
function CreateGlamusPreviewArticleDataModel (config) {
	return {
		request: async () => {
			let obj = {
				status: 200,
				data: {}
			}

			/* Fetch all articles (details) */
			obj.data.article = []

			let modelArticle = CreateGlamusArticleDataModel(null, config.singleViewToken)
			let responseArticle = null
			try {
				/* get news detail */
				responseArticle = await modelArticle.request()

				if (responseArticle.status === 200) {
					obj.data.article.push(responseArticle.data)
				} else {
					obj.status = responseArticle.status
				}
			} catch (e) {
				obj.status = 500
				console.error(e)
			}

			return obj
		}
	}
}

/**
 * Get a news single view item via hash call and build and return the obj array:
 * - obj.status
 * - obj.data.news
 *
 * TODO: This function is no longer necessary and can be deleted. Use the
 *       function CreateGlamusNewsDetailDataModel directly instead of
 *       CreateGlamusPreviewNewsDataModel.
 *
 * @returns {{request: (function(): {data: {}, status: number})}}
 * @constructor
 */
function CreateGlamusPreviewNewsDataModel (config) {
	return {
		request: async () => {
			let obj = {
				status: 200,
				data: {}
			}

			obj.data.news = []

			let modelNewsDetail = CreateGlamusNewsDetailDataModel(null, config.singleViewToken)
			let responseNewsDetail = null
			try {
				/* get news detail */
				responseNewsDetail = await modelNewsDetail.request()

				if (responseNewsDetail.status === 200) {
					obj.data.news.push(responseNewsDetail.data)
				} else {
					obj.status = responseNewsDetail.status
				}
			} catch (e) {
				obj.status = 500
				console.error(e)
			}

			return obj
		}
	}
}

/**
 * Get a press release single view item via hash call and build and return the obj array:
 * - obj.status
 * - obj.data.pressrelease
 *
 * TODO: This function is no longer necessary and can be deleted. Use the
 *       function CreateGlamusPressReleaseDetailDataModel directly instead of
 *       CreateGlamusPreviewPressReleaseDataModel.
 *
 * @returns {{request: (function(): {data: {}, status: number})}}
 * @constructor
 */
function CreateGlamusPreviewPressReleaseDataModel (config) {
	return {
		request: async () => {
			let obj = {
				status: 200,
				data: {}
			}

			obj.data.pressrelease = []

			let modelPressReleaseDetail = CreateGlamusPressReleaseDetailDataModel(null, config.singleViewToken)
			let responsePressReleaseDetail = null
			try {
				responsePressReleaseDetail = await modelPressReleaseDetail.request()

				if (responsePressReleaseDetail.status === 200) {
					obj.data.pressrelease.push(responsePressReleaseDetail.data)
				} else {
					obj.status = responsePressReleaseDetail.status
				}
			} catch (e) {
				obj.status = 500
				console.error(e)
			}

			return obj
		}
	}
}

/**
 * Retries the object type of given single view hash.
 * - https://vfa.de/ws/applicationrest/cockpit/objectinfo
 *
 * @returns {{request: (function(): {data: {}, status: number})}}
 * @constructor
 */
function CreateGlamusTypeModel (singleViewToken) {
	let endpoint = 'objectinfo'

	let apiUrl = DATA_LAYER_GLAMUS_BASE_URL.replace(/%s/, endpoint)

	let requestParams = {
		baseURL: createCompleteUrl(apiUrl, '', singleViewToken),
		method: 'get',
		mode: 'no-cors'
	}

	return axios.create(requestParams)
}

/**
 * Requests the entire content..
 * - https://vfa.de/ws/applicationrest/cockpit/content
 *
 * @returns {{request: (function(): {data: {}, status: number})}}
 * @constructor
 */
function CreateGlamusAllDataModel () {
	let endpoint = 'content'

	let apiUrl = DATA_LAYER_GLAMUS_BASE_URL.replace(/%s/, endpoint)

	let requestParams = {
		baseURL: createCompleteUrl(apiUrl),
		method: 'get',
		mode: 'no-cors'
	}

	return axios.create(requestParams)
}

/**
 * Requests all changed data since a specific timestamp.
 * - https://vfa.de/ws/applicationrest/cockpit/content
 *
 * @param since {Number}
 * @returns {{request: (function(): {data: {}, status: number})}}
 * @constructor
 */
function CreateGlamusAllDataUpdateModel (since) {
	let endpoint = 'content'

	let apiUrl = DATA_LAYER_GLAMUS_BASE_URL.replace(/%s/, endpoint)

	let requestParams = {
		baseURL: createCompleteUrl(apiUrl),
		method: 'get',
		mode: 'no-cors',
		params: {
			aktualisiertSeit: since
		}
	}

	return axios.create(requestParams)
}

/**
 * Requests all categories.
 * - https://vfa.de/ws/applicationrest/cockpit/categories
 *
 * @returns {AxiosInstance}
 * @constructor
 */
function CreateGlamusCategoryDataModel () {
	let endpoint = 'categories'

	let apiUrl = DATA_LAYER_GLAMUS_BASE_URL.replace(/%s/, endpoint)

	let requestParams = {
		baseURL: createCompleteUrl(apiUrl),
		method: 'get',
		mode: 'no-cors'
	}

	return axios.create(requestParams)
}

/**
 * Requests a category based on the given ID.
 * - https://vfa.de/ws/applicationrest/cockpit/category
 *
 * @param id
 * @returns {AxiosInstance}
 * @constructor
 */
function CreateGlamusCategoryArticleDataModel (id) {
	let endpoint = 'category'
	let query = 'id=' + id

	let apiUrl = DATA_LAYER_GLAMUS_BASE_URL.replace(/%s/, endpoint)

	let requestParams = {
		baseURL: createCompleteUrl(apiUrl, query),
		method: 'get',
		mode: 'no-cors'
	}

	return axios.create(requestParams)
}

/**
 * Requests an article based on the given ID.
 * - https://vfa.de/ws/applicationrest/cockpit/article
 *
 * @param id
 * @param hash
 * @returns {AxiosInstance}
 * @constructor
 */
function CreateGlamusArticleDataModel (id, hash = null) {
	let endpoint = 'article'
	let query = id ? 'id=' + id : ''
	let apiUrl = DATA_LAYER_GLAMUS_BASE_URL.replace(/%s/, endpoint)

	let requestParams = {
		baseURL: createCompleteUrl(apiUrl, query, hash),
		method: 'get',
		mode: 'no-cors'
	}

	return axios.create(requestParams)
}

/**
 * Requests all slider elements.
 * - https://vfa.de/ws/applicationrest/cockpit/slider
 *
 * @returns {AxiosInstance}
 * @constructor
 */
function CreateGlamusSliderDataModel () {
	let endpoint = 'slider'
	let apiUrl = DATA_LAYER_GLAMUS_BASE_URL.replace(/%s/, endpoint)

	let requestParams = {
		baseURL: createCompleteUrl(apiUrl),
		method: 'get',
		mode: 'no-cors'
	}

	return axios.create(requestParams)
}

/**
 * Requests all slider elements.
 * - https://vfa.de/ws/applicationrest/cockpit/newslist
 *
 * @returns {AxiosInstance}
 * @constructor
 */
function CreateGlamusNewsDataModel () {
	let endpoint = 'newslist'
	let apiUrl = DATA_LAYER_GLAMUS_BASE_URL.replace(/%s/, endpoint)

	let requestParams = {
		baseURL: createCompleteUrl(apiUrl),
		method: 'get',
		mode: 'no-cors'
	}

	return axios.create(requestParams)
}

/**
 * Requests a news item based on the given ID.
 * - https://vfa.de/ws/applicationrest/cockpit/news
 *
 * @param id
 * @param hash
 * @returns {AxiosInstance}
 * @constructor
 */
function CreateGlamusNewsDetailDataModel (id, hash = null) {
	let endpoint = 'news'
	let query = id ? 'id=' + String(id).replace(/^[a-z]/, '') : ''
	let apiUrl = DATA_LAYER_GLAMUS_BASE_URL.replace(/%s/, endpoint)

	let requestParams = {
		baseURL: createCompleteUrl(apiUrl, query, hash),
		method: 'get',
		mode: 'no-cors'
	}

	return axios.create(requestParams)
}

/**
 * Requests all press release elements.
 * - https://vfa.de/ws/applicationrest/cockpit/pressreleaselist
 *
 * @returns {AxiosInstance}
 * @constructor
 */
function CreateGlamusPressReleaseDataModel () {
	let endpoint = 'pressreleaselist'
	let apiUrl = DATA_LAYER_GLAMUS_BASE_URL.replace(/%s/, endpoint)

	let requestParams = {
		baseURL: createCompleteUrl(apiUrl),
		method: 'get',
		mode: 'no-cors'
	}

	return axios.create(requestParams)
}

/**
 * Requests a press release item based on the given ID.
 * - https://vfa.de/ws/applicationrest/cockpit/pressrelease
 *
 * @param id
 * @param hash
 * @returns {AxiosInstance}
 * @constructor
 */
function CreateGlamusPressReleaseDetailDataModel (id, hash = null) {
	let endpoint = 'pressrelease'
	let query = id ? 'id=' + String(id).replace(/^[a-z]/, '') : ''
	let apiUrl = DATA_LAYER_GLAMUS_BASE_URL.replace(/%s/, endpoint)

	let requestParams = {
		baseURL: createCompleteUrl(apiUrl, query, hash),
		method: 'get',
		mode: 'no-cors'
	}

	return axios.create(requestParams)
}

/**
 * Requests a login token from Glamus if the login credentials are correct.
 * - https://vfa.de/ws/applicationrest/cockpit/login
 *
 * @param username
 * @param password
 * @returns {AxiosInstance}
 * @constructor
 */
function CreateGlamusLoginModel (username, password) {
	let endpoint = 'login'
	let apiUrl = DATA_LAYER_GLAMUS_BASE_URL.replace(/%s/, endpoint)

	let data = String('username=%username&password=%password').replace(
		/%username/, encodeURIComponent(username)
	).replace(
		/%password/, encodeURIComponent(password)
	)

	return axios.create({
		baseURL: createCompleteUrl(apiUrl),
		method: 'post',
		validateStatus: function (status) {
			return status >= 200 && (status < 300 || status === 401)
		},
		data: data
	})
}

/**
 * Add an item to the reading list via a single call.
 * - https://vfa.de/ws/applicationrest/cockpit/addtoreadinglist
 *
 * @param id
 *
 * @returns {{request: (function(): {data: {}, status: number})}}
 * @constructor
 */
function CreateGlamusReadingListAddModel (id) {
	let endpoint = 'addtoreadinglist'
	let query = 'id=' + String(id).replace(/^[a-z]/, '')
	let apiUrl = DATA_LAYER_GLAMUS_BASE_URL.replace(/%s/, endpoint)

	let requestParams = {
		baseURL: createCompleteUrl(apiUrl, query),
		method: 'get',
		mode: 'no-cors'
	}

	return axios.create(requestParams)
}

/**
 * Remove an item to the reading list via a single call.
 * - https://vfa.de/ws/applicationrest/cockpit/removefromreadinglist
 *
 * @param {string} id
 * @param {string} date Glamus style timestamp
 *
 * @returns {{request: (function(): {data: {}, status: number})}}
 * @constructor
 */
function CreateGlamusReadingListRemoveModel (id, date) {
	let endpoint = 'removefromreadinglist'
	let query = 'id=' + String(id).replace(/^[a-z]/, '') + '&timestamp=' + date
	let apiUrl = DATA_LAYER_GLAMUS_BASE_URL.replace(/%s/, endpoint)

	let requestParams = {
		baseURL: createCompleteUrl(apiUrl, query),
		method: 'get',
		mode: 'no-cors'
	}

	return axios.create(requestParams)
}

/**
 * Get the entire reading list via a single call.
 * - https://vfa.de/ws/applicationrest/cockpit/readinglist
 *
 * @returns {{request: (function(): {data: {}, status: number})}}
 * @constructor
 */
function CreateGlamusReadingListDataModel () {
	let endpoint = 'readinglist'
	let apiUrl = DATA_LAYER_GLAMUS_BASE_URL.replace(/%s/, endpoint)

	let requestParams = {
		baseURL: createCompleteUrl(apiUrl),
		method: 'get',
		mode: 'no-cors'
	}

	return axios.create(requestParams)
}

/**
 * Full-text search.
 * - https://vfa.de/ws/applicationrest/cockpit/search
 *
 * @param searchRequest
 * @returns {AxiosInstance}
 * @constructor
 */
function CreateGlamusSearchModel (searchRequest) {
	let endpoint = 'search'
	let query = 'query=' + searchRequest
	let apiUrl = DATA_LAYER_GLAMUS_BASE_URL.replace(/%s/, endpoint)

	let requestParams = {
		baseURL: createCompleteUrl(apiUrl, query),
		method: 'get',
		mode: 'no-cors'
	}

	return axios.create(requestParams)
}

/**
 * Causes the Glamus CMS to send an email to a given email address.
 * - https://vfa.de/ws/applicationrest/cockpit/mail
 *
 * @param email
 * @param message
 * @param articleIds
 * @returns {AxiosInstance}
 * @constructor
 */
function CreateGlamusSendMessageModel (email, message, articleIds) {
	let endpoint = 'mail'
	let query = 'email=%email&message=%message'
	let idsQuery = '&ids[]=%ids'

	/* add ids to send to query */
	if (typeof articleIds !== 'string') {
		articleIds.forEach((articleId) => {
			query += idsQuery.replace(/%ids/, articleId)
		})
	} else {
		query += idsQuery.replace(/%ids/, articleIds)
	}

	/* replace ids, message and article-ids */
	query = query
		.replace(/%email/, email)
		.replace(/%message/, encodeURI(message))
		.replace(/%ids/, articleIds)

	let apiUrl = DATA_LAYER_GLAMUS_BASE_URL.replace(/%s/, endpoint)

	let requestParams = {
		baseURL: createCompleteUrl(apiUrl, query),
		method: 'post',
		mode: 'no-cors'
	}

	return axios.create(requestParams)
}

/**
 * Model to save push totifications token
 * @param nToken
 * @return {AxiosInstance}
 * @constructor
 */
function CreateGlamusSaveNotificationsTokenModel (nToken) {
	let url = createCompleteUrl(
		'https://www.vfa.de/ws/applicationrest/cockpit/setpushnotificationtoken',
		`pushNotificationToken=${nToken}`
	)

	console.log('URL: ', url)

	let requestParams = {
		baseURL: url,
		method: 'get'
	}

	return axios.create(requestParams)
}

/**
 * Get Glamus settings.
 * - https://vfa.de/ws/applicationrest/cockpit/getemailaddresses
 *
 * @returns {AxiosInstance}
 * @constructor
 */
function CreateGlamusGetSettingsModel () {
	let endpoint = 'getemailaddresses'

	let apiUrl = DATA_LAYER_GLAMUS_BASE_URL.replace(/%s/, endpoint)

	let requestParams = {
		baseURL: createCompleteUrl(apiUrl, ''),
		method: 'get',
		mode: 'no-cors'
	}

	return axios.create(requestParams)
}

/**
 * Set Glamus settings.
 * - https://vfa.de/ws/applicationrest/cockpit/setemailstatus
 *
 * @param emails
 * @returns {AxiosInstance}
 * @constructor
 */
function CreateGlamusSetSettingsModel (emails) {
	let endpoint = 'setemailstatus'

	let data = ''

	for (let index = 0; index < emails.length; index++) {
		let email = emails[index]

		data += data ? '&' : ''

		if (email.active) {
			data += String('active[]=%s').replace(/%s/, email.email)
		} else {
			data += String('canceled[]=%s').replace(/%s/, email.email)
		}
	}

	let apiUrl = DATA_LAYER_GLAMUS_BASE_URL.replace(/%s/, endpoint)

	let requestParams = {
		baseURL: createCompleteUrl(apiUrl, ''),
		method: 'post',
		mode: 'no-cors',
		data: data
	}

	return axios.create(requestParams)
}

/* Export all api wrappers */
export {
	CreateGlamusSaveNotificationsTokenModel,
	CreateGlamusTypeModel,
	CreateGlamusAllDataModel,
	CreateGlamusAllDataSingleModel,
	CreateGlamusPreviewArticleDataModel,
	CreateGlamusPreviewNewsDataModel,
	CreateGlamusPreviewPressReleaseDataModel,
	CreateGlamusCategoryDataModel,
	CreateGlamusCategoryArticleDataModel,
	CreateGlamusArticleDataModel,
	CreateGlamusSliderDataModel,
	CreateGlamusNewsDataModel,
	CreateGlamusNewsDetailDataModel,
	CreateGlamusPressReleaseDataModel,
	CreateGlamusPressReleaseDetailDataModel,
	CreateGlamusLoginModel,
	CreateGlamusReadingListDataModel,
	CreateGlamusReadingListAddModel,
	CreateGlamusReadingListRemoveModel,
	CreateGlamusAllDataUpdateModel,
	CreateGlamusSearchModel,
	CreateGlamusSendMessageModel,
	CreateGlamusGetSettingsModel,
	CreateGlamusSetSettingsModel
}
