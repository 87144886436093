import Logger from '../logger'

class ConnectionReactionsManager {

	constructor () {
		this.reactions = []
	}

	addReaction (reaction) {
		Logger.logOnce('Connection Reactions Manager', 'Added reaction', reaction)
		this.reactions.push(reaction)
	}

	async executeReactions (isOnline) {
		Logger.logOnce('Connection Reactions Manager', 'Executing reactions')
		this.reactions.forEach(r => r.react(isOnline))
	}
}

export let connectionReactionsManager = new ConnectionReactionsManager()
