import concat from 'lodash/concat'
import { IS_STATIC_HEADER, IS_IMAGE_HEADER } from '../../constants/names'
import { imageRequestBuilder } from '../../data-layer/implementations/glamus-api/lib/file-models'

export default class FilesDownloader {
	constructor (sArr, dArr) {
		this.__sFiles = sArr
		this.__dFiles = dArr
		this.__requests = []
	}

	__buildStaticFileRequest (path) {
		return imageRequestBuilder(1, path)
	}

	__buildDynamicFileRequest (path) {
		return imageRequestBuilder(0, path)
	}

	prepareRequests () {
		this.__requests = concat(
			this.__sFiles.map(f => this.__buildStaticFileRequest(f)),
			this.__dFiles.map(f => this.__buildDynamicFileRequest(f))
		)
	}

	triggerRequestGun () {
		try {
			this.__requests.map(this.__sendRequest)
		} catch (e) {
			console.warn(e)
		}
	}

	__sendRequest (requestObject) {
		try {
			fetch(requestObject)
		} catch (e) {
			console.warn(e)
		}
	}
}
