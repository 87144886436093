/* global Array */

import {
	SEND_ARTICLE_ERROR,
	SEND_ARTICLE_SUCCESS,
	SEND_ARTICLE_RESET,
	SEND_ARTICLE_UPDATE_FORM_FIELD
} from '../constants/action-types'
import routes from '../utils/routes'
import { history } from '../utils/browser-history'
import StorageManager from 'js-storage-manager'
import { QUEUE_NAMESPACE, MESSAGE_QUEUE_NAMESPACE } from '../constants/names'
import { syncMessageQueue } from './message-queue.actions'
import {
	CreateGlamusSendMessageModel
} from '../data-layer/implementations/glamus-api/lib/models'
import dataLayer from '../data-layer/dataLayer'
import { createModal } from './modal.actions'
import i18n from '../i18n'
import store from '../store'
import { LoginProcess } from '../utils/login-process.js'
import Logger from '../utils/logger'

function validateEmail (email) {
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(String(email).toLowerCase())
}

export function doSendArticle (emailTo, emailSubject, emailMessage, ids) {
	// Todo: enable loader
	return async function (dispatch) {
		let sm = new StorageManager(QUEUE_NAMESPACE)

		let articleSend = {}

		if (!emailTo) {
			dispatch(createModal(
				i18n.t('TXT_GENERAL_WORD_ERROR.label'),
				i18n.t('TXT_ARTICLE_SEND_MISSING_EMAIL_ADDRESS.label')
			))
			return
		} else {
			articleSend.emailTo = emailTo
		}

		if (!validateEmail(emailTo)) {
			dispatch(createModal(
				i18n.t('TXT_GENERAL_WORD_ERROR.label'),
				i18n.t('TXT_ARTICLE_SEND_MISSING_VALID_EMAIL_ADDRESS.label')
			))
			return
		} else {
			articleSend.emailTo = emailTo
		}

		if (dataLayer.articleSendHasSubject) {
			if (!emailSubject) {
				dispatch(createModal(
					i18n.t('TXT_GENERAL_WORD_ERROR.label'),
					i18n.t('TXT_ARTICLE_SEND_MISSING_SUBJECT.label')
				))
				return
			} else {
				articleSend.emailSubject = emailSubject
			}
		}

		if (!emailMessage) {
			dispatch(createModal(
				i18n.t('TXT_GENERAL_WORD_ERROR.label'),
				i18n.t('TXT_ARTICLE_SEND_MISSING_MESSAGE.label')
			))
			return
		} else {
			articleSend.emailMessage = emailMessage
		}

		if (!ids) {
			dispatch(createModal(
				i18n.t('TXT_GENERAL_WORD_ERROR.label'),
				i18n.t('TXT_ARTICLE_SEND_MISSING_IDS.label')
			))
			return
		} else {
			articleSend.ids = ids
		}

		// This field stores information about send errors.
		articleSend.error = false

		let qm = sm.initQueue(MESSAGE_QUEUE_NAMESPACE)
		qm.push(articleSend)

		dispatch(throwSendArticleSuccess(200, ''))
		dispatch(syncMessageQueue())
		dispatch(doSendMessageQueue())
	}
}

export function updateSendArticleFormField (name, value) {
	return {
		type: SEND_ARTICLE_UPDATE_FORM_FIELD,
		name,
		value
	}
}

export function throwSendArticleSuccess (code, message) {
	return {
		type: SEND_ARTICLE_SUCCESS,
		code,
		message,
		alert: 'SUCCESS'
	}
}

export function throwSendArticleError (code, message) {
	return {
		type: SEND_ARTICLE_ERROR,
		code,
		message,
		alert: 'ERROR'
	}
}

export function resetSendArticle () {
	return {
		type: SEND_ARTICLE_RESET
	}
}

export function resetSendArticleAndGoBack () {
	return function (dispatch) {
		let localHistory = history.localHistory
		let wentBack = false

		dispatch(resetSendArticle())

		if (localHistory) {
			wentBack = localHistory.goBack()
		}

		if (!wentBack) {
			history.replace(routes.root.build())
		}
	}
}

/**
 * TODO: Use the data layer instead of direct dispatch!
 */
async function doSendToGlamus (emailTo, emailMessage, ids) {
	let modelSendMessage = CreateGlamusSendMessageModel(emailTo, emailMessage, ids)
	let responseSendMessage = null
	try {
		responseSendMessage = await modelSendMessage.request()

		return {
			sent: responseSendMessage.status === 200,
			response: responseSendMessage
		}
	} catch (e) {
		responseSendMessage = e.response

		/* We have handed over an invalid token and log out in here. */
		if (responseSendMessage.status === 401) {
			LoginProcess.logout(true)
		}

		return {
			sent: false,
			response: e.response
		}
	}
}

/**
 * Helper function to return the first item (or null) of an array.
 *
 * @param {Object[]|null} items
 * @return {Object|null}
 */
function getFirstArrayItemOrNull (items) {
	let firstItem = null

	/* get the first item if possible */
	if (Array.isArray(items) && items.length > 0) {
		firstItem = items[0]
	}

	return firstItem
}

/**
 * Helper function to handle errors while sending the first message in queue.
 *
 * @param {string} errorMessage
 * @param {StorageManager} sm
 * @param {Array} values
 * @param {Object} queueItem
 * @param {boolean} silentMode
 * @param {function} dispatch
 */
function handleMessageSendErrorForFirstItem (errorMessage, sm, values, queueItem, silentMode, dispatch) {
	// Inform the user about send errors if applicable.
	if (!silentMode) {
		dispatch(createModal(
			i18n.t('TXT_ARTICLE_SEND_ERROR.label'),
			errorMessage
		))
	}
	// Always log to console.
	Logger.errorOnce(
		'ARTICLE SEND',
		i18n.t('TXT_ARTICLE_SEND_ERROR.label'),
		errorMessage
	)

	// Mark the item as erroneous and update the queue.
	if (!queueItem.error) {
		values[0].error = true
		sm.set(MESSAGE_QUEUE_NAMESPACE, values)
		/* sync message queue */
		dispatch(syncMessageQueue())
	}
}

export function doSendMessageQueue (silentMode = true) {
	// Todo: enable loader
	return async function (dispatch) {
		let state = store.getState()

		let sm = new StorageManager(QUEUE_NAMESPACE)

		let values = sm.get(MESSAGE_QUEUE_NAMESPACE, [])

		/* get first message if possible */
		let nextQueueItem = getFirstArrayItemOrNull(values)

		if (nextQueueItem === null) {
			if (!silentMode) {
				dispatch(createModal(
					i18n.t('TXT_GENERAL_WORD_INFO.label'),
					i18n.t('TXT_ARTICLE_SEND_NO_MESSAGE.label')
				))
			}
			return
		}

		if (!state.basic.online) {
			if (!silentMode) {
				dispatch(createModal(
					i18n.t('TXT_GENERAL_WORD_INFO.label'),
					i18n.t('TXT_ARTICLE_SEND_OFFLINE.label')
				))
			}
			return
		}

		/* iterate all the messages */
		while (nextQueueItem !== null) {
			let data

			/* send mail to glamus */
			try {
				data = await doSendToGlamus(nextQueueItem.emailTo, nextQueueItem.emailMessage, nextQueueItem.ids)
			} catch (e) {
				handleMessageSendErrorForFirstItem(e.message, sm, values, nextQueueItem, silentMode, dispatch)
				return
			}

			/* handle erroneous result */
			if (!data.sent) {
				handleMessageSendErrorForFirstItem(data.response.data.message, sm, values, nextQueueItem, silentMode, dispatch)
				return
			}

			/* delete first message from message queue (values) */
			values.shift()

			/* set new message queue */
			sm.set(MESSAGE_QUEUE_NAMESPACE, values)

			/* sync message queue */
			dispatch(syncMessageQueue())

			/* get first message or reset next queue item */
			nextQueueItem = getFirstArrayItemOrNull(values)
		}

		if (!silentMode) {
			dispatch(createModal(
				i18n.t('TXT_GENERAL_WORD_SUCCESS.label'),
				i18n.t('TXT_ARTICLE_SEND_SENT.label')
			))
		}
	}
}

/**
 * If the first message queue item is erroneous,
 * remove it and requeue it as last item.
 * This is intended as a way to unblock sending for other messages.
 *
 * @return {Function}
 */
export function requeueFirstArticleIfErroneous () {
	return function (dispatch) {
		/* get storage manager for message queue */
		let sm = new StorageManager(QUEUE_NAMESPACE)

		/* get message queue items */
		let values = sm.get(MESSAGE_QUEUE_NAMESPACE, [])

		/* get first message if possible */
		let nextQueueItem = getFirstArrayItemOrNull(values)

		/* guard: abort if there is no nextQueueItem */
		if (nextQueueItem === null) {
			return
		}

		/* guard: do not requeue the item if it has no errors */
		if (!nextQueueItem.error) {
			return
		}

		/* delete first message from message queue (values) */
		values.shift()

		/* ... and re-add it as last item */
		values.push(nextQueueItem)

		/* set new message queue */
		sm.set(MESSAGE_QUEUE_NAMESPACE, values)

		dispatch(syncMessageQueue())
		dispatch(doSendMessageQueue())
	}
}
