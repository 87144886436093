/* global __DEV__ */
import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import MessageQueueController from './message-queue.controller'
import routes from '../../utils/routes'
import NotFoundController from '../general/not-found.controller'

class MessageQueueIndex extends Component {
	render () {
		return (
			<Switch>
				<Route exact path={routes.messageQueueList.path} component={MessageQueueController}/>
				<Route component={NotFoundController} />
			</Switch>
		)
	}
}

if (__DEV__) {
	MessageQueueIndex.propTypes = {}
}

export default MessageQueueIndex
