/* global __DEV__ */
import React, { Fragment } from 'react'
import propTypes from 'prop-types'

function FullscreenloaderView ({ message }) {
	const headline = message === ''
		? null
		: (
			<Fragment>
				<h2 className='m-load-indicator__message a-headline a-headline--h1'>{message}</h2>
			</Fragment>
		)

	return (
		<Fragment>
			<div className={'m-load-indicator'}>
				<div className={'m-load-indicator__inner'}>
					{headline}
					<div className="m-load-indicator__spinner">
						<div className="m-load-indicator__bounce-1" />
						<div className="m-load-indicator__bounce-2" />
						<div className="m-load-indicator__bounce-3" />
					</div>
				</div>
			</div>
		</Fragment>
	)
}

FullscreenloaderView.defaultProps = {
	message: ''
}

if (__DEV__) {
	// Properties types validation
	FullscreenloaderView.propTypes = {
		message: propTypes.string
	}
}

// Export component
export default FullscreenloaderView
