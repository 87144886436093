/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToModalControllerComponent } from '../../utils/mappings'
import { closeModal } from '../../actions/modal.actions'
import ModalView from './views/modal.view'
import i18n from '../../i18n'

const defaultLabelConfirm = i18n.t('TXT_GENERAL_CONFIRM.label')
const defaultLabelCancel = i18n.t('TXT_GENERAL_CANCEL.label')

class ModalController extends Component {
	/**
	 * Closes the current Modal and dispatches a given action.
	 *
	 * @param e event
	 * @param {{}} action
	 */
	close (e, action) {
		let { dispatch } = this.props
		e.preventDefault()
		dispatch(closeModal())
		action && dispatch(action)
	}

	/**
	 * Closes the current Modal and dispatches the modal's confirmation action.
	 *
	 * @param e event
	 */
	confirm (e) {
		this.close(e, this.props.modal.actionConfirm)
	}

	/**
	 * Closes the current Modal and dispatches the modal's cancellation action.
	 *
	 * @param e event
	 */
	cancel (e) {
		this.close(e, this.props.modal.actionCancel)
	}

	render () {
		let { modal } = this.props
		let callbackCancel

		if (modal === null) {
			return null
		}

		callbackCancel = modal.actionCancel !== null ? this.cancel.bind(this) : null

		return (
			<ModalView
				title={modal.title}
				message={modal.message}
				callbackConfirm={this.confirm.bind(this)}
				callbackCancel={callbackCancel}
				labelConfirm={modal.labelConfirm || defaultLabelConfirm}
				labelCancel={modal.labelCancel || defaultLabelCancel}
				repetitionCount={modal.repetitionCount}
			/>
		)
	}
}

ModalController.defaultProps = {
	modal: null
}

if (__DEV__) {
	ModalController.propTypes = {
		dispatch: propTypes.func.isRequired,
		modal: propTypes.object
	}
}

export default connect(
	(state, ownProps) => mapAppStateToModalControllerComponent(state, ownProps)
)(ModalController)
