/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'

function BackButtonView (props) {
	let { disabled, handleClick } = props
	const classNames = ['m-header__button-back']

	if (disabled) {
		classNames.push('m-header__button-back--hide')
	}

	return (
		<button
			id={''}
			className={classNames.join(' ')}
			onClick={handleClick}>
			Zurück
		</button>
	)
}

BackButtonView.defaultProps = {
	disabled: false
}

if (__DEV__) {
	// Properties types validation
	BackButtonView.propTypes = {
		disabled: propTypes.bool,
		handleClick: propTypes.func.isRequired
	}
}

// Export component
export default BackButtonView
