/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToBackButtonControllerComponent } from '../../../utils/mappings'
import BackButtonView from './views/back-button.view'
import { withRouter } from 'react-router'

class BackButtonController extends Component {
	render () {
		const { localHistory, canGoBack } = this.props

		return (
			<BackButtonView
				disabled={ !canGoBack }
				handleClick={ localHistory.goBack }
			/>
		)
	}
}

if (__DEV__) {
	BackButtonController.propTypes = {
		localHistory: propTypes.object.isRequired,
		canGoBack: propTypes.bool.isRequired
	}
}

export default withRouter(connect(
	(state, ownProps) => mapAppStateToBackButtonControllerComponent(state, ownProps)
)(BackButtonController))
