/* global __DEV__ */
import React, { Component, Fragment } from 'react'
import propTypes from 'prop-types'
import SectionMenuListView from './view/section-menu-list.view'
import SectionMenuItemView from './view/section-menu-item.view'
import { setActiveSectionMenuTab } from '../../../actions/basic.actions'
import { connect } from 'react-redux'
import { mapAppStateToSectionMenuControllerComponent } from '../../../utils/mappings'

class SectionMenuController extends Component {
	constructor (props) {
		super(props)

		const sectionsWithContent = props.sections.filter(section => section.elements.length > 0)
		const initialSection = sectionsWithContent.length > 0 ? sectionsWithContent[0].name : ''

		props.dispatch(setActiveSectionMenuTab(initialSection))
	}

	handleSectionChange (sectionKey) {
		this.props.dispatch(setActiveSectionMenuTab(sectionKey))
	}

	render () {
		const { sections, activeSectionKey, hideSingleTab } = this.props

		if (sections.length === 0) {
			return null
		}

		if (sections.length === 1 && hideSingleTab) {
			return null
		}

		return (
			<Fragment>
				<SectionMenuListView>
					{sections.map((section, keyIndex) => {
						return (
							<SectionMenuItemView
								key={section.name}
								handleSectionChange={this.handleSectionChange.bind(this, section.name)}
								section={section}
								active={section.name === activeSectionKey}
							/>
						)
					})
					}
				</SectionMenuListView>
			</Fragment>
		)
	}
}

SectionMenuController.defaultProps = {
	hideSingleTab: false
}

if (__DEV__) {
	SectionMenuController.propTypes = {
		sections: propTypes.array.isRequired,
		activeSectionKey: propTypes.string.isRequired,
		hideSingleTab: propTypes.bool,

		dispatch: propTypes.func.isRequired
	}
}

export default connect(mapAppStateToSectionMenuControllerComponent)(SectionMenuController)
