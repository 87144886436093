/* global __DEV__ */
import React, { Fragment } from 'react'
import propTypes from 'prop-types'
import i18n from 'i18next'

const SettingsGuiView = (props) => {
	// Assign all properties to variables for convenience
	const {
		emails,
		showOffline,
		handleCheckboxChange,
		handleSaveSettings,
		handleRefresh
	} = props

	if (showOffline) {
		return (
			<div className="o-article-send">
				<p>{i18n.t('TXT_SETTINGS_OFFLINE.label')}</p>
				<button className="a-button a-button--center" onClick={ handleRefresh.bind(this) }>{i18n.t('TXT_GENERAL_REFRESH.label')}</button>
			</div>
		)
	}

	const Checkbox = props => (
		<input type="checkbox" {...props} />
	)

	let emailCheckboxFields = []

	for (const [index, email] of emails.entries()) {
		emailCheckboxFields.push(
			<Fragment key={'f-' + index}>
				<p>
					<Checkbox
						checked={email.active}
						onChange={handleCheckboxChange.bind(this, 'emails')}
						name="emails[]"
						value={index}
						id={'emails' + index}
					/>
					<label htmlFor={'emails' + index}>&nbsp;{email.email}</label>
				</p>
			</Fragment>
		)
	}

	return (
		<div className="o-article-send">
			<p>{i18n.t('TXT_SETTINGS_VIEW_EMAILS_TEXT.label')}</p>
			{emailCheckboxFields}
			<button className="a-button a-button--center" onClick={ handleSaveSettings.bind(this) }>{i18n.t('TXT_GENERAL_SAVE.label')}</button>
		</div>
	)
}

if (__DEV__) {
	// Properties types validation
	SettingsGuiView.propTypes = {
		emails: propTypes.array,
		showOffline: propTypes.bool,
		handleCheckboxChange: propTypes.func,
		handleSaveSettings: propTypes.func,
		handleRefresh: propTypes.func
	}
}

// Export component
export default SettingsGuiView
