import InstallationEvent from './Installation'
import Logger from '../../logger'
import {
	BUILDINFO_FILENAME,
	BUILDINFO_NAME,
	BUILT_FILE_MARKER, MAINJS_FILENAME,
	MAINJS_NAME,
	STATIC_CACHE_NAME
} from '../../../constants/names'
import { asSequence } from 'sequency'
import { Exception } from '../../errors'

export default class CachesCorruptedHandler {

	static async removeOldBuilds () {
		let log = new Logger('remove Old Builds')

		let staticAssets = await (await fetch('/asset-manifest.json')).json()

		log.log('Current static assets', staticAssets)

		let cache = await caches.open(STATIC_CACHE_NAME)
		let cacheKeys = await cache.keys()

		let buildInfoNewName = staticAssets[BUILDINFO_FILENAME]
		let mainNewName = staticAssets[MAINJS_FILENAME]

		if (buildInfoNewName === undefined || mainNewName === undefined) {
			throw new Exception('BuildInfo or Main is not defined in asset-manifest')
		}

		asSequence(cacheKeys)
			.filter(k =>
				(k.url.includes(MAINJS_NAME) || k.url.includes(BUILDINFO_NAME)) && k.url.includes(BUILT_FILE_MARKER))
			.forEach(async k => {
				if (!k.url.includes(buildInfoNewName) && !k.url.includes(mainNewName)) {
					log.log('Deleting old entity:', k)
					await cache.delete(k)
				}
			})
	}

	static async handle (urls) {
		Logger.logOnce('CachesCorruptedHandler', 'VFA_STATIC cache is not equals to asset-manifest, trying to fix')
		let inev = new InstallationEvent()
		await inev.handle(urls)
		Logger.logOnce('CachesCorruptedHandler', 'VFA_STATIC cache is fixed')
	}
}
