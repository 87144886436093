/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import connect from 'react-redux/lib/connect/connect'
import { mapAppStateToHeaderControllerComponent } from '../../utils/mappings'
import { openSideMenu } from '../../actions/basic.actions'
import HeaderView from './views/header.view'
import BackButtonController
	from '../general/menu-buttons/back-button.controller'

class HeaderController extends Component {
	onMenuButtonClick (e) {
		e.preventDefault()
		this.props.dispatch(openSideMenu(!this.props.sideMenuIsOpen))
	}

	render () {
		return (
			<HeaderView
				backButton={<BackButtonController/>}
				onMenuButtonClick={ this.onMenuButtonClick.bind(this) }
				title={ this.props.title }
			/>
		)
	}
}

if (__DEV__) {
	HeaderController.propTypes = {
		title: propTypes.string.isRequired,
		sideMenuIsOpen: propTypes.bool.isRequired,
		dispatch: propTypes.func.isRequired
	}
}

export default connect(
	(state) => mapAppStateToHeaderControllerComponent(state)
)(HeaderController)
