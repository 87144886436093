import * as types from '../constants/action-types'

/**
 * Sets an accordion's active header index
 * @param {string} accordionKey
 * @param activeTabIndex
 * @return {{type: string, index: *}}
 */
export function accordionSetActiveHeader (accordionKey, activeTabIndex) {
	return {
		type: types.ACCORDION_SET_ACTIVE_HEADER,
		accordionKey,
		activeTabIndex
	}
}
