// TODO check https://www.npmjs.com/package/moment
//   But: Do we really need that package?
class DF {
	/**
	 *
	 * @param {Date} date
	 */
	constructor (date) {
		this.date = date
	}

	static fromTimestamp (timestamp) {
		let date = new Date(timestamp)
		if (typeof timestamp === 'string' && isNaN(1 + date.getTime())) {
			date = new Date(timestamp.replace(' ', 'T') + '+02:00')
		}
		return new DF(date)
	}

	/**
	 *
	 * @return {string}
	 */
	toJJJJMMDD () {
		return `${this.date.getDate()}.${this.date.getMonth() + 1}.${this.date.getFullYear()}`
	}

	/**
	 * Converts the glamus time stamp to "german/UK" version
	 *
	 * @author Björn Hempel <bjoern.hempel@ressourcenmangel.de>
	 * @version 1.0 (2019-10-21)
	 */
	static convertGlamusTimestamp (glamusTimestamp) {
		const dateTemplate = '%(day)s.%(month)s.%(year)s %(hour)s:%(minute)s'

		let dateParts = glamusTimestamp.match(/^([0-9]{4,4})-([0-9]{2,2})-([0-9]{2,2}) ([0-9]{2,2}):([0-9]{2,2}):([0-9]{2,2})/)

		if (dateParts === null) {
			return null
		}

		return dateTemplate.replace(
			'%(day)s', dateParts[3]
		).replace(
			'%(month)s', dateParts[2]
		).replace(
			'%(year)s', dateParts[1]
		).replace(
			'%(hour)s', dateParts[4]
		).replace(
			'%(minute)s', dateParts[5]
		)
	}
}

export default DF
