/* global __DEV__, alert */
import React, { Component, Fragment } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToMessageQueueControllerComponent } from '../../utils/mappings'
import { deleteMessageQueueItem, deleteMessageQueue } from '../../actions/message-queue.actions'
import MessageQueueListView from './views/message-queue-list.view'
import MessageQueueListEmptyView from './views/message-queue-list-empty.view'
import MessageQueueListItemView from './views/message-queue-list-item.view'
import { withPageContentSections } from '../pages/page-content.controller'
import withTitle from '../header/withTitle.hoc'
import { compose } from 'redux/lib/redux'
import commonPropTypes from '../../utils/common-prop-types'
import { createModal } from '../../actions/modal.actions'
import i18n from '../../i18n'
import {
	doSendMessageQueue,
	requeueFirstArticleIfErroneous
} from '../../actions/article-send.actions'

class MessageQueueController extends Component {
	static handleRemove (id) {
		this.props.dispatch(createModal(
			i18n.t('TXT_GENERAL_ATTENTION.label'),
			i18n.t('TXT_MESSAGE_QUEUE_LIST_ITEM_CLEAR_QUESTION.label'),
			() => {
				this.props.dispatch(deleteMessageQueueItem(id))
				/* TODO: Maybe redirect to another page. */
			},
			() => { /* Nothing to do. */ },
			i18n.t('TXT_MESSAGE_QUEUE_LIST_ITEM_CLEAR_QUESTION_YES.label'),
			i18n.t('TXT_MESSAGE_QUEUE_LIST_ITEM_CLEAR_QUESTION_NO.label')
		))
	}

	handleSendMessageQueueList () {
		let silentMode = false
		this.props.dispatch(doSendMessageQueue(silentMode))
	}

	handleClearMessageQueueList () {
		this.props.dispatch(createModal(
			i18n.t('TXT_GENERAL_ATTENTION.label'),
			i18n.t('TXT_MESSAGE_QUEUE_LIST_CLEAR_QUESTION.label'),
			() => {
				this.props.dispatch(deleteMessageQueue())
				/* TODO: Maybe redirect to another page. */
			},
			() => { /* Nothing to do. */ },
			i18n.t('TXT_MESSAGE_QUEUE_LIST_CLEAR_QUESTION_YES.label'),
			i18n.t('TXT_MESSAGE_QUEUE_LIST_CLEAR_QUESTION_NO.label')
		))
	}

	handleElementRequeueing () {
		this.props.dispatch(requeueFirstArticleIfErroneous())
	}

	render () {
		const { messageQueue } = this.props

		let messageQueueListCount = messageQueue.length
		let hasErroneousMessages = false

		/* We do have an empty reading list. */
		if (messageQueue.length === 0) {
			return (
				<MessageQueueListEmptyView />
			)
		}

		return (
			<MessageQueueListView
				messageQueueListLink={messageQueueListCount > 0 ? this.handleSendMessageQueueList.bind(this) : null}
				handleClearMessageQueueList={messageQueueListCount > 0 ? this.handleClearMessageQueueList.bind(this) : null}
			>
				{messageQueue.map((message, keyIndex) => {
					let item = (
						<Fragment key={keyIndex}>
							<MessageQueueListItemView
								emailTo={message.emailTo}
								emailSubject={message.emailSubject}
								emailMessage={message.emailMessage}
								ids={message.ids}
								keyIndex={keyIndex}
								handleRemove={MessageQueueController.handleRemove.bind(this, keyIndex)}
								error={message.error}
								errorShowSkipButton={message.error && keyIndex === 0 && messageQueue.length > 1}
								handleSkipMessageClick={this.handleElementRequeueing.bind(this)}
								blockedByPreviousError={hasErroneousMessages}
							/>
						</Fragment>
					)

					if (message.error) {
						hasErroneousMessages = true
					}

					return item
				})}
			</MessageQueueListView>
		)
	}
}

if (__DEV__) {
	MessageQueueController.propTypes = {
		messageQueue: propTypes.array.isRequired,

		...commonPropTypes.mixinFromReduxConnect
	}
}

export default compose(
	connect(mapAppStateToMessageQueueControllerComponent),
	withPageContentSections,
	withTitle(null, 'TXT_MESSAGE_QUEUE_LIST_TITLE.label')
)(MessageQueueController)
