/* global ga */
import { ANALYTICS_SIGNALS } from '../analytics'
import { AsyncPromiseWithLimitedProcessingTime } from '../../utils/async-promise-with-limited-processing-time'

/**
 * Signal processor that pushes tracking data to Google Analytics.
 * Maps basic signal types to according GA hitTypes.
 *
 * @param {AnalyticsSignal} signal
 * @return {boolean}
 * @private
 */
export default function analyticsSignalProcessorGoogle (signal) {
	let processed = true
	switch (signal.name) {
		case ANALYTICS_SIGNALS.GENERIC:
			processed = _sendEvent(signal)
			break
		case ANALYTICS_SIGNALS.PAGE_VIEW:
			processed = _sendPageview(signal)
			break
		default:
			// This SIGNAL is not supported.
			// We do not sent, but keep processed = true.
			// Otherwise the queue would be blocked.
			break
	}

	// Mark signal as processed.
	return processed
}

/**
 * Sends basic pageview signal as hitType 'pageview' to Google Analytics.
 *
 * @param {AnalyticsSignal} signal
 * @return {boolean}
 * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#hit
 * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#content
 * @private
 */
function _sendPageview (signal) {
	// Map signal data to GA data structure.
	let pageviewData = {
		hitType: 'pageview',
		hostname: document.location.hostname,
		page: signal.data.pathname,
		title: ''
	}

	// Send data to GA.
	return _send(pageviewData, signal)
}

/**
 * Sends basic generic signal as hitType 'event' to Google Analytics.
 *
 * @param {AnalyticsSignal} signal
 * @return {boolean}
 * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#hit
 * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#events
 * @private
 */
function _sendEvent (signal) {
	// Map signal data to GA data structure.
	let eventData = {
		hitType: 'event',
		eventCategory: signal.name,
		eventAction: signal.data.message,
		eventLabel: '',
		eventValue: signal.nr
	}

	// Send data to GA.
	return _send(eventData, signal)
}

/**
 * Sends prepared tracking data to Google Analytics.
 *
 * @param {Object} data
 * @param {AnalyticsSignal} signal
 * @return {boolean}
 * @private
 */
function _send (data, signal) {
	return new AsyncPromiseWithLimitedProcessingTime(function (finish, cancel) {
		if (!ga.hasOwnProperty('loaded') || ga.loaded !== true) {
			cancel(new Error('ga not loaded'))
			return
		}

		/**
		 * Get's time offset between signal queueing and now via a dynamic setter.
		 *
		 * @type {number}
		 */
		data.queueTime = getSignalTSOffsetMS(signal)
		data.hitCallback = function (error, success) {
			// CAUTION (default behaviour):
			//  hitCallback is executed even if the request failed.
			//  At least it seems to wait for the request to be finished.
			//  Sometimes it even seems to be called without executing the
			//  sendHitTask.
			// CAUTION (custom sendHitTask):
			//  Our own implemenation of the sendHitTask calls this one with an
			//  Error parameter if the custom request failed.
			if (error instanceof Error) {
				cancel(error)
			} else if (success === true) {
				finish()
			}
		}

		// TODO #1
		//  Delete queue when the user withdrawls tracking consent.

		// TODO #2
		//  Save queue in local storage to send unsent signals after page
		//  reload.

		// TODO #3
		//  Skip failed entries and process others, before retrying.
		//  Otherwise one erroneous signal will block tracking forever.

		// TODO #4
		//  Add information whether the signal was sent from offline queue.

		// TODO #5
		//  Check if offset is < 4h. This is because older events are not
		//  guarateed to be processed by GA. See documentation on queueTime for
		//  more information.
		//  Otherwise do one of the following:
		//   1. Send additional "offline signals lost" event to inform about
		//      probably missing signals.
		//   2. Send it without queueTime, but with additional information
		//      about delayed tracking.

		try {
			// Sends the data.
			ga('send', data)
		} catch (e) {
			cancel(e)
		}
	}, /* handlerTimeoutMS */ 30000, /* finalizeTimeoutMS */ 100)
}

/**
 * Returns number of milliseconds between signal timestamp and now.
 *
 * @return {number}
 */
const getSignalTSOffsetMS = function (signal) {
	return (new Date().getTime()) - signal.ts
}
