/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToGenericControllerComponent } from '../../../../src/utils/mappings'
import { logout } from '../../../actions/login-process.actions'
import LogoutButtonView from './views/logout-button.view'
import analytics from "../../../analytics/analytics"

class LogoutButtonController extends Component {
	constructor (props) {
		super(props)

		// Bind once, instead of binding everytime anew while rendering.
		this.onLogoutButtonClick = this.onLogoutButtonClick.bind(this)
	}

	onLogoutButtonClick (e) {
		/* TODO: change the following lines with (DRY injury)
		 * import { LoginProcess } from './utils/login-process.js'
		 * LoginProcess.doLogout()
		 */

		this.props.dispatch(logout())
		document.body.classList.remove('menu-open')

		// Custom Tracking
		analytics.track('Logout')
	}

	render () {
		return (
			<LogoutButtonView onClick={this.onLogoutButtonClick} />
		)
	}
}

if (__DEV__) {
	LogoutButtonController.propTypes = {
		dispatch: propTypes.func.isRequired
	}
}

export default connect(
	(state, ownProps) => mapAppStateToGenericControllerComponent(state, ownProps)
)(LogoutButtonController)
