/* global __DEV__ */
import React from 'react'

function LoadableLoaderView () {
	return (
		<div className={'m-load-indicator'}>
			<div className={'m-load-indicator__inner'}>
				<div className="m-load-indicator__spinner">
					<div className="m-load-indicator__bounce-1"></div>
					<div className="m-load-indicator__bounce-2"></div>
					<div className="m-load-indicator__bounce-3"></div>
				</div>
			</div>
		</div>

	)
}

LoadableLoaderView.defaultProps = {
}

if (__DEV__) {
	// Properties types validation
	LoadableLoaderView.propTypes = {
	}
}

// Export component
export default LoadableLoaderView
