import { Exception } from '../utils/errors'
import AnalyticsSignal from './analytics-signal'
import { ANALYTICS_MODULE_ACTIONS } from './constants'
import StorageManager from 'js-storage-manager'

/**
 * Basic signal queue. It is used by analytics modules to store signals until
 * they get processed.
 */
export default class AnalyticsSignalQueue {
	/**
	 * Constructor.
	 *
	 * @param {function} _dispatch Message handler through which the queue can communicate with the analytics module.
	 * @param {boolean} withStorage Flag: store queue data in local storage
	 * @param {string} storageName  a unique storageName for the queue
	 */
	constructor (_dispatch, withStorage, storageName) {
		this.id = 0
		this.dispatch = _dispatch
		this._queueData = []
		this.dispatch(ANALYTICS_MODULE_ACTIONS.QUEUE_INITIALIZED, this)
		this._lastNr = -1

		this._enabled = false
		this._deleteQueueIfNotEnabled = true
		this.storage = null
		this.withStorage = withStorage === true

		this.initStorage(storageName)
	}

	/**
	 * Saves the given signals as queue data.
	 * If storage is enabled, it is automatically written to local storage too.
	 *
	 * @param queueData
	 */
	setQueueData (queueData) {
		this._queueData = queueData
		this.writeToStorage()
	}

	/**
	 * Returns the current queue data.
	 * If storage is enabled, queue data is read from local storage first.
	 * If this._deleteQueueIfNotEnabled and the queue is disabled, queue data is
	 * purged beforehand.
	 *
	 * @return {Array}
	 */
	getQueueData () {
		this.readFromStorage()
		if (!this.enabled && this._deleteQueueIfNotEnabled && this._queueData.length > 0) {
			// clear array, but keep reference
			this._queueData.splice(0, this._queueData.length)
			this.writeToStorage()
		}
		return this._queueData
	}

	/**
	 * Initializes storage, if enabled.
	 *
	 * @param storageName
	 */
	initStorage (storageName) {
		if (!this.withStorage) {
			return
		}

		this.storage = new StorageManager(storageName)
	}

	/**
	 * Copies queue data to storage.
	 */
	writeToStorage () {
		if (!this.withStorage) {
			return
		}

		this.storage.set('queue', this._queueData)
	}

	/**
	 * Copies queue data from storage.
	 */
	readFromStorage () {
		if (!this.withStorage) {
			return
		}

		this._queueData = this.storage.get('queue') || []
	}

	/**
	 * Getter that returns the length of the queue.
	 *
	 * @return {*}
	 */
	get length () {
		return this.getQueueData().length
	}

	/**
	 * Getter that dynamically returns the next free slot in the queue.
	 *
	 * @return {number}
	 */
	get nextNr () {
		this._lastNr += 1
		return this._lastNr
	}

	/**
	 * Getter that dynamically determines if the queue is enabled or not.
	 *
	 * @return {*}
	 */
	get enabled () {
		switch (typeof this._enabled) {
			case 'function':
				return this._enabled()
			case 'boolean':
				return this._enabled
			default:
				return false
		}
	}

	/**
	 * Enables/disables the queue statically.
	 *
	 * @param enabled
	 */
	toggleEnabled (enabled) {
		if (typeof enabled !== 'boolean') {
			throw new Exception('Wrong type given for AnalyticsSignalQueue.toggleEnabled. Must be "boolean".')
		}
		this._enabled = enabled
	}

	/**
	 * Sets a callback function that dynamically determines whether the queue is
	 * enabled or not.
	 * A use case would be to disable tracking initially and enable it after
	 * the user gave consent to tracking.
	 *
	 * @param func
	 */
	setEnabledCallback (func) {
		if (typeof func !== 'function') {
			throw new Exception('Wrong type given for AnalyticsSignalQueue.setEnabledCallback. Must be "function" but is "' + (typeof func) + '".')
		}
		this._enabled = func
	}

	/**
	 * Set false if existing queue data should be kept even if the queue is
	 * disabled.
	 *
	 * @param {boolean} deleteQueueIfNotEnabled
	 */
	setDeleteQueueIfNotEnabled (deleteQueueIfNotEnabled) {
		if (typeof deleteQueueIfNotEnabled !== 'boolean') {
			throw new Exception('Wrong type given for AnalyticsSignalQueue.setDeleteQueueIfNotEnabled. Must be "boolean" but is "' + (typeof deleteQueueIfNotEnabled) + '".')
		}
		this._deleteQueueIfNotEnabled = deleteQueueIfNotEnabled
	}

	/**
	 * Dispatches an action to the linked analytics module.
	 *
	 * @param action
	 */
	dispatch (action) {
		this._dispatch(action)
	}

	/**
	 * Pushes a signal to the queue.
	 *
	 * @param name
	 * @param data
	 */
	pushSignal (name, data) {
		let queueData
		if (!this.enabled) {
			return
		}
		queueData = this.getQueueData()
		queueData.push(new AnalyticsSignal(this.nextNr, name, data))
		this.setQueueData(queueData)
		this.dispatch(ANALYTICS_MODULE_ACTIONS.QUEUE_UPDATED)
	}

	/**
	 * Returns the first signal in the queue.
	 *
	 * @return {null}
	 */
	getCurrent () {
		let queueData = this.getQueueData()
		return queueData.length > 0
			? queueData[0]
			: null
	}

	/**
	 * Removes the first signal in the queue.
	 *
	 * @return {*|*}
	 */
	removeCurrent () {
		let queueData = this.getQueueData()
		let current = queueData.shift()
		this.setQueueData(queueData)

		return current
	}
}
