/* global __DEV__ */
import React, { Fragment } from 'react'
import AccordionController from '../../general/accordion/accordion.controller'
import SliderController from '../../general/slider/slider.controller'
import AccordionWithTeaserController
	from '../../general/accordion/accordion-with-teaser.controller'
import routes from '../../../utils/routes'
import { Link } from 'react-router-dom'

function DevMoleculesView (props) {
	return (
		<div className='o-detail-view'>
			<ul>
				<li>Dummy-Content</li>
				<li>Siehe <span style={{ fontFamily: 'monospace' }}>src/components/dev/views/dev-molecules.view.js</span> für View, um weitere Elemente hinzuzufügen.</li>
				<li>Siehe <span style={{ fontFamily: 'monospace' }}>src/components/dev/dev-molecules.controller.js</span> für Controller, um der View Dummy-Daten mitzugeben und Zustände/Daten zu steuern.</li>
				<li>Siehe <span style={{ fontFamily: 'monospace' }}>src/components/dev/index.js</span> für Dev-Router, um weitere dev-*-Seiten hinzuzufügen.</li>
			</ul>

			<hr />

			{/* HEADLINES */}
			<h2 className="a-headline a-headline--opener">Überschriften</h2>

			<h1 className="a-headline a-headline--h1">Headline H1</h1>
			<h2 className="a-headline a-headline--h2">Headline H2</h2>
			<h3 className="a-headline a-headline--h3">Headline H3</h3>

			<hr />

			{/* Text */}
			<h2 className="a-headline a-headline--opener">Text</h2>

			<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no se</p>
			<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no se</p>

			<hr />

			{/* LISTS */}
			<h2 className="a-headline a-headline--opener">Listen</h2>

			<ul className="a-list">
				<li>Listen Punkt 1</li>
				<li>Listen Punkt 2</li>
				<li>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt</li>
			</ul>

			<ol className="a-list">
				<li>Listen Punkt 1</li>
				<li>Listen Punkt 2</li>
				<li>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt</li>
			</ol>

			<ul className="a-list a-list--download">
				<li><a href="#">Download Liste</a></li>
				<li><a href="#">Spezifikationen.pdf</a></li>
				<li><a href="#">Medikament.exe</a></li>
				<li><a href="#">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt</a></li>
			</ul>

			<hr />

			{/* LINKS */}
			<h2 className="a-headline a-headline--opener">Links</h2>

			<a className="a-link a-link--extern" href="#">Liste der Pharma-Unternehmen (Extern)</a>
			<a className="a-link a-link--extern" href="#">consetetur sadipscing elitr, sed diam nonumy eirmod (Extern)</a>

			<hr />

			{/* TAGCLOUD */}
			<h2 className="a-headline a-headline--opener">Tagcloud</h2>
			<h2 className="a-headline a-headline--h2">Mehr zum Thema</h2>
			<a className="a-button a-button--small a-button--inline" href="#">Schlüsselwort</a>
			<a className="a-button a-button--small a-button--inline" href="#">Tagcloud</a>
			<a className="a-button a-button--small a-button--inline" href="#">Schlagwort</a>
			<a className="a-button a-button--small a-button--inline" href="#">Begriff</a>

			<hr />

			{/* BUTTONS */}
			<h2 className="a-headline a-headline--opener">Buttons</h2>

			<button className="a-button">Normal</button>
			<button className="a-button a-button--light">Light</button>

			<a className="a-button a-button--small" href="#">Small</a>
			<a className="a-button a-button--small a-button--light" href="#">Small | Light</a>

			<a className="a-button a-button--small a-button--center" href="#">Center</a>

			<hr />

			{/* ACCORDION */}
			<h2 className="a-headline a-headline--opener">Akkordion</h2>

			<AccordionController data={[
				{ title: 'Akkordeontitel', content: 'Multiplen Sklerose (MS) können bei vielen Patienten einen Teil der Krankheitsschübe verhindern und das Fortschreiten der Erkrankung verlangsamen – allerdings nicht bei allen.' },
				{ title: 'Akkordeontitel Zweizeilig Mindestens', content: 'Multiplen Sklerose (MS) können bei vielen Patienten einen Teil der Krankheitsschübe verhindern und das Fortschreiten der Erkrankung verlangsamen – allerdings nicht bei allen.' }]}
			/>

			<hr />

			{/* SLIDER */}
			<h2 className="a-headline a-headline--opener">SLIDER (NEWS)</h2>

			<SliderController additionalClassName='m-slider--darken' data={[
				{
					link        : 'https://www.vfa.de/',
					image       : 'https://cmzone-vzbqbxhynotw9ion96xv.netdna-ssl.com/wp-content/uploads/2017/07/the-wall-wallpaper.jpg',
					headline    : 'Fortschritte in der Krebstherapie',

					text        : 'Bei etlichen Krebsarten gab es ganz erhebliche Behandlungs-Fortschritte in den vergangenen 15 Jahren.'
				},
				{
					link        : 'https://www.vfa.de/',
					image       : 'https://cmzone-vzbqbxhynotw9ion96xv.netdna-ssl.com/wp-content/uploads/2017/07/the-wall-wallpaper.jpg',
					headline    : 'Fortschritte in der Krebstherapie 2',
					text        : 'Bei etlichen Krebsarten gab es ganz erhebliche Behandlungs-Fortschritte in den vergangenen 15 Jahren.'
				},
				{
					link        : 'https://www.vfa.de/',
					image       : 'https://cmzone-vzbqbxhynotw9ion96xv.netdna-ssl.com/wp-content/uploads/2017/07/the-wall-wallpaper.jpg',
					headline    : 'Fortschritte in der Krebstherapie 3',
					text        : 'Bei etlichen Krebsarten gab es ganz erhebliche Behandlungs-Fortschritte in den vergangenen 15 Jahren.'
				}
			]} />

			<hr />

			{/* SLIDER */}
			<h2 className="a-headline a-headline--opener">SLIDER</h2>

			<SliderController additionalClassName='m-slider--content' data={[
				{
					image       : 'https://cmzone-vzbqbxhynotw9ion96xv.netdna-ssl.com/wp-content/uploads/2017/07/the-wall-wallpaper.jpg',
					headline	: 'Überschrift',
					text        : 'Forschungsprojekt Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, n'
				},
				{
					image       : 'https://cmzone-vzbqbxhynotw9ion96xv.netdna-ssl.com/wp-content/uploads/2017/07/the-wall-wallpaper.jpg',
					text        : 'Bildunterschrift'
				},
				{
					image       : 'https://cmzone-vzbqbxhynotw9ion96xv.netdna-ssl.com/wp-content/uploads/2017/07/the-wall-wallpaper.jpg',
					text        : 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr'
				}
			]} />

			<hr />

			{/* IFRAME */}
			<h2 className="a-headline a-headline--opener">iFrame</h2>

			<div className="a-iframe">
				<iframe width="100%" src="https://www.youtube.com/embed/QsbfpgI1d64" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" />
			</div>

			<div className="a-iframe">
				<iframe width="100%" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/214363415&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true" />
			</div>

			<hr />

			{/* AKTEURE */}
			<h2 className="a-headline a-headline--opener">Akteure</h2>

			<AccordionWithTeaserController
				title='Deutscher Bundestag - Ausschuss für Forschung'
				initiallyClosed
				data={
					[
						{
							title: 'Mitglieder',
							content: (
								<Fragment>
									<div className="a-list-item">
										<Link to={routes.categoriesTopic.build(10, 't216')}>Rüddel, Erwin</Link>
									</div>
									<div className="a-list-item">
										<Link to={routes.categoriesTopic.build(10, 't216')}>Kippels, Dr. Georg</Link>
									</div>
								</Fragment>
							)
						}
					]
				}
			/>

			<AccordionWithTeaserController
				title='Deutscher Bundestag - Ausschuss für Forschung'
				initiallyClosed
				data={
					[
						{
							title: 'Mitglieder',
							content: (
								<Fragment>
									<div className="a-list-item">
										<Link to={routes.categoriesTopic.build(10, 't216')}>Rüddel, Erwin</Link>
									</div>
									<div className="a-list-item">
										<Link to={routes.categoriesTopic.build(10, 't216')}>Kippels, Dr. Georg</Link>
									</div>
								</Fragment>
							)
						}
					]
				}
			/>

			{/* UNSAFE VERSION */}
			<AccordionWithTeaserController
				title='Deutscher Bundestag - Ausschuss für Forschung'
				initiallyClosed
				unsafeParseContent
				data={
					[
						{
							title: 'Mitglieder',
							content: '<div class="a-list-item"><a class="" href="/categories/10/t216">Rüddel, Erwin</a></div><div class="a-list-item"><a class="" href="/categories/10/t216">Kippels, Dr. Georg</a></div>'
						}
					]
				}
			/>

			{/* COOKIE */}
			<div className="m-cookie">
				<div className="m-cookie__inner">
					<span className="m-cookie__message">Diese Website nutzt Cookies, um Ihnen ein bestmögliche Benutzererfahrung bieten zu können. <a href={'#'}>mehr erfahren</a></span>
					<div className={'m-cookie__interaction'}>
						<a className={'m-cookie__allow a-button a-button--light'}>Verstanden</a>
						<a className={'m-cookie__close'}>Schließen</a>
					</div>
				</div>
			</div>

		</div>

	)
}

if (__DEV__) {
	// Properties types validation
	DevMoleculesView.propTypes = {
	}
}

// Export component
export default DevMoleculesView
