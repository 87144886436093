/* global __DEV__ */
import React, { Fragment } from 'react'
import propTypes from 'prop-types'
import ImagePreloader from '../../general/image-preloader'
import Img from 'react-image'

function ContentElementImageView (props) {
	// TODO render title
	// TODO implement intelligent general ResponsiveImageController that gets
	//  the images array and makes a selection on it's own.
	// TODO use <ResponsiveImageController images={images} /> instead of <Img />
	return (
		<Fragment>
			<figure className="a-image">
				<picture>
					<source media="(max-width: 1024px)" srcSet={props.images[1].url} />
					<source media="(max-width: 767px)" srcSet={props.images[1].url} />
					<img src={props.images[1].url} className="" srcSet={props.images[1].url} loader={(<ImagePreloader/>)} />
				</picture>
				<figcaption>{props.caption}</figcaption>
			</figure>
		</Fragment>
	)
}

ContentElementImageView.defaultProps = {
	caption: '',
	title: '',
	url: ''
}

if (__DEV__) {
	// Properties types validation
	ContentElementImageView.propTypes = {
		caption: propTypes.string,
		title: propTypes.string.isRequired,
		images: propTypes.array.isRequired
	}
}

// Export component
export default ContentElementImageView
