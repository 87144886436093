/* global __DEV__ */
/* eslint-disable no-fallthrough */
import * as types from '../constants/action-types'
import propTypes from 'prop-types'
import commonPropTypes from '../utils/common-prop-types'

const name = 'content'

function updateEntityUpdatesInformation (contentType, state, handleDependencies = true) {
	state.updatesInformation[contentType].quantity = countUpdatedContentItems(state, contentType)

	if (!handleDependencies) {
		return
	}

	switch (contentType) {
		case 'topics':
			updateCategoryUpdatesInformation(state)
			// TODO remove as soon as news are not copies of topics anymore
			updateEntityUpdatesInformation('news', state, false)
			break

		case 'news':
			// TODO remove as soon as news are not copies of topics anymore
			updateEntityUpdatesInformation('topics', state, false)
			// TODO remove as soon as news are not copies of topics anymore
			updateCategoryUpdatesInformation(state)
			break
	}
}

function countUpdatedContentItems (state, contentType) {
	return Object.keys(state[contentType])
		.map((id) => {
			return state[contentType][id].isUpdated ? 1 : 0
		})
		.reduce((a, b) => a + b, 0)
}

function updateCategoryUpdatesInformation (state) {
	const updatedCategoryTopicsQuantity = {}

	Object.keys(state.topics).map((topicId) => {
		const topic = state.topics[topicId]
		if (topic.isUpdated) {
			updatedCategoryTopicsQuantity[topic.category] = (updatedCategoryTopicsQuantity[topic.category] || 0) + 1
		}
	})

	state.updatesInformation.categories.topicQuantities = updatedCategoryTopicsQuantity
}

/**
 * We use a function here to prevent passing an object reference.
 *
 * Otherwise this could happen:
 * 1. Use initialState reference for initialisation. state.foo is now a
 *    reference to initialState.foo if foo is an object or array.
 * 2. Some action sets state.foo.bar.
 *    This is where the problem occurs: initialState.foo.bar now has been
 *    changed.
 * 3. Some other action wants to reset state.foo by assigning initialState.foo.
 *    But instead of having the actual initial value of state.foo.bar it now has
 *    the value that got set in 2.)
 *
 * @return {Object}
 */
const getInitialState = () => {
	return {
		categories: {},
		topics: {},
		news: {},
		readingList: {},
		readingListCount: 0,
		messageQueueListCount: 0,
		search: {
			request: '',
			result: {},
			isOnline: navigator.onLine
		},
		stakeholders: {},
		pressReleases: {},
		tags: {},
		sliderElements: [],
		updatesInformation: {
			topics: { quantity: 0 },
			news: { quantity: 0 },
			pressReleases: { quantity: 0 },
			stakeholders: { quantity: 0 },
			categories: {
				topicQuantities: {}
			}
		},
		timestamp: 0
	}
}

function getReadingListCount (readingList) {
	if (!readingList) {
		return 0
	}

	return Object.keys(readingList)
		.map(key => readingList[key].length)
		.reduce(
			(a, b) => {
				return a + b
			},
			0
		)
}

function getMessageQueueListCount (messageQueueList) {
	return 99

	if (!messageQueueList) {
		return 0
	}

	return 10
}

const reducer = function (state = getInitialState(), action) {
	let newState = { ...state }
	switch (action.type) {
		case types.INJECT_DATA:
			newState = {
				...newState,
				...action.contentData
			}

			/* add last timestamp to state */
			let localStorage = JSON.parse(window.localStorage.storage)
			if ('CONTENT_STORAGE_NAMESPACE' in localStorage &&
				'CONTENT' in localStorage.CONTENT_STORAGE_NAMESPACE &&
				'timestamp' in localStorage.CONTENT_STORAGE_NAMESPACE.CONTENT
			) {
				newState.timestamp = localStorage.CONTENT_STORAGE_NAMESPACE.CONTENT.timestamp
			} else if (action.timestamp) {
				/* if timestamp from localstorage is empty set the last one (first update) */
				newState.timestamp = action.timestamp
			}

			return newState

		// TODO: Update content in reading list
		case types.INJECT_DATA_UPDATES:
			newState = {
				...newState,
				...action.contentData
			}
			return newState

		case types.SET_TIMESTAMP:
			newState.timestamp = action.timestamp
			return newState

		// TODO refactor from temp data pollution to own updates tracking
		case types.ENTITY_VIEWED:
			const contentType = action.contentType
			let item = newState[contentType][action.id]

			if (!item.isUpdated) {
				return state
			}

			item.isUpdated = false

			updateEntityUpdatesInformation(contentType, newState)

			newState.updatesInformation = { ...newState.updatesInformation }

			return {
				...newState
			}

		// READING LIST
		// ------------
		case types.INJECT_READING_LIST:
			newState.readingList = action.readingList
			return {
				...newState,
				readingListCount: getReadingListCount(newState.readingList)
			}

		// MESSAGE QUEUE
		// ------------
		case types.INJECT_MESSAGE_QUEUE_LIST:
			newState.messageQueueList = action.messageQueueList
			return {
				...newState,
				messageQueueListCount: getMessageQueueListCount(newState.messageQueueList)
			}

		// SEARCH
		// ------
		case types.SET_SEARCH:
			return {
				...newState,
				search: {
					request: action.searchRequest,
					result: action.searchResult
				}
			}

		case types.SET_SEARCH_REQUEST:
			return {
				...newState,
				search: {
					...newState.search,
					request: action.searchRequest
				}
			}

		case types.SET_SEARCH_RESULT:
			return {
				...newState,
				search: {
					...newState.search,
					result: action.searchResult,
					isOnline: action.isOnline
				}
			}

		case types.UNSET_SEARCH:
			return {
				...newState,
				search: {
					request: '',
					result: {}
				}
			}

		case types.SET_ENTITY_LOADING:
			newState[action.contentType][action.id] = newState[action.contentType][action.id] || {
				id: action.id,
				isDummy: true
			}
			newState[action.contentType][action.id].isLoading = true
			return newState

		case types.SET_ENTITY_LOADED:
			if (action.entry === null) {
				if (newState[action.contentType][action.id].isDummy) {
					delete newState[action.contentType][action.id]
				} else {
					newState[action.contentType][action.id].isLoading = false
				}
			} else {
				newState[action.contentType][action.id] = action.entry
			}
			return newState

		default:
			return state
	}
}

const reducerPropTypes = __DEV__ && propTypes.exact({
	_tmp: propTypes.object,
	categories: commonPropTypes.content.categoryCollection.isRequired,
	topics: commonPropTypes.content.topicCollection.isRequired,
	news: commonPropTypes.content.newsCollection.isRequired,
	pressReleases: commonPropTypes.content.pressReleaseCollection.isRequired,
	stakeholders: commonPropTypes.content.stakeholderCollection.isRequired,
	tags: commonPropTypes.content.tagCollection.isRequired,
	readingList: propTypes.objectOf(
		propTypes.arrayOf(
			// Some content ids are numeric and some are strings.
			propTypes.oneOfType([
				propTypes.string,
				propTypes.number
			])
		).isRequired
	).isRequired,
	sliderElements: commonPropTypes.content.sliderElements.isRequired,

	// TODO move to another reducer
	readingListCount: propTypes.number.isRequired,
	// TODO move to another reducer
	messageQueueListCount: propTypes.number.isRequired,
	// TODO move to another reducer
	search: propTypes.exact({
		request: propTypes.string.isRequired,
		// TODO TAG_STATE_NORMALIZATION complete propTypes definition
		result: propTypes.object.isRequired,
		isOnline: propTypes.bool.isRequired
	}).isRequired,
	updatesInformation: propTypes.exact({
		topics: commonPropTypes.content.updatesInformationElement.isRequired,
		news: commonPropTypes.content.updatesInformationElement.isRequired,
		pressReleases: commonPropTypes.content.updatesInformationElement.isRequired,
		stakeholders: commonPropTypes.content.updatesInformationElement.isRequired,
		categories: propTypes.exact({
			topicQuantities: propTypes.objectOf(
				propTypes.number
			).isRequired
		}).isRequired
	}).isRequired,
	timestamp: propTypes.number.isRequired
}).isRequired

const contentReducerDefiniton = {
	name,
	/**
	 * We want to be able to use reducerDefinition.initialState without
	 * polluting initial state.
	 *
	 * @return {Object}
	 */
	get initialState () {
		return getInitialState()
	},
	reducer,
	reducerPropTypes
}

export default contentReducerDefiniton
