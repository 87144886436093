/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'
import { imageInfoPropType } from '../content-element-image.controller'
import SliderController from '../../general/slider/slider.controller'

function ContentElementGalleryView (props) {
	let { elements } = props

	return (
		<SliderController
			additionalClassName='m-slider--content' data={ elements }/>
	)
}

ContentElementGalleryView.defaultProps = {
	elements: []
}

if (__DEV__) {
	// Properties types validation
	ContentElementGalleryView.propTypes = {
		elements: propTypes.array
	}
}

// Export component
export default ContentElementGalleryView
