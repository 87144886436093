/* global __DEV__ */
import React, { Component } from 'react'
import DevExamplesView from './views/dev-examples.view'
import { compose } from 'redux/lib/redux'
import withTitle from '../header/withTitle.hoc'
import { connect } from 'react-redux'
import { mapAppStateToDevExamplesControllerComponent } from '../../utils/mappings'
import commonPropTypes from '../../utils/common-prop-types'
import {
	acceptCookieNotice, declineCookieNotice,
	unsetCookieNotice
} from '../../actions/basic.actions'

class DevExamplesController extends Component {
	acceptCookies (e) {
		e.preventDefault()
		this.props.dispatch(acceptCookieNotice())
	}

	declineCookies (e) {
		e.preventDefault()
		this.props.dispatch(declineCookieNotice())
	}

	unsetCookies (e) {
		e.preventDefault()
		this.props.dispatch(unsetCookieNotice())
	}

	render () {
		return (
			<DevExamplesView
				cookieNotice={this.props.cookieNotice}
				acceptCookies={this.acceptCookies.bind(this)}
				declineCookies={this.declineCookies.bind(this)}
				unsetCookies={this.unsetCookies.bind(this)}
			/>
		)
	}
}

if (__DEV__) {
	DevExamplesController.propTypes = {
		cookieNotice: commonPropTypes.cookieNotice.isRequired,
		...commonPropTypes.mixinFromReduxConnect
	}
}

export default compose(
	connect(mapAppStateToDevExamplesControllerComponent),
	withTitle(null, 'TXT_DEV_EXAMPLES_TITLE.label')
)(DevExamplesController)
