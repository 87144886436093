import { LoginProcess } from '../../../../utils/login-process'

export default class ResponseCodeCheckers {
	/**
	 * @method isRequestFailed
	 * @description Checks 299 status (REQUEST_FAILED)
	 * @param response
	 * @return boolean
	 */
	static isRequestFailed (response) {
		switch (true) {
			/* unauthorized request */
			case 'unauthorized' in response:
				LoginProcess.logout(true)
				return true

			/* sw.constants.js RESPONSE_FLAGS.REQUEST_FAILED_RESPONSE_STATUS */
			case 299:
				/* nothing to do */
				return true

			/* unauthorized request */
			case 401:
				LoginProcess.logout(true)
				return true

			default:
				return false
		}
	}
}
