/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'
import '../../../../i18n'

function SectionMenuItemView (props) {
	// Assign all properties to variables for convenience
	const { handleSectionChange, section, active } = props

	return (
		<button
			onClick={handleSectionChange}
			className={'m-tab-header__tabs' + (active ? ' m-tab-header__tabs--active' : '')}>{section.name}</button>
	)
}

if (__DEV__) {
	// Properties types validation
	SectionMenuItemView.propTypes = {
		active: propTypes.bool,
		handleSectionChange: propTypes.func.isRequired,
		section: propTypes.object
	}
}

// Export component
export default SectionMenuItemView
