/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import SliderView from './views/slider.view'
import SlideView from './views/slide.view'
import commonPropTypes from '../../../utils/common-prop-types'
import { connect } from 'react-redux'
import { mapAppStateToSliderControllerComponent } from '../../../utils/mappings'
import { TRANSITIONS } from '../../../reducers/transitions.reducer'
import { withRouter } from 'react-router'
import { compose } from 'redux/lib/redux'
import sliderAdaptiveHeightFix from './sliderAdaptiveHeightFix.hoc'

class SliderController extends Component {
	render () {
		let { data, additionalClassName, children, pageTransitionState, slickOnInit, slickOnReInit, slickOnLazyLoad } = this.props
		let settings /* = (
			({ dots, infinite, speed, slidesToShow, slidesToScroll }) => ({ dots, infinite, speed, slidesToShow, slidesToScroll })
		)(this.props) */

		// fake settings
		settings = {
			onInit: slickOnInit,
			onReInit: slickOnReInit,
			onLazyLoad: slickOnLazyLoad,
			// desktop > 1024
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			centerMode: false,
			slidesToScroll: 1,
			centerPadding: '0px',
			adaptiveHeight: true,

			responsive: [
				// mobile settings 0 -767
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						centerMode: false
					}
				},

				// tablet 768 - 1024
				{
					breakpoint: 1023,
					settings: {
						slidesToShow: 2,
						centerMode: false
					}
				}

			]
		}

		return (
			<SliderView
				data={data}
				settings={settings}
				additionalClassName={additionalClassName}
				showPlaceholder={[TRANSITIONS.PAGE.STATES.ENTERING, TRANSITIONS.PAGE.STATES.EXITED].indexOf(pageTransitionState) > -1}
			>
				{children}
			</SliderView>
		)
	}
}

SliderController.defaultProps = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				centerMode: true
			}
		}
	],
	additionalClassName: '',

	data: [],

	slickOnInit: null,
	slickOnReInit: null,
	slickOnLazyLoad: null
}

if (__DEV__) {
	SliderController.propTypes = {
		children: commonPropTypes.reactChildren,
		dots: propTypes.bool,
		infinite: propTypes.bool,
		speed: propTypes.number,
		slidesToShow: propTypes.number,
		slidesToScroll: propTypes.number,
		pageTransitionState: propTypes.string,

		additionalClassName: propTypes.string,

		data: propTypes.arrayOf(
			propTypes.shape(
				(({ image, link, headline, text }) => ({ image, link, headline, text }))(SlideView.propTypes)
			)
		),

		slickOnInit: propTypes.func,
		slickOnReInit: propTypes.func,
		slickOnLazyLoad: propTypes.func
	}
}

export default compose(
	withRouter,
	connect(mapAppStateToSliderControllerComponent),
	sliderAdaptiveHeightFix()
)(SliderController)
