/* global __DEV__ */
/* eslint-disable no-fallthrough */
import * as types from '../constants/action-types'
import propTypes from 'prop-types'

const name = 'accordion'

/**
 * This sets the maximum number of remembered accordion keys. With this we want
 * to prevent memory leaking by possibly dynamically set accordion keys.
 *
 * @type {number}
 */
const ACCORDION_KEYS_STACK_SIZE = 10

/**
 * Removes the saved activeTabIndex for that key from state.
 *
 * @param {string} accordionKey
 * @param {Object} state
 */
function removeKeyFromState (accordionKey, state) {
	delete state.activeTabIndex[accordionKey]
}

/**
 * Updates the keys stack and limits the number of saved tab indexes.
 *
 * @param accordionKey
 * @param state
 */
function updateKeysStack (accordionKey, state) {
	let keyIndex = state.keysStack.indexOf(accordionKey)

	// remove from stack
	if (keyIndex > -1) {
		state.keysStack.splice(keyIndex, 1)
	}

	// push to stack (new order)
	state.keysStack.push(accordionKey)

	// remove oldest items from stack and state
	if (state.keysStack.length > ACCORDION_KEYS_STACK_SIZE) {
		let oldKeys = state.keysStack.splice(0, state.keysStack.length - ACCORDION_KEYS_STACK_SIZE)
		oldKeys.forEach((key) => {
			removeKeyFromState(key, state)
		})
	}
}

/**
 * We use a function here to prevent passing an object reference.
 *
 * Otherwise this could happen:
 * 1. Use initialState reference for initialisation. state.foo is now a
 *    reference to initialState.foo if foo is an object or array.
 * 2. Some action sets state.foo.bar.
 *    This is where the problem occurs: initialState.foo.bar now has been
 *    changed.
 * 3. Some other action wants to reset state.foo by assigning initialState.foo.
 *    But instead of having the actual initial value of state.foo.bar it now has
 *    the value that got set in 2.)
 *
 * @return {Object}
 */
const getInitialState = () => {
	return {
		keysStack: [],
		activeTabIndex: {}
	}
}

const reducer = function (state = getInitialState(), action) {
	let newState = { ...state }
	switch (action.type) {
		case types.ACCORDION_SET_ACTIVE_HEADER:
			updateKeysStack(action.accordionKey, newState)
			newState.activeTabIndex[action.accordionKey] = action.activeTabIndex
			return newState

		default:
			return state
	}
}

const reducerPropTypes = __DEV__ && propTypes.exact({
	keysStack: propTypes.arrayOf(
		propTypes.string
	).isRequired,
	activeTabIndex: propTypes.objectOf(
		propTypes.number
	).isRequired
}).isRequired

const accordionReducerDefinition = {
	name,
	/**
	 * We want to be able to use reducerDefinition.initialState without
	 * polluting initial state.
	 *
	 * @return {Object}
	 */
	get initialState () {
		return getInitialState()
	},
	reducer,
	reducerPropTypes
}

export default accordionReducerDefinition
