/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'

function NewsListItemView (props) {
	// Assign all properties to variables for convenience
	const { title, link, classes, imageBgUrl, date } = props

	// news backgroundImage // Dummy Image
	var teaserStyle = {
		backgroundImage: 'url(' + imageBgUrl + ')'
	}

	return (
		<div className="m-teaser m-teaser--horizontal" style={teaserStyle}>

			<Link className={classes} to={link}>
				<img className="m-teaser__image" src={imageBgUrl} alt='' />
			</Link>

			<div className="m-teaser__inner">
				<Link data-cy={'news-list-item'} className={classes} to={link}>
					<span className="m-teaser__date">{date}</span>
					<span className="m-teaser__title">{title}</span>
				</Link>
			</div>
		</div>
	)
}

if (__DEV__) {
	// Properties types validation
	NewsListItemView.propTypes = {
		title: propTypes.string,
		link: propTypes.string,
		date: propTypes.string,
		classes: propTypes.array,
		imageBgUrl: propTypes.string
	}
}

// Export component
export default NewsListItemView
