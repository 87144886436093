/* global __DEV__ */
import React from 'react'
import commonPropTypes from '../../../utils/common-prop-types'
import LinkAll from '../../general/link-all'

function ContentElementLinkView (props) {
	let { title, url, internal } = props
	let classNames = ['a-link']
	let additionalProps = {}

	if (!internal) {
		classNames.push('a-link--extern')
		additionalProps.target = '_blank'
	}

	return <LinkAll {...additionalProps} className={classNames.join(' ')} to={url}>{title}</LinkAll>
}

ContentElementLinkView.defaultProps = {
	title: '',
	url: '',
	internal: false
}

if (__DEV__) {
	// Properties types validation
	ContentElementLinkView.propTypes = {
		...commonPropTypes.mixinPreparedLink
	}
}

// Export component
export default ContentElementLinkView
