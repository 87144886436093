/* global __DEV__ */
import React, { Fragment } from 'react'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'
import routes from '../../../../utils/routes'

// eslint-disable-next-line react/display-name
export function SearchInputViewMenu (props) {
	const { handleSearchSubmit, handleSearchChange, searchRequest, suggestionsComponent, handleEnterClick } = props
	return (
		<Fragment>

			<div className="m-search-box margin-top-small">

				<input
					onKeyDown={ handleEnterClick.bind(this) }
					className="a-input"
					list="search-word-suggestions-menu"
					type='text'
					value={ searchRequest }
					onChange={ handleSearchChange.bind(this) }
					placeholder="Suchbegriff"
				/>
				{ suggestionsComponent }

				<Link
					to={ routes.search.build() }
					className="m-search-box__submit"
					onClick={ handleSearchSubmit.bind(this) }/>

			</div>
		</Fragment>
	)
}

if (__DEV__) {
	SearchInputViewMenu.propTypes = {
		suggestionsComponent: propTypes.object,
		searchRequest: propTypes.string,
		handleSearchSubmit: propTypes.func,
		handleSearchChange: propTypes.func,
		handleEnterClick: propTypes.func
	}
}

export default SearchInputViewMenu
