/* global __DEV__ */
import React, { Component } from 'react'
import routes from '../../utils/routes'
import { Route, Switch } from 'react-router-dom'
import HomeController from '../home/home.controller'
import NotFoundController from '../general/not-found.controller'

class HomeIndex extends Component {
	render () {
		return (
			<Switch>
				<Route exact path={routes.root.path} component={HomeController}/>
				<Route component={NotFoundController} />
			</Switch>
		)
	}
}

if (__DEV__) {
	HomeIndex.propTypes = {}
}

export default HomeIndex
