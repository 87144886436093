/* global __DEV__ */
import React, { Component, Fragment } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToGenericControllerComponent } from '../../../utils/mappings'
import Img from 'react-image'
import ImagePreloader from '../../general/image-preloader'
import Parser from 'html-react-parser'
import ContentElementSubheadlineView
	from '../views/content-element-subheadline.view'

class ContentElementAdacController extends Component {
	render () {
		let { data } = this.props

		let contentElement

		switch (data.type) {
			case 'image':
				// TODO: Nice preload: https://www.npmjs.com/package/react-image
				contentElement = (
					<Img
						className={'topic-view__image'}
						src={data.content}
						loader={(<ImagePreloader/>)}
						key={data.id}
					/>
				)
				break
			case 'headline':
				contentElement = (
					<ContentElementSubheadlineView content={data.content} />
				)
				break
			default:
				switch (typeof data.content) {
					case 'string':
						contentElement = (
							<Fragment key={data.id}>
								{Parser(data.content)}
							</Fragment>
						)
						break
					default:
						contentElement = <p>Unknown ADAC content element type: { data.type }</p>
				}
		}

		return contentElement
	}
}

if (__DEV__) {
	ContentElementAdacController.propTypes = {
		data: propTypes.object
	}
}

export default connect(
	(state, ownProps) => mapAppStateToGenericControllerComponent(state, ownProps)
)(ContentElementAdacController)
