/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'
import logo from '../../../images/vfa.svg'
import i18n from 'i18next'
import '../../../i18n'

function ConfirmationView (props) {
	// Assign all properties to variables for convenience
	const { activationCode, handleActivationCodeChange, handleConfirmationSubmit, handleConfirmationCancel } = props

	return (
		<div className="o-login-registration" id="login-registration">
			<div className="o-login-registration__wrapper">
				<img className="a-image" src={logo} width="150" alt='' />

				<p className="a-paragraph align-center margin-top">Sie erhalten in den nächsten Minuten einen Freischaltcode an die angegebene E-Mail-Adresse. Bitte geben Sie den Freischaltcode in das nachfolgende Feld ein, um die Registrierung abzuschließen.</p>

				<form className="o-login-registration__form margin-top">
					<input
						className="a-input"
						type="text"
						data-cy="activation-code"
						name="activationCode"
						value={activationCode}
						onChange={handleActivationCodeChange}
						placeholder={i18n.t('TXT_LOGIN_REGISTRATION_INPUT_ACTIVATION_CODE.label')}
					/>

					<button data-cy="submit" className="a-button a-button--center" onClick={handleConfirmationSubmit}>{i18n.t('TXT_LOGIN_REGISTRATION_INPUT_ACTIVATION_CODE_SUBMIT.label')}</button>

					{/* chancel button, no function */}
					<button data-cy="cancel" className="a-button a-button--light a-button--center" onClick={handleConfirmationCancel}>{i18n.t('TXT_LOGIN_REGISTRATION_INPUT_ACTIVATION_CODE_CHANCEL.label')}</button>
				</form>
			</div>
		</div>
	)
}

if (__DEV__) {
	// Properties types validation
	ConfirmationView.propTypes = {
		activationCode: propTypes.string,
		handleActivationCodeChange: propTypes.func,
		handleConfirmationSubmit: propTypes.func,
		handleConfirmationCancel: propTypes.func
	}
}

// Export component
export default ConfirmationView
