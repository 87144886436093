/* global __DEV__ */
import React, { Component, Fragment } from 'react'
import commonPropTypes from '../../utils/common-prop-types'
import hoistStatics from 'hoist-non-react-statics'

class PageContentController extends Component {
	render (x) {
		const { header, content, children, footer } = this.props

		return (
			<Fragment>
				{(header !== null) && <div className='page__header'>{header}</div>}
				<div className='page__content'>{content}{children}</div>
				{(footer !== null) && <div className='page__footer'>{footer}</div>}
			</Fragment>
		)
	}
}

PageContentController.defaultProps = {
	header: null,
	footer: null
}

if (__DEV__) {
	PageContentController.propTypes = {
		header: commonPropTypes.reactChildren,
		content: commonPropTypes.reactChildren,
		children: commonPropTypes.reactChildren,
		footer: commonPropTypes.reactChildren
	}
}

const withPageContentSections = function (WrappedComponent) {
	class WithPageContentSections extends React.Component {
		render () {
			return (
				<PageContentController
					header={WrappedComponent.renderContentHeader && WrappedComponent.renderContentHeader(this.props)}
					content={<WrappedComponent {...this.props} />}
					footer={WrappedComponent.renderContentFooter && WrappedComponent.renderContentFooter(this.props)}
				/>
			)
		}
	}

	WithPageContentSections.displayName = 'withPageContentSections(' + (WrappedComponent.displayName || WrappedComponent.name || 'Component') + ')'

	return hoistStatics(WithPageContentSections, WrappedComponent)
}

export default PageContentController
export {
	withPageContentSections
}
