import * as types from '../constants/action-types'

export function getTopic (id) {
	return {
		type: types.GET_NEWS_ITEM,
		id
	}
}

export function prepareTopic (id) {
	return {
		type: types.PREPARE_TOPIC,
		id
	}
}

export function setTopicLoading (loading) {
	return {
		type: types.SET_TOPIC_LOADING,
		loading
	}
}

export function entityViewed (contentType, id) {
	return {
		type: types.ENTITY_VIEWED,
		contentType,
		id
	}
}

export function setEntityLoading (contentType, id) {
	return {
		type: types.SET_ENTITY_LOADING,
		contentType,
		id
	}
}

export function setEntityLoaded (contentType, id, entry) {
	return {
		type: types.SET_ENTITY_LOADED,
		contentType,
		id,
		entry
	}
}

export function loadEntity (contentType, id) {
	return function (dispatch) {
		dispatch(setEntityLoading(contentType, id))
		setTimeout(
			() => {
				dispatch(setEntityLoaded(contentType, id, null))
			},
			1000
		)
	}
}
