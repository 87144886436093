/* global __DEV__ */
/* global __FEATURE__REDUX__LOGGING__ */
/* global __FEATURE_STATE_STRUCTURE_CHECKS__ */
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import StateValidator from './utils/state-validator'
import accordionReducerDefinition from './reducers/accordion.reducer'
import loginProcessReducerDefinition from './reducers/login-process.reducer'
import basicReducerDefinition from './reducers/basic.reducer'
import modalReducerDefinition from './reducers/modal.reducer'
import transitionsReducerDefinition from './reducers/transitions.reducer'
import contentReducerDefiniton from './reducers/content.reducer'
import ContentSaveController from './utils/content-storage-controller/content-set-controller'
import Updater from './utils/content-storage-controller/Updater'

let stateValidator = null
let reducers = {}
let propTypes = {}
let initialStates = {}

/* ******************** *\
 * BASIC DEFINITIONS    *
 * ******************** */

const basicReducerDefinitions = [
	loginProcessReducerDefinition,
	basicReducerDefinition,
	contentReducerDefiniton,
	accordionReducerDefinition,
	modalReducerDefinition,
	transitionsReducerDefinition
]

const middleware = [thunk]

if (__DEV__ && __FEATURE__REDUX__LOGGING__) {
	middleware.push(createLogger())
}

/* ******************** *\
 * UTILITY              *
 * ******************** */

function updateStateValidator () {
	if (!__DEV__) {
		return
	}

	if (!stateValidator) {
		return
	}

	stateValidator.setPropTypes(propTypes)
	// Validate initial states.
	stateValidator.validate(initialStates, 'initial state')
	// Validate current state.
	stateValidator.validate()
}

function updateReducers () {
	initialStates = {}
	reducers = {}
	propTypes = {}

	basicReducerDefinitions.forEach((reducerDefinition) => {
		initialStates[reducerDefinition.name] = reducerDefinition.initialState
		reducers[reducerDefinition.name] = reducerDefinition.reducer
		propTypes[reducerDefinition.name] = reducerDefinition.reducerPropTypes
	})

	Object.keys(dynamicStore.dynamicReducerDefinitions).forEach((reducerName) => {
		const reducerDefinition = dynamicStore.dynamicReducerDefinitions[reducerName]
		initialStates[reducerDefinition.name] = reducerDefinition.initialState
		reducers[reducerDefinition.name] = reducerDefinition.reducer
		propTypes[reducerDefinition.name] = reducerDefinition.reducerPropTypes
	})
}

const dynamicStore = {
	store: null,
	dynamicReducerDefinitions: {},
	combineReducers () {
		return combineReducers(reducers)
	},
	addReducer (reducerDefinition) {
		dynamicStore.dynamicReducerDefinitions[reducerDefinition.name] = reducerDefinition
		updateReducers()
		store.replaceReducer(dynamicStore.combineReducers())
		updateStateValidator()
	},
	removeReducer (key) {
		delete dynamicStore.dynamicReducerDefinitions[key]
		updateReducers()
		store.replaceReducer(dynamicStore.combineReducers())
		updateStateValidator()
	}
}

/* ******************** *\
 * CREATE STORE         *
 * ******************** */

updateReducers()

const store = createStore(
	combineReducers(reducers),
	composeWithDevTools(applyMiddleware(...middleware))
)

dynamicStore.store = store

/* ******************** *\
 * STATE VALIDATION     *
 * ******************** */

if (__DEV__ && __FEATURE_STATE_STRUCTURE_CHECKS__) {
	stateValidator = new StateValidator(store, propTypes)
	updateStateValidator()
	stateValidator.listen()
}

/* ******************** *\
 * CACHE SAVE CONTROLLER SUBSCRIPTION *
 * ******************** */
function subscribeContentSaveController (storeRef) {
	let contentSaveController = new ContentSaveController(storeRef)
	contentSaveController.subscribeStorageUpdateHandler()
}

/* ******************** *\
 * START UPDATE *
 * ******************** */
function startUpdateController (storeRef) {
	let content = storeRef.getState().content
	let dispatch = storeRef.dispatch
	let firstUpdate = !content.timestamp

	/* start the update process */
	if (!firstUpdate) {
		Updater.update(dispatch, content, 0, true)
	}
}

// TODO: IF offline - subscribe only after successfull get of store
// subscribeContentSaveController(store)

/* ******************** *\
 * EXPORTS              *
 * ******************** */

export default store

export {
	dynamicStore,
	subscribeContentSaveController,
	startUpdateController
}
