/* global __DEV__ */
import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import ReadingListController from './reading-list.controller'
import ArticleSendController from '../general/article-send.controller'
import routes from '../../utils/routes'
import NotFoundController from '../general/not-found.controller'

class ReadingListIndex extends Component {
	render () {
		return (
			<Switch>
				<Route exact path={routes.readingListList.path} component={ReadingListController}/>
				<Route exact path={routes.readingListSend.path}	component={ArticleSendController}/>
				<Route component={NotFoundController} />
			</Switch>
		)
	}
}

if (__DEV__) {
	ReadingListIndex.propTypes = {}
}

export default ReadingListIndex
