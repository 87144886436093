/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'
import logo from '../../../images/vfa.svg'
import i18n from 'i18next'
import '../../../i18n'

function RegistrationView (props) {
	// Assign all properties to variables for convenience
	const { nameAndSurname, email, handleNameAndSurnameChange, handleEmailChange, handleRegistrationSubmit,
		handleOpenDpr, handleCloseDpr, handleSwitchDpr, dprChecked, dprLayerOpen } = props

	return (
		<div className="o-login-registration" id="login-registration">
			<div className="o-login-registration__wrapper registration-wrapper">

				<img className="a-image a-brand" src={logo} width="150" alt='' />

				<h1 data-cy={'main-header'} className="a-headline a-headline--opener align-center">{i18n.t('TXT_LOGIN_REGISTRATION_CAPTION.label')}</h1>
				<h2 className="a-headline a-headline--h3 align-center">
					{i18n.t('TXT_LOGIN_REGISTRATION_DESCRIPTION_LINE1.label')}<br />
					{i18n.t('TXT_LOGIN_REGISTRATION_DESCRIPTION_LINE2.label')}
				</h2>

				<form className="o-login-registration__form">

					<input
						className="a-input"
						type='text'
						data-cy='name-and-surname'
						name='nameAndSurname'
						value={nameAndSurname}
						onChange={handleNameAndSurnameChange.bind(this)}
						placeholder={i18n.t('TXT_LOGIN_REGISTRATION_INPUT_NAME.label')}
					/>

					<input
						className="a-input"
						type='text'
						data-cy='email'
						name='email'
						value={email}
						onChange={handleEmailChange.bind(this)}
						placeholder={i18n.t('TXT_LOGIN_REGISTRATION_INPUT_MAIL.label')}
					/>

					<div className="a-checkbox">
						<label className={dprChecked ? 'a-checkbox__label--checked a-checkbox__label' : 'a-checkbox__label'} htmlFor="dpr" onClick={handleSwitchDpr.bind(this)} />
						<input id="dprChecked" type="checkbox"  data-cy="dpr" name="dprChecked" data-cacheval="true" checked={dprChecked} onChange={handleSwitchDpr.bind(this)} />

						<div className="a-checkbox__description">
							<a href="#" onClick={handleOpenDpr.bind(this)}>Datenschutzbestimmungen</a> gelesen und akzeptiert
						</div>
					</div>

					<button data-cy='submit' className="a-button a-button--center" onClick={handleRegistrationSubmit.bind(this)}>{i18n.t('TXT_LOGIN_REGISTRATION_BUTTON.label')}</button>

				</form>

				<div className={dprLayerOpen ? 'm-layer m-layer--open' : 'm-layer'}>
					<i className='m-layer__close' onClick={handleCloseDpr.bind(this)} />
					<p>
						Die Datenschutz-Grundverordnung (DSGVO; frz. Règlement général sur la protection des données
						RGPD, engl. General Data Protection Regulation GDPR) ist eine Verordnung der Europäischen
						Union, mit der die Regeln zur Verarbeitung personenbezogener Daten durch private Unternehmen
						und öffentliche Stellen EU-weit vereinheitlicht werden. Dadurch soll einerseits der Schutz
						personenbezogener Daten innerhalb der Europäischen Union sichergestellt, und auch andererseits
						der freie Datenverkehr innerhalb des Europäischen Binnenmarktes gewährleistet werden.
					</p>
					<p>
						Die Verordnung ersetzt die aus dem Jahr 1995 stammende Richtlinie 95/46/EG zum Schutz
						natürlicher Personen bei der Verarbeitung personenbezogener Daten und zum freien Datenverkehr.
					</p>
				</div>
			</div>
		</div>
	)
}

if (__DEV__) {
	// Properties types validation
	RegistrationView.propTypes = {
		nameAndSurname: propTypes.string,
		email: propTypes.string,
		handleNameAndSurnameChange: propTypes.func,
		handleEmailChange: propTypes.func,
		handleRegistrationSubmit: propTypes.func,
		handleOpenDpr: propTypes.func,
		handleCloseDpr: propTypes.func,
		handleSwitchDpr: propTypes.func,
		dprChecked: propTypes.bool,
		dprLayerOpen: propTypes.bool
	}
}

// Export component
export default RegistrationView
