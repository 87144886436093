import Logger from '../../utils/logger'

/**
 * Signal processor that logs tracking data via Logger utility.
 *
 * @param {AnalyticsSignal} signal
 * @return {boolean}
 * @private
 */
export default function analyticsSignalProcessorJsConsole (signal) {
	Logger.logOnce('CONSOLE ANALYTICS', 'Signal', signal)

	// Mark signal as processed.
	return Promise.resolve()
}
