const CHANGE_TYPE_ADD = '+'
const CHANGE_TYPE_REMOVE = '-'

/**
 * This class is a model class that reflects un-synced changes to a {@link ReadingListItem}.
 */
class ReadingListChange {
	/**
	 * Constructor
	 *
	 * @param {string} [changeType]
	 * @param {number} [changeTS]
	 */
	constructor (changeType = CHANGE_TYPE_ADD, changeTS = new Date().getTime()) {
		/** @member {number} */
		this.changeTS = changeTS

		/** @member {string} */
		this.changeType = changeType
	}

	/**
	 * Creates an instance from plain data.
	 *
	 * @param {ReadingListChangeData|ReadingListChange} data
	 *
	 * @return {ReadingListChange}
	 */
	static fromData (data) {
		return new ReadingListChange(data.changeType, data.changeTS)
	}
}

export default ReadingListChange

export {
	CHANGE_TYPE_ADD,
	CHANGE_TYPE_REMOVE
}
