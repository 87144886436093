import {
	CONTENT_STORAGE_NAMESPACE,
	CONTENT_OBJECT_KEY,
	CONTENT_TYPE_TOPIC,
	CONTENT_TYPE_NEWS,
	CONTENT_TYPE_PRESSRELEASE
} from '../../constants/names'
import StorageManager from 'js-storage-manager'
import dataLayer from '../../data-layer/dataLayer'
import Logger from '../logger'
import ContentGetUpdateInjector from './content-get-update-injector'
import { AuthException } from '../errors'
import Updater from './Updater'
import store from '../../store'

export default class ContentGetController {
	static async getDataType (singleViewToken) {
		return dataLayer.getType(singleViewToken)
	}

	static async getDataLayerResponse () {
		return dataLayer.getDataFull()
	}

	static async getArticleLayerResponse (config) {
		return dataLayer.getArticlePreview(config)
	}

	static async getNewsLayerResponse (config) {
		return dataLayer.getNewsPreview(config)
	}

	static async getPressreleaseLayerResponse (config) {
		return dataLayer.getPressReleasePreview(config)
	}

	static getRawDataOffline () {
		let cS = new StorageManager(CONTENT_STORAGE_NAMESPACE)
		return cS.get(CONTENT_OBJECT_KEY)
	}

	static processRawDataFromLS (responseObject) {
		responseObject.success = responseObject.rawData !== null
	}

	static isOnlineResponse (responseObject) {
		return responseObject === null
	}

	static injectOnlineTimestamp (responseObject) {
		responseObject.timestamp = Math.round((new Date()).getTime() / 1000)
	}

	static injectLSTimestamp (responseObject) {
		responseObject.timestamp = responseObject.rawData.timestamp
	}

	static handleInjectTimestamp (responseObject) {
		if (responseObject.success === true) {
			if (responseObject.online === true) {
				ContentGetController.injectOnlineTimestamp(responseObject)
			} else {
				ContentGetController.injectLSTimestamp(responseObject)
			}
		} else {
			responseObject.timestamp = 0
		}
	}

	static handleInjectUpdateInformations (rawData) {
		try {
			let updateInjector = new ContentGetUpdateInjector(rawData)
			updateInjector.handle()
		} catch (e) {
			console.warn(e)
		}
	}

	/**
	 * Returns the content controller according the given content type (singleView).
	 *
	 * @param singleView
	 * @param config
	 * @returns {*}
	 */
	static getContentController (singleView, config = {}) {
		if (!singleView) {
			return ContentGetController.getDataLayerResponse
		}

		switch (config.type) {
			case CONTENT_TYPE_TOPIC:
				return ContentGetController.getArticleLayerResponse

			case CONTENT_TYPE_NEWS:
				return ContentGetController.getNewsLayerResponse

			case CONTENT_TYPE_PRESSRELEASE:
				return ContentGetController.getPressreleaseLayerResponse
		}

		return null
	}

	/**
	 * Returns DataLayerResponse with online flag, timestamp and eventually content from local storage
	 * @param singleViewToken
	 * @param loggedin
	 * @returns {Promise<{rawData: Object, online: boolean, success: boolean, supported: boolean}>}
	 */
	static async handleGetContent (singleViewToken = null, loggedin = false) {
		let tmpMode = false
		let assKeys = tmpMode ? ['categories', 'topics', 'news', 'pressReleases', 'sliderElements', 'stakeholders'] : []
		let firstUpdate = true

		let contentController, responseObjectInformation, responseObject
		let Lg = new Logger('Content Get Controller')
		Lg.startGroup()

		/* single view view mode */
		if (singleViewToken) {
			responseObjectInformation = await ContentGetController.getDataType(singleViewToken)

			if (!responseObjectInformation.success) {
				throw new AuthException('Unknown token.')
			}

			contentController = ContentGetController.getContentController(true, responseObjectInformation.rawData)
			responseObject = await contentController(responseObjectInformation.rawData)

			/* move some associative rawData to rawData._tmp */
			responseObject.rawData._tmp = {}
			responseObject.rawData._tmp.data = {}
			responseObject.rawData._tmp.objectInformation = responseObjectInformation.rawData
			assKeys.forEach(function (name) {
				if (name in responseObject.rawData) {
					responseObject.rawData._tmp.data[name] = responseObject.rawData[name]
					responseObject.rawData[name] = {}
				}
			})

		/* default view mode */
		} else {
			contentController = ContentGetController.getContentController(false)
			responseObject = await contentController()
		}

		Lg.log('Got data response from data layer: ', responseObject)

		responseObject.online = true

		if (responseObject.success === false) {
			responseObject.online = false
			Lg.log('dataResponseContent.success=false => We are offline or request error!')
			Lg.log('Will try to get content from local storage')

			responseObject.rawData = await ContentGetController.getRawDataOffline()

			ContentGetController.processRawDataFromLS(responseObject)
		}

		ContentGetController.handleInjectTimestamp(responseObject)

		if (responseObject.online === true) {
			ContentGetController.handleInjectUpdateInformations(responseObject.rawData)
		}

		Lg.log('Return object: ', responseObject)

		Lg.endGroup()

		return responseObject
	}
}
