import * as types from '../constants/action-types'
import { ReadingList } from '../reading-list/reading-list'
import dataLayer from '../data-layer/dataLayer'
import ReadingListProcessor from '../reading-list/reading-list-processor'
import store from '../store'

export function injectReadingList (readingList) {
	return {
		type: types.INJECT_READING_LIST,
		readingList
	}
}

export function syncReadingList () {
	return async function (dispatch) {
		let state = store.getState()

		/* read reading list only, if logged in */
		if (!state.loginProcess.authData.loggedIn) {
			return
		}

		let dataResponseReadingList = await dataLayer.getReadingList()

		if (!dataResponseReadingList.success) {
			// TODO ERROR_HANDLING

			return
		}

		if (dataResponseReadingList.supported) {
			ReadingList.mergeItemData(dataResponseReadingList.rawData.items)
		}

		dispatch(injectReadingList(ReadingList.getItemsForState()))

		ReadingListProcessor.setActive()
	}
}

// Adds item to redux reading list, launches adding to LocalStorage
export function addToReadingList (item, itemType) {
	return function (dispatch) {
		ReadingList.addRLItem(itemType, item.id)
		dispatch(injectReadingList(ReadingList.getItemsForState()))
	}
}

// Removes item from redux reading list, launches removing
// to LocalStorage
export function removeFromReadingList (itemId, itemType) {
	return function (dispatch) {
		ReadingList.removeRLItem(itemType, itemId)
		dispatch(injectReadingList(ReadingList.getItemsForState()))
	}
}

/**
 * Remove all reading list items from reading list from local storage
 */
export function removeAllItemsFromReadingList () {
	return function (dispatch) {
		ReadingList.removeAllRLItems()
		dispatch(injectReadingList(ReadingList.getItemsForState()))
	}
}
