/* global __DEV__ */
import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import routes from '../../utils/routes'
import NotFoundController from '../general/not-found.controller'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToPreviewSwitchControllerComponent } from '../../utils/mappings'
import ControllerUtils from '../../utils/controller-utils'

class PreviewSwitch extends Component {
	/**
	 * The main render function from PreviewSwitchController.
	 *
	 * @returns {*}
	 */
	render () {
		let controller = ControllerUtils.getController(this.props.objectInformation)

		return (
			<Switch>
				<Route path={routes.singleView.path} component={controller} />
				<Route path={routes.cmsPreview.path} component={controller} />
				<Route component={NotFoundController} />
			</Switch>
		)
	}
}

if (__DEV__) {
	PreviewSwitch.propTypes = {
		objectInformation: propTypes.object
	}
}

export default connect(mapAppStateToPreviewSwitchControllerComponent)(PreviewSwitch)
