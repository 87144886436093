/* global __DEV__ */
import React, { Fragment } from 'react'
import propTypes from 'prop-types'
import ContentElementLinkController
	from '../../content-elements/content-element-link.controller'
import commonPropTypes from '../../../utils/common-prop-types'

function LinkListView (props) {
	let { preparedLinks, contentBefore } = props

	if (preparedLinks.length === 0) {
		// noinspection JSConstructorReturnsPrimitive
		return null
	}

	return (
		<Fragment>
			{contentBefore}
			<ul>
				{
					preparedLinks.map((preparedLinks, key) => {
						return (
							<li key={key}>
								<ContentElementLinkController
									contentElement={preparedLinks}
								/>
							</li>
						)
					})
				}
			</ul>
		</Fragment>
	)
}

LinkListView.defaultProps = {
	bar: 'bar',
	contentBefore: null
}

if (__DEV__) {
	// Properties types validation
	LinkListView.propTypes = {
		preparedLinks: propTypes.arrayOf(
			propTypes.exact({
				...commonPropTypes.mixinPreparedLink
			})
		).isRequired,
		contentBefore: commonPropTypes.reactChildren
	}
}

// Export component
export default LinkListView
