import * as types from '../constants/action-types'

/**
 *
 * @param name
 * @param state
 * @return {{name: *, state: *, type: string}}
 */
export function setTransitionState (name, state) {
	return {
		type: types.TRANSITIONS_SET_TRANSITION_STATE,
		name,
		state
	}
}
