/* global __DEV__ */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { mapAppStateToArticleSendControllerComponent } from '../../utils/mappings'
import SendArticleView from './views/article-send.view'
import { doSendArticle, resetSendArticle, resetSendArticleAndGoBack, updateSendArticleFormField } from '../../actions/article-send.actions'
import { createModal } from '../../actions/modal.actions'
import i18n from '../../i18n'
import { withRouter } from 'react-router'
import { withPageContentSections } from '../pages/page-content.controller'
import analytics from '../../analytics/analytics'

class ArticleSendController extends Component {
	handleSendArticleFieldChange (name, e) {
		this.props.dispatch(updateSendArticleFormField(name, e.target.value))
	}

	handleSendArticleSubmit () {
		const { dispatch, emailTo, emailSubject, emailMessage, ids } = this.props
		dispatch(doSendArticle(emailTo, emailSubject, emailMessage, ids, true))
	}

	render () {
		const { emailTo, emailSubject, emailMessage } = this.props

		return (
			<SendArticleView
				emailTo={emailTo}
				emailSubject={emailSubject}
				emailMessage={emailMessage}
				handleSendArticleEmailToChange={this.handleSendArticleFieldChange.bind(this, 'emailTo')}
				handleSendArticleEmailSubjectChange={this.handleSendArticleFieldChange.bind(this, 'emailSubject')}
				handleSendArticleEmailMessageChange={this.handleSendArticleFieldChange.bind(this, 'emailMessage')}
				handleSendArticleSubmit={this.handleSendArticleSubmit.bind(this)}
			/>
		)
	}

	// noinspection JSUnusedGlobalSymbols
	componentDidUpdate (/* prevProps */) {
		switch (this.props.apiMessageType) {
			case 'ERROR':
				this.props.dispatch(resetSendArticle())
				this.props.dispatch(createModal(
					i18n.t('TXT_GENERAL_WORD_ERROR.label'),
					this.props.apiMessage,
					resetSendArticle()
				))

				break

			case 'SUCCESS':
				this.props.dispatch(resetSendArticle())
				this.props.dispatch(createModal(
					i18n.t('TXT_GENERAL_WORD_SUCCESS.label'),
					i18n.t('TXT_ARTICLE_WILL_BE_SEND.label'),
					resetSendArticleAndGoBack(),
					// Custom Tracking
					analytics.track('send Article')
				))

				break
		}
	}
}

if (__DEV__) {
	ArticleSendController.propTypes = {
		emailTo: propTypes.string,
		emailSubject: propTypes.string,
		emailMessage: propTypes.string,
		id: propTypes.string,
		apiMessageType: propTypes.string,
		apiMessage: propTypes.string,
		apiCode: propTypes.number,
		match: propTypes.object,
		ids: propTypes.oneOfType([
			propTypes.array,
			propTypes.string
		]).isRequired,
		dispatch: propTypes.func.isRequired
	}
}

export default withRouter(connect(mapAppStateToArticleSendControllerComponent)(withPageContentSections(ArticleSendController)))
