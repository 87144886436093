import React from 'react'
import AddAppToHomescreenView from './views/addAppToHomescreen.view'
import { withPageContentSections } from '../pages/page-content.controller'
import { compose } from 'redux/lib/redux'
import withTitle from '../header/withTitle.hoc'

let AddAppToHomescreenController = function (/* props */) {
	return (
		<AddAppToHomescreenView/>
	)
}

export default AddAppToHomescreenController = compose(
	withPageContentSections,
	withTitle(null, 'TXT_PROJECT_ADD_APP_TO_HOMESCREEN_TITLE.label')
)(AddAppToHomescreenController)
