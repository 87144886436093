/* global __DEV__ */
import React from 'react'
import commonPropTypes from '../../../utils/common-prop-types'
import propTypes from 'prop-types'
import i18n from '../../../i18n'
import { Link } from 'react-router-dom'
import LinkAll from '../../general/link-all'

export function ReadingListView (props) {
	const {
		children,
		readingListLink,
		handleClearReadingList
	} = props

	let classNamesSend = 'a-button a-button--center'
	let classNamesClear = 'a-button a-button--center a-button--small a-button--light'

	if (readingListLink === '') {
		classNamesSend += ' a-button--inactive'
		classNamesClear += ' a-button--inactive'
	}

	return (
		<div className="container o-list-view o-list-view--reading-list">
			<div className="m-list-item-wrapper">
				{ children }
			</div>

			<div className={'m-action-button-wrapper'}>
				<LinkAll data-cy={'reading-list-send'} className={classNamesSend} to={readingListLink} optional={readingListLink === ''}>
					{i18n.t('TXT_READING_LIST_SEND.label')}
				</LinkAll>
				<a data-cy={'reading-list-clear'} className={classNamesClear} onClick={handleClearReadingList}>
					{i18n.t('TXT_READING_LIST_CLEAR.label')}
				</a>
			</div>
		</div>
	)
}

if (__DEV__) {
	ReadingListView.propTypes = {
		children: commonPropTypes.reactChildren,
		readingListLink: propTypes.string,
		handleClearReadingList: propTypes.func
	}
}

export default ReadingListView
