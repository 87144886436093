/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToGenericControllerComponent } from '../../utils/mappings'
import Parser from 'html-react-parser'
import ContentElementParagraphView from './views/content-element-paragraph.view'

/**
 * Unwrappable tag names.
 *
 * @type {string[]}
 */
const tagNamesNotToWrap = [
	'ul',
	'ol',
	'dl',
	'div',
	'section'
]

/**
 * This component takes an html string, parses it to react elements and
 * wraps them in paragraph elements if applicable.
 */
class ContentElementParagraphController extends Component {
	/**
	 * Checks if a parsed element is wrappable. (by blacklisting tag names).
	 *
	 * @param element
	 * @return {boolean}
	 */
	static isParsedElementNotWrappable (element) {
		return tagNamesNotToWrap.indexOf(element.type) !== -1
	}

	/**
	 * Removes leading and trailing br elements from an array.
	 *
	 * @param {string[]|Object[]} elements An array of parsed elements.
	 * @return {Array} An array of strings and/or react elements.
	 */
	static trimBrTagsFromArray (elements) {
		// Create a string of zeroes and ones. Ones stand for br elements.
		let elementIsBR = elements.map(element => {
			return element.type === 'br' ? '1' : '0'
		})
		// Get the position of the first non br element or string.
		let firstNonBr = elementIsBR.indexOf('0')
		// Get the position of the last non br element or string.
		let lastNonBr = elementIsBR.lastIndexOf('0')

		// If we have no non br elements we return an empty array directly.
		if (firstNonBr === -1) {
			return []
		}

		// Return the trimmed array.
		return elements.slice(firstNonBr, lastNonBr + 1)
	}

	/**
	 * Wraps parsed elements and/or strings into paragraph tags if applicable.
	 * Example input:
	 *   [ text, <br>, <span>, <img>, <ul>, <div>, <br>, <br>, <div>, <br>, text, <br> ]
	 * Example output:
	 *   [ <p children=[ text, <br>, <span>, <img> ]>, <ul>, <div>, <div>, <p children=[ text ]> ]
	 *
	 * @param {Array} parsedElements An array of strings and/or react elements.
	 * @return {Array}
	 */
	static wrapParsedElements (parsedElements) {
		let processedElements = []
		let wrappableElementsBuffer = null

		// Fills result set with raw unwrappable elements and
		// buffer arrays that contain successive wrappable elements.
		// intermediate result:
		//   [ [ text, <br>, <span>, <img> ], <ul>, <div>, [ <br>, <br> ], <div>, [ <br>, text, <br> ] ]
		parsedElements.forEach((element) => {
			if (ContentElementParagraphController.isParsedElementNotWrappable(element)) {
				// Prevent next wrappable elements from being placed into the
				// old buffer array element.
				wrappableElementsBuffer = null
				// Add raw element to result set.
				processedElements.push(element)

				return
			}

			if (wrappableElementsBuffer === null) {
				// Create a new buffer array of wrappable elements.
				wrappableElementsBuffer = []
				// Add that buffer array to the result set.
				// We can add wrappable elements to that buffer array later.
				processedElements.push(wrappableElementsBuffer)
			}

			// Add the current element to the buffer array.
			wrappableElementsBuffer.push(element)
		})

		// Trims br elements from buffer arrays.
		// intermediate result:
		//   [ [ text, <br>, <span>, <img> ], <ul>, <div>, [], <div>, [ text ] ]
		processedElements = processedElements.map(element => {
			if (!Array.isArray(element)) {
				return element
			}

			return ContentElementParagraphController.trimBrTagsFromArray(element)
		})

		// Removes empty buffer arrays.
		// intermediate result:
		//   [ [ text, <br>, <span>, <img> ], <ul>, <div>, <div>, [ text ] ]
		processedElements = processedElements.filter(element => {
			return !Array.isArray(element) || element.length > 0
		})

		// Wraps buffer arrays into paragraph elements.
		// intermediate result:
		//   [ <p children=[ text, <br>, <span>, <img> ]>, <ul>, <div>, <div>, <p children=[ text ]> ]
		processedElements = processedElements.map((element, key) => {
			if (!Array.isArray(element)) {
				return element
			}

			return <p key={key}>{element}</p>
		})

		// Return the result set of unwrappable elements and wrapped buffer
		// arrays.
		return processedElements
	}

	/**
	 * Parses html to react elements and wraps elements in paragraphs if
	 * applicable.
	 *
	 * @param {string} content An html string.
	 * @return {Array}
	 */
	static parseAndWrapContent (content) {
		// Parse elements.
		let parsedElements = Parser(content)

		// Wrap single text nodes or elements into an array.
		if (!Array.isArray(parsedElements)) {
			parsedElements = [parsedElements]
		}

		// Wrap elements and return.
		return ContentElementParagraphController.wrapParsedElements(parsedElements)
	}

	/**
	 * Renders the component.
	 *
	 * @return {*}
	 */
	render () {
		let { contentElement } = this.props

		return (
			<ContentElementParagraphView
				content={ContentElementParagraphController.parseAndWrapContent(contentElement.content)}
			/>
		)
	}
}

if (__DEV__) {
	ContentElementParagraphController.propTypes = {
		contentElement: propTypes.object.isRequired
	}
}

export default connect(
	(state, ownProps) => mapAppStateToGenericControllerComponent(state, ownProps)
)(ContentElementParagraphController)
