import React from 'react'
import hoistStatics from 'hoist-non-react-statics'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToGenericControllerComponent } from './../../utils/mappings'
import { setTitle } from '../../actions/basic.actions'
import i18n from '../../i18n'

/**
 * Dispatches a title update on component mount and when the title changes through a prop update.
 * Prevents unnecessary title updates triggered through other prop's changes.
 *
 * @param {function|null} funcGetTitle
 * @param {string} [i18nKey]
 * @return {function(*=)}
 */
var withTitle = function (funcGetTitle, i18nKey) {
	const staticTitle = funcGetTitle === null ? i18n.t(i18nKey) : null

	return function withTitle (WrappedComponent) {
		class WithTitle extends React.Component {
			constructor (props) {
				super(props)
				this.title = null
			}

			// noinspection JSUnusedGlobalSymbols
			componentDidMount () {
				this.updateTitle()
			}

			// noinspection JSUnusedGlobalSymbols
			componentDidUpdate () {
				this.updateTitle()
			}

			updateTitle () {
				const newTitle = staticTitle === null ? funcGetTitle(this.props) || '' : staticTitle

				if (newTitle !== this.title) {
					this.title = newTitle
					this.props.dispatch(setTitle(newTitle))
				}
			}

			render () {
				const { wrappedComponentRef, ...passThroughProps } = this.props

				return (
					<WrappedComponent ref={wrappedComponentRef} {...passThroughProps}/>
				)
			}
		}

		WithTitle.displayName = 'withTitle(' + (WrappedComponent.displayName || WrappedComponent.name || 'Component') + ')'
		WithTitle.WrappedComponent = WrappedComponent
		WithTitle.propTypes = {
			wrappedComponentRef: propTypes.func,

			dispatch: propTypes.func.isRequired
		}

		hoistStatics(WithTitle, WrappedComponent)

		return connect(mapAppStateToGenericControllerComponent)(WithTitle)
	}
}

export default withTitle
