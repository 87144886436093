/* global __DEV__ */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { mapAppStateToNewsListControllerComponent } from '../../utils/mappings'
import NewsListView from './views/news-list.view'
import NewsListItemView from './views/news-list-item.view'
import routes from '../../utils/routes'
import DF from '../../utils/dateFormat'
import Sorter from '../../utils/sorter'
import { withPageContentSections } from '../pages/page-content.controller'
import NewsSliderController from './news-slider.controller'
import commonPropTypes from '../../utils/common-prop-types'
import { compose } from 'redux/lib/redux'
import withTitle from '../header/withTitle.hoc'

class NewsListController extends Component {
	render () {
		const { news } = this.props

		/* sort news elements by glamus sort index */
		let newsSorted = Sorter.sortAssociativeArrayByGivenKey(news, 'sortGlamus')

		/* build news item list */
		const listItems = newsSorted.map((newsItemTuple, keyIndex) => {
			const newsItem = newsItemTuple[1]
			const link = routes.newsDetail.build(newsItem.id)
			let classes = []
			let dateFormatted = DF.convertGlamusTimestamp(newsItem.tstamp)
			if (newsItem.isUpdated) {
				classes.push('topic-updated')
			}

			return (
				<NewsListItemView
					key={keyIndex}
					link={link}
					date={dateFormatted}
					title={newsItem.title}
					classes={classes}
					imageBgUrl={newsItem.image_bg}
				/>
			)
		})

		return (
			<NewsListView>
				<NewsSliderController/>
				<div className="m-teaser-wrapper">
					{listItems}
				</div>
			</NewsListView>
		)
	}
}

if (__DEV__) {
	NewsListController.propTypes = {
		news: commonPropTypes.content.newsCollection.isRequired,

		...commonPropTypes.mixinFromReduxConnect
	}
}

export default compose(
	connect(mapAppStateToNewsListControllerComponent),
	withPageContentSections,
	withTitle(null, 'TXT_NEWS_LIST_TITLE.label')
)(NewsListController)
