import AnalyticsModuleBasic from './analytics-module-basic'
import analyticsSignalProcessorJsConsole from '../signal-processors/analytics-signal-processor-js-console'

/**
 * Analytics module that logs tracking data via Logger utility.
 */
class AnalyticsModuleJSConsole extends AnalyticsModuleBasic {
	/**
	 * Initialize module settings.
	 */
	constructor () {
		super()

		// Use the JS console signal processor.
		this.signalProcessor = analyticsSignalProcessorJsConsole
	}
}

const analyticsModuleJSConsole = new AnalyticsModuleJSConsole()
analyticsModuleJSConsole.initQueue()

export default analyticsModuleJSConsole
