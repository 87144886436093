/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'
import LinkAll from '../../../general/link-all'

function FooterButtonGenericView (props) {
	let { url, onClick, icon, title, inactive, dataCy } = props
	let classNames = 'm-content-footer__element'

	if (inactive) {
		classNames += ' m-content-footer__element--inactive'
	}

	return (
		<LinkAll
			className={classNames}
			to={url}
			onClick={onClick}
			linkType={(!url && onClick) ? LinkAll.LINK : LinkAll.AUTO}
			dataCy={dataCy}
		>
			<i className={icon}/>
			{title}
		</LinkAll>
	)
}

FooterButtonGenericView.defaultProps = {
	inactive: false
}

if (__DEV__) {
	// Properties types validation
	FooterButtonGenericView.propTypes = {
		url: propTypes.string,
		onClick: propTypes.func,
		icon: propTypes.string.isRequired,
		title: propTypes.string.isRequired,
		inactive: propTypes.bool
	}
}

// Export component
export default FooterButtonGenericView
