/* global __DEV__ */
import React, { Fragment } from 'react'
import propTypes from 'prop-types'

function ContentElementParagraphView (props) {
	return (
		<Fragment>{props.content}</Fragment>
	)
}

ContentElementParagraphView.defaultProps = {
	content: ''
}

if (__DEV__) {
	// Properties types validation
	ContentElementParagraphView.propTypes = {
		content: propTypes.oneOfType([
			propTypes.node,
			propTypes.arrayOf(
				propTypes.node
			),
			propTypes.string
		])
	}
}

// Export component
export default ContentElementParagraphView
