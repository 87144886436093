/* global __DEV__ */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import AccordionController from './accordion.controller'
import { mapAppStateToAccordionWithTeaserControllerComponent } from '../../../utils/mappings'
import commonPropTypes from '../../../utils/common-prop-types'
import propTypes from 'prop-types'
import AccordionWithTeaserView from './views/accordion-with-teaser.view'

class AccordionWithTeaserController extends Component {
	constructor (props) {
		super(props)

		this.reduxControlled = props.accordionKey.length > 0

		this.state = {
			isActive: props.isActive
		}
	}

	handleActiveTabIndexChange (activeTabIndex) {
		if (this.reduxControlled) {
			return
		}

		this.setState({
			isActive: activeTabIndex !== -1
		})
	}

	render () {
		let { title, isActive, ...accordionProps } = this.props

		if (!this.reduxControlled) {
			isActive = this.state.isActive
		}

		return (
			<AccordionWithTeaserView title={title} isActive={isActive} >
				<AccordionController onChangeActiveTabIndex={this.handleActiveTabIndexChange.bind(this)} { ...accordionProps } />
			</AccordionWithTeaserView>
		)
	}
}

AccordionWithTeaserController.defaultProps = {
	accordionKey: '',
	title: ''
}

if (__DEV__) {
	AccordionWithTeaserController.propTypes = {
		// accordionKey -> see AccordionController props
		title: propTypes.string.isRequired,
		/**
		 * This one is set by the props mapper.
		 */
		isActive: propTypes.bool.isRequired,

		...AccordionController.propTypes,

		...commonPropTypes.mixinFromReduxConnect
	}
}

export default connect(mapAppStateToAccordionWithTeaserControllerComponent)(AccordionWithTeaserController)
