/* global __DEV__ */
import React, { Component, Fragment } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToGenericControllerComponent } from '../../utils/mappings'
import ContentElementAdacController from './adac/content-element-adac.controller'
import ContentElementAccordionController from './content-element-accordion.controller'
import ContentElementAudioController from './content-element-audio.controller'
import ContentElementDownloadController from './content-element-download.controller'
import ContentElementGalleryController from './content-element-gallery.controller'
import ContentElementImageController from './content-element-image.controller'
import ContentElementLinkController from './content-element-link.controller'
import ContentElementParagraphController from './content-element-paragraph.controller'
import ContentElementSubheadlineController from './content-element-subheadline.controller'
import ContentElementVideoController from './content-element-video.controller'

const contentComponents = {
	accordion: ContentElementAccordionController,
	audio: ContentElementAudioController,
	download: ContentElementDownloadController,
	gallery: ContentElementGalleryController,
	image: ContentElementImageController,
	link: ContentElementLinkController,
	paragraph: ContentElementParagraphController,
	subheadline: ContentElementSubheadlineController,
	video: ContentElementVideoController

}

class ContentElementsController extends Component {
	render () {
		let { contentElements } = this.props

		return (
			<Fragment>
				{
					contentElements.map((contentElement, key) => {
						switch (contentElement.type) {
							case '_ADAC':
								return <ContentElementAdacController key={key} data={contentElement.data}/>
							default:
								let ContentComponent = contentComponents[contentElement.type]
								if (ContentComponent) {
									return <ContentComponent key={key} contentElement={contentElement}/>
								}
								return <p key={key}>Unknown content element type: { contentElement.type }</p>
						}
					})
				}
			</Fragment>
		)
	}
}

ContentElementsController.defaultProps = {
	contentElements: []
}

if (__DEV__) {
	ContentElementsController.propTypes = {
		contentElements: propTypes.array
	}
}

export default connect(
	(state, ownProps) => mapAppStateToGenericControllerComponent(state, ownProps)
)(ContentElementsController)
