/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToGenericControllerComponent } from '../../utils/mappings'
import ContentFooterView from './views/content-footer.view'
import FooterButtonSendArticleController
	from './buttons/footer-button-send-article.controller'
import FooterButtonReadingListController
	from './buttons/footer-button-reading-list.controller'

class ContentFooterController extends Component {
	render () {
		const { showSendButton, readingListInformation } = this.props
		const rlButton = readingListInformation !== null && <FooterButtonReadingListController readingListInformation={readingListInformation}/>
		const saButton = showSendButton && <FooterButtonSendArticleController />

		return (
			<ContentFooterView>
				{rlButton}
				{saButton}
			</ContentFooterView>
		)
	}
}

ContentFooterController.defaultProps = {
	showSendButton: false,
	readingListInformation: null
}

if (__DEV__) {
	ContentFooterController.propTypes = {
		showSendButton: propTypes.bool,
		readingListInformation: FooterButtonReadingListController.sharedPropTypes.readingListInformation,

		dispatch: propTypes.func.isRequired
	}
}

export default connect(
	(state, ownProps) => mapAppStateToGenericControllerComponent(state, ownProps)
)(ContentFooterController)
