/* global __DEV__ */
/* eslint-disable no-fallthrough */
import * as types from '../constants/action-types'
import propTypes from 'prop-types'

const name = 'modal'

/**
 * We use a function here to prevent passing an object reference.
 *
 * Otherwise this could happen:
 * 1. Use initialState reference for initialisation. state.foo is now a
 *    reference to initialState.foo if foo is an object or array.
 * 2. Some action sets state.foo.bar.
 *    This is where the problem occurs: initialState.foo.bar now has been
 *    changed.
 * 3. Some other action wants to reset state.foo by assigning initialState.foo.
 *    But instead of having the actual initial value of state.foo.bar it now has
 *    the value that got set in 2.)
 *
 * @return {Object}
 */
const getInitialState = () => {
	return {
		modals: [],
		lastModal: null,
		lastModalClosedAt: 0
	}
}

const LAST_MODAL_REPETITION_DURATION_MS = 1000 * 5

function modalsHaveSameSignature (modalA, modalB) {
	return modalA &&
		modalB &&
		modalA.title === modalB.title &&
		modalA.message === modalB.message
}

function getRepetionCount (modal, state) {
	let count = 0

	if (state.modals.length === 0) {
		if (state.lastModal === null) {
			count = 0
		} else {
			let now = new Date().getTime()
			if ((now - state.lastModalClosedAt) > LAST_MODAL_REPETITION_DURATION_MS) {
				// keep 0
			} else {
				if (modalsHaveSameSignature(state.lastModal, modal)) {
					count = state.lastModal.repetitionCount + 1
				} else {
					// keep 0
				}
			}
		}
	} else {
		let prevModal = state.modals[state.modals.length - 1]
		if (modalsHaveSameSignature(prevModal, modal)) {
			count = prevModal.repetitionCount + 1
		} else {
			// keep 0
		}
	}

	return count
}

const reducer = function (state = getInitialState(), action) {
	let newState = { ...state }

	switch (action.type) {
		case types.MODAL_CREATE:
			let newModal = {
				title: action.title,
				message: action.message,
				actionConfirm: action.actionConfirm,
				actionCancel: action.actionCancel,
				labelConfirm: action.labelConfirm,
				labelCancel: action.labelCancel
			}

			newModal.repetitionCount = getRepetionCount(newModal, newState)

			newState.modals.push(newModal)

			return newState

		case types.MODAL_CLOSE:
			let lastModal = newState.modals.shift()

			// Memorize last modal to be able to emphazize that a
			// second identical modal is indeed a new one.
			newState.lastModal = lastModal
			newState.lastModalClosedAt = new Date().getTime()

			return newState

		default:
			return state
	}
}

// TODO TAG_STATE_NORMALIZATION Check if we can refactor to object only.
//  Currently we use some action creators that return functions
//  e.g. resetSendArticleAndGoBack()
const modalActionPropType = __DEV__ && propTypes.oneOfType([
	propTypes.shape({
		type: propTypes.string.isRequired
	}),
	propTypes.func
])

const modalPropTypes = __DEV__ && propTypes.exact({
	title: propTypes.string.isRequired,
	message: propTypes.string.isRequired,
	actionConfirm: modalActionPropType,
	actionCancel: modalActionPropType,
	labelConfirm: propTypes.string.isRequired,
	labelCancel: propTypes.string.isRequired,
	repetitionCount: propTypes.number.isRequired
})

const reducerPropTypes = __DEV__ && propTypes.exact({
	modals: propTypes.arrayOf(
		modalPropTypes
	).isRequired,
	lastModal: modalPropTypes,
	lastModalClosedAt: propTypes.number.isRequired
}).isRequired

const modalReducerDefinition = {
	name,
	/**
	 * We want to be able to use reducerDefinition.initialState without
	 * polluting initial state.
	 *
	 * @return {Object}
	 */
	get initialState () {
		return getInitialState()
	},
	reducer,
	reducerPropTypes
}

export default modalReducerDefinition
