/* global __DEV__ */
/* eslint-disable react/prop-types */
import React from 'react'
import { Link } from 'react-router-dom'
import propTypes from 'prop-types'

function CategoryTopicsListItemView (props) {
	const { topic, link, keyIndex, classes, handleTopicView } = props

	return (
		<div key={keyIndex} className='a-list-item'>

			<Link
				className={classes}
				onClick={handleTopicView}
				to={link}> {topic.title}
			</Link>

		</div>
	)
}

if (__DEV__) {
	CategoryTopicsListItemView.propTypes = {
		keyIndex: propTypes.number,
		topic: propTypes.object.isRequired,
		handleTopicView: propTypes.func,
		classes: propTypes.array
	}
}

export default CategoryTopicsListItemView
