/* global __DEV__ */
import React, { Fragment } from 'react'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'

export function SearchListItemView (props) {
	const { item, linkTo } = props
	return (
		<div className={'a-list-item'}>
			<Link to={linkTo}>{item.title}</Link>
		</div>
	)
}

if (__DEV__) {
	SearchListItemView.propTypes = {
		item: propTypes.object,
		linkTo: propTypes.string
	}
}

export default SearchListItemView
