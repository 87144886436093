/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToUpdateButtonControllerComponent } from '../../../../src/utils/mappings'
import UpdateButtonView from './views/update-button.view'
import Updater from '../../../utils/content-storage-controller/Updater'
import store from '../../../store'

class LogoutButtonController extends Component {
	constructor (props) {
		super(props)

		// Bind once, instead of binding everytime anew while rendering.
		this.onUpdateButtonClick = this.onUpdateButtonClick.bind(this)
	}

	onUpdateButtonClick (/* e */) {
		Updater.update(this.props.dispatch, store.getState().content)
	}

	render () {
		return (
			<UpdateButtonView onClick={this.onUpdateButtonClick} />
		)
	}
}

if (__DEV__) {
	LogoutButtonController.propTypes = {
		dispatch: propTypes.func.isRequired
	}
}

export default connect(
	(state, ownProps) => mapAppStateToUpdateButtonControllerComponent(state, ownProps)
)(LogoutButtonController)
