/* global __DEV__ */
import React from 'react'
import i18n from './../../../i18n'

function LoadableDummyLoaderView (props) {
	return (
		<div className={'o-detail-view o-detail-view--no-tab'}>
			<h1 data-cy={'main-header'} className="a-headline a-headline--h1">{i18n.t('TXT_GENERAL_LOADING.label')} ...</h1>
			<div className="c-m-load-indicator c-m-load-indicator--inline"><span>{i18n.t('TXT_GENERAL_LOADING.label')} ...</span></div>
		</div>
	)
}

LoadableDummyLoaderView.defaultProps = {
}

if (__DEV__) {
	// Properties types validation
	LoadableDummyLoaderView.propTypes = {
	}
}

// Export component
export default LoadableDummyLoaderView
