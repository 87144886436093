/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'

function PressReleasesListItemView (props) {
	// Assign all properties to variables for convenience
	const { title, link, date, classes } = props

	return (
		<div className="a-list-item">
			<Link className={classes} to={link}>
				<span>{date} | </span>
				<span>{title}</span>
			</Link>
		</div>
	)
}

PressReleasesListItemView.defaultProps = {
	classes: ''
}

if (__DEV__) {
	// Properties types validation
	PressReleasesListItemView.propTypes = {
		// id: propTypes.string,
		title: propTypes.string,
		link: propTypes.string,
		date: propTypes.string,
		classes: propTypes.string
	}
}

// Export component
export default PressReleasesListItemView
