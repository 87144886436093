exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/**\n * Make sass variables available to JS.\n * @see ./src/constants/sassVariables.js\n */\n", ""]);

// exports
exports.locals = {
	"pageTransitionsDurationMS": "500"
};