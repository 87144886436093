/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'
import { imagesPropType } from '../content-element-image.controller'

function ContentElementVideoView (props) {
	let { images, title, youtubeId } = props

	return (
		<div className="a-iframe">
				<iframe
					width="100%"
					src={"https://www.youtube.com/embed/" + youtubeId}
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				/>
		</div>
	)
}

ContentElementVideoView.defaultProps = {
	images: [],
	title: '',
	youtubeId: ''
}

if (__DEV__) {
	// Properties types validation
	ContentElementVideoView.propTypes = {
		images: imagesPropType,
		title: propTypes.string,
		youtubeId: propTypes.string.isRequired
	}
}

// Export component
export default ContentElementVideoView
