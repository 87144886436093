/* global __DEV__ */
import React, { Component, Fragment } from 'react'
import { Route } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import commonPropTypes from '../../utils/common-prop-types'
import sassVariables from '../../constants/sassVariables'
import PageController from './page.controller'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { mapAppStateToPagesControllerComponent } from '../../utils/mappings'

class PagesController extends Component {
	render () {
		let { children, prev, scrollTopBody } = this.props

		return (
			<Fragment>
				<div className={'pages' + (prev ? ' pages--prev' : '')}>
					<Route
						render={({ location }) => {
							const { pathname } = location
							return (
								<TransitionGroup component={null}>
									<CSSTransition
										key={pathname}
										classNames='page'
										timeout={sassVariables.pageTransitionsDurationMS}
									>
										{(transitionState) => {
											return (
												<Route
													location={location}
													render={() => (
														<PageController
															transitionState={transitionState}
															transitionKey={location.pathname}
															scrollTopBody={scrollTopBody || 0}
														>
															{ children }
														</PageController>
													)}
												/>
											)
										}}
									</CSSTransition>
								</TransitionGroup>
							)
						}}
					/>
				</div>
			</Fragment>
		)
	}
}

if (__DEV__) {
	PagesController.propTypes = {
		children: commonPropTypes.reactChildren,
		prev: propTypes.bool,
		scrollTopBody: propTypes.number
	}
}

export default connect(mapAppStateToPagesControllerComponent)(PagesController)
