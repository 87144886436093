import * as types from '../constants/action-types'
import ContentGetController from '../utils/content-storage-controller/content-get-controller'
import Logger from '../utils/logger'
import { syncMessageQueue } from './message-queue.actions'
import { syncReadingList } from './reading-list.actions'
import store, { subscribeContentSaveController, startUpdateController } from '../store'
import FMController from '../utils/files-management/fm.controller'
import { CachesVerifyHandler } from '../utils/files-management/persistancy/caches-verify-handler'
import CachesCorruptedHandler from '../utils/files-management/persistancy/caches-corrupted-handler'
import CookieNotice from '../utils/cookie-notice'
import { FirebaseController } from '../utils/push-notification'
import { getServiceWorkerRegistration } from '../utils/sw-helpers'
import ContentUpdateController from '../utils/content-storage-controller/content-update-controller'

// const smCookieNotice = new StorageManager(STORAGE_NAME_COOKIE_NOTICE)
import analytics from '../analytics/analytics'
import { history } from '../utils/browser-history'
import {
	connectionReactionsManager
} from '../utils/connection-status-reactions/ConnectionReactionsManager'
import MessageQueReaction from '../utils/connection-status-reactions/MessageQueReaction'
import ReadingListReaction
	from '../utils/connection-status-reactions/ReadingListReaction'
import MessageQueOperation from '../utils/daemon/operations/MessageQueOperation'
import { messageQueDaemon } from '../utils/daemon/daemons/MessageQueDaemon'

/**
 * @description Sets App loading and Data loading
 * @param loading
 * @param dataLoading
 * @returns {{type: string, loading: *, dataLoading: *}}
 */
export function setLoading (loading, dataLoading) {
	return {
		type: types.SET_LOADING,
		loading,
		dataLoading
	}
}

/**
 * @description Enables the loader layer.
 * @returns {{}}
 */
export function enableLayerLoader () {
	return {
		type: types.ENABLE_LAYER_LOADER
	}
}

/**
 * @description Disables the loader layer.
 * @returns {{}}
 */
export function disableLayerLoader () {
	return {
		type: types.DISABLE_LAYER_LOADER
	}
}

/**
 * @description Enables the loader offline.
 * @returns {{}}
 */
export function enableLayerOffline () {
	return {
		type: types.ENABLE_LAYER_OFFLINE
	}
}

/**
 * @description Disables the loader offline.
 * @returns {{}}
 */
export function disableLayerOffline () {
	return {
		type: types.DISABLE_LAYER_OFFLINE
	}
}

/**
 * @description Sets App CMS PREVIEW state
 * @param {boolean} cmsPreview
 */
export function setCmsPreview (cmsPreview) {
	return {
		type: types.SET_CMS_PREVIEW,
		cmsPreview: cmsPreview
	}
}

/**
 * @description Sets App single view state
 * @param singleView
 * @returns {{type: string, loading: *, dataLoading: *}}
 */
export function setSingleView (singleView) {
	return {
		type: types.SET_SINGLE_VIEW,
		singleView: singleView
	}
}

/**
 * @description Sets App single view error state
 * @param singleViewError
 * @returns {{type: string, loading: *, dataLoading: *}}
 */
export function setSingleViewError (singleViewError) {
	return {
		type: types.SET_SINGLE_VIEW_ERROR,
		singleViewError: singleViewError
	}
}

// TODO: Move header actions and reducer to another file
/**
 * @description Opens / closes side menu
 * @param isOpen -> true to open, false to close
 * @returns {{type: string, isOpen: *}}
 */
export function openSideMenu (isOpen) {
	return {
		type: types.OPEN_SIDE_MENU,
		isOpen
	}
}

/**
 * @description Sets title
 * @param title
 * @returns {{type: string, title: *}}
 */
export function setTitle (title) {
	return {
		type: types.SET_TITLE,
		title
	}
}

/**
 * @description Creates object to easy access topics
 * @param {Object} contentData
 * @param {Integer} timestamp
 * @returns {{type: string, contentData: {}, readingListData: {}}}
 */
export function injectData (contentData, timestamp = null) {
	return {
		type: types.INJECT_DATA,
		contentData,
		timestamp
	}
}

export function setTimestamp (timestamp) {
	return {
		type: types.SET_TIMESTAMP,
		timestamp
	}
}

async function verifyAndFixStaticCaches () {
	let log = new Logger('STATIC CACHE VERIFIER')
	log.startGroup()
	let verifier = new CachesVerifyHandler()
	let verification = await verifier.verifyCacheStorage()
	log.log('VERIFICATION RESULT', verification)
	if (verification.ok === false) {
		await CachesCorruptedHandler.handle(verification.urls)
		try {
			CachesCorruptedHandler.removeOldBuilds(verifier.staticAssets)
		} catch (e) {
			console.warn(e)
		}
	}
	log.endGroup()
}

function dataPreparationErrorHandling (dataResponseContent) {
	if (!dataResponseContent.success) {
		// TODO ERROR_HANDLING

		if (!dataResponseContent.supported) {
			// TODO ERROR_HANDLING
		}
	}
}

/**
 * Initializes Reactions to online status changes.
 *
 * @param {function} dispatch
 */
function initConnectionStatusReactions (dispatch) {
	connectionReactionsManager.addReaction(new MessageQueReaction(dispatch))
	connectionReactionsManager.addReaction(new ReadingListReaction(dispatch))
}

/**
 * Initializes daemons
 * @param dispatch
 */
function initDaemons (dispatch) {
	try {
		messageQueDaemon.addOperation(new MessageQueOperation(dispatch))
	} catch (e) {
		console.warn(e)
	}
}

/**
 * @description Fetches and prepares data for on app load
 * @param singleViewToken
 * @param loggedin
 * @returns {Function}
 */
export function dataPreparationPreview (singleViewToken) {
	// TODO: I18N Messages
	return async function (dispatch) {
		/* get data depending from given singleViewToken */
		let dataResponseContent
		try {
			dataResponseContent = await ContentGetController.handleGetContent(singleViewToken)
		} catch (e) {
			dispatch(setSingleViewError(true))
			dispatch(setLoading(false, false))
			return
		}

		initConnectionStatusReactions(dispatch)

		dispatch(syncMessageQueue())

		dataPreparationErrorHandling(dataResponseContent)

		if (dataResponseContent.online) {
			await verifyAndFixStaticCaches()
			let fm = new FMController(dataResponseContent.rawData)
			fm.prepareDownload()
			fm.download()
		}

		dispatch(setOnline(dataResponseContent.online))
		dispatch(injectData(dataResponseContent.rawData))
		dispatch(syncReadingList())
		subscribeContentSaveController(store)
		dispatch(setLoading(false, false))
		dispatch(prepareFirebase())
	}
}

/**
 * @description Fetches and prepares data for on app load
 * @returns {Function}
 */
export function dataPreparation (singleViewToken = null) {
	// TODO: I18N Messages
	return async function (dispatch) {
		let dataResponseContent

		try {
			dataResponseContent = await ContentGetController.handleGetContent(singleViewToken, true)
		} catch (e) {
			dispatch(setSingleViewError(true))
			dispatch(setLoading(false, false))
			return
		}

		initConnectionStatusReactions(dispatch)
		initDaemons(dispatch)

		dispatch(syncMessageQueue())

		dataPreparationErrorHandling(dataResponseContent)

		if (dataResponseContent.online) {
			await verifyAndFixStaticCaches()
			let fm = new FMController(dataResponseContent.rawData)
			fm.prepareDownload()
			fm.download()
		}

		dispatch(setOnline(dataResponseContent.online))
		dispatch(injectData(dataResponseContent.rawData, dataResponseContent.timestamp))
		dispatch(syncReadingList())
		subscribeContentSaveController(store)
		startUpdateController(store)
		dispatch(setLoading(false, false))
		dispatch(prepareFirebase())
	}
}

/**
 * @description Injects normalized data updates into store's state.content
 * @param {Object} data
 * @returns {{type: string, content: *}}
 */
export function injectDataUpdates (data) {
	return {
		type: types.INJECT_DATA_UPDATES,
		contentData: data
	}
}

export function prepareFirebase () {
	return async () => {
		let firebaseController = new FirebaseController(await getServiceWorkerRegistration())
		await firebaseController.askForPermissionToReceiveNotifications()
	}
}

export function setActiveSectionMenuTab (sectionKey) {
	return {
		type: types.SECTIONMENU_SET_ACTIVE_TAB,
		sectionKey
	}
}

export function setNextScrollPosition (scrollTopWindow, scrollTopBody) {
	return {
		type: types.NEXT_SCROLL_POSITION,
		scrollTopWindow,
		scrollTopBody
	}
}

/**
 * Dummy action for general development and testing purpose.
 *
 * @return {{arguments: Array, type: string}}
 */
export function debugLog () {
	return {
		type: types.DEBUG_LOG,
		args: arguments
	}
}

export function doSetOnline (isOnline) {
	return {
		type: types.SET_ONLINE,
		online: isOnline
	}
}

export function setOnline (isOnline) {
	return dispatch => {
		dispatch(doSetOnline(isOnline))
		connectionReactionsManager.executeReactions(isOnline)
	}
}

export function syncCookieNoticeState () {
	return {
		type: types.SYNC_COOKIE_NOTICE_STATE,
		accepted: CookieNotice.accepted,
		closed: CookieNotice.closed
	}
}

export function unsetCookieNotice () {
	CookieNotice.unset()

	return async function (dispatch) {
		dispatch(syncCookieNoticeState())
	}
}

export function acceptCookieNotice () {
	CookieNotice.accept()

	return async function (dispatch) {
		dispatch(syncCookieNoticeState())
		analytics.trackPageVisit(history.localHistory.getEntry().location)
		// TODO track cookie acceptance as entry point for tracking
	}
}

export function declineCookieNotice () {
	CookieNotice.decline()

	return async function (dispatch) {
		dispatch(syncCookieNoticeState())
	}
}
