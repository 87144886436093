/*
 * TODO DATA_LAYER Check if service worker and data layer can be separated,
 *                 so that they do not use these consts together.
 */

export const CACHE_NAME = 'VFA'
export const AUTH_DATA_CACHE_NAME = '/intern-logic/auth-data'
export const IS_INITIAL_HEADER_NAME = 'isInit'

export const INSTALLATION_FAILED_RESPONSE_STATUS = 599
export const INSTALLATION_FAILED_RESPONSE_STATUS_TEXT = 'INSTALLATION_FAILED'
export const REQUEST_FAILED_RESPONSE_STATUS_TEXT = 'REQUEST_FAILED'

export const IS_UP_TO_DATE = 'IS_UP_TO_DATE'
export const UPDATE_FAILED_RESPONSE_STATUS = 'UPDATE_FAILED'

export const CONTENT_STORAGE_NAMESPACE = 'CONTENT_STORAGE_NAMESPACE'
export const QUEUE_NAMESPACE = 'QUEUE_NAMESPACE'
export const MESSAGE_QUEUE_NAMESPACE = 'MESSAGE_QUEUE_NAMESPACE'
export const READING_LIST_QUEUE_NAMESPACE = 'READING_LIST_QUEUE_NAMESPACE'
export const CONTENT_OBJECT_KEY = 'CONTENT'

export const CONTENT_NAMES_WITH_FILES = ['topics', 'news', 'sliderElements', 'categories']

export const STATIC_CACHE_NAME = 'VFA_STATIC'
export const DYNAMIC_CACHE_NAME = 'VFA_DYNAMIC'
export const ASSET_MANIFEST_URL = '/asset-manifest.json'
export const STATIC_FILES_ARRAY = ['/', '/manifest.json']

export const FILES_PROPS = ['image_bg', 'image', 'files']

export const IS_STATIC_HEADER = 'IS_STATIC'
export const IS_IMAGE_HEADER = 'IS_IMAGE'

export const LOGIN_PROCESS_PA_APP = 'pa-app'
export const LOGIN_PROCESS_SIMPLE = 'simple'

export const ONLINE_BODY_CLASS = 'online'
export const OFFLINE_BODY_CLASS = 'offline'

export const SINGLE_VIEW_BODY_CLASS = 'single-view'

export const APP_CONTENT_TYPE_ARTICLES = 'topics'
export const APP_CONTENT_TYPE_PRESS_RELEASES = 'pressReleases'
export const APP_CONTENT_TYPE_NEWS = 'news'

export const BUILT_FILE_MARKER = 'bundle.js'
export const BUILDINFO_NAME = 'buildinfo'
export const MAINJS_NAME = 'main'
export const MAINJS_FILENAME = 'main.js'
export const BUILDINFO_FILENAME = 'buildinfo.js'

export const CONTENT_ELEMENT_TYPE_UNKNOWN = 'content-element-type-unknown'

export const MESSAGE_QUE_OPERATION = 'MESSAGE_QUE_OPERATION'
export const DAEMON_BASE_NAME = 'DAEMON_BASE'
export const DAEMON_MESSAGE_QUE_NAME = 'DAEMON_MESSAGE_QUE'

/* some content types */
export const CONTENT_TYPE_ARTICLE = 'article'
export const CONTENT_TYPE_TOPIC = 'article'
export const CONTENT_TYPE_NEWS = 'newsmeldung'
export const CONTENT_TYPE_PRESSRELEASE = 'pressrelease'

export const PUSH_NOTIFICATIONS_NAMESPACE = 'PUSH_NOTIFICATIONS_NAMESPACE'
export const PN_USER_TOKEN = 'PN_USER_TOKEN'

/**
 * DO NOT CHANGE. hard coded in API
 * @type {string}
 */
export const PREVIEW_ROUTE_PATH_SEGMENT = 'view'
export const PREVIEW_ROUTE_TOKEN_NAME = 'token'
export const CMS_PREVIEW_ROUTE_PATH_SEGMENT = 'preview'

/**
 * Keep up to date with service workers MESSAGE_TYPES in public/service-worker/constants/sw.constants.js
 */
export const SW_MESSAGE_TYPE_GET_VERSION = 'get-version'
export const SW_MESSAGE_TYPE_RELOAD_CLIENTS = 'reload-clients'
