/* global __DEV__ */
import React from 'react'
import commonPropTypes from '../../../utils/common-prop-types'

function NewsListView (props) {
	// Assign all properties to variables for convenience
	const { children } = props

	return (
		<div className="o-news-overview">
			{ children }
		</div>
	)
}

if (__DEV__) {
	// Properties types validation
	NewsListView.propTypes = {
		children: commonPropTypes.reactChildren
	}
}

// Export component
export default NewsListView
