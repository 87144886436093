/* global __DEV__ */
import React, { Fragment } from 'react'
import LinkAll from '../../general/link-all'
import SlideView from '../../general/slider/views/slide.view'
import SliderController from '../../general/slider/slider.controller'
import routes from '../../../utils/routes'
import AccordionController from '../../general/accordion/accordion.controller'
import propTypes from 'prop-types'
import commonPropTypes from '../../../utils/common-prop-types'

// TODO @tb Update examples as soon as the slider works with generic classNames.
export const examplesForSlider = function () {
	return (
		<Fragment>
			<h2 className='a-headline a-headline--h2'>Slides als children</h2>
			<SliderController>
				<SlideView headline='Slide 1' />
				<SlideView headline='Slide 2' />
				<SlideView headline='Slide 3' />
			</SliderController>

			<br />
			<br />

			<h2 className='a-headline a-headline--h2'>Slides als data-array</h2>
			<SliderController data={[
				{
					image: 'https://cmzone-vzbqbxhynotw9ion96xv.netdna-ssl.com/wp-content/uploads/2017/07/the-wall-wallpaper.jpg',
					link: 'https://vfa.de',
					headline: 'Test 1 (data)',
					text: 'Bei etlichen Krebsarten gab es ganz erhebliche Behandlungs-Fortschritte in den vergangenen 15 Jahren.'
				},
				{ headline: 'Test 2 (data)' },
				{ headline: 'Test 3 (data)' }
			]}/>
		</Fragment>
	)
}

export const examplesForLinkAll = function () {
	return (
		<Fragment>
			<LinkAll linkType={LinkAll.AUTO } to='https://www.ressourcenmangel.de'>linkType: AUTO, to: https://www.ressourcenmangel.de, works</LinkAll><br />
			<LinkAll linkType={LinkAll.ROUTE} to='https://www.ressourcenmangel.de'>linkType: ROUTE, to: https://www.ressourcenmangel.de, invalid routing</LinkAll><br />
			<LinkAll linkType={LinkAll.LINK } to='https://www.ressourcenmangel.de'>linkType: LINK, to: https://www.ressourcenmangel.de, works</LinkAll><br />
			<br />
			<LinkAll linkType={LinkAll.AUTO } to={routes.stakeholdersList.build()}>linkType: AUTO, to: /stakeholders, works</LinkAll><br />
			<LinkAll linkType={LinkAll.ROUTE} to={routes.stakeholdersList.build()}>linkType: ROUTE, to: /stakeholders, works</LinkAll><br />
			<LinkAll linkType={LinkAll.LINK } to={routes.stakeholdersList.build()}>linkType: LINK, to: /stakeholders, reloads page and routes afterwards</LinkAll><br />
			<br />
			<LinkAll linkType={LinkAll.AUTO } to='mailto:mailto:dresden@ressourcenmangel.de'>linkType: AUTO, to: mailto:dresden@ressourcenmangel.de, works</LinkAll><br />
			<LinkAll linkType={LinkAll.ROUTE} to='mailto:mailto:dresden@ressourcenmangel.de'>linkType: ROUTE, to: mailto:dresden@ressourcenmangel.de, invalid routing</LinkAll><br />
			<LinkAll linkType={LinkAll.LINK } to='mailto:mailto:dresden@ressourcenmangel.de'>linkType: LINK, to: mailto:dresden@ressourcenmangel.de, works</LinkAll><br />
			<br />
			<LinkAll linkType={LinkAll.AUTO } to='/bundle.js'>linkType: AUTO, to: /bundle.js, invalid routing</LinkAll><br />
			<LinkAll linkType={LinkAll.ROUTE} to='/bundle.js'>linkType: ROUTE, to: /bundle.js, invalid routing</LinkAll><br />
			<LinkAll linkType={LinkAll.LINK } to='/bundle.js'>linkType: LINK, to: /bundle.js, works</LinkAll><br />
		</Fragment>
	)
}

const examplesForAccordionController = function () {
	return (
		<Fragment>
			<p>Accordions can be controlled (redux state) and uncontrolled (component state).</p>

			{/* CONTROLLED */}

			<h2 className='a-headline a-headline--h2'>I. Controlled</h2>
			<h2 className='a-headline a-headline--h2'>Example I.1 - basic</h2>
			<AccordionController
				accordionKey='dev-examples-1'
				data={[
					{ title: 'Akkordeon 1a', content: 'Eins, ah.' },
					{ title: 'Akkordeon 1b', content: 'Eins, beh.' }
				]}
			/>
			<h2 className='a-headline a-headline--h2'>Example I.2 - basic, linked to example I.3</h2>
			<AccordionController
				accordionKey='dev-examples-2-3'
				data={[
					{ title: 'Akkordeon 2a', content: 'Zwei, ah.' },
					{ title: 'Akkordeon 2b', content: 'Zwei, beh.' }
				]}
			/>
			<h2 className='a-headline a-headline--h2'>Example I.3 - basic, linked to example I.2</h2>
			<AccordionController
				accordionKey='dev-examples-2-3'
				data={[
					{ title: 'Akkordeon 3a', content: 'Drei, ah.' },
					{ title: 'Akkordeon 3b', content: 'Drei, beh.' }
				]}
			/>
			<h2 className='a-headline a-headline--h2'>Example I.4 - initially closed</h2>
			<AccordionController
				accordionKey='dev-examples-4'
				initiallyClosed
				data={[
					{ title: 'Akkordeon 4a', content: 'Vier, ah.' },
					{ title: 'Akkordeon 4b', content: 'Vier, beh.' }
				]}
			/>
			<h2 className='a-headline a-headline--h2'>Example I.5 - non-closable</h2>
			<AccordionController
				accordionKey='dev-examples-5'
				closable={false}
				data={[
					{ title: 'Akkordeon 5a', content: 'Fünf, ah.' },
					{ title: 'Akkordeon 5b', content: 'Fünf, beh.' }
				]}
			/>
			<h2 className='a-headline a-headline--h2'>Example I.6 - initially closed, non-closable</h2>
			<AccordionController
				accordionKey='dev-examples-6'
				initiallyClosed
				closable={false}
				data={[
					{ title: 'Akkordeon 6a', content: 'Sechs, ah.' },
					{ title: 'Akkordeon 6b', content: 'Sechs, beh.' }
				]}
			/>

			{/* UNCONTROLLED */}

			<h2 className='a-headline a-headline--h2'>II. Uncontrolled</h2>
			<h3 className='a-headline a-headline--h3'>Example II.1 - basic</h3>
			<AccordionController
				data={[
					{ title: 'Akkordeon 1a', content: 'Eins, ah.' },
					{ title: 'Akkordeon 1b', content: 'Eins, beh.' }
				]}
			/>
			<h3 className='a-headline a-headline--h3'>Example II.2 - initially closed</h3>
			<AccordionController
				initiallyClosed
				data={[
					{ title: 'Akkordeon 2a', content: 'Zwei, ah.' },
					{ title: 'Akkordeon 2b', content: 'Zwei, beh.' }
				]}
			/>
			<h3 className='a-headline a-headline--h3'>Example II.3 - non-closable</h3>
			<AccordionController
				closable={false}
				data={[
					{ title: 'Akkordeon 3a', content: 'Drei, ah.' },
					{ title: 'Akkordeon 3b', content: 'Drei, beh.' }
				]}
			/>
			<h3 className='a-headline a-headline--h3'>Example II.4 - initially closed, non-closable</h3>
			<AccordionController
				initiallyClosed
				closable={false}
				data={[
					{ title: 'Akkordeon 4a', content: 'Vier, ah.' },
					{ title: 'Akkordeon 4b', content: 'Vier, beh.' }
				]}
			/>
		</Fragment>
	)
}

// eslint-disable-next-line react/prop-types
const examplesForCookieNotice = function ({ acceptCookies, declineCookies, unsetCookies, cookieNotice }) {
	return <Fragment>
		<a className="a-button a-button--small a-button--inline" href="#" onClick={acceptCookies}> Cookies akzeptieren</a>
		<a className="a-button a-button--small a-button--inline" href="#" onClick={declineCookies}>Cookies ablehnen</a>
		<a className="a-button a-button--small a-button--inline" href="#" onClick={unsetCookies}>  Cookies-Akzeptanz zurücksetzen</a>
		<p>
			accepted: {cookieNotice.accepted ? 'true' : 'false'}
			<br />
			closed: {cookieNotice.closed ? 'true' : 'false'}
		</p>
	</Fragment>
}

function DevExamplesView (props) {
	return (
		<div className='o-detail-view'>
			Diese Seite dient der Demonstration der Nutzung bestehender Komponenten.
			Relevant ist insbesondere der Code der DevExamplesView.
			Dort können Entwickler einsehen, wie sie die Komponenten nutzen können.

			<hr />

			<h2 className='a-headline a-headline--opener'>AccordionController</h2>
			{examplesForAccordionController()}

			<hr />

			<h2 className='a-headline a-headline--opener'>SliderView</h2>
			{examplesForSlider()}

			<hr />

			<h2 className='a-headline a-headline--opener'>LinkAll</h2>
			{examplesForLinkAll()}

			<hr />

			<h2 className='a-headline a-headline--opener'>Cookie Notice</h2>
			{examplesForCookieNotice(props)}
		</div>
	)
}

if (__DEV__) {
	// Properties types validation
	DevExamplesView.propTypes = {
		cookieNotice: commonPropTypes.cookieNotice.isRequired,
		acceptCookies: propTypes.func.isRequired,
		declineCookies: propTypes.func.isRequired,
		unsetCookies: propTypes.func.isRequired
	}
}

// Export component
export default DevExamplesView
