import React, { Fragment } from 'react'

function ImprintView (props) {
	return (
		<div className={'o-detail-view o-detail-view--no-tab'}>
			<p>
				Verband Forschender Arzneimittelhersteller e.V.<br />
				Hausvogteiplatz 13<br />
				10117 Berlin<br />
				Telefon: +49 30 20604-0<br />
				Fax: +49 30 20604-222<br />
				Email: <a href={'mailto:info@vfa.de'}>info@vfa.de</a><br />
				Internet: <a href={'https://www.vfa.de'}>www.vfa.de</a>
			</p>

			<p>
				Kontakt zum Messagecockpit: <a href={'mailto:m.butte@vfa.de'}>m.butte@vfa.de</a>
			</p>

			<h2 className={'a-headline a-headline--h2'}>Allgemeine Informationspflichten nach § 5 TMG</h2>
			<h3 className={'a-headline a-headline--h2'}>Vertretungsberechtigte:</h3>
			<p>
				Han Steutel<br />
				Präsident<br />
				<br />
				vfa<br />
				Hausvogteiplatz 13<br />
				10117 Berlin<br />
				Telefon: +49 30 20604-100<br />
				Fax: +49 30 20604-103
			</p>
			<h3 className={'a-headline a-headline--h2'}>Der vfa ist eingetragen im Vereinsregister:</h3>
			<p>
				Registernummer VR 20153 B<br />
				Vereinsregister Berlin<br />
				Amtsgericht Charlottenburg<br />
				Amtsgerichtsplatz 1<br />
				14057 Berlin-Charlottenburg
			</p>
		</div>
	)
}

// Export component
export default ImprintView
