import {
	GLAMUS_CONTENT_TYPE_ARTICLES,
	GLAMUS_CONTENT_TYPE_NEWS
} from './constants'
import {
	DataModifierArticleDetailNormalized
} from './data-modifier-article-detail-normalized'
import {
	APP_CONTENT_TYPE_ARTICLES,
	APP_CONTENT_TYPE_NEWS
} from '../../../../constants/names'

const DATA_MODIFIER_SLIDER_NORMALIZED_IMAGE_DUMMY = 'https://dummyimage.com/730x400/5771c7/fff.png'

/**
 * This class adds data that does not exist in the api already.
 * That data has to match the normalized state structure.
 */
class DataModifierSliderNormalized {
	/**
	 * Returns a default slider element.
	 *
	 * @param obj
	 * @param data
	 * @returns {{image: string, link: string, text: string, headline: string}}
	 */
	static getDefaultElement (obj = {}, data = {}) {
		let imageFirst = obj.teaserImages.length > 0 ? obj.teaserImages[0] : null

		return {
			link: DataModifierSliderNormalized.getLink(obj, data),
			image: imageFirst === null ? DATA_MODIFIER_SLIDER_NORMALIZED_IMAGE_DUMMY : imageFirst.url,
			headline: obj.title ? obj.title : '',
			text: obj.teaser ? obj.teaser : ''
		}
	}

	/**
	 * Modify news slider elements.
	 *
	 * @param data
	 * @param dataApi
	 */
	static addElements (data, dataApi) {
		data.sliderElements = data.sliderElements ? data.sliderElements : []

		dataApi.forEach(function (sliderElementApi) {
			data.sliderElements.push(DataModifierSliderNormalized.getDefaultElement(sliderElementApi, data))
		})
	}

	/**
	 * Build the link depending on the object type.
	 *
	 * @param obj
	 * @param data
	 * @returns {*}
	 */
	static getLink (obj = {}, data = {}) {
		obj.type = obj.type ? obj.type : 'unknown'

		let id = DataModifierSliderNormalized.getId(obj)
		let template

		switch (obj.type) {
			case GLAMUS_CONTENT_TYPE_NEWS:
				template = '/news/%id'
				return template.replace(/%id/, id)

			case GLAMUS_CONTENT_TYPE_ARTICLES:
				template = '/categories/%category/%id'
				let category = id in data.topics && data.topics[id].category ? data.topics[id].category : null
				return template.replace(/%category/, category).replace(/%id/, id)

			default:
				throw Error('Unknown type')
		}
	}

	/**
	 * Build the id depending on the object type.
	 *
	 * @param obj
	 * @returns {*}
	 */
	static getId (obj = {}) {
		obj.type = obj.type ? obj.type : 'unknown'

		let namespaceTranslator = {}
		namespaceTranslator[GLAMUS_CONTENT_TYPE_NEWS] = APP_CONTENT_TYPE_NEWS
		namespaceTranslator[GLAMUS_CONTENT_TYPE_ARTICLES] = APP_CONTENT_TYPE_ARTICLES

		if (!(obj.type in namespaceTranslator)) {
			throw Error('Unknown type')
		}

		return DataModifierArticleDetailNormalized.getId({ id: obj.itemId }, namespaceTranslator[obj.type])
	}
}

export { DataModifierSliderNormalized }
