/* global __DEV__ */
import React from 'react'
import commonPropTypes from '../../../utils/common-prop-types'

const CategoryListView = (props) => {
	const { children } = props

	return (

		<div className="o-teaser-list">
			{ children }
		</div>

	)
}

if (__DEV__) {
	CategoryListView.propTypes = {
		children: commonPropTypes.reactChildren
	}
}

export default CategoryListView
