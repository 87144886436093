/* global Array */

import {
	SETTINGS_UPDATE_CHECKBOX_FIELD,
	SETTINGS_SET_EMAIL_CHECKBOX_FIELDS,
	SETTINGS_SET_STATUS
} from '../constants/action-types'
import {
	CreateGlamusGetSettingsModel,
	CreateGlamusSetSettingsModel
} from '../data-layer/implementations/glamus-api/lib/models'
import { createModal } from './modal.actions'
import i18n from '../i18n'
import store from '../store'
import { LoginProcess } from '../utils/login-process.js'
import { disableLayerLoader, enableLayerOffline, disableLayerOffline } from './basic.actions'

/* settings */
export function updateSettingsCheckboxField (name, value, checked) {
	return {
		type: SETTINGS_UPDATE_CHECKBOX_FIELD,
		name,
		value,
		checked
	}
}

export function setSettingsCheckboxFields (name, values) {
	return {
		type: SETTINGS_SET_EMAIL_CHECKBOX_FIELDS,
		name,
		values
	}
}

export function setSaveStatus (code, message) {
	return {
		type: SETTINGS_SET_STATUS,
		code,
		message
	}
}

export function getSettings () {
	return async function (dispatch) {
		let state = store.getState()

		/* check online state */
		if (!state.basic.online) {
			dispatch(createModal(
				i18n.t('TXT_GENERAL_WORD_INFO.label'),
				i18n.t('TXT_SETTINGS_OFFLINE.label')
			))
			dispatch(enableLayerOffline())
			return {
				emails: []
			}
		}

		dispatch(disableLayerOffline())
		let modelGetSettings = CreateGlamusGetSettingsModel()
		let responseGetSettings = null
		try {
			responseGetSettings = await modelGetSettings.request()

			return {
				emails: responseGetSettings.data
			}
		} catch (e) {
			responseGetSettings = e.response

			/* We have handed over an invalid token and log out in here. */
			if (responseGetSettings.status === 401) {
				LoginProcess.logout(true)
			}

			return null
		}
	}
}

export function doSaveSettings (emails) {
	// Todo: enable loader
	return async function (dispatch) {
		let state = store.getState()

		/* check online mode */
		if (!state.basic.online) {
			dispatch(createModal(
				i18n.t('TXT_GENERAL_WORD_INFO.label'),
				i18n.t('TXT_SETTINGS_OFFLINE.label')
			))
			dispatch(disableLayerLoader())
			return false
		}

		let modelGetSettings = CreateGlamusSetSettingsModel(emails)
		let responseGetSettings = null
		try {
			responseGetSettings = await modelGetSettings.request()

			dispatch(setSaveStatus(200, ''))
			dispatch(disableLayerLoader())
			return true
		} catch (e) {
			responseGetSettings = e.response

			/* We have handed over an invalid token and log out in here. */
			if (responseGetSettings.status === 401) {
				LoginProcess.logout(true)
			}

			dispatch(setSaveStatus(responseGetSettings.status, responseGetSettings.data.message))
			dispatch(disableLayerLoader())
			return false
		}
	}
}
