/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'
import AccordionController from '../../general/accordion/accordion.controller'

function ContentElementAccordionView (props) {
	let { content } = props

	// TODO render content[].content via Parser(content)

	return (
			<AccordionController data={content} unsafeParseContent/>
	)
}

ContentElementAccordionView.defaultProps = {
	content: []
}

if (__DEV__) {
	// Properties types validation
	ContentElementAccordionView.propTypes = {
		content: propTypes.arrayOf(
			propTypes.shape({
				title: propTypes.string.isRequired,
				content: propTypes.oneOfType([
					propTypes.node,
					propTypes.arrayOf(
						propTypes.node
					),
					propTypes.string
				])
			})
		)
	}
}

// Export component
export default ContentElementAccordionView
