/* global __DEV__ */
import React, { Fragment } from 'react'
import propTypes from 'prop-types'

export function SearchInputViewFull (props) {
	const { handleSearchSubmit, handleSearchChange, searchRequest, suggestionsComponent, handleEnterClick } = props
	return (
		<Fragment>
			<input
				className={'a-input'}
				onKeyDown={ handleEnterClick.bind(this) }
				list="search-word-suggestions-full"
				type='text'
				placeholder={'Suchbegriff'}
				value={ searchRequest }
				onChange={ handleSearchChange.bind(this) }/>
			{ suggestionsComponent }
			<a className={'m-search-box__submit'} onClick={ handleSearchSubmit.bind(this) }></a>
		</Fragment>
	)
}

if (__DEV__) {
	SearchInputViewFull.propTypes = {
		suggestionsComponent: propTypes.object,
		searchRequest: propTypes.string,
		handleSearchSubmit: propTypes.func,
		handleSearchChange: propTypes.func,
		handleEnterClick: propTypes.func
	}
}

export default SearchInputViewFull
