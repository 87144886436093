/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'
import LinkAll from '../../general/link-all'

function HelpButtonView (props) {
	let { clickHandler } = props

	return (
		<div className='align-right' style={{ zIndex: 1, position: 'relative' }}>
			<LinkAll
				to='/update.html'
				linkType={LinkAll.LINK}
				onClick={clickHandler}
				className='a-button a-button--small a-button--light a-button--inline no-margin-top-important'
			>?</LinkAll>
		</div>
	)
}

if (__DEV__) {
	// Properties types validation
	HelpButtonView.propTypes = {
		clickHandler: propTypes.func.isRequired
	}
}

// Export component
export default HelpButtonView
