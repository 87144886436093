import { DataLayerResponseSendArticle } from '../../../lib/DataLayerResponse'

const sendArticle = async function (/* authData, emailTo, emailSubject, emailMessage, ids */) {
	return new DataLayerResponseSendArticle(
		false,
		false,
		'',
		''
	)
}

const paAppEmailService = {
	sendArticle
}

export default paAppEmailService
