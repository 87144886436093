/* global __DEV__ */
import React, { Component, Fragment } from 'react'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'
import SearchInputController from '../../../search/search-input/search-input.controller'
import UpdateButtonController from '../../../general/menu-buttons/update-button.controller'
import LogoutButtonController from '../../../general/menu-buttons/logout-button.controller'
import routes from '../../../../utils/routes'
import commonPropTypes from '../../../../utils/common-prop-types'

class SideMenuView extends Component {
	render () {
		const { classNames, logo, children, childrenMeta, closeMenu } = this.props
		const btnUpdate = __DEV__
			? (
				<li className={'m-menu__element'}>
					<UpdateButtonController/>
				</li>
			)
			: null

		return (
			<Fragment>
				<div id="side-menu" className={'m-menu ' + classNames }>

					<Link className={'m-menu__logo'} onClick={ closeMenu } to={ routes.root.build() }>

						<img src={logo} width="140" height="" alt='' />

					</Link>

					<SearchInputController callFromMenu={true}/>

					<ul className="m-menu__elements">
						{ children }
					</ul>

					<ul className="m-menu__elements m-menu__elements--meta">
						{childrenMeta}

						{btnUpdate}

						<li className={'m-menu__element m-menu__logout'}>
							<LogoutButtonController/>
						</li>
					</ul>

				</div>
			</Fragment>
		)
	}
}

if (__DEV__) {
	SideMenuView.propTypes = {
		closeMenu: propTypes.func.isRequired,
		logo: propTypes.string,
		classNames: propTypes.array,
		children: commonPropTypes.reactChildren,
		childrenMeta: propTypes.array
	}
}

export default SideMenuView
