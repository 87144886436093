/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'

const ImagePreloader = (props) => {
	return (
		'[Preloading IMAGE]'
	)
}

if (__DEV__) {
	ImagePreloader.propTypes = {
	}
}

export default ImagePreloader
