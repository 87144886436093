/* global __DEV__ */
import React from 'react'
import commonPropTypes from '../../../utils/common-prop-types'

function ContentFooterView (props) {
	let { children } = props

	return (
		<div className="m-content-footer">
			{children}
		</div>
	)
}

if (__DEV__) {
	// Properties types validation
	ContentFooterView.propTypes = {
		children: commonPropTypes.reactChildren
	}
}

// Export component
export default ContentFooterView
