/* global __DEV__ */
import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import NewsIndex from '../news'
import CategoriesIndex from '../categories'
import HomeIndex from '../home'
import SearchIndex from '../search'
import PressReleasesIndex from '../press-releases'
import StakeholdersIndex from '../stakeholders'
import ReadingListIndex from '../reading-list'
import MessageQueueIndex from '../message-queue'
import ImprintIndex from '../imprint'
import DevIndex from '../dev'
import routes from '../../utils/routes'
import NotFoundController from '../general/not-found.controller'
import PrivacyIndex from '../privacy'
import SettingsIndex from '../settings'
import PagesController from '../pages/pages.controller'
import PreviewSwitch from '../single-view/switch'
import ShortcutSwitch from '../shortcut-view/switch'
import AddAppToHomescreenIndex from '../addAppToHomescreen'

class MainIndex extends Component {
	render () {
		return (
			<PagesController>
				<Switch>
					<Route path={routes.newsList.path}			component={NewsIndex}/>
					<Route path={routes.categoriesList.path}	component={CategoriesIndex}/>
					<Route exact path={routes.search.path}		component={SearchIndex}/>
					<Route path={routes.pressReleasesList.path}	component={PressReleasesIndex}/>
					<Route path={routes.stakeholdersList.path}	component={StakeholdersIndex}/>
					<Route path={routes.readingListList.path}	component={ReadingListIndex}/>
					<Route path={routes.messageQueueList.path}	component={MessageQueueIndex}/>
					<Route path={routes.settings.path}	component={SettingsIndex}/>
					<Route exact path={routes.addAppToHomescreen.path}	component={AddAppToHomescreenIndex}/>
					<Route exact path={routes.imprint.path}		component={ImprintIndex}/>
					<Route exact path={routes.privacy.path}		component={PrivacyIndex}/>
					{
						__DEV__
							? (
								<Route path={routes.dev.path}	component={DevIndex}/>
							)
							: null
					}
					<Route path={routes.singleView.path}		component={PreviewSwitch} />
					<Route path={routes.cmsPreview.path}		component={PreviewSwitch} />
					<Route path={routes.shortcut.path}			component={ShortcutSwitch} />
					<Route path={routes.root.path}				component={HomeIndex} />
					<Route component={NotFoundController} />
				</Switch>
			</PagesController>
		)
	}
}

if (__DEV__) {
	MainIndex.propTypes = {}
}

export default MainIndex
