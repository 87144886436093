import { DYNAMIC_CACHE_NAME, STATIC_CACHE_NAME } from '../../../constants/names'
import FMFeature from '../fm.feature'
import Logger from '../../logger'

export class CachesVerifyHandler {
	constructor () {
		this.matchParams = {
			ignoreVary: true
		}
		this.staticAssets = null
		this.staticCaches = null
	}

	__getDifference () {
		return Promise
			.all(this.staticAssets.map(async url => {
				return {
					ok: await this.__presentInCaches(url),
					url: url
				}
			}))
			.then(m => m.filter(obj => obj.ok === false).map(obj => obj.url))
	}

	async __presentInCaches (url) {
		let presentPromises = this.staticCaches
			.match(new Request(url), this.matchParams)
			.then(r => r !== undefined)
		return presentPromises
	}

	async __getStaticAssets () {
		// eslint-disable-next-line no-undef
		return fetch('/asset-manifest.json').then(r => r.json())
	}

	async __assetManifestRemover () {
		let assetRequest = new Request('/asset-manifest.json')
		if (await this.staticCaches.match(assetRequest, this.matchParams)) {
			Logger.logOnce('Cache Verify Handler', 'asset-manifest.json found in caches => Removing')
			await this.staticCaches.delete(assetRequest, this.matchParams)
		}
	}

	/**
	 * Checks if every url from asset-manifest presents in caches
	 * @returns {Promise<Object>}
	 */
	async verifyCacheStorage () {
		if (!FMFeature.checkSupport()) {
			return {
				urls: [],
				ok: true
			}
		}

		try {
			this.staticCaches = await caches.open(STATIC_CACHE_NAME)
			// If asset manifest is somehow cached (For example by opening /asset-manifest.json) => Remove it!
			await this.__assetManifestRemover()
			this.staticAssets = Object.values(await this.__getStaticAssets())
			let difference = await this.__getDifference()
			return {
				urls: difference,
				ok: difference.length === 0
			}
		} catch (e) {
			console.error(e)
			return {
				urls: [],
				ok: false
			}
		}
	}
}
