import {
	GLAMUS_CONTENT_TYPE_ARTICLES,
	GLAMUS_CONTENT_TYPE_NEWS,
	GLAMUS_CONTENT_TYPE_PRESS_RELEASES
} from './constants'
import {
	APP_CONTENT_TYPE_ARTICLES, APP_CONTENT_TYPE_NEWS,
	APP_CONTENT_TYPE_PRESS_RELEASES
} from '../../../../constants/names'

/**
 * This class transforms raw API reading list item data into a structure that
 * can be utilized by the reading list of the app.
 */
class DataModifierReadingListNormalized {
	/**
	 * Returns a default plain data object for a reading list item.
	 *
	 * @param {Object} item Raw press release from Glamus API
	 *
	 * @returns {Object} Data parseable by ReadingListItem.fromData()
	 */
	static getDefaultElement (item = {}) {
		let type
		switch (item.type) {
			case GLAMUS_CONTENT_TYPE_ARTICLES:
				type = APP_CONTENT_TYPE_ARTICLES
				break
			case GLAMUS_CONTENT_TYPE_PRESS_RELEASES:
				type = APP_CONTENT_TYPE_PRESS_RELEASES
				break
			case GLAMUS_CONTENT_TYPE_NEWS:
				type = APP_CONTENT_TYPE_NEWS
				break
			default:
				console.warn('invalid press release from api', item)
				break
		}
		return {
			type: type,
			id: item.itemId,
			ts: Date.parse(item.added),
			exists: true,
			change: null
		}
	}

	/**
	 * Transforms raw API reading list items data into a structure that can be
	 * utilized by the reading list of the app.
	 *
	 * @param {Object} data target object for transformed data
	 * @param {Object} dataApi raw API data
	 */
	static getReadingListEntries (data, dataApi) {
		data.items = []
		dataApi.map((item) => {
			data.items.push(this.getDefaultElement(item))
		})
	}
}

export { DataModifierReadingListNormalized }
