import ReadingListChange, {
	CHANGE_TYPE_ADD,
	CHANGE_TYPE_REMOVE
} from './reading-list-change'

/**
 * This class is a model that reflects a reading list item.
 */
class ReadingListItem {
	/**
	 * Constructor
	 *
	 * @param {string} type
	 * @param {string} id
	 * @param {number} [ts]
	 * @param {boolean} [exists]
	 */
	constructor (type, id, ts = 0, exists = false) {
		/** @member {string} */
		this.id = id

		/** @member {string} */
		this.type = type

		/** @member {number} */
		this.ts = ts

		/** @member {boolean} */
		this.exists = exists

		/** @member {null|ReadingListChange} */
		this.change = null
	}

	/**
	 * Creates an instance from plain data.
	 *
	 * @param {ReadingListItemData} data
	 *
	 * @return {ReadingListItem}
	 */
	static fromData (data) {
		const item = new ReadingListItem(data.type, data.id, data.ts, data.exists)
		item.change = typeof data.change === 'object' && data.change !== null ? ReadingListChange.fromData(data.change) : null
		return item
	}

	/**
	 * Adds a change and does some merging if applicable.
	 *
	 * @param {ReadingListChange} change
	 */
	setChange (change) {
		// _         | _     => _
		// _         | +A(3) => _+A(3)
		// _         | -A(3) => _
		// A(2)      | _     => A(2)
		// A(2)      | +A(1) => A(2)
		// A(2)      | -A(1) => A(2)
		// A(2)      | +A(2) => A(2)
		// A(2)      | -A(2) => A(2) KEEP to avoid unwanted deletes.
		// A(2)      | +A(3) => A(2)+A(3)
		// A(2)      | -A(3) => A(2)-A(3)
		// A(2)+A(4) | _     => A(2)+A(4)
		// A(2)+A(4) | +A(1) => A(2)+A(4)
		// A(2)+A(4) | -A(1) => A(2)+A(4)
		// A(2)+A(4) | +A(2) => A(2)+A(4)
		// A(2)+A(4) | -A(2) => A(2)+A(4)
		// A(2)+A(4) | +A(3) => A(2)+A(4)
		// A(2)+A(4) | -A(3) => A(2)+A(4)
		// A(2)+A(4) | +A(4) => A(2)+A(4)
		// A(2)+A(4) | -A(4) => A(2)+A(4) KEEP to avoid unwanted deletes.
		// A(2)+A(4) | +A(5) => A(2)+A(5)
		// A(2)+A(4) | -A(5) => A(2)-A(5)
		// A(2)-A(4) | _     => A(2)-A(4)
		// A(2)-A(4) | +A(1) => A(2)-A(4)
		// A(2)-A(4) | -A(1) => A(2)-A(4)
		// A(2)-A(4) | +A(2) => A(2)-A(4)
		// A(2)-A(4) | -A(2) => A(2)-A(4)
		// A(2)-A(4) | +A(3) => A(2)-A(4)
		// A(2)-A(4) | -A(3) => A(2)-A(4)
		// A(2)-A(4) | +A(4) => A(2)+A(4) KEEP to avoid unwanted deletes.
		// A(2)-A(4) | -A(4) => A(2)-A(4)
		// A(2)-A(4) | +A(5) => A(2)+A(5)
		// A(2)-A(4) | -A(5) => A(2)-A(5)

		if (change === null) {
			return
		}

		if (change.changeType === CHANGE_TYPE_REMOVE && this.exists === false && this.change === null) {
			return
		}

		if (this.ts >= change.changeTS) {
			return
		}

		if (this.change && this.change.changeTS > change.changeTS) {
			return
		}

		if (this.change && this.change.changeTS === change.changeTS && change.changeType === CHANGE_TYPE_REMOVE) {
			return
		}

		this.change = change
	}

	/**
	 * Getter for this.change.
	 * @return {null|ReadingListChange}
	 */
	getChange () {
		return this.change
	}

	/**
	 * Returns false if any state is set that could not be reached by using
	 * this.setChange without modifying the item afterwards.
	 *
	 * @return {boolean}
	 */
	validate () {
		if (this.change) {
			if (this.exists === false && this.change.changeType === CHANGE_TYPE_REMOVE) {
				return false
			}
			if (this.ts >= this.change.changeTS) {
				return false
			}
		}

		return true
	}
}

export default ReadingListItem
