import appEnv from './app-env'
import * as firebase from 'firebase'
import Logger from './logger'
import { CreateGlamusSaveNotificationsTokenModel } from '../data-layer/implementations/glamus-api/lib/models'
import StorageManager from 'js-storage-manager'
import { PUSH_NOTIFICATIONS_NAMESPACE } from '../constants/names'

class PNTokenController {
	constructor (currentToken) {
		this.sm = new StorageManager(PUSH_NOTIFICATIONS_NAMESPACE)
		this.key = 'PN_USER_TOKEN'
		this.currentToken = currentToken
		this.prevToken = this.sm.get(this.key)
	}

	async __sendToken (token) {
		let model = CreateGlamusSaveNotificationsTokenModel(token)
		let response = await model.request()
		return response.data
	}

	__processResult (result) {
		if (result.success) {
			Logger.logOnce('PNTokenController', 'Token saved, response:', result)
			this.sm.set(this.key, this.currentToken)
		} else {
			console.warn('PNTokenController, Token is not saved!, response:', result)
		}
	}

	__isTokenAlreadySent () {
		return this.currentToken === this.prevToken
	}

	async handleSendToken () {
		try {
			if (!this.__isTokenAlreadySent()) {
				this.__processResult(await this.__sendToken(this.currentToken))
			} else {
				Logger.logOnce('PN Token Controller', 'Token already exists. (Token already saved)')
			}
		} catch (e) {
			console.warn(e)
		}
	}

}

export class FirebaseController {
	/**
	 * The constructor of the FirebaseController. Determine whether an instance already exists.
	 *
	 * @param swRegistration
	 */
	constructor (swRegistration) {
		Logger.logOnce('FirebaseController', 'START INIT FIREBASE CONTROLLER')
		this.firebase = firebase

		this.alreadyInitalized = this.firebase.apps.length > 0

		this.__initFirebaseWithRegistration(swRegistration)
	}

	/**
	 * Initialize the FirebaseController.
	 *
	 * @param registration
	 * @private
	 */
	__initFirebaseWithRegistration (registration) {
		/* Stop here if the FirebaseController has already been initialized. */
		if (this.alreadyInitalized) {
			return
		}

		let l = new Logger('FirebaseController')
		l.startGroup()
		l.log('INIT WITH REGISTRATION: ', registration)
		if (appEnv.firebaseConfig !== null) {
			l.log('INIT WITH firebaseConfig: ', appEnv.firebaseConfig)
			this.firebase.initializeApp(appEnv.firebaseConfig)
			const messaging = this.firebase.messaging()
			messaging.useServiceWorker(registration)
			l.log('INITIALIZED')
		}
		l.endGroup()
	}

	/**
	 * Ask the user for user permissions.
	 *
	 * @returns {Promise<string>}
	 */
	async askForPermissionToReceiveNotifications () {
		/* Stop here if the FirebaseController has already been initialized. */
		if (this.alreadyInitalized) {
			return
		}

		try {
			const messaging = firebase.messaging()
			await messaging.requestPermission()
			const token = await messaging.getToken()
			Logger.logOnce('FirebaseController', 'User token', token)
			await (new PNTokenController(token)).handleSendToken()
			return token
		} catch (error) {
			console.error(error)
		}
	}
}
