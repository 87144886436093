/* global __DEV__ */
import React, { Component } from 'react'
import RegistrationView from './views/registration-simple.view'
import propTypes from 'prop-types'
import LoadingView from './views/loading.view'
import connect from 'react-redux/lib/connect/connect'
import { mapAppStateToRegistrationControllerComponent } from '../../utils/mappings'
import { setAuthDataSaveProgressStatus, setLoginError, loginSubmit } from '../../actions/login-process.actions'
import i18n from '../../i18n'
import appEnv from '../../utils/app-env'
import { LOGIN_PROCESS } from '../../reducers/login-process.reducer'

const ENABLE_DPR_CHECK = false

class RegistrationController extends Component {

	constructor (props) {
		super(props)
		this.state = {
			username: appEnv.devBuildOnly.registration.defaultUsername,
			password: appEnv.devBuildOnly.registration.defaultPassword,
			dprChecked: false,
			dprLayerOpened: false
		}
	}

	logError (message) {
		this.props.dispatch(setLoginError(true, message))
		this.props.dispatch(setAuthDataSaveProgressStatus(LOGIN_PROCESS.AUTH_DATA_SAVE_PROGRESS_STATUS.FALSE))
	}

	async handleRegistrationSubmit (e) {
		e.preventDefault()

		/* the dpr checkbox was not confirmed -> stop here */
		if (ENABLE_DPR_CHECK && !this.state.dprChecked) {
			this.logError(i18n.t('TXT_LOGIN_MISSING_DPR_CONFIRMATION.label'))
			return
		}

		this.props.dispatch(setAuthDataSaveProgressStatus(LOGIN_PROCESS.AUTH_DATA_SAVE_PROGRESS_STATUS.IN_PROGRESS))
		this.props.dispatch(loginSubmit(this.state.username, this.state.password))
	}

	handleUsernameChange (e) {
		this.setState({ username: e.target.value })
	}

	handlePasswordChange (e) {
		this.setState({ password: e.target.value })
	}

	handleOpenDpr (e) {
		this.setState({ dprLayerOpened: true })
	}

	handleCloseDpr (e) {
		this.setState({ dprLayerOpened: false })
	}

	handleSwitchDpr (e) {
		this.setState({ dprChecked: !this.state.dprChecked })
	}

	render () {
		const { authDataSaveProgressStatus } = this.props
		let { username, password, dprChecked, dprLayerOpened } = this.state

		if (authDataSaveProgressStatus === LOGIN_PROCESS.AUTH_DATA_SAVE_PROGRESS_STATUS.IN_PROGRESS) {
			return (<LoadingView/>)
		}

		return (
			<RegistrationView
				password={password}
				username={username}
				handlePasswordChange={this.handlePasswordChange.bind(this)}
				handleUsernameChange={this.handleUsernameChange.bind(this)}
				handleRegistrationSubmit={this.handleRegistrationSubmit.bind(this)}
				handleOpenDpr={this.handleOpenDpr.bind(this)}
				handleCloseDpr={this.handleCloseDpr.bind(this)}
				handleSwitchDpr={this.handleSwitchDpr.bind(this)}
				dprChecked={dprChecked}
				dprLayerOpen={dprLayerOpened}
				showDprChecker={ENABLE_DPR_CHECK}
			/>
		)
	}
}

if (__DEV__) {
	RegistrationController.propTypes = {
		dispatch: propTypes.func.isRequired,
		authDataSaveProgressStatus: propTypes.string
	}
}

export default connect(mapAppStateToRegistrationControllerComponent)(RegistrationController)
