/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'
import Parser from 'html-react-parser'
import commonPropTypes from '../../../../utils/common-prop-types'

function AccordionView (props) {
	let { data, onHeaderClick, activeTabIndex, unsafeParseContent } = props

	return (
		<div className="m-accordion">
			{
				data.map((item, index) => {
					let classNameActive = activeTabIndex === index ? 'm-accordion__element--active' : ''
					let content = item.content

					if (unsafeParseContent && typeof content === 'string') {
						content = Parser(item.content)
					}

					if (typeof content === 'string'){
						content = <p>{item.content}</p>
					}

					return (
						<div key={index} className={'m-accordion__element ' + classNameActive}>
							<h3 onClick={() => { onHeaderClick(index) }} className="m-accordion__title">{item.title}</h3>
							<div className="m-accordion__content">
								{content}
							</div>
						</div>
					)
				})
			}
		</div>
	)
}

AccordionView.defaultProps = {
	activeTabIndex: 0,
	unsafeParseContent: false
}

if (__DEV__) {
	// Properties types validation
	AccordionView.propTypes = {
		activeTabIndex: propTypes.number,
		onHeaderClick: propTypes.func.isRequired,
		data: propTypes.arrayOf(
			propTypes.shape({
				title: propTypes.string.isRequired,
				content: commonPropTypes.reactChildren.isRequired
			})
		).isRequired,
		unsafeParseContent: propTypes.bool
	}
}

// Export component
export default AccordionView
