export const LOGOUT = 'LOGOUT'
// General
export const SET_LOADING = 'SET_LOADING'
export const SET_SINGLE_VIEW = 'SET_SINGLE_VIEW'
export const SET_SINGLE_VIEW_ERROR = 'SET_SINGLE_VIEW_ERROR'
export const SET_CMS_PREVIEW = 'SET_CMS_PREVIEW'
export const SET_TITLE = 'SET_TITLE'

// Login
export const ADD_AUTH_DATA = 'ADD_AUTH_DATA'
export const SET_AUTH_DATA_SAVE_PROGRESS_STATUS = 'SET_AUTH_DATA_SAVE_PROGRESS_STATUS'
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR'
export const REMEMBER_PATH = 'REMEMBER_PATH'
// Data
export const DATA_REQUEST_FAIL = 'DATA_REQUEST_FAIL'
export const INJECT_DATA = 'INJECT_DATA'
export const INJECT_DATA_UPDATES = 'INJECT_DATA_UPDATES'
export const ENTITY_VIEWED = 'ENTITY_VIEWED'

export const SET_ENTITY_LOADING = 'SET_ENTITY_LOADING'
export const SET_ENTITY_LOADED = 'SET_ENTITY_LOADED'

// News
export const GET_NEWS_ITEM = 'GET_NEWS_ITEM'
export const SET_TOPIC_LOADING = 'SET_TOPIC_LOADING'
export const PREPARE_TOPIC = 'PREPARE_TOPIC'

// Header
export const OPEN_SIDE_MENU = 'OPEN_SIDE_MENU'

// Reading list
export const INJECT_READING_LIST = 'INJECT_READING_LIST'

// Messege queue list
export const INJECT_MESSAGE_QUEUE_LIST = 'INJECT_MESSAGE_QUEUE_LIST'

// Search
export const SET_SEARCH = 'SET_SEARCH'
export const CALL_SEARCH = 'CALL_SEARCH'
export var UNSET_SEARCH = 'UNSET_SEARCH'
export var SET_SEARCH_REQUEST = 'SET_SEARCH_REQUEST'
export var SET_SEARCH_RESULT = 'SET_SEARCH_RESULT'

// Accordion
export const ACCORDION_SET_ACTIVE_HEADER = 'ACCORDION_SET_ACTIVE_HEADER'

// Send Article
export const SEND_ARTICLE_ERROR = 'SEND_ARTICLE_ERROR'
export const SEND_ARTICLE_SUCCESS = 'SEND_ARTICLE_SUCCESS'
export const SEND_ARTICLE_RESET = 'SEND_ARTICLE_RESET'
export const SEND_ARTICLE_UPDATE_FORM_FIELD = 'SEND_ARTICLE_UPDATE_FORM_FIELD'

// Settings
export const SETTINGS_UPDATE_CHECKBOX_FIELD = 'SETTINGS_UPDATE_CHECKBOX_FIELD'
export const SETTINGS_SET_EMAIL_CHECKBOX_FIELDS = 'SETTINGS_SET_EMAIL_CHECKBOX_FIELDS'
export const SETTINGS_SET_STATUS = 'SETTINGS_SET_STATUS'

// Message Queue
export const MESSAGE_QUEUE_SYNC = 'MESSAGE_QUEUE_SYNC'
export const MESSAGE_QUEUE_DELETE = 'MESSAGE_QUEUE_DELETE'
export const MESSAGE_QUEUE_ITEM_DELETE = 'MESSAGE_QUEUE_ITEM_DELETE'

// Modal
export const MODAL_CREATE = 'MODAL_CREATE'
export const MODAL_CLOSE = 'MODAL_CLOSE'

// Transitions
export const TRANSITIONS_SET_TRANSITION_STATE = 'TRANSITIONS_SET_TRANSITION_STATE'

// Section menu
export const SECTIONMENU_SET_ACTIVE_TAB = 'SECTIONMENU_SET_ACTIVE_TAB'

// Scroll position for next page
export const NEXT_SCROLL_POSITION = 'NEXT_SCROLL_POSITION'

// Layer
export const DISABLE_LAYER_LOADER = 'DISABLE_LAYER_LOADER'
export const ENABLE_LAYER_LOADER = 'ENABLE_LAYER_LOADER'
export const DISABLE_LAYER_OFFLINE = 'DISABLE_LAYER_OFFLINE'
export const ENABLE_LAYER_OFFLINE = 'ENABLE_LAYER_OFFLINE'

// Debug
export const DEBUG_LOG = 'DEBUG_LOG'

// Online status
export const SET_ONLINE = 'SET_ONLINE'

export var SET_TIMESTAMP = 'SET_TIMESTAMP'

export const SYNC_COOKIE_NOTICE_STATE = 'SYNC_COOKIE_NOTICE_STATE'

// Local History
// LOCAL_HISTORY_...
// see /utils/browser-history-local.js
