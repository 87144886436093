/* global __DEV__ */
import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import DevExamplesController from './dev-examples.controller'
import DevMoleculesController from './dev-molecules.controller'
import DevAlertController from './dev-alert.controller'
import DevInfoController from './dev-info.controller'
import LinkAll from '../general/link-all'
import routes from '../../utils/routes'
import NotFoundController from '../general/not-found.controller'
import PageContentController from '../pages/page-content.controller'
import { compose } from 'redux/lib/redux'
import withTitle from '../header/withTitle.hoc'

class DevIndex extends Component {
	render () {
		return (
			<PageContentController
				header={
					<div style={{ position: 'absolute', top: -29, left: 0, right: 0, textAlign: 'center' }}>
						<LinkAll className='a-button a-button--small a-button--inline' to={routes.devMolecules.build()}>Moleküle</LinkAll>
						<LinkAll className='a-button a-button--small a-button--inline' to={routes.devUsageExamples.build()}>Beispiele Komponenten-Nutzung</LinkAll>
						<LinkAll className='a-button a-button--small a-button--inline' to={routes.devAlert.build()}>Alert</LinkAll>
						<LinkAll className='a-button a-button--small a-button--inline' to={routes.devInfo.build()}>Info</LinkAll>
						<LinkAll className='a-button a-button--small a-button--inline' to={routes.devBuildInfo.build()} linkType={LinkAll.LINK} target='_blank'>Build-Info</LinkAll>
					</div>
				}
				content={
					<Switch>
						<Route exact path={routes.dev.path}					render={() => { return null }} />
						<Route exact path={routes.devMolecules.path}		component={DevMoleculesController} />
						<Route exact path={routes.devInfo.path}				component={DevInfoController} />
						<Route exact path={routes.devAlert.path}			component={DevAlertController} />
						<Route exact path={routes.devUsageExamples.path}	component={DevExamplesController} />
						<Route component={NotFoundController} />
					</Switch>
				}
			/>
		)
	}
}

if (__DEV__) {
	DevIndex.propTypes = {}
}

export default compose(
	withTitle(null, 'TXT_DEV_OVERVIEW_TITLE.label')
)(DevIndex)
