import React from 'react'
import hoistStatics from 'hoist-non-react-statics'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { entityViewed } from '../../actions/content.actions'
import {
	mapAppStateToGenericControllerComponent,
	mapAppStateToSetEntityViewedHOC
} from '../../utils/mappings'

/**
 * Triggers update information updates when the component is rendered.
 *
 * @param {string} contentType
 * @param {string} entityPropName
 * @return {function(*=)}
 */
var setEntityViewed = function (contentType, entityPropName) {
	return function setEntityViewed (WrappedComponent) {
		class SetEntityViewed extends React.Component {
			// noinspection JSUnusedGlobalSymbols
			componentDidMount () {
				this.setViewed()
			}

			// noinspection JSUnusedGlobalSymbols
			componentDidUpdate () {
				this.setViewed()
			}

			setViewed () {
				const item = this.props[entityPropName]

				if (item) {
					this.props.dispatch(entityViewed(contentType, item.id))
				}
			}

			render () {
				const { wrappedComponentRef, ...passThroughProps } = this.props

				return (
					<WrappedComponent ref={wrappedComponentRef} {...passThroughProps}/>
				)
			}
		}

		SetEntityViewed.displayName = 'setEntityViewed(' + (WrappedComponent.displayName || WrappedComponent.name || 'Component') + ')'
		SetEntityViewed.WrappedComponent = WrappedComponent
		SetEntityViewed.propTypes = {
			wrappedComponentRef: propTypes.func,

			dispatch: propTypes.func.isRequired
		}

		hoistStatics(SetEntityViewed, WrappedComponent)

		return connect(mapAppStateToGenericControllerComponent)(SetEntityViewed)
	}
}

export default setEntityViewed
