/**
 * Returns a promise wrapper which runs the given handler with two extensions:
 * 1. It automatically rejects after handlerTimeoutMS if it did not get resolved
 * by the handler before.
 * 2. It waits for finalizeTimeoutMS after it got marked as resolved by the
 * handler before the actual promise resolves. During that timeout the handler
 * has a chance to "change its mind" and mark it as rejected afterwards.
 *
 * @param {function} handler
 * @param {int} handlerTimeoutMS
 * @param {int} finalizeTimeoutMS
 * @return {Promise}
 * @constructor
 */
const AsyncPromiseWithLimitedProcessingTime = function (handler, handlerTimeoutMS, finalizeTimeoutMS) {
	// Sanitize timeouts to an integer value >= 10.
	handlerTimeoutMS	= Math.max(10, parseInt(handlerTimeoutMS,	10) || 0)
	finalizeTimeoutMS	= Math.max(10, parseInt(finalizeTimeoutMS,	10) || 0)

	return new Promise(function (resolve, reject) {
		let error = null
		let finalizeTimeout = null

		/**
		 * Resolves/rejects the actual promise based on the error.
		 */
		let finalize = function () {
			if (error) {
				reject(error)
			} else {
				resolve()
			}
		}
		/**
		 * Marks the promise as resolved and waits finalizeTimeoutMS before
		 * the actual promise gets finalized.
		 */
		let finish = function () {
			clearTimeout(finalizeTimeout)
			finalizeTimeout = setTimeout(finalize, finalizeTimeoutMS)
		}
		/**
		 * Marks the promise as rejected and finalizes the actual promise
		 * immediately.
		 *
		 * @param {Error} e
		 */
		let cancel = function (e) {
			error = e || new Error('unknown error')
			clearTimeout(finalizeTimeout)
			finalize()
		}

		// Set timeout for automatic rejection.
		finalizeTimeout = setTimeout(function () {
			cancel(new Error('handler timed out'))
		}, handlerTimeoutMS)

		// Run promise handler.
		handler(finish, cancel)
	})
}

export {
	AsyncPromiseWithLimitedProcessingTime
}
