import React from 'react'
import PrivacyView from './views/privacy.view'
import { withPageContentSections } from '../pages/page-content.controller'
import { compose } from 'redux/lib/redux'
import withTitle from '../header/withTitle.hoc'

function PrivacyController (/* props */) {
	return (
		<PrivacyView/>
	)
}

export default compose(
	withPageContentSections,
	withTitle(null, 'TXT_PROJECT_PRIVACY_TITLE.label')
)(PrivacyController)
