/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToNewsItemControllerComponent } from '../../utils/mappings'
import NewsItemView from './views/news-item.view'
import SectionMenuController from '../general/section-menu/section-menu.controller'
import i18n from 'i18next'
import routes from '../../utils/routes'
import ContentElementsController from '../content-elements/content-elements.controller'
import NotFoundController from '../general/not-found.controller'
import FooterButtonReadingListController from '../footer/buttons/footer-button-reading-list.controller'
import ContentFooterController from '../footer/content-footer.controller'
import { withPageContentSections } from '../pages/page-content.controller'
import { compose } from 'redux/lib/redux'
import withTitle from '../header/withTitle.hoc'
import commonPropTypes from '../../utils/common-prop-types'
import setEntityViewed from '../general/set-entity-viewed.hoc'
import loadable from '../loadable/loadable.hoc'
import LinkListController from '../general/link-list.controller'

class NewsItemController extends Component {
	static getTitle (props) {
		const { newsItem } = props
		let title

		if (newsItem) {
			title = newsItem.title
		} else {
			title = i18n.t('TXT_NEWS_DETAIL_TITLE.label')
		}

		return title
	}

	render () {
		const { newsItem, activeSectionKey } = this.props
		let activeContentElements
		let activeSection

		if (!newsItem) {
			let links = [{ name: i18n.t('TXT_NEWS_LIST_TITLE.label'), href: routes.newsList.build() }]
			return <NotFoundController links={links} />
		}

		activeSection = newsItem.contentElements.find(section => section.name === activeSectionKey)
		activeContentElements = activeSection ? activeSection.elements : []

		return (
			<NewsItemView title={newsItem.title}>
				<ContentElementsController contentElements={activeContentElements} />
				<LinkListController links={newsItem.links}>
					<hr />
					<h2 className='a-headline--h2'>Links</h2>
				</LinkListController>
			</NewsItemView>
		)
	}

	static renderContentHeader (props) {
		return <SectionMenuController sections={props.newsItem.contentElements} hideSingleTab/>
	}

	static renderContentFooter (props) {
		const { newsItem, inReadingList } = props
		const readingListInformation = FooterButtonReadingListController.createReadingListInformation(
			'news',
			newsItem,
			inReadingList
		)

		return (
			<ContentFooterController
				readingListInformation={readingListInformation}
				showSendButton
			/>
		)
	}
}

if (__DEV__) {
	NewsItemController.propTypes = {
		newsItem: propTypes.object,
		id: commonPropTypes.content.id,
		inReadingList: propTypes.bool.isRequired,
		activeSectionKey: propTypes.string.isRequired,

		...commonPropTypes.mixinFromReduxConnect
	}
}

export default compose(
	connect(mapAppStateToNewsItemControllerComponent),
	withPageContentSections,
	withTitle(NewsItemController.getTitle),
	loadable('news', 'newsItem', 'id'),
	setEntityViewed('news', 'newsItem')
)(NewsItemController)
