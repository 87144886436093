import StorageManager from 'js-storage-manager'
import { CONTENT_OBJECT_KEY, CONTENT_STORAGE_NAMESPACE } from '../../constants/names'
import { Exception } from '../errors'

export default class ContentGetUpdateInjector {
	constructor (contentFromApi) {
		this.__requireParam(contentFromApi, 'contentFromApi')
		this.contentApi = contentFromApi
		this.contentLocal = null
		this.storageManager = new StorageManager(CONTENT_STORAGE_NAMESPACE)
	}

	__requireParam (param, name) {
		if (param === undefined || param === null) {
			throw new Exception('Param must not be undefined or null :: param name: ' + name)
		}
	}

	__initContentOld () {
		this.contentLocal = this.storageManager.get(CONTENT_OBJECT_KEY)
	}

	__handleInjectQuantities () {
		try {
			this.__requireParam(this.contentLocal, 'contentLocal')
			this.contentApi.updatesInformation = this.contentLocal.updatesInformation
		} catch (e) {
			console.warn(e)
		}
	}

	__setUpdatedFlag (type, itemId) {
		if (this.contentApi.hasOwnProperty(type) && this.contentApi[type].hasOwnProperty(itemId)) {
			this.contentApi[type][itemId].isUpdated = true
		}
	}

	__handleInjectFlags () {
		for (let type in this.contentLocal) {
			// Go through items of type
			for (let itemId in this.contentLocal[type]) {
				let item = this.contentLocal[type][itemId]

				if (!item.isUpdated) {
					continue
				}

				this.__setUpdatedFlag(type, itemId)
			}
		}
	}

	handle () {
		this.__initContentOld()
		this.__handleInjectQuantities()
		this.__handleInjectFlags()
	}
}
