/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'

function ContentElementSubheadlineView (props) {
	return (
		<h2 className='a-headline a-headline--h2'>{props.content}</h2>
	)
}

ContentElementSubheadlineView.defaultProps = {
	content: ''
}

if (__DEV__) {
	// Properties types validation
	ContentElementSubheadlineView.propTypes = {
		content: propTypes.string
	}
}

// Export component
export default ContentElementSubheadlineView
