/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToShortcutSwitchControllerComponent } from '../../utils/mappings'
import ControllerUtils from '../../utils/controller-utils'

class ShortcutSwitch extends Component {
	/**
	 * The main render function from PreviewSwitchController.
	 *
	 * @returns {*}
	 */
	render () {
		let Controller = ControllerUtils.getController(this.props.objectInformation)
		let shortcutId = this.props.objectInformation && this.props.objectInformation.id

		return (
			<Controller shortcutId={shortcutId} id={''} />
		)
	}
}

if (__DEV__) {
	ShortcutSwitch.propTypes = {
		objectInformation: propTypes.object
	}
}

export default connect(mapAppStateToShortcutSwitchControllerComponent)(ShortcutSwitch)
