export function imageRequestBuilder (isStatic, path) {

	const url = path

	let params = {
		mode: 'no-cors',
		headers: new Headers({
			'IS_STATIC': isStatic.toString(),
			'IS_IMAGE': '1'
		})
	}

	return new Request(url, params)
}