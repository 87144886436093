import ApplicationFeature from '../application-feature'
import featureDetection from '../feature-detection'
import i18n from '../../i18n'

class FMFeature extends ApplicationFeature {
	constructor () {
		super(
			[
				featureDetection.hasCacheApi,
				featureDetection.hasFetchApi
			],
			i18n.t('TXT_FM_UNSUPPORTED_MESSAGE.title'),
			i18n.t('TXT_FM_UNSUPPORTED_MESSAGE.text')
		)
	}
}

FMFeature.featureName = 'file-management'

export default FMFeature
