import { DAEMON_BASE_NAME } from '../../constants/names'
import NotUniqException from './exceptions/NotUniqException'
import Logger from '../logger'
import { daemonsService } from './DaemonsService'

export default class DaemonBase {
	/**
	 * @param {String} name
	 * @param {number} period - execution period in ms
	 * @param {Array<Operation>} operations
	 */
	constructor (name = DAEMON_BASE_NAME, period, ...operations) {
		this.operations = operations
		this.period = period
		this.timerId = null
		this.name = name
		daemonsService.registerDaemon(this)
	}

	__requireOpUniq (op) {
		if (this.operations.findIndex(o => op.name === o.name) !== -1) {
			throw new NotUniqException(op)
		}
	}

	/**
	 *
	 * @param {Operation} operation
	 */
	addOperation (operation) {
		try {
			this.__requireOpUniq()
		} catch (e) {
			console.warn(e)
			return
		}
		this.operations.push(operation)
		Logger.logOnce('daemon', 'Operation added to daemon: daemon, operation: ', this, operation)
	}

	/**
	 * Deletes operation by name
	 * @param {String} name
	 * @returns {boolean} success
	 */
	removeOperation (name) {
		let index = this.operations.findIndex(o => o.name === name)
		if (index < 0) {
			return false
		}
		this.operations = this.operations.splice(index, 1)
		Logger.logOnce('daemon', 'Operation removed from daemon: daemon, operation name: ', this, name)
		return true
	}

	__executeOperations () {
		this.operations.forEach(o => o.execute())
	}

	__timerIsStarted () {
		return this.timerId !== null
	}

	/**
	 * Changes period of operations execution
	 * @param {number} period - period in ms
	 */
	changePeriod (period) {
		if (this.__timerIsStarted()) {
			this.stop()
		}
		this.period = period
		Logger.logOnce('daemon', 'Period changed in daemon: daemon', this)
		this.start()
	}

	/**
	 * Starts daemon
	 * Do not use directly, because we wan't all daemons in DeamonsService
	 */
	start () {
		if (this.__timerIsStarted()) {
			console.warn('Daemon is already started. Use changePeriod() to change period.')
			return
		}
		this.timerId = setInterval(() => {
			this.__executeOperations()
		}, this.period)
		Logger.logOnce('daemon', 'Daemon started', this)
	}

	/**
	 * Stops daemon
	 */
	stop () {
		if (!this.__timerIsStarted()) {
			console.warn('Daemon is not started => you cant stop it')
			return
		}
		clearInterval(this.timerId)
		this.timerId = null
		Logger.logOnce('daemon', 'Daemon stopped', this)
	}
}
