/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToSideMenuControllerComponent } from '../../../utils/mappings'
import SideMenuView from './views/side-menu.view'
import { openSideMenu } from '../../../actions/basic.actions'
import logo from '../../../images/vfa.svg'
import SideMenuElementView from './views/side-menu-element.view'
import routes from '../../../utils/routes'
import i18n from 'i18next'

const elementDefinitions = {
	/*
	search: {
		name: i18n.t('TXT_SEARCH_TITLE.label'),
		link: routes.search.build(),
	},
	*/
	news: {
		name: i18n.t('TXT_NEWS_LIST_TITLE.label'),
		link: routes.newsList.build(),
		updatesQuantity: 0,
		dataCy: 'news-side-menu'
	},
	categories: {
		name: i18n.t('TXT_CATEGORIES_LIST_TITLE.label'),
		link: routes.categoriesList.build(),
		updatesQuantity: 0,
		dataCy: 'categories-side-menu'
	},
	pressReleases: {
		name: i18n.t('TXT_PRESS_RELEASES_LIST_TITLE.label'),
		link: routes.pressReleasesList.build(),
		updatesQuantity: 0,
		dataCy: 'press-releases-side-menu'
	},
	stakeholders: {
		name: i18n.t('TXT_STAKEHOLDER_LIST_TITLE.label'),
		link: routes.stakeholdersList.build(),
		updatesQuantity: 0,
		dataCy: 'stakeholders-side-menu'
	},
	readingList: {
		name: i18n.t('TXT_READING_LIST_LIST_TITLE.label'),
		link: routes.readingListList.build(),
		updatesQuantity: 0,
		dataCy: 'reading-list-side-menu'
	},
	messageQueueList: {
		name: i18n.t('TXT_MESSAGE_QUEUE_LIST_TITLE.label'),
		link: routes.messageQueueList.build(),
		updatesQuantity: 0,
		dataCy: 'message-queue-list-side-menu'
	},
	dev: (
		__DEV__
			? {
				meta: true,
				name: 'Developer',
				link: routes.dev.build()
			}
			: null
	),
	addAppToHomescreen: {
		meta: true,
		name: i18n.t('TXT_PROJECT_ADD_APP_TO_HOMESCREEN_TITLE.label'),
		link: routes.addAppToHomescreen.build()
	},
	settings: {
		meta: true,
		name: i18n.t('TXT_PROJECT_SETTINGS_TITLE.label'),
		link: routes.settings.build()
	},
	imprint: {
		meta: true,
		name: i18n.t('TXT_PROJECT_IMPRINT_TITLE.label'),
		link: routes.imprint.build()
	},
	privacy: {
		meta: true,
		name: i18n.t('TXT_PROJECT_PRIVACY_TITLE.label'),
		link: routes.privacy.build()
	}
}

const allElements = Object.keys(elementDefinitions)
	.map(key => elementDefinitions[key])
	.filter(element => element !== null)

class SideMenuController extends Component {
	updateUpdateQuantities () {
		elementDefinitions.news.updatesQuantity = this.props.newsUpdatesQuantity
		elementDefinitions.categories.updatesQuantity = this.props.categoriesUpdatesQuantity
		elementDefinitions.pressReleases.updatesQuantity = this.props.pressReleasesUpdatesQuantity
		elementDefinitions.stakeholders.updatesQuantity = this.props.stakeholdersUpdatesQuantity
		elementDefinitions.readingList.updatesQuantity = this.props.readingListUpdatesQuantity
		elementDefinitions.messageQueueList.updatesQuantity = this.props.messageQueueListUpdatesQuantity
	}

	getElements (isMeta = false) {
		return allElements
			.filter((element) => {
				return !!element.meta === isMeta
			})
	}

	closeMenu (/* e */) {
		this.props.dispatch(openSideMenu(false))
	}

	renderElements (isMeta = false) {
		let elements = this.getElements(isMeta)
		return elements.map((element, keyIndex) => {
			let updatesQuantity = element.updatesQuantity || 0

			return (
				<SideMenuElementView
					key={ keyIndex }
					link={ element.link }
					name={ element.name }
					updatesQuantity={ updatesQuantity }
					onClick={ this.closeMenu.bind(this) }
					isMeta={ isMeta }
					dataCy={ element.dataCy }
				/>
			)
		})
	}

	render () {
		const { isOpen } = this.props

		this.updateUpdateQuantities()

		let classNames = ['']
		let children = this.renderElements(false, 'reading-list-quantity')
		let childrenMeta = this.renderElements(true)

		if (isOpen) {
			classNames = ['m-menu--open']

			// set body class if menu ist open
			document.body.classList.add('menu-open')
		} else {
			// remove body class if menu ist closed
			document.body.classList.remove('menu-open')
		}

		return (
			<SideMenuView
				logo={ logo }
				classNames={ classNames }
				closeMenu={ this.closeMenu.bind(this) }
				childrenMeta={childrenMeta}
			>
				{children}
			</SideMenuView>
		)
	}
}

if (__DEV__) {
	SideMenuController.propTypes = {
		dispatch: propTypes.func.isRequired,
		isOpen: propTypes.bool.isRequired,
		newsUpdatesQuantity: propTypes.number,
		categoriesUpdatesQuantity: propTypes.number,
		pressReleasesUpdatesQuantity: propTypes.number,
		stakeholdersUpdatesQuantity: propTypes.number,
		readingListUpdatesQuantity: propTypes.number,
		messageQueueListUpdatesQuantity: propTypes.number
	}
}

export default connect(
	(state) => mapAppStateToSideMenuControllerComponent(state)
)(SideMenuController)
