import StorageManager from 'js-storage-manager'
import ReadingListChange, {
	CHANGE_TYPE_ADD,
	CHANGE_TYPE_REMOVE
} from './reading-list-change'
import ReadingListItem from './reading-list-item'

const STORAGE_AREA = 'reading-list'
const ITEM_KEY_GLUE = '|'

/**
 * ReadingList provides static easy access functions to work with. For that it
 * uses a default instance that is created on demand. This instance is stored
 * here.
 *
 * @type {null|ReadingList}
 */
let defaultInstance = null

/**
 * factory for default ReadingListProcessor for usage by defaultInstance
 *
 * @type {function|null}
 */
let defaultReadingListProcessorFactory = null

/**
 * Removes an item from the storage.
 *
 * TODO This has to be implemented in StorageManager itself.
 *
 * @param {string} key
 */
StorageManager.prototype.remove = function (key) {
	let storageArea = this.getStorage()

	delete storageArea[key]

	this.setStorage(storageArea)
}

/**
 * This class manages the local reading list and queue.
 */
export class ReadingList {
	/**
	 * Creates an instance with initialized storage manager.
	 *
	 * @param {string} [storageArea]
	 * @param {ReadingListProcessor|null} readingListProcessor
	 */
	constructor (storageArea = STORAGE_AREA, readingListProcessor = null) {
		/** @member {StorageManager} */
		this.storage = new StorageManager(storageArea)
		this.readingListProcessor = readingListProcessor

		this.readingListProcessor && this.readingListProcessor.injectReadingList(this)
	}

	/**
	 * Returns/Creates the default instance that is used by easy access
	 * functions.
	 *
	 * @return {ReadingList}
	 */
	static get instance () {
		if (defaultInstance === null) {
			const readingListProcessor = defaultReadingListProcessorFactory === null ? null : defaultReadingListProcessorFactory()
			defaultInstance = new ReadingList(STORAGE_AREA, readingListProcessor)
		}
		return defaultInstance
	}

	/**
	 * injects factory method for default ReadingListProcessor
	 * @param {function} readingListProcessor
	 */
	static injectDefaultReadingListProcessorFactory (readingListProcessorFactory) {
		defaultReadingListProcessorFactory = readingListProcessorFactory
	}

	/**
	 * Adds an existing ReadingListItem to the local storage without any
	 * validation or queue logic.
	 *
	 * @param {ReadingListItem} item
	 */
	putItem (item) {
		const composedItemKey = ReadingList._composeItemKey(item.type, item.id)

		this.storage.set(composedItemKey, item)
	}

	/**
	 * Adds a reading list item to the local storage (as change) and triggers
	 * the ReadingListProcessor.
	 *
	 * @param {string} type
	 * @param {string} id
	 */
	addRLItem (type, id) {
		const composedItemKey = ReadingList._composeItemKey(type, id)
		const item = this._getOrCreateItem(composedItemKey, type, id)
		let date = new Date()
		let change
		// We round our reading list timestamps to whole seconds.
		// This originates from the Glamus API, that does not support milliseconds.
		// There it happened that:
		// - we added at x+400ms
		// - we wrote to api, who saved at x
		// - on next load from api we had the item at x and our item at x+400
		// - with our merging policy this resultet in another write with x+400
		// - and so on
		date.setMilliseconds(0)
		change = new ReadingListChange(CHANGE_TYPE_ADD, date.getTime())

		item.setChange(change)

		this.putItem(item)

		this.readingListProcessor && this.readingListProcessor.setActive()
	}

	/**
	 * Removes a reading list item from the local storage (as change) and
	 * triggers the ReadingListProcessor.
	 *
	 * @param {string} type
	 * @param {string} id
	 */
	removeRLItem (type, id) {
		const composedItemKey = ReadingList._composeItemKey(type, id)
		const item = this._getOrCreateItem(composedItemKey, type, id)
		const change = new ReadingListChange(CHANGE_TYPE_REMOVE)

		item.setChange(change)

		this.putItem(item)

		this.readingListProcessor && this.readingListProcessor.setActive()
	}

	/**
	 * Returns all reading list items from local storage.
	 *
	 * @return {ReadingListItemSet}
	 */
	getAllRLItems () {
		const items = this.storage.getStorage()

		Object.keys(items).forEach((composedItemKey) => {
			items[composedItemKey] = ReadingListItem.fromData(items[composedItemKey])
		})
		return items
	}

	/**
	 * Removes all reading list items from local storage (as change) and
	 * indirectly triggers the ReadingListProcessor.
	 */
	removeAllRLItems () {
		const items = this.getAllRLItems()

		Object.keys(items).forEach((composedItemKey) => {
			const item = items[composedItemKey]
			this.removeRLItem(item.type, item.id)
		})
	}

	/**
	 * Deletes a reading list item from local storage (hard, no sync).
	 *
	 * @param {string} composedItemKey
	 */
	deleteRLItem (composedItemKey) {
		this.storage.remove(composedItemKey)
	}

	/**
	 * Deletes all reading list items from local storage (hard, no sync).
	 */
	deleteAllRLItems () {
		const items = this.getAllRLItems()

		Object.keys(items).forEach((composedItemKey) => {
			this.deleteRLItem(composedItemKey)
		})
	}

	/**
	 * Gets a reading list item from local storage or - if non-existant -
	 * creates one.
	 *
	 * @param {string} composedItemKey
	 * @param {string} type
	 * @param {string} id
	 *
	 * @return {ReadingListItem}
	 *
	 * @private
	 */
	_getOrCreateItem (composedItemKey, type, id) {
		let data = this.storage.get(composedItemKey)
		let item

		if (data === null) {
			item = new ReadingListItem(type, id)
		} else {
			item = ReadingListItem.fromData(data)
		}

		return item
	}

	/**
	 * Merges our local reading list with given remote item data.
	 *
	 * @param {ReadingListItemData[]} itemsData
	 *
	 * @return {boolean} success
	 */
	mergeItemData (itemsData) {
		let remoteItems = {}

		itemsData.map((itemData) => {
			let item = ReadingListItem.fromData(itemData)
			remoteItems[ReadingList._composeItemKey(item.type, item.id)] = item
		})

		return this.mergeItems(remoteItems)
	}

	/**
	 * Merges our local reading list with given remote items.
	 *
	 * @param {ReadingListItemSet} remoteItems
	 *
	 * @return {boolean} success
	 */
	mergeItems (remoteItems) {
		let localItems = this.getAllRLItems()
		let itemKeys = []
			// local item keys
			.concat(Object.keys(localItems))
			// plus remote item keys
			.concat(Object.keys(remoteItems))
			// remove duplicates
			.filter((key, index, self) => { return self.indexOf(key) === index })

		const itemsMerged = itemKeys.map((itemKey) => {
			const localItem = localItems[itemKey] || null
			const remoteItem = remoteItems[itemKey] || null
			return this.mergeItem(localItem, remoteItem)
		})

		return itemsMerged.reduce((a, b) => a && b, true)
	}

	/**
	 * Merges a local reading list item with it's given remote.
	 *
	 * @param {ReadingListItem|null} _localItem
	 * @param {ReadingListItem|null} _remoteItem
	 *
	 * @return {boolean} success
	 */
	mergeItem (_localItem, _remoteItem) {
		let success = true
		const type = _localItem ? _localItem.type : _remoteItem.type
		const id = _localItem ? _localItem.id : _remoteItem.id

		/**
		 * Normalized local item.
		 * We want to check for localItem.exists directly instead of also checking for null.
		 *
		 * @type {ReadingListItem}
		 */
		const localItem = _localItem || new ReadingListItem(type, id)
		/**
		 * Normalized remote item.
		 * We want to check for remoteItem.exists directly instead of also checking for null.
		 *
		 * @type {ReadingListItem}
		 */
		const remoteItem = _remoteItem || new ReadingListItem(type, id)
		/**
		 * Merged Item - initialized with local data.
		 *
		 * @type {ReadingListItem}
		 */
		let mergedItem

		//  1) _         |    _ =>    _      KEEP:SYNCED
		//  2) _         | A(1) => A(1)      PULL:NEW_DATA

		//  3) _+A(3)    |    _ =>    _+A(3) PUSH:NEW_DATA
		//  4) _+A(3)    | A(1) => A(1)+A(3) PUSH:NEW_DATA
		//  5) _+A(3)    | A(3) => A(3)      PULL:REVERT_ACHIEVED_CHANGES
		//  6) _+A(3)    | A(5) => A(5)      PULL:REVERT_ACHIEVED_CHANGES PULL:REVERT_OUTDATED_CHANGES

		//  7) A(2)      |    _ =>    _      PULL:DELETE_OLD_DATA
		//  8) A(2)      | A(1) => A(1)+A(2) ERR:LOCAL_CONFIRMED_NEWER_THAN_REMOTE PUSH:CAUTIOUS
		//  9) A(2)      | A(2) => A(2)      KEEP:SYNCED
		// 10) A(2)      | A(3) => A(3)      PULL:NEW_DATA

		// 11) A(2)+A(5) |    _ =>    _+A(5) PULL:DELETE_OLD_DATA PUSH:NEW_DATA
		// 12) A(2)+A(5) | A(1) => A(1)+A(5) ERR:LOCAL_CONFIRMED_NEWER_THAN_REMOTE (skip: PUSH:CAUTIOUS) PUSH:NEW_DATA
		// 13) A(2)+A(5) | A(2) => A(2)+A(5) PUSH:NEW_DATA
		// 14) A(2)+A(5) | A(3) => A(3)+A(5) PULL:NEW_DATA PUSH:NEW_DATA
		// 15) A(2)+A(5) | A(5) => A(5)      PULL:NEW_DATA PULL:REVERT_ACHIEVED_CHANGES
		// 16) A(2)+A(5) | A(6) => A(6)      PULL:NEW_DATA PULL:REVERT_ACHIEVED_CHANGES PULL:REVERT_OUTDATED_CHANGES

		// 17) A(2)-A(5) |    _ =>    _      DELETE_OLD_DATA PULL:REVERT_ACHIEVED_CHANGES
		// 18) A(2)-A(5) | A(1) => A(1)-A(5) ERR:LOCAL_CONFIRMED_NEWER_THAN_REMOTE (skip: PUSH:CAUTIOUS) PUSH:NEW_DATA
		// 19) A(2)-A(5) | A(2) => A(2)-A(5) PUSH:NEW_DATA
		// 20) A(2)-A(5) | A(3) => A(3)-A(5) PULL:NEW_DATA PUSH:NEW_DATA
		// 21) A(2)-A(5) | A(5) => A(5)      PULL:NEW_DATA PULL:CAUTIOUS
		// 22) A(2)-A(5) | A(6) => A(6)      PULL:NEW_DATA PULL:REVERT_OUTDATED_CHANGES

		// INVALID original items - catch with ReadingListItem.validate()
		//         discard original change

		// 23)    _-A(2) |    _ =>    _      SEE  1)
		// 24)    _-A(2) | A(1) => A(1)      SEE  2)
		// 25) A(2)+A(1) | A(1) => A(1)+A(2) SEE  8)
		// 26) A(2)+A(1) | A(2) => A(2)      SEE  9)
		// 27) A(2)+A(1) | A(3) => A(3)      SEE 10)
		// 28) A(2)-A(1) | A(1) => A(1)+A(2) SEE  8)
		// 29) A(2)-A(1) | A(2) => A(2)      SEE  9)
		// 30) A(2)-A(1) | A(3) => A(3)      SEE 10)
		// 31) A(2)+A(2) | A(1) => A(1)+A(2) SEE  8)
		// 32) A(2)+A(2) | A(2) => A(2)      SEE  9)
		// 33) A(2)+A(2) | A(3) => A(3)      SEE 10)
		// 34) A(2)-A(2) | A(1) => A(1)+A(2) SEE  8)
		// 35) A(2)-A(2) | A(2) => A(2)      SEE  9)
		// 36) A(2)-A(2) | A(3) => A(3)      SEE 10)

		// KEEP:SYNCED
		//   We do noting, because there are no changes.

		// PUSH:NEW_DATA
		//   We keep our changes to be written to the API because our data is
		//   newer.

		// PUSH:CAUTIOUS
		//   We write our data even if it has an invalid state to avoid unwanted
		//   deletions.

		// PULL:DELETE_OLD_DATA
		//   We delete our item because it does not exist remotely anymore.

		// PULL:NEW_DATA
		//   We copy the state of the remoteItem over to ours because it's data
		//   is newer.

		// PULL:REVERT_ACHIEVED_CHANGES
		//   We copy the state of the remoteItem over to ours because it's data
		//   is already in the state that our changes want to achieve.

		// PULL:REVERT_OUTDATED_CHANGES
		//   We copy the state of the remoteItem over to ours because it's data
		//   is newer than our changes.

		// PULL:CAUTIOUS
		//   We keep the remote state to avoid unwanted deletions.

		// ERR:LOCAL_CONFIRMED_NEWER_THAN_REMOTE
		//   A local confirmed entry can never be newer than the current entry
		//   on the server.

		/*
		 * STEP 0 - normalize invalid local items and create mergedItem
		 */

		if (localItem && localItem.validate() === false) {
			localItem.change = null
		}

		mergedItem = new ReadingListItem(type, id, localItem.ts, localItem.exists)

		if (localItem.change) {
			mergedItem.change = ReadingListChange.fromData(localItem.change)
		}

		/*
		 * STEP 1 - merge item (without change)
		 */
		if (remoteItem.exists === false) {
			if (localItem.exists) {
				// Remove deleted item
				mergedItem.exists = false
				mergedItem.ts = 0
			}
		} else {
			if (localItem.exists === false) {
				// Add new item from remote
				mergedItem.exists = true
				mergedItem.ts = remoteItem.ts
			} else {
				if (localItem.ts < remoteItem.ts) {
					// Update updated item from remote
					mergedItem.ts = remoteItem.ts
				} else if (localItem.ts > remoteItem.ts) {
					// ERR:LOCAL_CONFIRMED_NEWER_THAN_REMOTE PUSH:CAUTIOUS
					// Set older confirmed remote ts and add newer local ts as change
					mergedItem.ts = remoteItem.ts
					// We can just set the change.
					// If there is a newer one already set, it won't be
					// overwritten.
					mergedItem.setChange(new ReadingListChange(CHANGE_TYPE_ADD, localItem.ts))
				}
			}
		}

		/*
		 * STEP 2 - merge item change
		 */
		if (mergedItem.change) {
			if (mergedItem.exists === false) {
				if (mergedItem.change.changeType === CHANGE_TYPE_REMOVE) {
					mergedItem.change = null
				}
			} else {
				if (mergedItem.change.changeTS < mergedItem.ts) {
					mergedItem.change = null
				} else if (mergedItem.change.changeTS === mergedItem.ts) {
					mergedItem.change = null
				}
			}
		}

		/*
		 * STEP 3 - write merged data to reading list
		 */
		if (mergedItem.exists === false && mergedItem.change === null) {
			this.deleteRLItem(ReadingList._composeItemKey(type, id))
		} else {
			this.putItem(mergedItem)
		}

		return success
	}

	/**
	 * Returns a projection of all relevant reading list items to redux state.
	 *
	 * @return {ReadingListProjection}
	 */
	getItemsForState () {
		return ReadingList.itemsToState(this.getAllRLItems())
	}

	/**
	 * Returns all reading list items that have a {@link ReadingListChange}.
	 *
	 * @return {ReadingListItem[]}
	 */
	getChangedRLItems () {
		const items = this.getAllRLItems()
		return Object.keys(items).map(key => items[key]).filter(item => item.getChange() !== null)
	}

	/**
	 * Applies a reading list item's change in local storage (no sync).
	 * This is used to update the item after it has been synced successfully.
	 *
	 * @param {ReadingListItem} item
	 */
	applyChange (item) {
		const change = item.getChange()

		if (change === null) {
			return
		}

		switch (change.changeType) {
			case CHANGE_TYPE_ADD:
				item.ts = change.changeTS
				item.change = null
				item.exists = true
				this.putItem(item)
				break
			case CHANGE_TYPE_REMOVE:
				this.deleteRLItem(ReadingList._composeItemKey(item.type, item.id))
				break
		}
	}

	/*
	 * STATIC METHODS FOR EASY ACCESS TO defaultInstance
	 */

	/**
	 * Easy access function for default instance's putItem().
	 *
	 * @param {ReadingListItem} item
	 */
	static putItem (item) {
		return ReadingList.instance.putItem(item)
	}

	/**
	 * Easy access function for default instance's addRLItem().
	 *
	 * @param {string} type
	 * @param {string} id
	 */
	static addRLItem (type, id) {
		return ReadingList.instance.addRLItem(type, id)
	}

	/**
	 * Easy access function for default instance's removeRLItem().
	 *
	 * @param {string} type
	 * @param {string} id
	 */
	static removeRLItem (type, id) {
		return ReadingList.instance.removeRLItem(type, id)
	}

	/**
	 * Easy access function for default instance's getAllRLItems().
	 *
	 * @return {ReadingListItemSet}
	 */
	static getAllRLItems () {
		return ReadingList.instance.getAllRLItems()
	}

	/**
	 * Easy access function for default instance's removeAllRLItems().
	 */
	static removeAllRLItems () {
		ReadingList.instance.removeAllRLItems()
	}

	/**
	 * Easy access function for default instance's deleteAllRLItems().
	 */
	static deleteData () {
		ReadingList.instance.deleteAllRLItems()
	}

	/**
	 * Easy access function for default instance's mergeItemData().
	 *
	 * @param {ReadingListItemData[]} itemsData
	 *
	 * @return {boolean} success
	 */
	static mergeItemData (itemsData) {
		return ReadingList.instance.mergeItemData(itemsData)
	}

	/**
	 * Easy access function for default instance's getItemsForState().
	 *
	 * @return {ReadingListProjection}
	 */
	static getItemsForState () {
		return ReadingList.instance.getItemsForState()
	}

	/**
	 * Easy access function for default instance's getChangedRLItems().
	 *
	 * @return {ReadingListItem[]}
	 */
	static getChangedRLItems () {
		return ReadingList.instance.getChangedRLItems()
	}

	/**
	 * Easy access function for default instance's applyChange().
	 *
	 * @param {ReadingListItem} item
	 */
	static applyChange (item) {
		ReadingList.instance.applyChange(item)
	}

	/*
	 * HELPERS
	 */

	/**
	 * Create an item key.
	 *
	 * @param {string} type
	 * @param {string} id
	 *
	 * @return {string}
	 *
	 * @private
	 */
	static _composeItemKey (type, id) {
		return type + ITEM_KEY_GLUE + id
	}

	/**
	 * Retrieves item key components from key.
	 *
	 * @param {string} composedItemKey
	 *
	 * @return {{id: string, type: string}}
	 *
	 * @private
	 */
	static _decomposeItemKey (composedItemKey) {
		const parts = composedItemKey.split(composedItemKey, ITEM_KEY_GLUE)
		return {
			type: parts[0],
			id: parts[1]
		}
	}

	/**
	 * Returns a projection of all relevant given reading list items to redux
	 * state.
	 *
	 * @param {ReadingListItemSet} items
	 *
	 * @return {ReadingListProjection}
	 */
	static itemsToState (items) {
		const stateItems = {}

		Object.keys(items).forEach((itemKey) => {
			/** @type {ReadingListItem} */
			const item = items[itemKey]
			const type = item.type
			const id = item.id
			const change = item.getChange()

			if (change !== null && change.changeType === CHANGE_TYPE_REMOVE) {
				return
			}

			if (!stateItems.hasOwnProperty(type)) {
				stateItems[type] = []
			}

			stateItems[type].push('' + id)
		})

		return stateItems
	}

	/**
	 * Returns reading list items created from a state projection.
	 * CAUTION: This is no data to really work with. Essential information like
	 *          changes and timestamps are missing.
	 *
	 * @param {ReadingListProjection} readingListProjection
	 * @param {boolean} [exists]
	 *
	 * @return {ReadingListItem[]}
	 */
	static stateToItems (readingListProjection, exists = false) {
		const items = []

		Object.keys(readingListProjection).forEach((type) => {
			readingListProjection[type].forEach((id) => {
				items.push(new ReadingListItem(type, id, 0, exists))
			})
		})

		return items
	}

	/**
	 * Returns a readable short notation of the given reading list items.
	 *
	 * @param {ReadingListItemSet|ReadingListItem[]} items
	 *
	 * @return {string}
	 */
	static itemsToShortNotation (items) {
		let i = items
		if (!Array.isArray(items)) {
			i = Object.keys(items).map((key) => {
				return items[key]
			})
		}
		return i.map((item) => { return ReadingList.itemToShortNotation(item) }).join(' ')
	}

	/**
	 * Returns a readable short notation of the given reading list item.
	 *
	 * @param {ReadingListItem|ReadingListItemData} item
	 *
	 * @return {string}
	 */
	static itemToShortNotation (item) {
		let ret = ''
		let key = ReadingList._composeItemKey(item.type, item.id)

		ret += item.exists ? (key + '(' + item.ts + ')') : '_'

		if (item.change) {
			ret += item.change.changeType + key + '(' + item.change.changeTS + ')'
		}

		return ret
	}
}
