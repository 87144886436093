import React from 'react'
import FullscreenloaderView from '../../general/views/fullscreenloader.view'
import i18n from '../../../i18n'

function LoadingView (props) {
	// Assign all properties to variables for convenience
	return (
		<FullscreenloaderView message={i18n.t('TXT_GENERAL_LOGIN_LOADING')}/>
	)
}

// Export component
export default LoadingView
