import React from 'react'
import DevInfoView from './views/dev-info.view'
import { compose } from 'redux/lib/redux'
import withTitle from '../header/withTitle.hoc'
import StorageManager from 'js-storage-manager'
import { PUSH_NOTIFICATIONS_NAMESPACE, PN_USER_TOKEN } from '../../constants/names'

function DevInfoController (/* props */) {
	let sm = new StorageManager(PUSH_NOTIFICATIONS_NAMESPACE)
	let pnToken = sm.get(PN_USER_TOKEN)

	return (
		<DevInfoView pnToken={pnToken}/>
	)
}

export default compose(
	withTitle(null, 'TXT_DEV_MOLECULES_TITLE.label')
)(DevInfoController)
