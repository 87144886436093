import * as types from '../constants/action-types'
import { Search } from '../utils/search'

export function doSearch (searchRequest, content) {
	return async function (dispatch) {
		dispatch(setSearchRequest(searchRequest))
		if (!navigator.onLine) {
			// Search offline
			let searchResult = Search.search(content, searchRequest)
			dispatch(setSearchResult(searchResult, false))
		} else {
			// Search online
			let searchResultRaw = await Search.getSearchResultsOnline(searchRequest)
			let searchResult = Search.prepareOnlineSearchResult(searchResultRaw)
			dispatch(setSearchResult(searchResult, true))
		}
	}
}

export function setSearchResult (searchResult, isOnline) {
	return {
		type: types.SET_SEARCH_RESULT,
		searchResult,
		isOnline
	}
}

export function setSearchRequest (searchRequest) {
	return {
		type: types.SET_SEARCH_REQUEST,
		searchRequest
	}
}

export function unsetSearch () {
	return {
		type: types.UNSET_SEARCH
	}
}
