/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'
import commonPropTypes from '../../utils/common-prop-types'

export default class LinkAll extends Component {
	static isRoute (to, linkType) {
		switch (linkType) {
			case LinkAll.ROUTE:
				return true
			case LinkAll.LINK:
				return false
			default:
				let link = document.createElement('a')
				link.setAttribute('href', to)
				return window.location.hostname === link.hostname
		}
	}

	render () {
		let { to, linkType, className, children, optional, target, onClick, dataCy } = this.props
		let additional = { className, target, onClick }
		const isRoute = LinkAll.isRoute(to, linkType)

		// target="blank" without rel="noopener noreferrer" is insecure.
		// It opens an attack vector where the target window can access our
		// window via window.opener.
		if (target === '_blank') {
			additional.rel = 'noopener noreferrer'
		}

		if (!to && optional) {
			return <span className={className}>{children}</span>
		}

		if (!isRoute && to.length > 0) {
			additional.href = to
		}

		return (
			isRoute
				? <Link data-cy={dataCy} to={to} {...additional}>{children}</Link>
				: <a data-cy={dataCy} {...additional}>{children}</a>
		)
	}
}

LinkAll.AUTO = 0
LinkAll.ROUTE = 1
LinkAll.LINK = 2

LinkAll.defaultProps = {
	to: '',
	linkType: LinkAll.AUTO,
	className: null,
	optional: false,
	children: [],
	target: null,
	onClick: null
}

if (__DEV__) {
	LinkAll.propTypes = {
		to: propTypes.string.isRequired,
		linkType: propTypes.number,
		className: propTypes.string,
		optional: propTypes.bool,
		children: commonPropTypes.reactChildren,
		target: propTypes.string,
		onClick: propTypes.func,
		dataCy: propTypes.string
	}
}
