/* global __DEV__ */
import React, { Fragment } from 'react'
import propTypes from 'prop-types'
import StakeholdersListItemView from './stakeholders-list-item.view'
import AccordionWithTeaserController from '../../general/accordion/accordion-with-teaser.controller'

import i18n from './../../../i18n'

function StakeholdersAkkordeonView (props) {
	// Assign all properties to variables for convenience
	const { items, name } = props

	// Prepare StakeholdersListItemView for each stakeholder in akkordeon
	const listItems =
		Object.keys(items)
			.map((keyName, keyIndex) => {
				const item = items[keyName]
				return (
					<StakeholdersListItemView
						key={ keyIndex }
						id={ item.id.toString() }
						link={ item.url }
						title={ item.title }
						classes={ item.classes }
					/>
				)
			})

	return (
		<Fragment>
			<AccordionWithTeaserController
				title={name}
				initiallyClosed
				data={
					[
						{
							title: i18n.t('TXT_STAKEHOLDERS_SECTIONS_TITLE.label'),
							content: listItems
						}
					]
				}
			/>
		</Fragment>
	)
}

if (__DEV__) {
	// Properties types validation
	StakeholdersAkkordeonView.propTypes = {
		items: propTypes.array.isRequired,
		name: propTypes.string.isRequired
	}
}

// Export component
export default StakeholdersAkkordeonView
