/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToGenericControllerComponent } from '../../utils/mappings'
import ContentElementAccordionView from './views/content-element-accordion.view'

class ContentElementAccordionController extends Component {
	render () {
		let { contentElement } = this.props

		return (
			<ContentElementAccordionView
				content={contentElement.content}
			/>
		)
	}
}

if (__DEV__) {
	ContentElementAccordionController.propTypes = {
		contentElement: propTypes.object.isRequired
	}
}

export default connect(
	(state, ownProps) => mapAppStateToGenericControllerComponent(state, ownProps)
)(ContentElementAccordionController)
