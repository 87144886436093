/**
 * Dummy signal processor for AnalyticsModuleBasic that actually does no
 * processing.
 *
 * _@param {AnalyticsSignal} signal
 * @return {boolean}
 * @private
 */
export default function analyticsSignalProcessorBasic (/* signal */) {
	// This is a dummy. Do NOT mark as processed.
	return Promise.reject(new Error('not implemented'))
}
