/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToGenericControllerComponent } from '../../../utils/mappings'
import FooterButtonGenericView from './views/footer-button-generic.view'
import i18n from '../../../i18n'
import { withRouter } from 'react-router'
import { advancedRoutes } from '../../../utils/routes'

class FooterButtonSendArticleController extends Component {
	render () {
		const { match } = this.props
		return (
			<FooterButtonGenericView
				url={advancedRoutes.buildSendRoute(match)}
				icon={'icon-share'}
				dataCy={'send-item'}
				title={i18n.t('TXT_GENERAL_SEND.label')}
			/>
		)
	}
}

if (__DEV__) {
	FooterButtonSendArticleController.propTypes = {
		match: propTypes.object.isRequired,
		dispatch: propTypes.func.isRequired
	}
}

export default withRouter(connect(
	(state, ownProps) => mapAppStateToGenericControllerComponent(state, ownProps)
)(FooterButtonSendArticleController))
