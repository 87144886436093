/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToTopicControllerComponent } from '../../../../utils/mappings'
import TopicView from './views/topic.view'
import SectionMenuController from '../../../general/section-menu/section-menu.controller'
import i18n from 'i18next'
import routes from '../../../../utils/routes'
import ContentElementsController from '../../../content-elements/content-elements.controller'
import NotFoundController from '../../../general/not-found.controller'
import FooterButtonReadingListController from '../../../footer/buttons/footer-button-reading-list.controller'
import ContentFooterController from '../../../footer/content-footer.controller'
import { withPageContentSections } from '../../../pages/page-content.controller'
import { compose } from 'redux/lib/redux'
import withTitle from '../../../header/withTitle.hoc'
import commonPropTypes from '../../../../utils/common-prop-types'
import setEntityViewed from '../../../general/set-entity-viewed.hoc'
import loadable from '../../../loadable/loadable.hoc'
import LinkListController from '../../../general/link-list.controller'

class TopicController extends Component {
	static getTitle (props) {
		const { topic } = props
		let title

		if (topic) {
			title = topic.title
		} else {
			title = i18n.t('TXT_CATEGORIES_TOPIC_DETAIL_TITLE.label')
		}

		return title
	}

	render () {
		const { category, topic, activeSectionKey } = this.props
		let activeContentElements
		let activeSection

		if (!topic) {
			let links = []

			if (category) {
				links.push({ name: i18n.t('TXT_CATEGORIES_DETAIL_TITLE.label') + ' - ' + category.title, href: routes.categoriesDetail.build(category.id) })
			}

			links.push({ name: i18n.t('TXT_CATEGORIES_LIST_TITLE.label'), href: routes.categoriesList.build() })

			return <NotFoundController links={links} />
		}

		activeSection = topic.contentElements.find(section => section.name === activeSectionKey)
		activeContentElements = activeSection ? activeSection.elements : []

		return (
			<TopicView title={topic.title}>
				<ContentElementsController contentElements={activeContentElements} />
				<LinkListController links={topic.links}>
					<hr />
					<h2 className='a-headline--h2'>Links</h2>
				</LinkListController>
			</TopicView>
		)
	}

	static renderContentHeader (props) {
		return <SectionMenuController sections={props.topic.contentElements}/>
	}

	static renderContentFooter (props) {
		const { topic, inReadingList } = props
		const readingListInformation = FooterButtonReadingListController.createReadingListInformation(
			'topics',
			topic,
			inReadingList
		)

		return (
			<ContentFooterController
				readingListInformation={readingListInformation}
				showSendButton
			/>
		)
	}
}

if (__DEV__) {
	TopicController.propTypes = {
		category: propTypes.object,
		topic: propTypes.object,
		topicId: propTypes.string.isRequired,
		inReadingList: propTypes.bool.isRequired,
		activeSectionKey: propTypes.string.isRequired,

		...commonPropTypes.mixinFromReduxConnect
	}
}

export default compose(
	connect(mapAppStateToTopicControllerComponent),
	withPageContentSections,
	withTitle(TopicController.getTitle),
	loadable('topics', 'topic', 'topicId'),
	setEntityViewed('topics', 'topic')
)(TopicController)
