import StorageManager from 'js-storage-manager'

const sm = new StorageManager('vfa-features')

const features = {}

/**
 * A callback, used to show a notification about a missing feature.
 *
 * @callback ApplicationFeature~notificationCallback
 * @param {string} title
 * @param {string} text
 * @param {func} confirmationCallback
 */

/**
 * Global callback, used to notify the user about missing features.
 *
 * @type {null|ApplicationFeature~notificationCallback}
 */
let funcCreateModal = null

class ApplicationFeature {
	constructor (requiredCapabilities, unsupportedMessageTitle, unsupportedMessageBody, enabled = true) {
		this.featureName = this.constructor.featureName
		this.isEnabled = enabled
		this.supported = requiredCapabilities.indexOf(false) === -1
		this.unsupportedMessageTitle = unsupportedMessageTitle
		this.unsupportedMessageBody = unsupportedMessageBody
		this.unsupportedMessageShown = this.hasUnsupportedMessageConfirmation()
		this.setUnsupportedMessageConfirmation = this.setUnsupportedMessageConfirmation.bind(this)
	}

	hasUnsupportedMessageConfirmation () {
		return sm.get(this.featureName) || false
	}

	setUnsupportedMessageConfirmation () {
		sm.set(this.featureName, true)
	}

	static getInstance () {
		if (!(this.featureName in features)) {
			features[this.featureName] = new this()
		}

		return features[this.featureName]
	}

	static get isSupported () {
		const feature = this.getInstance()

		if (feature === null) {
			return false
		}

		return feature.supported
	}

	static get isEnabled () {
		const feature = this.getInstance()

		if (feature === null) {
			return false
		}

		return feature.isEnabled
	}

	static showUnsupportedMessage (name, force = false) {
		const feature = this.getInstance()

		if (feature === null) {
			return false
		}

		if (feature.unsupportedMessageShown && !force) {
			return
		}

		feature.unsupportedMessageShown = true

		// Notify the user about the missing feature.
		ApplicationFeature.createModal(
			feature.unsupportedMessageTitle,
			feature.unsupportedMessageBody,
			feature.setUnsupportedMessageConfirmation
		)
	}

	/**
	 * Sets the global callback, used to notify the user about missing features.
	 *
	 * @param {ApplicationFeature~notificationCallback} func
	 */
	static setFuncCreateModal (func) {
		funcCreateModal = func
	}

	/**
	 * Notifies the user about the missing feature if funcCreateModal
	 * was set before via ApplicationFeature.setFuncCreateModal.
	 *
	 * @param {string} title
	 * @param {string} text
	 * @param {func} confirmationCallback
	 */
	static createModal (title, text, confirmationCallback) {
		funcCreateModal && funcCreateModal(title, text, confirmationCallback)
	}

	static checkSupport () {
		if (!this.isEnabled) {
			return false
		}

		if (!this.isSupported) {
			this.showUnsupportedMessage()
			return false
		}

		return true
	}
}

ApplicationFeature.featureName = ''

export default ApplicationFeature
