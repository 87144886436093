/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import CategoryListView from './views/category-list.view'
import CategoryListItemView from './views/category-list-item.view'
import { connect } from 'react-redux'
import { mapAppStateToCategoriesComponent } from '../../utils/mappings'
import { withPageContentSections } from '../pages/page-content.controller'
import routes from '../../utils/routes'
import { compose } from 'redux/lib/redux'
import withTitle from '../header/withTitle.hoc'
import commonPropTypes from '../../utils/common-prop-types'

class CategoryListController extends Component {
	render () {
		const { categories, updatedCategoryTopicsQuantity } = this.props

		/* transform categories object into array */
		let categoryArray = []
		Object.keys(categories).map((categoryId, keyIndex) => {
			const category = categories[categoryId]
			categoryArray.push(category)
		})

		/* sort array by sorting property */
		categoryArray.sort((a, b) => (a.sorting > b.sorting) ? 1 : -1)

		return (
			<CategoryListView>
				{categoryArray.map((category, keyIndex) => {
					const categoryId = category.id

					const stylesObject = {
						backgroundImage: `url(${category.image_bg})`
					}

					const link = routes.categoriesDetail.build(categoryId)
					let updatesQuantity = updatedCategoryTopicsQuantity[categoryId] || 0

					return (
						<CategoryListItemView
							key={keyIndex}
							updatesQuantity={updatesQuantity}
							category={category}
							stylesObject={stylesObject}
							link={link}
						/>
					)
				})}
			</CategoryListView>
		)
	}
}

if (__DEV__) {
	CategoryListController.propTypes = {
		categories: propTypes.object.isRequired,
		updatedCategoryTopicsQuantity: propTypes.objectOf(
			propTypes.number
		).isRequired,

		...commonPropTypes.mixinFromReduxConnect
	}
}

const CategoryListControllerWithoutPageSections = compose(
	connect(mapAppStateToCategoriesComponent)
)(CategoryListController)

export default compose(
	withPageContentSections,
	withTitle(null, 'TXT_CATEGORIES_LIST_TITLE.label')
)(CategoryListControllerWithoutPageSections)

export {
	CategoryListControllerWithoutPageSections
}
