/* global __DEV__ */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { accordionSetActiveHeader } from '../../../actions/accordion.actions'
import AccordionView from './views/accordion.view'
import { mapAppStateToAccordionControllerComponent } from '../../../utils/mappings'
import commonPropTypes from '../../../utils/common-prop-types'
import propTypes from 'prop-types'

class AccordionController extends Component {
	constructor (props) {
		super(props)

		this.reduxControlled = props.accordionKey.length > 0

		this.state = {
			activeTabIndex: props.activeTabIndex
		}
	}

	toggleActiveHeader (index) {
		const { dispatch, closable, activeTabIndex, accordionKey, onChangeActiveTabIndex } = this.props
		let currentActiveTabIndex

		if (this.reduxControlled) {
			currentActiveTabIndex = activeTabIndex
		} else {
			currentActiveTabIndex = this.state.activeTabIndex
		}

		if (closable && (index === currentActiveTabIndex)) {
			index = -1
		}

		if (onChangeActiveTabIndex !== null) {
			onChangeActiveTabIndex(index)
		}

		if (this.reduxControlled) {
			dispatch(accordionSetActiveHeader(accordionKey, index))
		} else {
			this.setState({
				activeTabIndex: index
			})
		}
	}

	render () {
		let { activeTabIndex, unsafeParseContent } = this.props

		if (!this.reduxControlled) {
			activeTabIndex = this.state.activeTabIndex
		}

		return (
			<AccordionView
				data={this.props.data}
				onHeaderClick={this.toggleActiveHeader.bind(this)}
				activeTabIndex={activeTabIndex}
				unsafeParseContent={unsafeParseContent}
			/>
		)
	}
}

AccordionController.defaultProps = {
	accordionKey: '',
	initiallyClosed: false,
	closable: true,
	onChangeActiveTabIndex: null
}

if (__DEV__) {
	AccordionController.propTypes = {
		/**
		 * Set this key if you want the component's state to be managed by
		 * redux. That means that you can link multiple accorions to one another
		 * and that the accordion state will be saved even when the page is
		 * left.
		 *
		 * This prop can be set manually for accordions that are uniquely
		 * identifiable. (e.g. some fixed position/UI accordion for some
		 * specific content)
		 * This could be for example for StakeholdersListController one
		 * accordion for each listed stakeholder, identified by
		 * '/stakeholders--id-123'.
		 *
		 * For non- (or hardly) identifiable accordions (e.g. content elements)
		 * skip this prop.
		 */
		accordionKey: propTypes.string,
		data: AccordionView.propTypes.data,
		activeTabIndex: AccordionView.propTypes.activeTabIndex,
		/**
		 * This property is evaluated in it's state->props mapping function.
		 */
		initiallyClosed: propTypes.bool,
		closable: propTypes.bool,
		onChangeActiveTabIndex: propTypes.func,
		unsafeParseContent: AccordionView.propTypes.unsafeParseContent,

		...commonPropTypes.mixinFromReduxConnect
	}
}

export default connect(mapAppStateToAccordionControllerComponent)(AccordionController)
