/* global __DEV__ */
import React, { Fragment } from 'react'
import propTypes from 'prop-types'
import SearchInputController from '../search-input/search-input.controller'
import i18n from 'i18next'
import '../../../i18n'

export function SearchListEmptyView (props) {
	const { searchRequest } = props
	return (
		<Fragment>
			<div className={'container o-list-view'}>
				<div className={'m-search-box m-search-box--main'}>
					<SearchInputController callFromMenu={false}/>
				</div>

				<div className={'m-list-item-wrapper'}>
					<p>{i18n.t('TXT_SEARCH_EMPTY_LIST.label').replace(/%search/, searchRequest)}</p>
				</div>
			</div>
		</Fragment>
	)
}

if (__DEV__) {
	SearchListEmptyView.propTypes = {
		searchRequest: propTypes.string
	}
}

export default SearchListEmptyView
