/* global __DEV__ */
import * as types from '../constants/action-types'
import propTypes from 'prop-types'
import routes from '../utils/routes'

const name = 'loginProcess'

const LOGIN_PROCESS = {
	AUTH_DATA_SAVE_PROGRESS_STATUS: {
		FALSE: 'false',
		IN_PROGRESS: 'in-progress',
		AUTH_DATA_SAVED: 'auth-data-saved'
	}
}

/**
 * We use a function here to prevent passing an object reference.
 *
 * Otherwise this could happen:
 * 1. Use initialState reference for initialisation. state.foo is now a
 *    reference to initialState.foo if foo is an object or array.
 * 2. Some action sets state.foo.bar.
 *    This is where the problem occurs: initialState.foo.bar now has been
 *    changed.
 * 3. Some other action wants to reset state.foo by assigning initialState.foo.
 *    But instead of having the actual initial value of state.foo.bar it now has
 *    the value that got set in 2.)
 *
 * @return {Object}
 */
const getInitialState = () => {
	return {
		error: {
			status: false,
			errorMessage: ''
		},
		authData: {
			loggedIn: false,
			email: '',
			message: ''
		},
		authDataSaveProgressStatus: LOGIN_PROCESS.AUTH_DATA_SAVE_PROGRESS_STATUS.FALSE,
		rememberPath: null
	}
}

const reducer = function (state = getInitialState(), action) {
	let newState = { ...state }
	switch (action.type) {
		case types.ADD_AUTH_DATA:
			newState.authData = action.authData
			return newState

		case types.SET_AUTH_DATA_SAVE_PROGRESS_STATUS:
			newState.authDataSaveProgressStatus = action.progressStatus
			return newState

		case types.SET_LOGIN_ERROR:
			newState.error = action.error
			return newState

		case types.REMEMBER_PATH:
			if (action.path !== routes.loginRegistration.path) {
				newState.rememberPath = action.path
			// } else {
			// 	newState.rememberPath = null
			}
			return newState

		default:
			return state
	}
}

const reducerPropTypes = __DEV__ && propTypes.exact({
	error: propTypes.exact({
		status: propTypes.bool.isRequired,
		errorMessage: propTypes.string.isRequired
	}).isRequired,
	authData: propTypes.exact({
		loggedIn: propTypes.bool.isRequired,
		email: propTypes.string,
		message: propTypes.string
	}).isRequired,
	authDataSaveProgressStatus: propTypes.string.isRequired,
	rememberPath: propTypes.string
}).isRequired

const loginProcessReducerDefinition = {
	name,
	/**
	 * We want to be able to use reducerDefinition.initialState without
	 * polluting initial state.
	 *
	 * @return {Object}
	 */
	get initialState () {
		return getInitialState()
	},
	reducer,
	reducerPropTypes
}

export default loginProcessReducerDefinition

export {
	LOGIN_PROCESS
}
