import React, { Fragment } from 'react'

function PrivacyView (props) {
	return (
		<div className={'o-detail-view o-detail-view--no-tab'}>

			<h2 className={'a-headline a-headline--h2'}>Wer sind wir?</h2>
			<p>
				Verband forschender Arzneimittelhersteller e.V.,<br /> vertreten durch den vfa-Vorstandsvorsitzenden und kommissarischen Leiter der
				vfa-Hauptgeschäftsführung Han Steutel mit Sitz am Hausvogteiplatz 13, 10117 Berlin und erreichbar per Telefon: +49 30 20604-0,
				per Fax: +49 30 20604-222 und per Email: <a href={"mailto:info@vfa.de"}>info@vfa.de</a>.
			</p>

			<h2 className={'a-headline a-headline--h2'}>Wer ist bei uns für den Datenschutz verantwortlich (Datenschutzbeauftragter)?</h2>
			<p>
				Bei uns ist jeder für das Thema Datenschutz verantwortlich. Zusätzlich haben wir uns entschieden, einen Datenschutzbeauftragten zu bestellen.
				Um seine Unabhängigkeit zu gewährleisten, haben wir einen externen Berater beauftragt. Dabei handelt es sich um den Rechtsanwalt Dr. Stephan Gärtner.
				Gern können Sie ihn jederzeit kontaktieren. Sie erreichen ihn wie folgt:<br /><br />

				Rechtsanwalt Dr. Stephan Gärtner,<br />
				StanhopeONE,<br />
				Voßstraße 20, 10117 Berlin<br />
				<a href={"mailto:gaertner@stanhope.de"}>gaertner@stanhope.de</a>,<br />
				Tel. +49 30 89614237<br />
				Fax. +49 3012053097 9<br />
			</p>

			<h2 className={'a-headline a-headline--h2'}>Was geschieht bei der rein informatorischen Nutzung der App?</h2>
			<p>
				Wenn Sie unsere App rein informatorisch nutzen, also wenn Sie sich als Nutzer weder registrieren noch anderweitig Informationen übermitteln,
				erheben wir folgende Daten von Ihnen: IP-Adresse, Datum und Uhrzeit der Anfrage, Zeitzonendifferenz zur Greenwich Mean Time (GMT),
				Inhalt der Anforderung (konkrete Seite), Zugriffsstatus/HTTP-Statuscode, jeweils übertragene Datenmenge, Website, von der die Anforderung kommt,
				Browser, Betriebssystem und dessen Oberfläche, Sprache und Version der Browsersoftware. Wir erhalten diese Daten über Cookies und direkt von Ihrem Browser.
				Zweck dieser Verarbeitung ist die Bereitstellung unserer Internetseite sowie die statistische Auswertung. Rechtsgrundlage hierfür ist Artikel 6 Absatz 1 Satz 1 lit.
				f DSGVO, wonach die Verarbeitung personenbezogener Daten auch ohne Einwilligung des Betroffenen möglich ist,
				wenn die Verarbeitung zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten erforderlich ist,
				sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern,
				überwiegen, insbesondere dann, wenn es sich bei der betroffenen Person um ein Kind handelt. Die vorgenannten Zwecke sind in unserem Interesse.
				Soweit wir Cookies einsetzen, verweisen wir auf unsere Ausführungen unter „Wie setzen wir Cookies auf dieser Internetseite ein?“.
			</p>

			<h2 className={'a-headline a-headline--h2'}>Wie setzen wir Google Analytics ein?</h2>
			<p>
				(1) Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien,
				die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen.
				Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
				Im Falle der Aktivierung der IP-Anonymisierung auf dieser Website, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union
				oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google
				in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports
				über die Website-Aktivitäten zusammenzustellen und um weitere mit der Website-Nutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Website-Betreiber zu erbringen.
				Rechtsgrundlage ist Artikel 6 Absatz 1 Satz 1 lit. f DSGVO, Artikel 6 Absatz 1 Satz 1 lit. f DSGVO, wonach die Verarbeitung personenbezogener Daten auch
				ohne Einwilligung des Betroffenen möglich ist, wenn die Verarbeitung zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten erforderlich ist,
				sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen,
				insbesondere dann, wenn es sich bei der betroffenen Person um ein Kind handelt. Unser berechtigtes unternehmerisches Interesse ist es, das Nutzerverhalten auf dieser Website auszuwerten.
				Ihre Interessen werden durch Ihre Vermeidungsmöglichkeiten, die wir später beschreiben und die Anonymisierung, gewahrt.
				Wir haben mit dem Anbieter ferner eine Auftragsverarbeitung geschlossen und so unser Weisungsrecht gegenüber dem Anbieter sichergestellt.
				Wir löschen die Daten spätestens nach 14 Monaten.<br />
				(2) Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.<br />
				(3) Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin,
				dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
				Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google
				sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plug-in herunterladen und
				installieren: <a href={"http://tools.google.com/dlpage/gaoptout?hl=de"}>http://tools.google.com/dlpage/gaoptout?hl=de</a>.<br />
				(4) Diese Website verwendet Google Analytics mit der Erweiterung „_anonymizeIp()“. Dadurch werden IP-Adressen gekürzt weiterverarbeitet,
				eine Personenbeziehbarkeit kann damit ausgeschlossen werden. Soweit den über Sie erhobenen Daten ein Personenbezug zukommt,
				wird dieser also sofort ausgeschlossen und die personenbezogenen Daten damit umgehend gelöscht.<br />
				(5) Wir nutzen Google Analytics, um die Nutzung unserer Website analysieren und regelmäßig verbessern zu können.
				Über die gewonnenen Statistiken können wir unser Angebot verbessern und für Sie als Nutzer interessanter ausgestalten.
				Für die Ausnahmefälle, in denen personenbezogene Daten in die USA übertragen werden, hat sich Google dem EU-US Privacy Shield unterworfen,
				<a href={"https://www.privacyshield.gov/EU-US-Framework"}>https://www.privacyshield.gov/EU-US-Framework</a>.<br />
				(6) Informationen des Drittanbieters: Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001.
				Nutzerbedingungen: <a href={"http://www.google.com/analytics/terms/de.html"}>http://www.google.com/analytics/terms/de.html</a>,
				Übersicht zum Datenschutz: <a href={"http://www.google.com/intl/de/analytics/learn/privacy.html"}>http://www.google.com/intl/de/analytics/learn/privacy.html</a>,
				sowie die Datenschutzerklärung:  <a href={"http://www.google.de/intl/de/policies/privacy"}>http://www.google.de/intl/de/policies/privacy</a>.<br />
				(7) Diese Website verwendet Google Analytics zudem für eine geräteübergreifende Analyse von Besucherströmen, die über Ihre persönliche Google-User-ID
				(sofern vorhanden und auf allen Geräten aktiviert) durchgeführt wird. Sie können in Ihrem Google-Kundenkonto unter „Meine Daten“, „persönliche Daten“
				die geräteübergreifende Analyse Ihrer Nutzung deaktivieren.<br />
				(8) Ergänzend setzen wir hier den Google Tag-Manager ein. Mit dem Google Tag Manager können wir Code Snippets wie Tracking Codes oder Conversionpixel auf
				unseren Websites und mobilen Apps einbauen, ohne dabei in den Quellcode einzugreifen. Es muss lediglich einmal der Google Tag Manager Code eingebunden werden.
				Nähere Informationen finden Sie hier: <a href={"http://www.google.com/intl/de/tagmanager/features.html"}>http://www.google.com/intl/de/tagmanager/features.html</a>.
			</p>

			<h2 className={'a-headline a-headline--h2'}>Wie setzen wir YouTube-Videos ein?</h2>
			<p>
				(1) Wir haben YouTube-Videos in unser Online-Angebot eingebunden, die auf <a href={"http://www.YouTube.com"}>http://www.YouTube.com</a> gespeichert sind und von unserer Website aus direkt abspielbar sind. [Diese sind alle im „erweiterten Datenschutz-Modus“ eingebunden, d. h. dass keine Daten über Sie als Nutzer an YouTube übertragen werden, wenn Sie die Videos nicht abspielen. Erst wenn Sie die Videos abspielen, werden die in Absatz 2 genannten Daten übertragen. Auf diese Datenübertragung haben wir keinen Einfluss.
				(2) Durch den Besuch auf der Website erhält YouTube die Information, dass Sie die entsprechende Unterseite unserer Website aufgerufen haben. Zudem werden die unter Absatz 3 genannten Daten übermittelt. Dies erfolgt unabhängig davon, ob YouTube ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei YouTube nicht wünschen, müssen Sie sich vor Aktivierung des Buttons ausloggen. YouTube speichert Ihre Daten als Nutzungsprofile und nutzt sie für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche Auswertung erfolgt insbesondere (selbst für nicht eingeloggte Nutzer) zur Erbringung von bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an YouTube richten müssen.
				(3) Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch YouTube erhalten Sie in der Datenschutzerklärung. Dort erhalten Sie auch weitere Informationen zu Ihren Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre: <a href={"https://www.google.de/intl/de/policies/privacy"}>https://www.google.de/intl/de/policies/privacy</a>. Google verarbeitet Ihre personenbezogenen Daten auch in den USA und hat sich dem EU-US-Privacy-Shield unterworfen, <a href={"https://www.privacyshield.gov/EU-US-Framework"}>https://www.privacyshield.gov/EU-US-Framework</a>.
				(4) Rechtsgrundlage ist Artikel 6 Absatz 1 Satz 1 lit. f DSGVO, Artikel 6 Absatz 1 Satz 1 lit. f DSGVO, wonach die Verarbeitung personenbezogener Daten auch ohne Einwilligung des Betroffenen möglich ist, wenn die Verarbeitung zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten erforderlich ist, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen, insbesondere dann, wenn es sich bei der betroffenen Person um ein Kind handelt. Hier berufen wir uns auf unser Interesse an Direktwerbung gemäß ErwG 47 a.E. DSGVO.
			</p>

			<h2 className={'a-headline a-headline--h2'}>Sind wir verpflichtet, diese Daten zu erheben? Was geschieht, wenn wir diese Daten nicht erheben?</h2>
			<p>Eine gesetzliche Pflicht zur Erhebung dieser Daten besteht nicht. Es besteht jedoch die Möglichkeit, dass bei Ausbleiben der Erhebung der Besuch unserer Internetseite erschwert wird.</p>

			<h2 className={'a-headline a-headline--h2'}>Wem übermitteln wir die Daten? Und: Verlassen die Daten die Europäische Union oder den europäischen Wirtschaftsraum?</h2>
			<p>Folgende Unternehmen empfangen die o.g. Daten, wobei nachfolgend darauf hinweisen, falls die Daten die Europäische Union oder den Europäischen Wirtschaftsraum verlassen:</p>

			<h2 className={'a-headline a-headline--h2'}>Gewährleisten wir hinreichende Datensicherheit?</h2>
			<p>Wir unterhalten aktuelle technische Maßnahmen zur Gewährleistung der Datensicherheit, insbesondere zum Schutz Ihrer personenbezogenen Daten vor Gefahren bei Datenübertragungen sowie vor Kenntniserlangung durch Dritte. Diese werden dem aktuellen Stand der Technik entsprechend jeweils angepasst.</p>

			<h2 className={'a-headline a-headline--h2'}>Wie setzen wir Cookies in unserer App ein?</h2>
			<p>Weiterhin werden bei Nutzung der Website Cookies auf Ihrem Rechner gespeichert. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen verwendeten Browser zugeordnet gespeichert werden und durch welche der Stelle, die den Cookie setzt (in diesem Fall also uns), bestimmte Informationen zufließen. Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und effektiver zu machen. Sie werden nach 15 Tagen wieder gelöscht.</p>

			<h2 className={'a-headline a-headline--h2'}>Welche Rechte haben Sie?</h2>
			<p>Sie haben einige Rechte. Sie haben das Recht auf Auskunft über die zu Ihrer Person verarbeiteten personenbezogenen Daten sowie auf Berichtigung oder Löschung, auf Einschränkung der Verarbeitung, auf Widerspruch gegen die Verarbeitung sowie auf Datenübertragbarkeit. Ferner haben Sie die Möglichkeit, sich über uns bei der für uns zuständigen Aufsichtsbehörde zu beschweren. Höflich weisen wir darauf hin, dass diese Rechte ggf. an Voraussetzungen geknüpft sind, auf deren Vorliegen wir bestehen werden.</p>
			<p>Stand: 24.05.2018</p>
		</div>
	)
}

// Export component
export default PrivacyView
