import { DataLayerResponseBase } from '../../../lib/DataLayerResponse'
import {
	CreateGlamusTypeModel,
	CreateGlamusAllDataModel,
	CreateGlamusAllDataSingleModel,
	CreateGlamusAllDataUpdateModel,
	CreateGlamusPreviewNewsDataModel,
	CreateGlamusPreviewPressReleaseDataModel,
	CreateGlamusPreviewArticleDataModel
} from '../lib/models'
import { DataModifierArticleAllNormalized } from '../lib/data-modifier-article-all-normalized'
import ResponseCodeCheckers from '../lib/response-code-checkers'
import { LoginProcess } from '../../../../utils/login-process.js'

/**
 * Gets the type of given single view token.
 *
 * @param singleViewToken
 * @returns {Promise<DataLayerResponseBase>}
 */
const getType = async function (singleViewToken) {
	let data = { 'singleViewToken': singleViewToken, type: null, id: null }
	let success = true

	let modelType = CreateGlamusTypeModel(singleViewToken)

	let responseType = null
	try {
		responseType = await modelType.request()

		if (ResponseCodeCheckers.isRequestFailed(responseType)) {
			success = false
		} else if (!('type' in responseType.data) || responseType.status !== 200) {
			success = false
		} else {
			data.type = responseType.data.type
			data.id = responseType.data.id
		}
	} catch (e) {
		success = false
	}

	return new DataLayerResponseBase(true, success, data)
}

/**
 * Gets the article item from given single view token and article item id.
 *
 * @param config
 * @returns {Promise<DataLayerResponseBase>}
 */
const getArticlePreview = async function (config) {
	let data = {}
	let success = true
	let modelPreviewNews = CreateGlamusPreviewArticleDataModel(config)
	let responsePreviewNews = null

	try {
		responsePreviewNews = await modelPreviewNews.request()

		if (ResponseCodeCheckers.isRequestFailed(responsePreviewNews)) {
			success = false
		} else if (responsePreviewNews.status === 200) {
			DataModifierArticleAllNormalized.addGroups(data, responsePreviewNews.data)
		} else {
			success = false
		}
	} catch (e) {
		console.error(e)
		success = false
	}

	return new DataLayerResponseBase(true, success, data)
}

/**
 * Gets the news item from given single view token and news item id.
 *
 * @param config
 * @returns {Promise<DataLayerResponseBase>}
 */
const getNewsPreview = async function (config) {
	let data = {}
	let success = true
	let modelPreviewNews = CreateGlamusPreviewNewsDataModel(config)
	let responsePreviewNews = null

	try {
		responsePreviewNews = await modelPreviewNews.request()

		if (ResponseCodeCheckers.isRequestFailed(responsePreviewNews)) {
			success = false
		} else if (responsePreviewNews.status === 200) {
			DataModifierArticleAllNormalized.addGroups(data, responsePreviewNews.data)
		} else {
			success = false
		}
	} catch (e) {
		console.error(e)
		success = false
	}

	return new DataLayerResponseBase(true, success, data)
}

/**
 * Gets the press release item from given single view token and press release item id.
 *
 * @param config
 * @returns {Promise<DataLayerResponseBase>}
 */
const getPressReleasePreview = async function (config) {
	let data = {}
	let success = true
	let modelPreviewPressRelease = CreateGlamusPreviewPressReleaseDataModel(config)
	let responsePreviewPressRelease = null

	try {
		responsePreviewPressRelease = await modelPreviewPressRelease.request()

		if (ResponseCodeCheckers.isRequestFailed(responsePreviewPressRelease)) {
			success = false
		} else if (responsePreviewPressRelease.status === 200) {
			DataModifierArticleAllNormalized.addGroups(data, responsePreviewPressRelease.data)
		} else {
			success = false
		}
	} catch (e) {
		console.error(e)
		success = false
	}

	return new DataLayerResponseBase(true, success, data)
}

const getDataFull = async function () {
	let data = {}
	let singleCalls = false
	let success = true

	if (!singleCalls) {
		/* Fetch all data at once (recommended) */
		let modelAll = CreateGlamusAllDataModel()
		let responseAll = null
		try {
			responseAll = await modelAll.request()

			if (ResponseCodeCheckers.isRequestFailed(responseAll)) {
				success = false
			} else if (responseAll.status === 200) {
				DataModifierArticleAllNormalized.addGroups(data, responseAll.data)
			} else {
				success = false
			}
		} catch (e) {
			console.error(e)

			responseAll = e.response

			/* We have handed over an invalid token and log out in here. */
			if (responseAll.status === 401) {
				LoginProcess.logout(true)
			}

			success = false
		}
	} else {
		/* Fetch all data via single calls (not recommended). */
		let modelAllSingle = CreateGlamusAllDataSingleModel()
		let responseAllSingle = null
		try {
			responseAllSingle = await modelAllSingle.request()

			if (ResponseCodeCheckers.isRequestFailed(responseAllSingle)) {
				success = false
			} else if (responseAllSingle.status === 200) {
				DataModifierArticleAllNormalized.addGroups(data, responseAllSingle.data)
			} else {
				success = false
			}
		} catch (e) {
			console.error(e)
			success = false
		}
	}

	return new DataLayerResponseBase(true, success, data)
}

/**
 *
 * @param since {Number}
 * @return {Promise<DataLayerResponseBase>}
 */
const getDataUpdates = async function (since) {
	/*
	* TODO:
	* 1) Get data from last update
	* 2) Normalize data
	* 3) return DataLayerResponseBase
	* */

	let success = true
	let data = {}
	let updateResponseRaw = null

	// Create model instance
	let updateModel = CreateGlamusAllDataUpdateModel(since)

	// Make request
	try {
		updateResponseRaw = await updateModel.request()

		if (ResponseCodeCheckers.isRequestFailed(updateResponseRaw)) {
			success = false
		}
	} catch (e) {
		console.error(e)
		success = false
	}

	// Normalize
	if (success === true) {
		DataModifierArticleAllNormalized.addGroups(data, updateResponseRaw.data)
	}

	return new DataLayerResponseBase(
		true,
		success,
		data
	)
}

const paAppDataService = {
	getType,
	getArticlePreview,
	getNewsPreview,
	getPressReleasePreview,
	getDataFull,
	getDataUpdates
}

export default paAppDataService
