/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from '../../i18n'
import routes from '../../utils/routes'
import NotFoundController from '../general/not-found.controller'
import ContentElementsController from '../content-elements/content-elements.controller'
import PressReleasesItemView from './views/press-releases-item.view'
import { mapAppStateToPressReleasesItemController } from '../../utils/mappings'
import ContentFooterController from '../footer/content-footer.controller'
import FooterButtonReadingListController from '../footer/buttons/footer-button-reading-list.controller'
import { withPageContentSections } from '../pages/page-content.controller'
import commonPropTypes from '../../utils/common-prop-types'
import loadable from '../../components/loadable/loadable.hoc'
import { compose } from 'redux/lib/redux'
import withTitle from '../header/withTitle.hoc'
import setEntityViewed from '../general/set-entity-viewed.hoc'

class PressReleasesItemController extends Component {
	static getTitle (props) {
		return props.pressRelease ? props.pressRelease.title : i18n.t('TXT_PRESS_RELEASES_DETAIL_TITLE.label')
	}

	render () {
		const { pressRelease, tags } = this.props

		if (!pressRelease) {
			let links = [{ name: i18n.t('TXT_PRESS_RELEASES_LIST_TITLE.label'), href: routes.pressReleasesList.build() }]
			return <NotFoundController links={links} />
		}

		return (
			<PressReleasesItemView title={pressRelease.title} tags={tags}>
				<ContentElementsController contentElements={pressRelease.contentElements} />
			</PressReleasesItemView>
		)
	}

	static renderContentFooter (props) {
		const readingListInformation = FooterButtonReadingListController.createReadingListInformation(
			'pressReleases',
			props.pressRelease,
			props.inReadingList
		)

		if (!props.pressRelease || props.pressRelease.isDummy) {
			return null
		}

		return (
			<ContentFooterController
				readingListInformation={readingListInformation}
				showSendButton
			/>
		)
	}
}

PressReleasesItemController.defaultProps = {
	pressRelease: null,
	tags: [],
	inReadingList: false
}

if (__DEV__) {
	PressReleasesItemController.propTypes = {
		id: commonPropTypes.content.id,
		pressRelease: commonPropTypes.content.pressRelease,
		tags: propTypes.array,
		inReadingList: propTypes.bool,

		...commonPropTypes.mixinFromReduxConnect
	}
}

export default compose(
	connect(mapAppStateToPressReleasesItemController),
	withPageContentSections,
	withTitle(PressReleasesItemController.getTitle),
	loadable('pressReleases', 'pressRelease', 'id'),
	setEntityViewed('pressReleases', 'pressRelease')
)(PressReleasesItemController)
