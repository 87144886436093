/* global __DEV__ */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { mapAppStateToSettingControllerComponent } from '../../utils/mappings'
import SettingsGui from './views/settings-gui.view'
import { doSaveSettings, getSettings, updateSettingsCheckboxField, setSettingsCheckboxFields } from '../../actions/settings.actions'
import { enableLayerLoader, disableLayerLoader } from '../../actions/basic.actions'
import { createModal } from '../../actions/modal.actions'
import i18n from '../../i18n'
import { compose } from 'redux/lib/redux'
import { withPageContentSections } from '../pages/page-content.controller'
import withTitle from '../header/withTitle.hoc'
import LoadableLoaderView from '../loadable/views/loadable-loader.view'

class SettingsIndex extends Component {
	constructor(props) {
		super(props)
		this.state = {
			emails: []
		}
	}

	async componentDidMount () {
		this.props.dispatch(enableLayerLoader())
		let settings = await this.props.dispatch(getSettings())
		this.props.dispatch(setSettingsCheckboxFields('emails', settings.emails))
		this.props.dispatch(disableLayerLoader())
	}

	// noinspection JSUnusedGlobalSymbols
	componentDidUpdate (/* prevProps */) {
		switch (this.props.apiMessageType) {
			case 'ERROR':
				this.props.dispatch(createModal(
					i18n.t('TXT_GENERAL_WORD_ERROR.label'),
					this.props.apiMessage
				))
				break

			case 'SUCCESS':
				this.props.dispatch(createModal(
					i18n.t('TXT_GENERAL_WORD_SUCCESS.label'),
					i18n.t('TXT_SETTINGS_SAVED_SUCCESSFULLY.label')
				))
				break
		}
	}

	handleSaveSettings () {
		const { dispatch, emails } = this.props
		this.props.dispatch(enableLayerLoader())
		dispatch(doSaveSettings(emails))
	}

	handleCheckboxChange (name, e) {
		this.props.dispatch(updateSettingsCheckboxField(name, e.target.value, e.target.checked))
	}

	async handleRefresh () {
		await this.componentDidMount()
		this.forceUpdate()
	}

	render () {
		const { emails, showLoader, showOffline } = this.props
		let loader = showLoader ? <LoadableLoaderView /> : null

		return (
			<div>
				<SettingsGui
					emails={emails}
					showOffline={showOffline}
					handleCheckboxChange={this.handleCheckboxChange.bind(this)}
					handleSaveSettings={this.handleSaveSettings.bind(this)}
					handleRefresh={this.handleRefresh.bind(this)}
				/>
				{loader}
			</div>
		)
	}
}

if (__DEV__) {
	SettingsIndex.propTypes = {
		apiMessageType: propTypes.string,
		apiMessage: propTypes.string,
		apiCode: propTypes.number,

		emails: propTypes.array,

		showLoader: propTypes.bool,
		showOffline: propTypes.bool,

		dispatch: propTypes.func.isRequired
	}
}

export default compose(
	connect(mapAppStateToSettingControllerComponent),
	withPageContentSections,
	withTitle(null, 'TXT_PROJECT_SETTINGS_TITLE.label')
)(SettingsIndex)
