/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'

export function ReadingListItemView (props) {
	const { itemId, itemType, item, linkTo, handleRemove } = props
	return (
		item === null
			? (
				<div className='a-list-item a-list-item--reading-list'>
					Unknown item: {itemType} | {itemId}
				</div>
			)
			: (
				<div className='a-list-item a-list-item--reading-list'>
					<Link to={linkTo}>{item.title}</Link>
					<button
						data-cy="reading-list-item-delete"
						onClick={handleRemove.bind(this, item.type, item.id)}
						className="a-list-item__delete"
					/>
				</div>
			)

	)
}

ReadingListItemView.defaultProps = {
	item: null
}

if (__DEV__) {
	ReadingListItemView.propTypes = {
		itemId: propTypes.string.isRequired,
		itemType: propTypes.string.isRequired,
		item: propTypes.object,
		linkTo: propTypes.string,
		handleRemove: propTypes.func
	}
}

export default ReadingListItemView
