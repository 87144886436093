/* global __DEV__ */
/* eslint-disable no-fallthrough */
import * as types from '../constants/action-types'
import propTypes from 'prop-types'

const name = 'transitions'

const TRANSITIONS = {
	PAGE: {
		NAME: 'page',
		STATES: {
			ENTERING: 'entering',
			ENTERED: 'entered',
			EXITING: 'exiting',
			EXITED: 'exited'
		}
	}
}

/**
 * We use a function here to prevent passing an object reference.
 *
 * Otherwise this could happen:
 * 1. Use initialState reference for initialisation. state.foo is now a
 *    reference to initialState.foo if foo is an object or array.
 * 2. Some action sets state.foo.bar.
 *    This is where the problem occurs: initialState.foo.bar now has been
 *    changed.
 * 3. Some other action wants to reset state.foo by assigning initialState.foo.
 *    But instead of having the actual initial value of state.foo.bar it now has
 *    the value that got set in 2.)
 *
 * @return {Object}
 */
const getInitialState = () => {
	return {}
}

const reducer = function (state = getInitialState(), action) {
	let newState = { ...state }

	switch (action.type) {
		case types.TRANSITIONS_SET_TRANSITION_STATE:
			// TODO remove old entries - can we simply delete when state == TRANSITIONS.PAGE.STATES.EXITED?
			//  Caution: That state is also the initial one for transitions of newly shown pages.
			newState[action.name] = action.state
			return newState

		default:
			return state
	}
}

const reducerPropTypes = __DEV__ && propTypes.objectOf(
	propTypes.string.isRequired
).isRequired

const transitionsReducerDefinition = {
	name,
	/**
	 * We want to be able to use reducerDefinition.initialState without
	 * polluting initial state.
	 *
	 * @return {Object}
	 */
	get initialState () {
		return getInitialState()
	},
	reducer,
	reducerPropTypes
}

export default transitionsReducerDefinition

export {
	TRANSITIONS
}
