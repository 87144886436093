/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToGenericControllerComponent } from '../../../utils/mappings'
import FooterButtonGenericView from './views/footer-button-generic.view'
import i18n from '../../../i18n'
import {
	addToReadingList,
	removeFromReadingList
} from '../../../actions/reading-list.actions'

class FooterButtonReadingListController extends Component {
	handleAddToReadingList (e) {
		e.preventDefault()
		this.props.dispatch(addToReadingList(this.props.readingListInformation.item, this.props.readingListInformation.itemType))
	}

	handleRemoveFromReadingList (e) {
		e.preventDefault()
		this.props.dispatch(removeFromReadingList(this.props.readingListInformation.item.id, this.props.readingListInformation.itemType))
	}

	render () {
		const { readingListInformation } = this.props
		const onClick = readingListInformation.inReadingList
			? this.handleRemoveFromReadingList
			: this.handleAddToReadingList

		return (
			<FooterButtonGenericView
				onClick={onClick.bind(this)}
				icon={'icon-reading-list'}
				dataCy={'reading-list-item-button'}
				title={i18n.t('TXT_PROJECT_TO_THE_READING_LIST.label')}
				inactive={readingListInformation.inReadingList}
			/>
		)
	}
}

/**
 * Convenience function to create information for this component.
 * (or parent components that pass that information down)
 *
 * @param {string} itemType
 * @param {Object} item
 * @param {boolean} inReadingList
 * @return {{inReadingList: boolean, itemType: string, item: Object}}
 */
FooterButtonReadingListController.createReadingListInformation = function (itemType, item, inReadingList = false) {
	return {
		itemType,
		item,
		inReadingList
	}
}

if (__DEV__) {
	FooterButtonReadingListController.sharedPropTypes = {
		readingListInformation: propTypes.shape({
			itemType: propTypes.string.isRequired,
			item: propTypes.object.isRequired,
			inReadingList: propTypes.bool.isRequired
		})
	}
	FooterButtonReadingListController.propTypes = {
		readingListInformation: FooterButtonReadingListController.sharedPropTypes.readingListInformation.isRequired,

		dispatch: propTypes.func.isRequired
	}
}

export default connect(
	(state, ownProps) => mapAppStateToGenericControllerComponent(state, ownProps)
)(FooterButtonReadingListController)
