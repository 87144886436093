/**
 * Canvas Fingerprint class to generate an unique fingerprint id from current environment.
 *
 * @author Björn Hempel <bjoern.hempel@ressourcenmangel.de>
 * @version 1.0
 * @date 2019-07-19
 */
class CanvasFingerprint {
	/**
	 * Calculates a numeric hash from given canvas object.
	 *
	 * @param canvas
	 * @returns {*}
	 */
	static getFingerprintHashFromCanvas (canvas) {
		/* This is a workaround because the fingerprint function does not work in the iPad. */
		return window.navigator.userAgent.replace(/\D+/g, '')

		let dataUrl = canvas.toDataURL()
		let hash = 0

		/* no canvas element was found */
		if (dataUrl.length === 0) {
			return null
		}

		/* calculate the fingerprint */
		for (let i = 0; i < dataUrl.length; i++) {
			let char = dataUrl.charCodeAt(i)
			hash = ((hash << 5) - hash) + char
			hash = hash & hash
		}

		return hash
	}

	/**
	 * Gets or creates the fingerprint canvas.
	 *
	 * @returns {*}
	 */
	static getFingerprintCanvas () {
		let canvasId = 'fingerprint-canvas'
		let canvas = document.getElementById(canvasId)

		/* no canvas element was found */
		if (canvas === null) {
			return this.createFingerprintCanvas(canvasId)
		}

		/* canvas must be an object */
		if (typeof canvas !== 'object') {
			return this.createFingerprintCanvas(canvasId)
		}

		/* canvas must be a <canvas> object */
		if (!('tagName' in canvas) || canvas.tagName.toLowerCase() !== 'canvas') {
			return this.createFingerprintCanvas(canvasId)
		}

		return canvas
	}

	/**
	 * Creates the fingerprint canvas.
	 *
	 * @param canvasId
	 * @returns {HTMLElement}
	 */
	static createFingerprintCanvas (canvasId) {
		let renderText = '!"§$%&/()=?😂🤔👌🤫💯'
		let canvasStyle = 'width: 0px; height: 0px; display: none'
		let textBaseline = 'top'
		let textFont = '14px \'Helvetica\''
		let textColor1 = 'red'
		let textColor2 = 'rgba(32, 64, 128, 0.2)'
		let textX = 2
		let textY = 15
		let textShadowWidth = 1
		let textRotate = 0.025
		let rectColor = 'black'
		let rectSize = 20
		let strokeColor = '#f84'
		let strokeShadowBlur = 10
		let strokeShadowColor = 'blue'

		/* create and append canvas */
		let canvas = document.createElement('canvas')
		canvas.setAttribute('style', canvasStyle)
		canvas.setAttribute('id', canvasId)
		document.body.appendChild(canvas)

		/* create draw layer */
		let ctx = canvas.getContext('2d')

		/* write text block */
		ctx.textBaseline = textBaseline
		ctx.textBaseline = 'alphabetic'
		ctx.font = textFont
		ctx.rotate(textRotate)
		ctx.fillStyle = textColor1
		ctx.fillText(renderText, textX, textY)
		ctx.fillStyle = textColor2
		ctx.fillText(renderText, textX + textShadowWidth, textY + textShadowWidth)

		/* draw rect */
		ctx.fillStyle = rectColor
		ctx.fillRect(0, 0, rectSize, rectSize)

		/* draw stroke */
		ctx.fillStyle = strokeColor
		ctx.shadowBlur = strokeShadowBlur
		ctx.shadowColor = strokeShadowColor
		ctx.fillRect(-20, 10, 234, 5)

		return canvas
	}

	/**
	 * Returns the fingerprint of the current environment.
	 *
	 * @returns {*}
	 */
	static getFingerprint () {
		return this.getFingerprintHashFromCanvas(this.getFingerprintCanvas())
	}
}

export {
	CanvasFingerprint
}
