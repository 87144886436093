/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import {
	mapAppStateToCategoryTopicsListControllerComponent
} from '../../../utils/mappings'
import CategoryTopicsListView from './views/cat-topics-list.view'
import CategoryTopicsListItemView from './views/cat-topics-list-item.view'
import routes from '../../../utils/routes'
import i18n from 'i18next'
import NotFoundController from './../../general/not-found.controller'
import { withPageContentSections } from '../../pages/page-content.controller'
import { compose } from 'redux/lib/redux'
import withTitle from '../../header/withTitle.hoc'
import commonPropTypes from '../../../utils/common-prop-types'

class CategoryTopicsListController extends Component {
	static getTitle (props) {
		return props.category ? props.category.title : i18n.t('TXT_CATEGORIES_DETAIL_TITLE.label')
	}

	render () {
		if (!this.props.category) {
			let links = [{ name: i18n.t('TXT_CATEGORIES_LIST_TITLE.label'), href: routes.categoriesList.build() }]
			return <NotFoundController links={links} />
		}

		const { topics } = this.props

		return (
			<CategoryTopicsListView>
				{
					topics
						.filter((topic) => { return topic !== null })
						.map((topic, keyIndex) => {
							const link = routes.categoriesTopic.build(this.props.category.id, topic.id)
							let classes = []
							if (topic.isUpdated) {
								classes.push('topic-updated')
							}
							return (
								<CategoryTopicsListItemView
									key={keyIndex}
									topic={topic}
									link={link}
									classes={classes}
								/>
							)
						})
				}
			</CategoryTopicsListView>
		)
	}
}

if (__DEV__) {
	CategoryTopicsListController.propTypes = {
		category: propTypes.object,
		topics: propTypes.array.isRequired,

		...commonPropTypes.mixinFromReduxConnect
	}
}

export default compose(
	connect(mapAppStateToCategoryTopicsListControllerComponent),
	withPageContentSections,
	withTitle(CategoryTopicsListController.getTitle)
)(CategoryTopicsListController)
