/* global __DEV__ */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { mapAppStateToLinkListControllerComponent } from '../../utils/mappings'
import LinkListView from './views/link-list.view'
import commonPropTypes from '../../utils/common-prop-types'
import { GeneralUtils } from '../../utils/general.utils'
import Logger from '../../utils/logger'
import {
	APP_CONTENT_TYPE_ARTICLES,
	APP_CONTENT_TYPE_NEWS
} from '../../constants/names'

class LinkListController extends Component {
	render () {
		const { links, children, topics } = this.props
		const preparedLinks = links
			.map(link => {
				Logger.logOnce('LinkListController', 'link', JSON.stringify(link))
				let hasUrl = typeof link.url === 'string' && link.url !== ''
				let hasValidTarget = true
				let preparedUrl
				let internal

				if (!link.targetId) {
					hasValidTarget = false
				}

				if (!link.targetType) {
					hasValidTarget = false
				}

				if ([APP_CONTENT_TYPE_ARTICLES, APP_CONTENT_TYPE_NEWS].indexOf(link.targetType) === -1) {
					hasValidTarget = false
				}

				if (!hasValidTarget && !hasUrl) {
					return null
				}

				if (hasValidTarget) {
					let topic = topics[link.targetId] || {}
					let categories = topic.category || []
					let category = categories[0] || '-'
					internal = true
					preparedUrl = GeneralUtils.buildItemUrl({ id: link.targetId, category: category }, link.targetType)
				} else {
					internal = false
					preparedUrl = link.url
				}

				return {
					title: link.title,
					url: preparedUrl,
					internal
				}
			})
			.filter(preparedLink => {
				return preparedLink !== null
			})

		return (
			<LinkListView preparedLinks={preparedLinks} contentBefore={children}/>
		)
	}
}

LinkListController.defaultProps = {
	links: [],
	children: null
}

if (__DEV__) {
	LinkListController.propTypes = {
		links: commonPropTypes.content.contentLinks,
		children: commonPropTypes.reactChildren,
		topics: commonPropTypes.content.topicCollection
	}
}

export default connect(
	(state, ownProps) => mapAppStateToLinkListControllerComponent(state, ownProps)
)(LinkListController)
