/* global __DEV__ */
import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import PressReleasesListController from './press-releases-list.controller'
import routes from '../../utils/routes'
import NotFoundController from '../general/not-found.controller'
import PressReleasesItemController from './press-releases-item.controller'
import ArticleSendController from '../general/article-send.controller'

class PressReleasesIndex extends Component {
	render () {
		return (
			<Switch>
				<Route exact path={routes.pressReleasesList.path}		component={PressReleasesListController} />
				<Route exact path={routes.pressReleasesDetail.path}		component={PressReleasesItemController} />
				<Route exact path={routes.pressReleasesDetailSend.path}	component={ArticleSendController} />
				<Route component={NotFoundController} />
			</Switch>
		)
	}
}

if (__DEV__) {
	PressReleasesIndex.propTypes = {}
}

export default PressReleasesIndex
