/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'

function StakeholdersListItemView (props) {
	// Assign all properties to variables for convenience
	const { title, link, classes } = props

	return (
		<div className='a-list-item'>
			<a className={classes} href={link} target={'_blank'}>{title}</a>
		</div>
	)
}

if (__DEV__) {
	// Properties types validation
	StakeholdersListItemView.propTypes = {
		title: propTypes.string,
		link: propTypes.string,
		classes: propTypes.string,
		id: propTypes.string
	}
}

// Export component
export default StakeholdersListItemView
