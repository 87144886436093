import propTypes from 'prop-types'

const commonPropTypes = {}

/**
 * REACT CHILDREN
 * @type {shim}
 */
commonPropTypes.reactChildren = propTypes.oneOfType([
	propTypes.string,
	propTypes.node,
	propTypes.arrayOf([
		propTypes.string,
		propTypes.node
	])
])

/**
 * MIXIN PROPS FROM redux connect()
 * @type {{dispatch: (shim|*|e)}}
 */
commonPropTypes.mixinFromReduxConnect = {
	dispatch: propTypes.func.isRequired
}

/**
 * MIXIN PROPS FROM react-router withRouter()
 * @type {{match: (shim|*|e), location: (shim|*|e), history: (shim|*|e)}}
 */
commonPropTypes.mixinFromReactRouterWithRouter = {
	match: propTypes.object.isRequired,
	location: propTypes.object.isRequired,
	history: propTypes.object.isRequired
}

/**
 * CONTENT LINKS for CONTENT TOPIC and CONTENT NEWS
 * @type {shim}
 */
commonPropTypes.mixinPreparedLink = {
	title: propTypes.string.isRequired,
	url: propTypes.string.isRequired,
	internal: propTypes.bool
}

commonPropTypes.content = {}

/**
 * CONTENT GENERIC ID
 * @type {shim}
 */
commonPropTypes.content.id = propTypes.string

/**
 * CONTENT GENERIC ID COLLECTION
 * @type {shim}
 */
commonPropTypes.content.idCollection = propTypes.arrayOf(
	commonPropTypes.content.id
)

/**
 * CONTENT MIXING LOADABLE ENTITY
 * @type {{isLoading: shim}}
 */
commonPropTypes.content.mixinLoadableEntity = {
	isLoading: propTypes.bool,
	isDummy: propTypes.bool
}

commonPropTypes.content.dummyEntity = propTypes.exact({
	id: commonPropTypes.content.id.isRequired,
	...commonPropTypes.content.mixinLoadableEntity
})

/**
 * CONTENT CONTENT-ELEMENT
 * @type {shim}
 */
// TODO TAG_STATE_NORMALIZATION complete propTypes definition
commonPropTypes.content.contentElement = propTypes.object

/**
 * CONTENT CONTENT-ELEMENT COLLECTION
 * @type {shim}
 */
commonPropTypes.content.contentElementCollection = propTypes.arrayOf(
	commonPropTypes.content.contentElement
)

/**
 * CONTENT CONTENT-SECTION
 * @type {shim}
 */
commonPropTypes.content.contentSection = propTypes.exact({
	name: propTypes.string.isRequired,
	elements: commonPropTypes.content.contentElementCollection.isRequired
})

/**
 * CONTENT CONTENT-SECTION COLLECTION
 * @type {shim}
 */
commonPropTypes.content.contentSectionCollection = propTypes.arrayOf(
	commonPropTypes.content.contentSection
)

/**
 * CONTENT CATEGORY
 * @type {shim}
 */
// TODO refactor to glamus structure
commonPropTypes.content.category = propTypes.exact({
	// CONTENT
	id: propTypes.string.isRequired,
	sorting: propTypes.number.isRequired,
	title: propTypes.string.isRequired,
	// TODO TAG_STATE_NORMALIZATION support array of images with different sizes
	image: propTypes.string.isRequired,
	// TODO TAG_STATE_NORMALIZATION support array of images with different sizes
	image_bg: propTypes.string.isRequired,

	// ENRICHED
	topics: commonPropTypes.content.idCollection.isRequired
})

/**
 * CONTENT CATEGORY COLLECTION
 * @type {shim}
 */
commonPropTypes.content.categoryCollection = propTypes.objectOf(
	commonPropTypes.content.category
)

/**
 * CONTENT LINKS for CONTENT TOPIC and CONTENT NEWS
 * @type {shim}
 */
commonPropTypes.content.contentLinks = propTypes.arrayOf(
	propTypes.exact({
		title: propTypes.string.isRequired,
		targetId: propTypes.string,
		targetType: propTypes.string,
		url: propTypes.string
	})
)

/**
 * CONTENT TOPIC
 * @type {shim}
 */
commonPropTypes.content.topic = propTypes.oneOfType([
	commonPropTypes.content.dummyEntity,
	propTypes.exact({
		id: propTypes.string.isRequired,
		// TODO TAG_STATE_NORMALIZATION complete propTypes definition
		tstamp: propTypes.number.isRequired,
		title: propTypes.string.isRequired,
		tags: commonPropTypes.content.idCollection.isRequired,
		// TODO TAG_STATE_NORMALIZATION complete propTypes definition
		feedback: propTypes.any,
		category: propTypes.any,
		links: commonPropTypes.content.contentLinks.isRequired,
		// TODO TAG_STATE_NORMALIZATION complete propTypes definition
		files: propTypes.array.isRequired,
		contentElements: commonPropTypes.content.contentSectionCollection,
		// TODO TAG_STATE_NORMALIZATION support array of images with different sizes
		image_bg: propTypes.string,

		// ENRICHED
		isUpdated: propTypes.bool,
		...commonPropTypes.content.mixinLoadableEntity
	})
])

/**
 * CONTENT TOPIC COLLECTION
 * @type {shim}
 */
commonPropTypes.content.topicCollection = propTypes.objectOf(
	commonPropTypes.content.topic
)

/**
 * CONTENT NEWS
 * @type {shim}
 */
commonPropTypes.content.news = propTypes.oneOfType([
	commonPropTypes.content.dummyEntity,
	// TODO TAG_STATE_NORMALIZATION complete propTypes definition | make exact
	propTypes.shape({
		// TODO

		// ENRICHED
		...commonPropTypes.content.mixinLoadableEntity
	}).isRequired
])

/**
 * CONTENT NEWS COLLECTION
 * @type {shim}
 */
commonPropTypes.content.newsCollection = propTypes.objectOf(
	commonPropTypes.content.news
)

/**
 * CONTENT PRESS-RELEASE
 * @type {shim}
 */
commonPropTypes.content.pressRelease = propTypes.oneOfType([
	commonPropTypes.content.dummyEntity,
	propTypes.shape({
		// id: propTypes.string.isRequired,
		// title: propTypes.string.isRequired,
		// tstamp: propTypes.number.isRequired,
		// tags: commonPropTypes.content.idCollection.isRequired,
		// contentElements: commonPropTypes.content.contentElementCollection.isRequired,
		//
		// // ENRICHED
		// isUpdated: propTypes.bool,
		...commonPropTypes.content.mixinLoadableEntity
	}).isRequired
])

/**
 * CONTENT PRESS-RELEASE COLLECTION
 * @type {shim}
 */
commonPropTypes.content.pressReleaseCollection = propTypes.objectOf(
	commonPropTypes.content.pressRelease
)

/**
 * CONTENT STAKEHOLDER
 * @type {shim}
 */
// TODO TAG_STATE_NORMALIZATION complete propTypes definition
commonPropTypes.content.stakeholder = propTypes.oneOfType([
	commonPropTypes.content.dummyEntity,
	// TODO TAG_STATE_NORMALIZATION complete propTypes definition | make exact
	propTypes.shape({
		// TODO

		// ENRICHED
		...commonPropTypes.content.mixinLoadableEntity
	}).isRequired
])

/**
 * CONTENT STAKEHOLDER COLLECTION
 * @type {shim}
 */
commonPropTypes.content.stakeholderCollection = propTypes.objectOf(
	commonPropTypes.content.stakeholder
)

/**
 * CONTENT TAG
 * @type {shim}
 */
commonPropTypes.content.tag = propTypes.exact({
	id: propTypes.string.isRequired,
	title: propTypes.string.isRequired
})

/**
 * CONTENT TAG COLLECTION
 * @type {shim}
 */
commonPropTypes.content.tagCollection = propTypes.objectOf(
	commonPropTypes.content.tag
)

/**
 * SLIDER ELEMENT OBJECT
 * @type {shim}
 */
commonPropTypes.content.sliderElement = propTypes.exact({
	link: propTypes.string.isRequired,
	image: propTypes.string.isRequired,
	headline: propTypes.string.isRequired,
	text: propTypes.string.isRequired
})

/**
 * ARRAY OF SLIDER ELEMENTS
 * @type {shim}
 */
commonPropTypes.content.sliderElements = propTypes.arrayOf(
	commonPropTypes.content.sliderElement
)

/**
 * CONTENT GENERIC UPDATES INFORMATION ELEMENT
 * @type {shim}
 */
commonPropTypes.content.updatesInformationElement = propTypes.exact({
	quantity: propTypes.number.isRequired
})

/**
 * COOKIE NOTICE INFORMATION
 * @type {shim}
 */
commonPropTypes.cookieNotice = propTypes.exact({
	accepted: propTypes.bool.isRequired,
	closed: propTypes.bool.isRequired
})

export default commonPropTypes
