/**
 * A class for signal queue items.
 */
export default class AnalyticsSignal {
	constructor (nr, name, data) {
		this.name = name
		this.data = data
		this.nr = nr
		this.ts = new Date().getTime()
	}
}
