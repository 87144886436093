/* global __DEV__ */
import React, { Fragment } from 'react'
import propTypes from 'prop-types'
import SearchInputController from '../search-input/search-input.controller'
import commonPropTypes from '../../../utils/common-prop-types'

export function SearchListView (props) {
	const { children, isOnline } = props
	return (
		<Fragment>
			<div className={'container o-list-view'}>
				<div className={'m-search-box m-search-box--main'}>
					<SearchInputController callFromMenu={false}/>
				</div>
				{/* <p>Search is online: {isOnline.toString()}</p> */}


				<div className={'m-list-item-wrapper'}>
					{children}
				</div>
			</div>
		</Fragment>
	)
}

if (__DEV__) {
	SearchListView.propTypes = {
		children: commonPropTypes.reactChildren,
		isOnline: propTypes.bool
	}
}

export default SearchListView
