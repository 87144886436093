/**
 * Imports variables from sass, to use them in JS also.
 */
import _sassVariables from '../styles/_sharedVariables.scss'

const sassVariables = {
	pageTransitionsDurationMS: parseInt(_sassVariables.pageTransitionsDurationMS, 10)
}

export default sassVariables
