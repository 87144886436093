/* global __DEV__ */
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { LoginProcess } from '../../utils/login-process'
import propTypes from 'prop-types'
import ConfirmationView from './views/confirmation.view'
import LoadingView from './views/loading.view'
import { connect } from 'react-redux'
import {
	confirmationCancel,
	confirmationSubmit,
	setAuthDataSaveProgressStatus
} from '../../actions/login-process.actions'
import { mapAppStateToConfirmationControllerComponent } from '../../utils/mappings'
import appEnv from '../../utils/app-env'
import routes from '../../utils/routes'
import { LOGIN_PROCESS } from '../../reducers/login-process.reducer'

class ConfirmationController extends Component {
	constructor (props) {
		super(props)
		this.state = {
			activationCode: appEnv.devBuildOnly.registration.defaultActivationCode,
			exit: false
		}
	}

	componentDidMount () {
		this.handleExit()
	}

	handleActivationCodeChange (e) {
		this.setState({ activationCode: e.target.value })
	}

	handleExit (submittingData) {
		if (!submittingData) {
			try {
				// TODO DATA_LAYER
				LoginProcess.getLocalAuthData()
			} catch (e) {
				this.setState({ exit: true })
			}
		} else {
			this.setState({ exit: true })
		}
	}

	async handleConfirmationSubmit (e) {
		e.preventDefault()
		// Login loading
		this.props.dispatch(setAuthDataSaveProgressStatus(LOGIN_PROCESS.AUTH_DATA_SAVE_PROGRESS_STATUS.IN_PROGRESS))
		this.props.dispatch(confirmationSubmit(this.state.activationCode))
	}

	async handleConfirmationCancel (e) {
		e.preventDefault()
		this.props.dispatch(confirmationCancel())
	}

	render () {
		const { authDataSaveProgressStatus } = this.props
		const { activationCode, exit } = this.state
		if (exit) {
			return (
				<Redirect to={routes.root.build()}/>
			)
		}

		if (authDataSaveProgressStatus === LOGIN_PROCESS.AUTH_DATA_SAVE_PROGRESS_STATUS.IN_PROGRESS) {
			return (<LoadingView/>)
		}

		return (
			<ConfirmationView
				activationCode={activationCode}
				handleActivationCodeChange={this.handleActivationCodeChange.bind(this)}
				handleConfirmationSubmit={this.handleConfirmationSubmit.bind(this)}
				handleConfirmationCancel={this.handleConfirmationCancel.bind(this)}
			/>
		)
	}
}

if (__DEV__) {
	ConfirmationController.propTypes = {
		authDataSaveProgressStatus: propTypes.string,
		dispatch: propTypes.func.isRequired
	}
}

export default connect(mapAppStateToConfirmationControllerComponent)(ConfirmationController)
