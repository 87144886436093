/* global __DEV__ */
import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import CategoryListController from './category-list.controller'
import CategoryTopicsListController from './category/cat-topics-list.controller'
import TopicController from './category/topic/topic.controller'
import routes from '../../utils/routes'
import NotFoundController from '../general/not-found.controller'
import ArticleSendController from '../general/article-send.controller'

class CategoriesIndex extends Component {
	render () {
		return (
			<Switch>
				<Route exact path={routes.categoriesList.path}		component={CategoryListController}/>
				<Route exact path={routes.categoriesDetail.path}	component={CategoryTopicsListController}/>
				<Route exact path={routes.categoriesTopic.path}		component={TopicController}/>
				<Route exact path={routes.singleView.path} 			component={TopicController}/>
				<Route exact path={routes.categoriesTopicSend.path}	component={ArticleSendController}/>
				<Route component={NotFoundController} />
			</Switch>
		)
	}
}

if (__DEV__) {
	CategoriesIndex.propTypes = {}
}

export default CategoriesIndex
