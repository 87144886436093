/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'

function ContentElementDownloadView (props) {
	let { title, url } = props

	return (
		<a className={"a-link a-link--download"} href={url}>{title}</a>
	)
}

ContentElementDownloadView.defaultProps = {
	title: '',
	url: ''
}

if (__DEV__) {
	// Properties types validation
	ContentElementDownloadView.propTypes = {
		title: propTypes.string,
		url: propTypes.string.isRequired
	}
}

// Export component
export default ContentElementDownloadView
