/* eslint-disable no-undef */
// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

import { SW_MESSAGE_TYPE_GET_VERSION, SW_MESSAGE_TYPE_RELOAD_CLIENTS } from './constants/names'
import SWFeature from './utils/sw.feature'
import Logger from './utils/logger'
import axios from 'axios'
import featureDetection from './utils/feature-detection'

export default function register () {
	if (!SWFeature.checkSupport()) {
		return
	}
	// The URL constructor is available in all browsers that support SW.
	const publicUrl = new URL(process.env.PUBLIC_URL, window.location)
	if (publicUrl.origin !== window.location.origin) {
		// Our service worker won't work if PUBLIC_URL is on a different origin
		// from what our page is served on. This might happen if a CDN is used to
		// serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
		return
	}

	Logger.logOnce('register Service Worker', 'Registering')

	window.addEventListener('load', () => {
		const swUrl = `service-worker.js`
		registerIfValid(swUrl)
	})
}

function registerValidSW (swUrl) {
	if (!SWFeature.checkSupport()) {
		return
	}

	navigator.serviceWorker
		.register(swUrl)
		.catch(error => {
			console.error('Error during service worker registration:', error)
		})
}

function registerIfValid (swUrl) {
	if (!SWFeature.checkSupport()) {
		return
	}

	// Check if the service worker can be found. If it can't reload the page.
	fetch(swUrl)
		.then(response => {
			// Ensure service worker exists, and that we really are getting a JS file.
			if (
				response.status === 404 ||
				response.headers.get('content-type').indexOf('javascript') === -1
			) {
				// No service worker found. Probably a different app. Reload the page.
				navigator.serviceWorker.ready.then(registration => {
					registration.unregister().then(() => {
						window.location.reload()
					})
				})
			} else {
				// Service worker found. Proceed as normal.
				registerValidSW(swUrl)
			}
		})
		.catch(() => {
			console.warn(
				'No internet connection found. App is running in offline mode.'
			)
		})
}

/**
 * Unregisters all service workers.
 *
 * @return {Promise}
 */
function unregister () {
	if (!SWFeature.checkSupport()) {
		return Promise.resolve()
	}

	return navigator.serviceWorker.getRegistrations()
		.then(function (registrations) {
			var promises = []
			for (let registration of registrations) {
				promises.push(registration.unregister())
			}
			return Promise.all(promises)
		})
}

/**
 * Clears cache storage.
 *
 * @return {Promise}
 */
function deleteCacheStorage () {
	if (!featureDetection.hasCacheApi) {
		return Promise.resolve()
	}

	return caches.keys()
		.then(function (cacheNames) {
			return Promise.all(
				cacheNames.map(function (cacheName) {
					return caches.delete(cacheName)
				})
			)
		})
}

/**
 * Unregisters service workers and clears cache storage.
 *
 * @return {Promise}
 */
export function unregisterServiceWorker () {
	return Promise.all([
		unregister(),
		deleteCacheStorage()
	])
}

/**
 * Returns the content of ./public/update.json
 *
 * @return {Promise<null>}
 */
export async function fetchUpdateJson () {
	let updateJson = null
	let requestParams = {
		baseURL: '/update.json',
		method: 'get'
	}

	let model = axios.create(requestParams)

	let response = null

	try {
		/* get news list */
		response = await model.request()

		if (response.status === 200) {
			if (typeof response.data === 'object') {
				updateJson = response.data
			} else {
				Logger.errorOnce('fetch update info for SW', 'Could not fetch udpateInfo. Invalid data.', response.data)
			}
		} else {
			Logger.errorOnce('fetch update info for SW', 'Could not fetch udpateInfo. HTTP Status Code: ' + response.status)
		}
	} catch (e) {
		Logger.errorOnce('fetch update info for SW', 'Could not fetch udpateInfo. An error occurred.', e)
	}

	return updateJson
}

/**
 * Updates the service worker if a new version is available.
 *
 * update.json is updated manually whenever service worker got changed.
 * Webpack builds updateJson.serviceworker.version into swEnv.js.
 *
 * We can fetch update.json from our app and get swEnv.__version inside the SW.
 *
 * Here we fetch update.json and message the SW to report its swEnv.__version to
 * us. After that we compare versions. If they do not match we trigger an
 * update.
 *
 * @return {Promise<null>}
 */
export async function updateServiceWorker () {
	if (!SWFeature.checkSupport()) {
		return
	}

	let handleError = function (e) {
		console.error('error updating the app', e)
	}

	let updateJson = null
	let swVersion = null

	// Get udpateJSON
	// ########################################

	try {
		updateJson = await fetchUpdateJson()
	} catch (e) {
		handleError(e)
	}

	if (updateJson === null) {
		handleError()
		return
	}

	// Get currently installed SW version
	// ########################################

	try {
		swVersion = await sendMessageToServiceWorker({ type: SW_MESSAGE_TYPE_GET_VERSION })
	} catch (e) {
		handleError(e)
		return
	}

	// Update service worker if needed.
	// ########################################

	if (swVersion === updateJson.serviceworker.version) {
		return
	}

	unregisterServiceWorker()
		.then(function () {
			sendMessageToServiceWorker({ type: SW_MESSAGE_TYPE_RELOAD_CLIENTS })
		})
		.catch(function (error) {
			handleError(error)
		})
}

/**
 * Sends a message to the installed service worker and returns it's response.
 * Returns null if no service worker is installed.
 *
 * @param {object} message
 * @param {string} message.type
 *
 * @return {Promise}
 */
export async function sendMessageToServiceWorker (message) {
	if (!navigator.serviceWorker.controller) {
		return Promise.resolve(null)
	}

	return new Promise(function (resolve, reject) {
		// Create a message channel for communication with SW.
		let messageChannel = new MessageChannel()

		// Create response handler.
		messageChannel.port1.onmessage = function (event) {
			if (event.data.error) {
				reject(event.data.error)
			} else {
				resolve(event.data)
			}
		}

		// Send message.
		navigator.serviceWorker.controller.postMessage(message, [messageChannel.port2])
	})
}
