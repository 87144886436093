/* global __DEV__ */
import merge from 'deepmerge'
import {
	APP_CONTENT_TYPE_ARTICLES,
	APP_CONTENT_TYPE_NEWS,
	APP_CONTENT_TYPE_PRESS_RELEASES,
	CONTENT_ELEMENT_TYPE_UNKNOWN
} from '../../../../constants/names'
import {
	GLAMUS_CONTENT_TYPE_ARTICLES,
	GLAMUS_CONTENT_TYPE_NEWS
} from './constants'
import Logger from '../../../../utils/logger'

export const DATA_MODIFIER_ARTICLE_NORMALIZED_TEXT_UNKNOWN_ELEMENT_TYPE = 'Unknown element type "%s".'

/**
 * This class adds data that does not exist in the api already.
 * That data has to match the normalized state structure.
 */
class DataModifierArticleDetailNormalized {
	/**
	 * Get default article object.
	 *
	 * @param objApi
	 * @param currentElement
	 * @param namespace
	 * @param tstamp
	 * @returns {{tstamp: number, contentElements: Array, files: Array, image_bg: null, links: Array, id: *, title: string, category: null, tags: Array}}
	 */
	static getDefaultElement (objApi = {}, currentElement = null, namespace = APP_CONTENT_TYPE_ARTICLES, tstamp = null) {
		let imageFirst = objApi.teaserImages && objApi.teaserImages.length > 0 ? objApi.teaserImages[0].url : null

		let withTabs = true

		if (namespace === APP_CONTENT_TYPE_PRESS_RELEASES) {
			withTabs = false
		}

		let links = []

		if ('links' in objApi) {
			links = objApi.links
			if (Array.isArray(links)) {
				links = links.map(link => {
					let normalizedLink = { ...link }

					switch (normalizedLink.targetType) {
						case GLAMUS_CONTENT_TYPE_NEWS:
							normalizedLink.targetType = APP_CONTENT_TYPE_NEWS
							break
						case GLAMUS_CONTENT_TYPE_ARTICLES:
							normalizedLink.targetType = APP_CONTENT_TYPE_ARTICLES
							break
					}

					return normalizedLink
				})
			}
		}

		/* create default element with api data */
		let defaultElement = {
			id: DataModifierArticleDetailNormalized.getId(objApi, namespace),
			tstamp: tstamp !== null ? tstamp : Date.now(),
			title: objApi.title ? objApi.title : '',
			tags: [],
			category: objApi.categoryId ? objApi.categoryId : null,
			image_bg: imageFirst,
			links: links,
			files: [],
			contentElements: DataModifierArticleDetailNormalized.getContents(objApi, withTabs)
		}

		/* merge with current element */
		DataModifierArticleDetailNormalized.mergeElements(defaultElement, currentElement)

		return defaultElement
	}

	/**
	 * Merges to article elements.
	 *
	 * @param defaultElement
	 * @param currentElement
	 * @returns {*}
	 */
	static mergeElements (defaultElement, currentElement) {
		if (currentElement === null) {
			return defaultElement
		}

		for (let name in currentElement) {
			switch (name) {
				/* skip the following elements */
				case 'id':
					break

				/* simple copy if exists */
				case 'title':
				case 'tstamp':
				case 'image_bg':
				case 'category':
					if (currentElement[name]) {
						defaultElement[name] = currentElement[name]
					}
					break

				/* array elements */
				case 'tags':
				case 'files':
				case 'links':
				case 'contentElements':
					defaultElement[name] = merge(defaultElement[name], currentElement[name])
					break

				default:
					Logger.logOnce(
						'DATA MODIFIER ARTICLE DETAIL NORMALIZED: name, currentElement[name]',
						name,
						currentElement[name]
					)
			}
		}

		return defaultElement
	}

	/**
	 * Add articles
	 *
	 * @param data
	 * @param datasApi: articles/... array
	 * @param namespace
	 */
	static addElements (data, datasApi, namespace = APP_CONTENT_TYPE_ARTICLES) {
		datasApi.forEach(function (dataApi) {
			DataModifierArticleDetailNormalized.addElement(data, dataApi, namespace)
		})
	}

	/**
	 * Add article.
	 *
	 * @param data
	 * @param dataApi
	 * @param namespace
	 */
	static addElement (data, dataApi, namespace = APP_CONTENT_TYPE_ARTICLES) {
		data[namespace] = data[namespace] ? data[namespace] : {}

		let articleId = DataModifierArticleDetailNormalized.getId(dataApi, namespace)

		if ('topics' in data && articleId in data.topics && data.topics[articleId].category) {
			dataApi.categoryId = data.topics[articleId].category
		}

		let defaultArticle

		if (namespace in data && articleId in data[namespace]) {
			defaultArticle = data[namespace][articleId]
		}

		data[namespace][articleId] = DataModifierArticleDetailNormalized.getDefaultElement(dataApi, defaultArticle, namespace, dataApi.timestamp)
		data[namespace][articleId]['sortGlamus'] = Object.keys(data[namespace]).length
	}

	/**
	 * Adds a contentElement to a contentElements array.
	 *
	 * @param contentElements
	 * @param contentElement
	 * @param withTabs
	 */
	static addContent (contentElements, contentElement, withTabs = true) {
		if (withTabs) {
			contentElements.push(contentElement)
		} else {
			contentElements = contentElements.concat(contentElement)
		}

		return contentElements
	}

	/**
	 * Gets all contents (tabs of content elements).
	 *
	 * @param obj
	 * @param withTabs
	 * @returns {Array}
	 */
	static getContents (obj = {}, withTabs = true) {
		let contentElements = []
		let contentElement

		if (obj.contentElements) {
			contentElement = DataModifierArticleDetailNormalized.getContentElements({
				tabTitle: 'Position',
				contentElements: obj.contentElements
			}, withTabs)
			contentElements = DataModifierArticleDetailNormalized.addContent(contentElements, contentElement, withTabs)

			return contentElements
		}

		if (!obj.content) {
			return contentElements
		}

		/* iterate through all tabs */
		obj.content.forEach(function (contentsTab) {
			contentElement = DataModifierArticleDetailNormalized.getContentElements(contentsTab, withTabs)
			DataModifierArticleDetailNormalized.addContent(contentElements, contentElement, withTabs)
		})

		return contentElements
	}

	/**
	 * Gets
	 *
	 * @param contentsTab
	 * @param withTabs
	 * @returns {{elements: Array, name: string}}
	 */
	static getContentElements (contentsTab, withTabs = true) {
		let elements = []

		/* iterate through all tab elements */
		contentsTab.contentElements.forEach(function (contentTab) {
			DataModifierArticleDetailNormalized.addContentElement(elements, contentTab, contentsTab.tabTitle)
		})

		if (!withTabs) {
			return elements
		}

		return {
			name: DataModifierArticleDetailNormalized.getTabId(contentsTab.tabTitle),
			elements: elements
		}
	}

	/**
	 * Get entry by given area or NULL for unknown types.
	 *
	 * @param contentTab
	 * @param area
	 * @returns null|{{data: {area: string, type: *, content: *}, type: string}}
	 */
	static getContentElement (contentTab, area) {
		let type = DataModifierArticleDetailNormalized.getType(contentTab.type)

		switch (type) {
			/* Add an accordion with only one element. */
			case 'accordion':
				return {
					type: type,
					content: [
						DataModifierArticleDetailNormalized.getAccordionEntry(contentTab.content, contentTab.content)
					]
				}

			case 'audio':
				return {
					type: type,
					title: contentTab.title,
					soundCloudId: contentTab.soundCloudId
				}

			/* Add a paragraph (text) element. */
			case 'paragraph':
				return {
					type: type,
					content: DataModifierArticleDetailNormalized.getText(contentTab.content, contentTab.type)
				}

			/* Add a subheadline */
			case 'subheadline':
				return {
					type: type,
					content: DataModifierArticleDetailNormalized.getText(contentTab.content, contentTab.type)
				}

			/* */
			case 'gallery':
				return {
					type: type,
					elements: DataModifierArticleDetailNormalized.getGalleryElements(contentTab.elements)
				}

			/* */
			case 'image':
				return {
					type: type,
					title: contentTab.title,
					caption: contentTab.caption,
					images: DataModifierArticleDetailNormalized.getImages(contentTab.images, contentTab.type)
				}

			/* */
			case 'video':
				return {
					type: type,
					title: contentTab.title,
					youtubeId: contentTab.youtubeId,
					images: DataModifierArticleDetailNormalized.getImages(contentTab.images, contentTab.type)
				}

			/* Add direct elements without translations. */
			case 'download':
				return contentTab

			/* Unknown type. */
			default:
				console.error(DATA_MODIFIER_ARTICLE_NORMALIZED_TEXT_UNKNOWN_ELEMENT_TYPE.replace(/%s/, type))
				// Return null. The calling code has to filter or handle otherwise.
				return null
		}
	}

	/**
	 * Adds an entry to element list.
	 *
	 * With the following additional features:
	 * - Simple addition.
	 * - Expand elements if they have already been added before (e.g. accordion).
	 *
	 * @param elements
	 * @param contentTab
	 * @param area
	 */
	static addContentElement (elements, contentTab, area) {
		let normalizedContentElement = null
		let type = DataModifierArticleDetailNormalized.getType(contentTab.type)

		switch (type) {
			/* Add or extend an accordion element. */
			case 'accordion':
				/* The element before also was an accordion element. */
				if (elements.length > 0 && elements[elements.length - 1].type === 'accordion') {
					elements[elements.length - 1].content.push(DataModifierArticleDetailNormalized.getAccordionEntry(
						contentTab.content,
						contentTab.content
					))
					return
				}
				normalizedContentElement = DataModifierArticleDetailNormalized.getContentElement(contentTab, area)

				if (normalizedContentElement !== null) {
					elements.push(normalizedContentElement)
				}

				return

			/* Add a paragraph (text) element. */
			case 'audio':
			case 'download':
			case 'gallery':
			case 'image':
			case 'paragraph':
			case 'subheadline':
			case 'video':
				normalizedContentElement = DataModifierArticleDetailNormalized.getContentElement(contentTab, area)

				if (normalizedContentElement !== null) {
					elements.push(normalizedContentElement)
				}
				return

			/* Unknown type. */
			default:
				// Ignore this. Do not add an element.
				console.error(DATA_MODIFIER_ARTICLE_NORMALIZED_TEXT_UNKNOWN_ELEMENT_TYPE.replace(/%s/, type))
				break
		}
	}

	/**
	 * Get id from namespace
	 *
	 * @param dataApi
	 * @param namespace
	 * @param withPrefix
	 * @returns {*}
	 */
	static getId (dataApi, namespace, withPrefix = false) {
		switch (namespace) {
			case APP_CONTENT_TYPE_ARTICLES:
				if (dataApi.id) {
					return (withPrefix ? 't' : '') + dataApi.id
				}
				return null

			case APP_CONTENT_TYPE_NEWS:
				if (dataApi.newsId) {
					return (withPrefix ? 'n' : '') + dataApi.newsId
				}
				if (dataApi.id) {
					return (withPrefix ? 'n' : '') + dataApi.id
				}
				return null

			case APP_CONTENT_TYPE_PRESS_RELEASES:
				if (dataApi.pressreleaseId) {
					return (withPrefix ? 'p' : '') + dataApi.pressreleaseId
				}
				if (dataApi.id) {
					return (withPrefix ? 'p' : '') + dataApi.id
				}
				return null

			default:
				throw Error('Unknown namespace')
		}
	}

	/**
	 * Get tab id from header title.
	 *
	 * @param tabName
	 * @returns {string}
	 */
	static getTabId (tabName) {
		/* TODO: Translate tabName into Index */
		return tabName
	}

	/**
	 * Translate the type (glamus -> rsm)
	 *
	 * @param {string} type
	 * @param {boolean} throwOnUnkown This is primarily here to decouple tests from __DEV__.
	 * @returns {*}
	 */
	static getType (type, throwOnUnkown = __DEV__) {
		switch (type) {
			case 'accordion':
			case 'audio':
			case 'download':
			case 'gallery':
			case 'image':
			case 'paragraph':
			case 'subheadline':
			case 'video':
				return type

			/* Unknown type. */
			// TODO implement audio and link
			// no break until implemented
			// run default
			case 'link':
			default:
				if (throwOnUnkown) {
					throw new Error(DATA_MODIFIER_ARTICLE_NORMALIZED_TEXT_UNKNOWN_ELEMENT_TYPE.replace(/%s/, type))
				}
				return CONTENT_ELEMENT_TYPE_UNKNOWN
		}
	}

	/**
	 * Get formated text (depending on given type).
	 *
	 * @param content
	 * @param type
	 * @returns {*}
	 */
	static getText (content, type = 'paragraph') {
		switch (type) {
			case 'accordion':
			case 'paragraph':
			case 'subheadline':
				return content

			/* Unknown type. */
			default:
				throw new Error(DATA_MODIFIER_ARTICLE_NORMALIZED_TEXT_UNKNOWN_ELEMENT_TYPE.replace(/%s/, type))
		}
	}

	/**
	 * Get gallery elements.
	 *
	 * @param elements
	 * @returns {*}
	 */
	static getGalleryElements (elements) {
		return elements
	}

	/**
	 * Get images
	 *
	 * @param content
	 * @param type
	 * @returns {*}
	 */
	static getImages (images, type = 'paragraph') {
		switch (type) {
			case 'image':
				return images

			case 'video':
				return images

			/* Unknown type. */
			default:
				throw new Error(DATA_MODIFIER_ARTICLE_NORMALIZED_TEXT_UNKNOWN_ELEMENT_TYPE.replace(/%s/, type))
		}
	}

	/**
	 * Get an accordion entry.
	 *
	 * @param title
	 * @param content
	 */
	static getAccordionEntry (title, content) {
		return {
			title: title,
			content: DataModifierArticleDetailNormalized.getText(content, 'accordion')
		}
	}
}

export { DataModifierArticleDetailNormalized }
