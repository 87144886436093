/* global __DEV__ */
import { Exception } from './errors'
import {
	CMS_PREVIEW_ROUTE_PATH_SEGMENT,
	PREVIEW_ROUTE_PATH_SEGMENT,
	PREVIEW_ROUTE_TOKEN_NAME
} from '../constants/names'

const routes = {}
const advancedRoutes = {}
const routesByPath = {}
const routesByEntityType = {}

// ROOT
routes.root						= { path: '/',								build: buildPlain }

// ROOT
routes.shortcut					= { path: '/:id',							build: buildPlain }

// NEWS
routes.newsList					= { path: '/news',							build: buildPlain }
routes.newsDetail				= {
	path: '/news/:id',
	entityType: 'news',
	build: buildById,
	buildSendRoute: (params) => { return routes.newsDetailSend.build(params.id) },
	buildByEntity: function (newsItem) { return this.build(newsItem.id) }
}
routes.newsDetailSend			= { path: '/news/:id/send',					build: buildById }

// CATEGORIES
routes.categoriesList			= { path: '/categories',					build: buildPlain }
routes.categoriesDetail			= { path: '/categories/:id',				build: buildById }
routes.categoriesTopic			= {
	path: '/categories/:id/:topicId',
	entityType: 'topics',
	build: function (catId, topId) { return build(arguments, this.path) },
	buildSendRoute: (params) => { return routes.categoriesTopicSend.build(params.id, params.topicId) },
	buildByEntity: function (topicItem) { return this.build(topicItem.category, topicItem.id) }
}
routes.categoriesTopicSend		= { path: '/categories/:id/:topicId/send',	build: function (catId, topId) { return build(arguments, this.path) } }

// PRESS RELEASES
routes.pressReleasesList		= { path: '/press-releases',				build: buildPlain }
routes.pressReleasesDetail		= {
	path: '/press-releases/:id',
	entityType: 'pressReleases',
	build: buildById,
	buildSendRoute: (params) => { return routes.pressReleasesDetailSend.build(params.id) },
	buildByEntity: function (pressReleaseItem) { return this.build(pressReleaseItem.id) }
}
routes.pressReleasesDetailSend	= { path: '/press-releases/:id/send',		build: buildById }

// STAKEHOLDERS
routes.stakeholdersList			= { path: '/stakeholders',					build: buildPlain }
routes.stakeholdersDetail		= {
	path: '/stakeholders/:id',
	entityType: 'stakeholders',
	build: buildById,
	buildByEntity: function (stakeholderItem) { return this.build(stakeholderItem.id) }
}

// READING LIST
routes.readingListList			= { path: '/reading-list',					build: buildPlain }
routes.readingListSend			= { path: '/reading-list/send',				build: buildPlain }

// MESSAGE QUEUE LIST
routes.messageQueueList			= { path: '/message-queue',					build: buildPlain }

// OTHER CONTENT
routes.addAppToHomescreen		= { path: '/addAppToHomescreen',			build: buildPlain }
routes.imprint					= { path: '/imprint',						build: buildPlain }
routes.privacy					= { path: '/privacy',						build: buildPlain }

// SEARCH
routes.search					= { path: '/search',						build: buildPlain }

// SEARCH
routes.settings					= { path: '/settings',						build: buildPlain }

// LOGIN PROCESS
routes.loginRegistration		= { path: '/registration',					build: buildPlain }
routes.loginConfirmation		= { path: '/confirmation',					build: buildPlain }

// singleView
routes.singleView				= {
	path: '/' + PREVIEW_ROUTE_PATH_SEGMENT + '/:' + PREVIEW_ROUTE_TOKEN_NAME,
	build: buildPlain
}

// CMS PREVIEW
routes.cmsPreview				= {
	path: '/' + CMS_PREVIEW_ROUTE_PATH_SEGMENT + '/:' + PREVIEW_ROUTE_TOKEN_NAME,
	build: buildPlain
}

// DEVELOPMENT
if (__DEV__) {
	routes.dev 					= { path: '/dev', 						build: buildPlain }
	routes.devAlert 			= { path: '/dev/alert', 				build: buildPlain }
	routes.devInfo				= { path: '/dev/info', 					build: buildPlain }
	routes.devMolecules 		= { path: '/dev/molecules', 			build: buildPlain }
	routes.devUsageExamples 	= { path: '/dev/usage-examples',		build: buildPlain }
	routes.devBuildInfo 		= { path: '/buildinfo.html',			build: buildPlain }
}

Object.keys(routes).forEach((key) => {
	const route = routes[key]
	if (routesByPath[key]) {
		throw new Exception('double declaration of route ' + route.path)
	}
	routesByPath[route.path] = route
	if (route.entityType) {
		if (routesByEntityType[route.entityType]) {
			throw new Exception('double declaration of route entityType ' + route.entityType)
		}
		routesByEntityType[route.entityType] = route
	}
})

// ROUTE BUILDERS
advancedRoutes.buildSendRoute = (reactRouterMatch) => {
	const route = getRouteByMatch(reactRouterMatch)

	if (route && route.buildSendRoute) {
		return route.buildSendRoute(reactRouterMatch.params)
	}

	return reactRouterMatch.url + '/send'
}

advancedRoutes.buildEntityRoute = (entityType, entity) => {
	let route = routesByEntityType[entityType]

	if (!route || typeof route.buildByEntity !== 'function') {
		throw new Exception('no route builder for entityType ' + entityType)
	}

	return route.buildByEntity(entity)
}

// TODO check if we can wrap withRouter into a custom HOC that also injects the
//  closest route
function getRouteByMatch (reactRouterMatch) {
	return routesByPath[reactRouterMatch.path] || null
}

export default routes
export {
	advancedRoutes,
	getRouteByMatch
}

/*
 * Basic url builder functions.
 * We keep them here instead of an own module to support IDE code assistance for arguments.
 */

function build (valueArguments, path) {
	let map = [...valueArguments]
	return path
	// split path into segments
		.split('/')
		// replace segments
		.map((segment) => {
			if (segment.substr(0, 1) === ':') {
				if (__DEV__) {
					if (map.length === 0) {
						throw new Exception('Route ' + path + ' can not be built. Not enough arguments given.')
					}
				}

				return map.shift()
			}
			return segment
		})
		// build path from replaced segments
		.join('/')
}

function buildPlain () {
	return this.path
}

function buildById (id) {
	return build(arguments, this.path)
}
