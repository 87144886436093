import { LoginProcess } from '../utils/login-process'
import dataLayer from '../data-layer/dataLayer'
import { Exception } from '../utils/errors'
import { CHANGE_TYPE_ADD } from './reading-list-change'

/**
 * ReadingListProcessor provides static easy access functions to work with. For
 * that it uses a default instance that is created on demand. This instance is
 * stored here.
 *
 * @type {null|ReadingListProcessor}
 */
let defaultInstance = null

/**
 * This defines the maximum time that is allowed to pass by before a new sync
 * has to be made.
 *
 * @type {number}
 */
const MAXIMUM_AGE = 5000

/**
 * This class is responsible for synchronisation of a {@link ReadingList} with
 * the API.
 */
class ReadingListProcessor {
	/**
	 * Constructor
	 */
	constructor () {
		/** @member {ReadingList|null} */
		this.readingList = null

		/** @member {number} */
		this.lastLoadFromDataLayer = 0

		/** @member {boolean} */
		this.processing = false
	}

	/**
	 * Returns/Creates the default instance that is used by easy access
	 * functions. The default instance of {@link ReadingList} will be injected.
	 *
	 * @return {ReadingListProcessor}
	 */
	static get instance () {
		if (defaultInstance === null) {
			defaultInstance = new ReadingListProcessor()
		}
		return defaultInstance
	}

	/**
	 * Injects readingList
	 *
	 * @param {ReadingList} readingList
	 */
	injectReadingList (readingList) {
		this.readingList = readingList
	}

	/**
	 * Triggers processing of the injected reading list.
	 */
	setActive () {
		if (!this.processing) {
			this._process()
		}
	}

	/**
	 * Loads from the API and merges (API->Local only) that data with ours.
	 *
	 * @return {Promise<void>}
	 *
	 * @private
	 */
	async _loadFromDataLayer () {
		const time = new Date().getTime()
		let authData = LoginProcess.getLocalAuthData()

		let dataResponseReadingList = await dataLayer.getReadingList(authData)

		if (!dataResponseReadingList.success) {
			throw new Exception('error loading reading list from data layer')
		}

		if (!dataResponseReadingList.supported) {
			this.lastLoadFromDataLayer = time
			return
		}

		if (this.readingList.mergeItemData(dataResponseReadingList.rawData.items) === false) {
			return
		}

		this.lastLoadFromDataLayer = time
	}

	/**
	 * Checks passed time since last sync. If too long it triggers data loading
	 * and merging from API.
	 *
	 * @return {Promise<boolean>}
	 *
	 * @private
	 */
	async _ensureFreshness () {
		const now = new Date().getTime()
		if (now - this.lastLoadFromDataLayer <= MAXIMUM_AGE) {
			return
		}

		try {
			await this._loadFromDataLayer()
		} catch (e) {
			console.error(e)
			return false
		}

		return true
	}

	/**
	 * Marks processor as not processing anymore.
	 *
	 * @private
	 */
	_stop () {
		this.processing = false
	}

	/**
	 * Processes a single reading list item. That is: sync with API.
	 *
	 * @param {ReadingListItem} item
	 *
	 * @return {Promise<boolean>}
	 *
	 * @private
	 */
	async _processItem (item) {
		let authData = LoginProcess.getLocalAuthData()
		let dataResponseReadingList

		try {
			if (item.getChange().changeType === CHANGE_TYPE_ADD) {
				dataResponseReadingList = await dataLayer.addRLItem(authData, item.id)
			} else {
				dataResponseReadingList = await dataLayer.removeRLItem(authData, item.id, item.ts)
			}
		} catch (e) {
			this.stop()
			console.error(e)
		}

		if (!dataResponseReadingList.success) {
			console.error('error updating reading list item in data layer')
			return false
		}

		if (!dataResponseReadingList.supported) {
			// that's okay. proceed
		}

		this.readingList.applyChange(item)

		return true
	}

	/**
	 * Processes the changed reading list items one by one.
	 *
	 * @return {Promise<void>}
	 *
	 * @private
	 */
	async _process () {
		this.processing = true
		var changedItems = this.readingList.getChangedRLItems()

		if (changedItems.length === 0) {
			this._stop()
			return
		}

		if (await this._ensureFreshness() === false) {
			this._stop()
			return
		}
		if (await this._processItem(changedItems[0]) === false) {
			this._stop()
			return
		}

		await this._process()
	}

	/*
	 * STATIC METHODS FOR EASY ACCESS TO defaultInstance
	 */

	/**
	 * Easy access function for default instance's setActive().
	 */
	static setActive () {
		ReadingListProcessor.instance.setActive()
	}
}

export default ReadingListProcessor
