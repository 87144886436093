function AuthException (message) {
	this.message = message
	this.name = 'Auth Exception'
}

function Exception (message) {
	this.message = message
	this.name = 'Exception'
}

function LoginError (code, message) {
	this.message = message
	this.code = code
}

function NoInternetException () {
	this.message = 'No internet connection (request failed or response undefined'
}

export {
	AuthException,
	Exception,
	LoginError,
	NoInternetException
}
