/* global __DEV__ */
import React from 'react'
import commonPropTypes from '../../../../utils/common-prop-types'

function SectionMenuListView (props) {
	// Assign all properties to variables for convenience
	const { children } = props

	return (
		<div className={'topic__section-menu m-tab-header'}>
			<div className={'m-tab-header__inner'}>
				{children}
			</div>
			<div className="m-tab-header__tab-border" />
		</div>
	)
}

if (__DEV__) {
	// Properties types validation
	SectionMenuListView.propTypes = {
		children: commonPropTypes.reactChildren
	}
}

// Export component
export default SectionMenuListView
