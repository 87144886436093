/**
 * Using Knuth-Morris-Pratt.
 * https://github.com/trekhleb/javascript-algorithms/tree/master/src/algorithms/string/knuth-morris-pratt
 */
import { LoginProcess } from './login-process'
import { GeneralUtils } from './general.utils'
import dataLayer from '../data-layer/dataLayer'
import {
	asSequence,
	sequenceOf,
	emptySequence,
	range,
	generateSequence,
	extendSequence
} from 'sequency'

class KMP {
	/**
	 * @see https://www.youtube.com/watch?v=GTJr8OvyEVQ
	 * @param {string} word
	 * @return {number[]}
	 */
	static buildPatternTable (word) {
		const patternTable = [0]
		let prefixIndex = 0
		let suffixIndex = 1

		while (suffixIndex < word.length) {
			if (word[prefixIndex].toLowerCase() === word[suffixIndex].toLowerCase()) {
				patternTable[suffixIndex] = prefixIndex + 1
				suffixIndex += 1
				prefixIndex += 1
			} else if (prefixIndex === 0) {
				patternTable[suffixIndex] = 0
				suffixIndex += 1
			} else {
				prefixIndex = patternTable[prefixIndex - 1]
			}
		}

		return patternTable
	}

	/**
	 * @param {string} text
	 * @param {string} word
	 * @param  {array} patternTable
	 * @return {number}
	 */
	static search (text, word, patternTable) {
		if (word.length === 0) {
			return 0
		}

		let textIndex = 0
		let wordIndex = 0

		while (textIndex < text.length) {
			if (text[textIndex].toLowerCase() === word[wordIndex].toLowerCase()) {
				// We've found a match.
				if (wordIndex === word.length - 1) {
					return (textIndex - word.length) + 1
				}
				wordIndex += 1
				textIndex += 1
			} else if (wordIndex > 0) {
				wordIndex = patternTable[wordIndex - 1]
			} else {
				wordIndex = 0
				textIndex += 1
			}
		}

		return -1
	}
}

const unsearchableContentTypes = [
	// TODO remove as soon as that data is not in state.content anymore
	'readingList',
	// TODO remove as soon as that data is not in state.content anymore
	'readingListCount',
	// TODO remove as soon as that data is not in state.content anymore
	'search',
	// TODO Do we want this to be searchable?
	'tags',
	// TODO remove as soon as that data is not in state.content anymore
	'updatesInformation',
	'timestamp',
	'sliderElements'
]

// TODO What about old ADAC-content-types?
//  -> convert to valid types in data layer
const searchableContentElementTypes = [
	'text',
	'paragraph',
	'subheadline'
]

class searchabilityHelpers {
	static isSearchableContentType (entry) {
		return !unsearchableContentTypes.includes(entry[0])
	}

	static isSearchableElementType (type) {
		return searchableContentElementTypes.includes(type)
	}
}

export class Search {
	/**
	 *  Searches offline and returns items from content object!
	 * @param content
	 * @param request
	 */
	static search (content, request) {
		let resultContents = {}
		let patternTable = KMP.buildPatternTable(request)
		for (let contentType in content) {
			if (unsearchableContentTypes.indexOf(contentType) > -1) {
				continue
			}
			// For example we are in topics now
			for (let itemId in content[contentType]) {
				if (!content[contentType][itemId]['contentElements']) {
					continue
				}
				let contentElements = Array.isArray(content[contentType][itemId]['contentElements'][0])
					? content[contentType][itemId]['contentElements'].reduce((a, b) => {
						return a.concat(b)
					}, [])
					: content[contentType][itemId]['contentElements']

				let item = content[contentType][itemId]

				let index = KMP.search(item.title, request, patternTable)
				if (index !== -1) {
					if (!resultContents.hasOwnProperty(contentType)) {
						resultContents[contentType] = {}
					}
					resultContents[contentType][itemId] = content[contentType][itemId]
					continue
				}

				// For example item is topic now
				for (let contentElementId in contentElements) {
					// We are in content elements
					let contentElement = contentElements[contentElementId]
					let hasContent = contentElement.hasOwnProperty('content') && contentElement.hasOwnProperty('type')
					let hasElements = contentElement.hasOwnProperty('elements')

					if (hasContent) {
						// Search only in text content Elements
						if (searchableContentElementTypes.indexOf(contentElement.type) > -1) {
							let index = KMP.search(contentElement.content, request, patternTable)
							if (index !== -1) {
								if (!resultContents.hasOwnProperty(contentType)) {
									resultContents[contentType] = {}
								}
								resultContents[contentType][itemId] = content[contentType][itemId]
							}
						}
					}
					if (hasElements) {
						contentElement.elements.forEach(contentElement => {
							// Search only in text content Elements
							if (searchableContentElementTypes.indexOf(contentElement.type) > -1 && contentElement.hasOwnProperty('content')) {
								let index = KMP.search(contentElement.content, request, patternTable)
								if (index !== -1) {
									if (!resultContents.hasOwnProperty(contentType)) {
										resultContents[contentType] = {}
									}
									resultContents[contentType][itemId] = content[contentType][itemId]
								}
							}
						})
					}
				}
			}
		}
		return resultContents
	}

	static async getSearchResultsOnline (searchRequest) {
		let results = []

		// TODO DATA_LAYER
		let dataResponse = await dataLayer.searchContent(searchRequest)

		if (dataResponse.success) {
			results = dataResponse.results
		} else {
			// TODO: SHOW SEARCH ERROR
			alert('Online search unavailable. CHANGE ME ON NORMAL ERROR ALERT PLEASE')

			// TODO ERROR_HANDLING

			if (!dataResponse.supported) {
				// TODO ERROR_HANDLING
			}
		}

		return results
	}

	static prepareOnlineSearchResult (searchResultRaw) {
		let searchResult = {}
		for (let itemIndex in searchResultRaw) {
			let item = searchResultRaw[itemIndex]

			// TODO refactor to real news
			// Temporary convert news type to topics and add category
			if (item.type === 'news') {
				item.type = 'topics'
				item.category = GeneralUtils.getTopicCategoryById(item.id)
			}

			// Init type
			if (searchResult[item.type] === undefined) {
				searchResult[item.type] = {}
			}

			// copy item to searchResult.type
			searchResult[item.type][item.id] = item
		}

		return searchResult
	}
}

class SearchSuggestionsFunctional extends Search {
	static getContentElements (item) {
		return Array.isArray(item.contentElements[0])
			? item.contentElements.reduce((a, b) => {
				return a.concat(b)
			}, [])
			: item.contentElements
	}

	static hasContent (contentElement) {
		return contentElement.hasOwnProperty('content') && contentElement.hasOwnProperty('type')
	}

	static hasElements (contentElement) {
		return contentElement.hasOwnProperty('elements')
	}

	static prepareContentElement (contentElement) {
		if (!SearchSuggestions.hasElements(contentElement)) {
			contentElement.elements = []
		}
		if (SearchSuggestions.hasContent(contentElement)) {
			contentElement.elements.push({ content: contentElement.content, type: contentElement.type })
		}
		return contentElement
	}

}

export class SearchSuggestions extends Search {
	/**
	 * Search all substrings that matches regex in text. Returns array of substrings or null
	 * @param text
	 * @param regexp
	 * @returns {*}
	 */
	static findMatches (text, regexp) {
		return text.match(regexp)
	}

	/**
	 * Iterates over all content to search suggestions.
	 * @param content
	 * @param regexp
	 * @returns {any[]} - array of suggestions
	 */
	static search (content, regexp) {
		let words = []
		for (let contentType in content) {
			if (unsearchableContentTypes.indexOf(contentType) > -1) {
				continue
			}
			// For example we are in topics now
			for (let itemId in content[contentType]) {
				if (!content[contentType][itemId]['contentElements']) {
					continue
				}
				let contentElements = Array.isArray(content[contentType][itemId]['contentElements'][0])
					? content[contentType][itemId]['contentElements'].reduce((a, b) => {
						return a.concat(b)
					}, [])
					: content[contentType][itemId]['contentElements']

				let item = content[contentType][itemId]

				let matches = SearchSuggestions.findMatches(
					item.title, regexp)
				if (matches !== null) {
					words = words.concat(matches)
				}

				// For example item is topic now
				for (let contentElementId in contentElements) {
					// We are in content elements
					let contentElement = contentElements[contentElementId]
					// Search only in text content Elements
					let hasContent = contentElement.hasOwnProperty('content') && contentElement.hasOwnProperty('type')
					let hasElements = contentElement.hasOwnProperty('elements')
					if (hasContent) {
						if (searchableContentElementTypes.indexOf(contentElement.type) > -1) {
							let matches = SearchSuggestions.findMatches(
								contentElement.content, regexp)
							if (matches !== null) {
								words = words.concat(matches)
							}
						}
					}
					if (hasElements) {
						contentElement.elements.forEach(contentElement => {
							if (searchableContentElementTypes.indexOf(contentElement.type) > -1 && contentElement.hasOwnProperty('content')) {
								let matches = SearchSuggestions.findMatches(
									contentElement.content, regexp)
								if (matches !== null) {
									words = words.concat(matches)
								}
							}
						})
					}
				}
			}
		}

		return [...new Set(words)]
	}

	/**
	 * Builds suggestions array
	 * @param content
	 * @param {string} request
	 * @returns {*}
	 */
	static buildSuggestions (content, request) {
		let regexp = new RegExp(String.raw`\b${request}[a-z0-9]*(?!>)`, 'gi')
		return SearchSuggestions.search(content, regexp)
	}
}
