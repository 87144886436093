/* global __DEV__ */
import propTypes from 'prop-types'
import Logger from './logger'

const SYMBOL_START = { name: 'SYMBOL_START' }

class LocalHistoryEntry {
	constructor (location, data = {}) {
		this.location = location
		this.data = data
	}
}

/**
 * Easy handling for local history via react router.
 * (i.e. those entries that are from this app and this page load only)
 * Problem: history.length does not only refer to pages of the same
 * website. When foo.bar was open in the tab and the user then switched
 * to our app he could go back to foo.bar with that button. Also
 * history.length also takes into account entries for goForward().
 *
 * Inspired by:
 * @see https://github.com/ReactTraining/history/issues/573#issuecomment-406871315
 *
 * Possible other solutions:
 * - https://github.com/ReactTraining/history/issues/573#issuecomment-436188976
 * - https://github.com/ReactTraining/history/issues/26#issuecomment-357062114
 *
 * Discarded ideas:
 * - See if document.referrer is a good property.
 *   -> Nope, it isn't.
 *     - Even after internal navigation it is empty.
 *     - Referrer can be hidden.
 */
export default class LocalHistory {
	constructor (history, dynamicStore, logging) {
		this._bindMethods()
		this.history = history
		this.dynamicStore = dynamicStore
		this.store = dynamicStore.store
		this.stack = null
		this.currentIndex = -1
		this.logging = logging
		this.listeners = []
	}

	_bindMethods () {
		this.getEntry			= this.getEntry.bind(this)
		this.canGoBack			= this.canGoBack.bind(this)
		this.canGoForward		= this.canGoForward.bind(this)
		this.goBack				= this.goBack.bind(this)
		this.goForward			= this.goForward.bind(this)
		this.updateLocalHistory	= this.updateLocalHistory.bind(this)
		this.log				= this.log.bind(this)
		this.dispatch			= this.dispatch.bind(this)
	}

	get length () {
		return this.currentIndex + 1
	}

	get size () {
		return this.stack.length
	}

	init () {
		// Make this instance available as a prop in the history object to allow
		// easy access globally, when no state is available.
		// (i.e. in login-process.actions.js confirmationSubmit())
		this.history.localHistory = this

		this.dynamicStore.addReducer(localHistoryReducerDefinition)

		this.reset()

		if (__DEV__) {
			this.log('init')
		}

		this._listen()
	}

	dispatch (action) {
		this.store && this.store.dispatch(action)
	}

	reset () {
		// We need to initialize with a dummy entry for our initial page because
		// we do not have a location object for our initial location.
		// Otherwise we couldn't go back after first navigation.
		this.stack = [new LocalHistoryEntry(SYMBOL_START)]
		this.currentIndex = 0

		this.dispatch(localHistoryUpdateState())
	}

	getEntry (offset) {
		let key = this.currentIndex + (offset || 0)

		if (key < 0) {
			return null
		}

		if (key >= this.size) {
			return null
		}

		return this.stack[key]
	}

	canGoBack () {
		return this.currentIndex > 0
	}

	canGoForward () {
		return this.currentIndex < (this.size - 1)
	}

	goBack () {
		if (!this.canGoBack()) {
			return false
		}

		this.history.goBack()
		return true
	}

	goForward () {
		if (!this.canGoForward()) {
			return false
		}

		this.history.goForward()
	}

	_pushed (location) {
		this.stack.splice(this.currentIndex + 1, this.size, new LocalHistoryEntry(location))
		this.currentIndex += 1
	}

	// TODO Should we keep the forward history instead? I'd say No, but do not
	//  know if that would align with history's standard beahviour.
	_replaced (location) {
		this.stack.splice(this.currentIndex, this.size, new LocalHistoryEntry(location))
	}

	_popped (location) {
		if (this._checkWentBack(location)) {
			this._wentBack()
		} else if (this._checkWentForth(location)) {
			this._wentForth()
		} else {
			this.reset()
		}
	}

	_wentBack () {
		this.currentIndex -= 1
	}

	_wentForth () {
		this.currentIndex += 1
	}

	_checkWentBack (location) {
		let last = this.getEntry(-1)
		if (last === null) {
			return false
		}

		// If our last entry is SYMBOL_START and we didn't move forward,
		// replace our dummy start with the actual location.
		if (last.location === SYMBOL_START && !this._checkWentForth(location)) {
			last.location = location
		}

		return last.location.key === location.key
	}

	_checkWentForth (location) {
		let next = this.getEntry(1)
		if (next === null) {
			return false
		}
		return next.location.key === location.key
	}

	updateLocalHistory (location, action) {
		this.callListenersBeforeUpdate()
		switch (action) {
			// We pushed a location already.
			// -> Push that to stack also.
			case 'PUSH':
				this._pushed(location)
				break
			// We replaced the last location.
			// -> Replace on stack also.
			case 'REPLACE':
				this._replaced(location)
				break
			// We went back or forth already.
			// -> Pop or push location from/to stack.
			case 'POP': {
				this._popped(location)
				break
			}
			default:
				break
		}
		if (__DEV__) {
			this.log(action)
		}

		this.callListenersAfterUpdate()

		this.dispatch(localHistoryUpdateState(action))
	}

	log (name) {
		if (!this.logging) {
			return
		}
		let info = {
			action: name,
			entries: this.stack.map(entry => JSON.stringify(entry)),
			currentIndex: this.currentIndex,
			canGoBack: this.canGoBack(),
			canGoForward: this.canGoForward(),
			length: this.length,
			size: this.size
		}
		Logger.logOnce('BROWSER HISTORY LOCAL', 'local history data', info)
	}

	_listen () {
		this.history.listen(this.updateLocalHistory)
	}

	registerListener (onBeforeUpdate, onAfterUpdate) {
		this.listeners.push({
			onBeforeUpdate,
			onAfterUpdate
		})
	}

	callListenersBeforeUpdate () {
		let locationEntry = this.getEntry()
		this.listeners.forEach(({ onBeforeUpdate }) => {
			onBeforeUpdate && onBeforeUpdate(locationEntry, this.dispatch)
		})
	}

	callListenersAfterUpdate () {
		let locationEntry = this.getEntry()
		this.listeners.forEach(({ onAfterUpdate }) => {
			onAfterUpdate && onAfterUpdate(locationEntry, this.dispatch)
		})
	}
}

const localHistoryActionTypes = {
	LOCAL_HISTORY_UPDATE: 'LOCAL_HISTORY_UPDATE'
}

/**
 * Trigger local history update.
 * @return {{type: string, index: *}}
 */
function localHistoryUpdateState (action = localHistoryReducerDefinition.initialState.lastAction) {
	return {
		type: localHistoryActionTypes.LOCAL_HISTORY_UPDATE,
		lastAction: action
	}
}

const localHistoryReducerDefinition = (function () {
	const name = 'localHistory'

	/**
	 * We use a function here to prevent passing an object reference.
	 *
	 * Otherwise this could happen:
	 * 1. Use initialState reference for initialisation. state.foo is now a
	 *    reference to initialState.foo if foo is an object or array.
	 * 2. Some action sets state.foo.bar.
	 *    This is where the problem occurs: initialState.foo.bar now has been
	 *    changed.
	 * 3. Some other action wants to reset state.foo by assigning initialState.foo.
	 *    But instead of having the actual initial value of state.foo.bar it now has
	 *    the value that got set in 2.)
	 *
	 * @return {Object}
	 */
	const getInitialState = () => {
		return {
			lastAction: ''
		}
	}

	const reducer = function (state = getInitialState(), action) {
		let newState = { ...state }
		switch (action.type) {
			case localHistoryActionTypes.LOCAL_HISTORY_UPDATE:
				newState.lastAction = action.lastAction
				return newState

			default:
				return state
		}
	}

	const reducerPropTypes = __DEV__ && propTypes.exact({
		lastAction: propTypes.string.isRequired
	}).isRequired

	return {
		name,
		/**
		 * We want to be able to use reducerDefinition.initialState without
		 * polluting initial state.
		 *
		 * @return {Object}
		 */
		get initialState () {
			return getInitialState()
		},
		reducer,
		reducerPropTypes
	}
})()
