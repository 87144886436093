/* global __DEV__ */
import React, { Fragment } from 'react'
import propTypes from 'prop-types'

function ModalView (props) {
	let { title, message, callbackConfirm, callbackCancel, labelConfirm, labelCancel, repetitionCount } = props
	let hasCancelButton = callbackCancel !== null

	return (
		<Fragment>
			<div className={"m-alert__overlay"}></div>
			<div data-cy={'m-alert'} className={'m-alert' + (hasCancelButton ? ' m-alert--multiple-options' : '')}>
				<div className={'m-alert__title'}>
					<span>
						{title}
						{repetitionCount > 0
							? (
								<Fragment>
									{' '}
									<span className='m-alert__repetition-indicator'>({repetitionCount + 1})</span>
								</Fragment>
							)
							: null
						}
					</span>
				</div>

				<div className={'m-alert__message'}>
					<p>{message}</p>
				</div>

				<div className={'m-alert__options'}>
					<div className={'m-alert__option'}>
						<a href='/#modal-confirm' onClick={callbackConfirm}>{labelConfirm}</a>
					</div>
					<div className={'m-alert__option'}>
						{hasCancelButton
							? (
								<a href='/#modal-cancel' onClick={callbackCancel}>{labelCancel}</a>
							) : (
								null
							)
						}
					</div>
				</div>
			</div>
		</Fragment>
	)
}

ModalView.defaultProps = {
	title: '',
	message: '',
	callbackConfirm: null,
	callbackCancel: null,
	labelConfirm: '',
	labelCancel: '',
	repetitionCount: 0
}

if (__DEV__) {
	// Properties types validation
	ModalView.propTypes = {
		title: propTypes.string.isRequired,
		message: propTypes.string.isRequired,
		callbackConfirm: propTypes.func.isRequired,
		callbackCancel: propTypes.func,
		labelConfirm: propTypes.string.isRequired,
		labelCancel: propTypes.string,
		repetitionCount: propTypes.number
	}
}

// Export component
export default ModalView
