/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'
import i18n from '../../../i18n'

function DevInfoView (props) {
	let {
		pnToken
	} = props

	pnToken = pnToken || i18n.t('TXT_GENERAL_NO_PN_APPROVED')

	return (
		<div className='o-detail-view'>
			<table className='table-info'>
				<tr>
					<td className='table-info--header'><b>Push-Notification Token:</b></td>
					<td className='table-info--text'>{pnToken}</td>
				</tr>
			</table>
		</div>
	)
}

if (__DEV__) {
	// Properties types validation
	DevInfoView.propTypes = {
		pnToken: propTypes.string
	}
}

// Export component
export default DevInfoView
