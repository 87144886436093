import paAppEmailService from './services/email-service'
import paAppSearchService from './services/search-service'
import paAppReadingListService from './services/reading-list-service'
import paAppDataService from './services/data-service'
import paAppLoginService from './services/login-service'
import { LOGIN_PROCESS_SIMPLE } from '../../../constants/names'

export default {
	/* LOGIN SERVICE */
	loginCheckAuth: paAppLoginService.loginCheckAuth,
	loginRegister: paAppLoginService.loginRegister,
	loginConfirm: paAppLoginService.loginConfirm,
	loginBasic: paAppLoginService.loginBasic,
	loginProcess: LOGIN_PROCESS_SIMPLE,

	/* DATA SERVICE */
	getType: paAppDataService.getType,
	getArticlePreview: paAppDataService.getArticlePreview,
	getNewsPreview: paAppDataService.getNewsPreview,
	getPressReleasePreview: paAppDataService.getPressReleasePreview,
	getDataFull: paAppDataService.getDataFull,
	getDataUpdates: paAppDataService.getDataUpdates,

	/* READING LIST SERVICE */
	getReadingList: paAppReadingListService.getReadingList,
	addRLItem: paAppReadingListService.addRLItem,
	removeRLItem: paAppReadingListService.removeRLItem,

	/* EMAIL SERVICE */
	sendArticle: paAppEmailService.sendArticle,
	articleSendHasSubject: false,

	/* SEARCH SERVICE */
	searchContent: paAppSearchService.searchContent
}
