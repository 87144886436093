import { DataLayerResponseBase } from '../../../lib/DataLayerResponse'
import {
	CreateGlamusReadingListAddModel,
	CreateGlamusReadingListDataModel, CreateGlamusReadingListRemoveModel
} from '../lib/models'
import ResponseCodeCheckers from '../lib/response-code-checkers'
import { DataModifierReadingListNormalized } from '../lib/data-modifier-reading-list-normalized'
import { timestampToGlamusDateTimeFormat } from '../lib/helpers'

/**
 * Adds a reading list item to the API.
 *
 * @param {DataLayerAuthData} authData
 * @param {string} id
 * @return {Promise<DataLayerResponseBase>}
 */
const addRLItem = async function (authData, id) {
	let data = {}

	let success = true

	/* Fetch all data at once (recommended) */
	let model = CreateGlamusReadingListAddModel(id)
	let response = null
	try {
		response = await model.request()

		if (response.status === 200) {
			data = response.data
		}

		if (ResponseCodeCheckers.isRequestFailed(response)) {
			success = false
		}
	} catch (e) {
		console.error(e)
		success = false
	}

	return new DataLayerResponseBase(
		true,
		success,
		data
	)
}

/**
 * Removes a reading list item from the API.
 *
 * @param {DataLayerAuthData} authData
 * @param {string} id
 * @param {number} ts
 * @return {Promise<DataLayerResponseBase>}
 */
const removeRLItem = async function (authData, id, ts) {
	let data = {}

	let success = true

	/* Fetch all data at once (recommended) */
	let model = CreateGlamusReadingListRemoveModel(id, timestampToGlamusDateTimeFormat(ts))
	let response = null
	try {
		response = await model.request()

		if (response.status === 200) {
			data = response.data
		}

		if (ResponseCodeCheckers.isRequestFailed(response)) {
			success = false
		}
	} catch (e) {
		console.error(e)
		success = false
	}

	return new DataLayerResponseBase(
		true,
		success,
		data
	)
}

/**
 * Loads reading list items from the API.
 *
 * @param {DataLayerAuthData} authData
 * @return {Promise<DataLayerResponseBase>}
 */
const getReadingList = async function (authData) {
	let data = {}

	let success = true

	/* Fetch all data at once (recommended) */
	let model = CreateGlamusReadingListDataModel()
	let response = null
	try {
		response = await model.request()

		if (response.status === 200) {
			DataModifierReadingListNormalized.getReadingListEntries(data, response.data)
		}

		if (ResponseCodeCheckers.isRequestFailed(response)) {
			success = false
		}
	} catch (e) {
		console.error(e)
		success = false
	}

	return new DataLayerResponseBase(
		true,
		success,
		data
	)
}

const paAppReadingListService = {
	getReadingList,
	addRLItem,
	removeRLItem
}

export default paAppReadingListService
