/* eslint-disable react/prop-types */
import React from 'react'

const CategoryTopicsListView = (props) => {
	const { children } = props

	return (
		<div className="container o-list-view">
			<div className="m-list-item-wrapper">
				{ children }
			</div>
		</div>
	)
}

export default CategoryTopicsListView
