/* eslint-disable no-undef */
// TODO DATA_LAYERS
import { AuthException, Exception } from './errors'
import { AUTH_DATA_CACHE_NAME, CACHE_NAME } from '../constants/names'
import dataLayer from '../data-layer/dataLayer'
import i18n from '../i18n'
import store from '../store'
import { createModal } from '../actions/modal.actions'
import { logout } from '../actions/login-process.actions'
import aes from 'crypto-js/aes'
import hex from 'crypto-js/enc-hex'
import base64 from 'crypto-js/enc-base64'
import utf8 from 'crypto-js/enc-utf8'
import md5 from 'crypto-js/md5'
import { CanvasFingerprint } from './canvas-fingerprint'
import Updater from './content-storage-controller/Updater'

class LoginProcess {
	// Generates random UUID.
	static generateUuid () {
		this.UUID = Math.floor(Math.random() * 100000000).toString()
	}

	// UUID is set by generateUUID function
	static getUuid () {
		return this.UUID
	}

	// email is set by register function
	static getEmail () {
		return this.email
	}

	// TODO: collect params
	/**
	 * @method collectDeviceParams
	 * @description Function to collect device params
	 * @author Yahor Paromau <yahor.paromau@ressourcenmangel.de>
	 * @returns {{canvas_width: string, canvas_height: string, device_pixel_ratio: string, real_width: string, real_height: string, available: string, cordova: string, manufacturer: string, model: string, platform: string, uuid: *, version: string, browser: string}}
	 */
	static collectDeviceParams () {
		let params = {
			'canvas_width': '1177',
			'canvas_height': '1067',
			'device_pixel_ratio': '1',
			'real_width': '1920',
			'real_height': '1200',
			'available': 'true',
			'cordova': '0',
			'manufacturer': '',
			'model': 'MacIntel',
			'platform': 'Mozilla',
			'uuid': this.getUuid(),
			'version': '5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit 537.36 (KHTML, like Gecko) Chrome 70.0.3538.45 Safari 537.36',
			'browser': '1'
		}
		return params
	}

	/***
	 * @method register
	 * @description Register new user and save email email in Local Storage
	 * @author Yahor Paromau <yahor.paromau@ressourcenmangel.de>
	 * @param nameAndSurname
	 * @param email
	 * @returns {Promise<void>}
	 */
	static async register (nameAndSurname, email) {
		let deviceParams = this.collectDeviceParams()

		let dataResponse = await dataLayer.loginRegister(nameAndSurname, email, deviceParams)

		if (!dataResponse.success) {
			// TODO ERROR_HANDLING

			if (!dataResponse.supported) {
				// TODO ERROR_HANDLING
			}

			throw new Exception('Login registration failed')
		}

		LoginProcess.saveAuthData(email)
	}

	/***
	 * @method login
	 * @description Login into glamus api and save token in Local Storage
	 * @author Björn Hempel <bjoern.hempel@ressourcenmangel.de>
	 * @param username
	 * @param password
	 * @returns {Promise<void>}
	 */
	static async login (username, password) {

		let dataResponse = await dataLayer.loginBasic(username, password)

		if (!dataResponse.success) {
			// TODO ERROR_HANDLING

			if (!dataResponse.supported) {
				// TODO ERROR_HANDLING
			}

			throw new Exception(i18n.t('ERROR_LOGIN_REGISTRATION_PROGRAM_ERROR.label'))
		}

		/* save username and token */
		LoginProcess.saveAuthDataLoginSecure(username, dataResponse.token)

		return {
			loggedIn: true,
			message: ''
		}
	}

	// TODO: ENCRYPTION
	static async saveAuthDataToCache (email, token) {
		if (token !== undefined) {
			let cache = await caches.open(CACHE_NAME)
			let blob = new Blob([JSON.stringify({ email, token })], { type: 'application/json' })
			let response = new Response(blob)
			await cache.put(AUTH_DATA_CACHE_NAME, response)
		}
	}

	// TODO: ENCRYPTION
	/**
	 * @method saveAuthData
	 * @description Save auth email and token as JSON in Local Storage
	 * @author Yahor Paromau <yahor.paromau@ressourcenmangel.de>
	 * @param email
	 * @param token
	 * @returns undefined
	 */
	static saveAuthData (email, token) {
		this.email = email
		let authData = {
			email: email,
			token: token
		}
		localStorage.setItem('authData', JSON.stringify(authData))
	}

	/**
	 * @method saveAuthData
	 * @description Save auth username and token as JSON in Local Storage
	 * @author Yahor Paromau <yahor.paromau@ressourcenmangel.de>
	 * @author Björn Hempel <bjoern.hempel@ressourcenmangel.de>
	 * @param username
	 * @param password
	 * @returns undefined
	 */
	static saveAuthDataLogin (username, token) {
		let authData = {
			username: username,
			token: token
		}

		/* TODO: Use the localStorage manager instead. */
		localStorage.setItem('authData', JSON.stringify(authData))
	}

	/**
	 * @method saveAuthDataLoginSecure
	 * @description Save auth username and token as JSON in Local Storage (encrypted version)
	 * @author Björn Hempel <bjoern.hempel@ressourcenmangel.de>
	 * @param username
	 * @param token
	 * @returns undefined
	 */
	static saveAuthDataLoginSecure (username, token) {
		let access = this.getKeyAndIvByBrowserFingerprint()

		/* encrypt the username and token */
		let usernameSecure = aes.encrypt(username, hex.parse(access.key), { iv: hex.parse(access.iv) })
		let tokenSecure = aes.encrypt(token, hex.parse(access.key), { iv: hex.parse(access.iv) })

		/* build the auth data object */
		let authData = {
			'username-secure': usernameSecure.ciphertext.toString(base64),
			'token-secure': tokenSecure.ciphertext.toString(base64)
		}

		localStorage.setItem('authData', JSON.stringify(authData))
	}

	/**
	 * TODO: Check only local authorisation when offline and display cached content
	 *
	 * @method checkAuthorisation
	 * @description Check local auth data and if exists make auth check request
	 * @author Yahor Paromau <yahor.paromau@ressourcenmangel.de>
	 * @returns {Promise<*>}
	 */
	static async checkAuthorisation () {
		let authData

		try {
			// TODO DATA_LAYER
			authData = this.getLocalAuthData()
		} catch (e) {
			console.error(e)
			return {
				loggedIn: false,
				message: ''
			}
		}

		let dataResponse = await dataLayer.loginCheckAuth(authData)

		if (!dataResponse.success) {
			// TODO ERROR_HANDLING

			if (!dataResponse.supported) {
				// TODO ERROR_HANDLING
			}
		}

		if (dataResponse.offline) {
			// TODO check if we need special offline handling
		}

		return {
			loggedIn: dataResponse.loggedIn,
			message: dataResponse.message
		}
	}

	/**
	 * Returns the key and iv identified by browser fingerprint.
	 *
	 * @returns {{iv: string, key: string}}
	 */
	static getKeyAndIvByBrowserFingerprint () {
		let fingerprint = CanvasFingerprint.getFingerprint()
		let salt = 'secret salt string rsm 2019'

		let key = md5(salt + fingerprint).toString(base64.Base64).toUpperCase()
		let iv = md5(salt + fingerprint).toString(base64.Base64).toUpperCase() + md5(fingerprint + salt).toString(base64.Base64).toUpperCase()

		return {
			key: key,
			iv: iv
		}
	}

	/**
	 * @method getLocalAuthData
	 * @description Get local auth data as Object
	 * @author Yahor Paromau <yahor.paromau@ressourcenmangel.de>
	 * @returns {any}
	 */
	static getLocalAuthData (throwNewException = true) {
		let authDataJson = localStorage.getItem('authData')
		let authData = JSON.parse(authDataJson)

		/* no auth data was found */
		if (authData === null) {
			if (throwNewException) {
				throw new AuthException('No local auth data')
			} else {
				return null
			}
		}

		let token = null
		let tokenSecure = null
		let username = null
		let usernameSecure = null
		let saveSecure = false
		let access = this.getKeyAndIvByBrowserFingerprint()

		/* for backward compatibility: save existing insecure tokens encrypted in LocalStorage */
		if ('token-secure' in authData) {
			tokenSecure = authData['token-secure']
		} else {
			if ('token' in authData) {
				token = authData['token']
				let tokenSecureBinar = aes.encrypt(token, hex.parse(access.key), { iv: hex.parse(access.iv) })
				tokenSecure = tokenSecureBinar.ciphertext.toString(base64)
			} else {
				if (throwNewException) {
					throw new AuthException('No token was found within the authData object.')
				} else {
					return null
				}
			}
			saveSecure = true
		}

		/* for backward compatibility: save existing insecure username encrypted in LocalStorage */
		if ('username-secure' in authData) {
			usernameSecure = authData['username-secure']
		} else {
			if ('username' in authData) {
				username = authData['username']
				let usernameSecureBinar = aes.encrypt(username, hex.parse(access.key), { iv: hex.parse(access.iv) })
				usernameSecure = usernameSecureBinar.ciphertext.toString(base64)
			} else {
				if (throwNewException) {
					throw new AuthException('No username was found within the authData object.')
				} else {
					return null
				}
			}
			saveSecure = true
		}

		/* save secured token and username */
		if (saveSecure) {
			this.saveAuthDataLoginSecure(username, token)
		}

		/* decrypt the token */
		if (token === null) {
			try {
				let tokenBinar = aes.decrypt(tokenSecure, hex.parse(access.key), { iv: hex.parse(access.iv) })
				token = tokenBinar.toString(utf8)
			} catch (e) {
				return null
			}
		}

		/* something went wrong while decrypting the token */
		if (token === '') {
			return null
		}

		/* decrypt the username */
		if (username === null) {
			try {
				let usernameBinar = aes.decrypt(usernameSecure, hex.parse(access.key), { iv: hex.parse(access.iv) })
				username = usernameBinar.toString(utf8)
			} catch (e) {
				return null
			}
		}

		/* something went wrong while decrypting the username */
		if (username === '') {
			return null
		}

		return {
			username: username,
			token: token
		}
	}

	/**
	 * @method confirm
	 * @description Send registration confirmation code and receive X-Token, then save it to Local Storage
	 * @author Yahor Paromau <yahor.paromau@ressourcenmangel.de>
	 * @param activationCode
	 * @returns {Promise<{loggedIn: boolean, email: *}>}
	 */
	static async confirm (activationCode) {
		let deviceParams = this.collectDeviceParams()
		let email = this.getEmail()

		let dataResponse = await dataLayer.loginConfirm(email, activationCode, deviceParams)

		if (!dataResponse.success) {
			// TODO ERROR_HANDLING

			if (!dataResponse.supported) {
				// TODO ERROR_HANDLING
			}

			throw new Exception('Login confirmation failed')
		}

		this.saveAuthData(email, dataResponse.token)
		return {
			loggedIn: true,
			email: email
		}
	}

	/**
	 *
	 * @returns {any}
	 */
	static logout (notify = false) {
		if (notify) {
			store.dispatch(createModal(
				i18n.t('TXT_GENERAL_ATTENTION.label'),
				i18n.t('ERROR_LOGIN_WRONG_TOKEN.label'),
				() => {
					this.doLogout()
				}
			))

			return
		}

		/* clears the update interval */
		Updater.clearInterval()

		/* clears the localstorage */
		localStorage.clear()
	}

	/**
	 * A collection of things we have to do the logout way.
	 */
	static doLogout () {
		store.dispatch(logout())
		document.body.classList.remove('menu-open')

		// Custom Tracking
		analytics.track('Logout')
	}
}

export {
	LoginProcess
}
