/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'
import i18n from '../../../i18n'

function CookieNoticeView ({ onAccept, onClose }) {
	return (
		<div className="m-cookie">
			<div className="m-cookie__inner">
				<span className="m-cookie__message">{i18n.t('TXT_COOKIE_NOTICE.text')} <a href={'#'}>{i18n.t('TXT_COOKIE_NOTICE.more')}</a></span>
				<div className={'m-cookie__interaction'}>
					<a href='#accept' onClick={onAccept} className={'m-cookie__allow a-button a-button--light'}>{i18n.t('TXT_COOKIE_NOTICE.accept')}</a>
					<a href='#close' onClick={onClose} className={'m-cookie__close'}>{i18n.t('TXT_COOKIE_NOTICE.decline')}</a>
				</div>
			</div>
		</div>
	)
}

CookieNoticeView.defaultProps = {
}

if (__DEV__) {
	// Properties types validation
	CookieNoticeView.propTypes = {
		onAccept: propTypes.func.isRequired,
		onClose: propTypes.func.isRequired
	}
}

// Export component
export default CookieNoticeView
