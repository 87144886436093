/* global __DEV__ */
import React, { Component } from 'react'
import { debugLog } from '../../actions/basic.actions'
import DevAlertView from './views/dev-alert.view'
import { createModal } from '../../actions/modal.actions'
import { compose } from 'redux/lib/redux'
import { mapAppStateToGenericControllerComponent } from '../../utils/mappings'
import withTitle from '../header/withTitle.hoc'
import { connect } from 'react-redux'
import commonPropTypes from '../../utils/common-prop-types'

class DevAlertController extends Component {
	openBasicModal () {
		this.props.dispatch(createModal(
			'Alert 1',
			'Das ist ein einfaches Modal ohne Callback-Funktion.'
		))
	}

	openDecisionModal () {
		this.props.dispatch(createModal(
			'Alert 2',
			'Das ist ein Modal mit zwei Entscheidungsmöglichkeiten und eigenen Button-Labels. Callback löst console.log aus.',
			debugLog('dummy modal 2 confirmed'),
			debugLog('dummy modal 2 cancelled'),
			'Nu, klar!',
			'Nö du, lass mal.'
		))
	}

	openMultipleModals () {
		this.props.dispatch(createModal(
			'Alert 3a',
			'Das ist ein einfaches Modal ohne Callback-Funktion.'
		))
		this.props.dispatch(createModal(
			'Alert 3b',
			'Das ist ein Modal mit zwei Entscheidungsmöglichkeiten und eigenen Button-Labels. Callback löst console.log aus.',
			debugLog('dummy modal 3b confirmed'),
			debugLog('dummy modal 3b cancelled'),
			'Nu, klar!',
			'Nö du, lass mal.'
		))
		this.props.dispatch(createModal(
			'Alert 3c',
			'Das ist ein einfaches Modal mit Callback-Funktion. Callback löst console.log aus.',
			debugLog('dummy modal 3c confirmed')
		))
	}

	openModalsProgrammatically () {
		setTimeout(() => {
			this.props.dispatch(createModal(
				'Alert 4',
				'Das ist ein einfaches Modal mit Callback-Funktion, das zeitverzögert erzeugt wurde. Callback löst console.log aus.',
				debugLog('dummy modal 4 confirmed')
			))
		}, 2000)
	}

	openSameModalThrice () {
		this.props.dispatch(createModal(
			'Alert 5',
			'Das ist ein einfaches Modal ohne Callback-Funktion, welches dreimal geöffnet wird, um visuelles Feedback zu demonstrieren.'
		))
		this.props.dispatch(createModal(
			'Alert 5',
			'Das ist ein einfaches Modal ohne Callback-Funktion, welches dreimal geöffnet wird, um visuelles Feedback zu demonstrieren.'
		))
		this.props.dispatch(createModal(
			'Alert 5',
			'Das ist ein einfaches Modal ohne Callback-Funktion, welches dreimal geöffnet wird, um visuelles Feedback zu demonstrieren.'
		))
	}

	render () {
		return (
			<DevAlertView
				openBasicModal={this.openBasicModal.bind(this)}
				openDecisionModal={this.openDecisionModal.bind(this)}
				openMultipleModals={this.openMultipleModals.bind(this)}
				openModalsProgrammatically={this.openModalsProgrammatically.bind(this)}
				openSameModalThrice={this.openSameModalThrice.bind(this)}
			/>
		)
	}
}

if (__DEV__) {
	DevAlertController.propTypes = {
		...commonPropTypes.mixinFromReduxConnect
	}
}

export default compose(
	connect(mapAppStateToGenericControllerComponent),
	withTitle(null, 'TXT_DEV_ALERT_TITLE.label')
)(DevAlertController)
