/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'

function ContentElementAudioView (props) {
	let { title, soundCloudId } = props

	return (
		<div className="a-iframe">
			<iframe
				width="100%"
				scrolling="no"
				frameBorder="no"
				allow="autoplay"
				src={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/" + soundCloudId + "&auto_play=false&hide_related=false&show_reposts=false&visual=true"}/>
		</div>
	)
}

ContentElementAudioView.defaultProps = {
	title: '',
	soundCloudId: ''
}

if (__DEV__) {
	// Properties types validation
	ContentElementAudioView.propTypes = {
		title: propTypes.string,
		soundCloudId: propTypes.string.isRequired
	}
}

// Export component
export default ContentElementAudioView
