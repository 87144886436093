// react-scripts dropped support for IE9-11 polyfills by default.
// We opt in for that manually now.
// @see https://github.com/facebook/create-react-app/releases/tag/v2.0.3
import 'react-app-polyfill/ie9'
import React from 'react'
import ReactDOM from 'react-dom'

/* import sass styles */
import './styles/main.scss'
import Root from './root.js'

ReactDOM.render(
	<Root
		ref={ (rootComponent) => {
			window.rootComponent = rootComponent
		} }
	/>,
	document.getElementById('root')
)

// Firebase initialization happens in registerServiceWorker
/* ask for push notification permissions */
