/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'
import commonPropTypes from '../../../../utils/common-prop-types'

function AccordionWithTeaserView (props) {
	let { title, isActive, children } = props

	let classNames = ['m-accordion-teaser']

	if (isActive) {
		classNames.push('active')
	}

	return (
		<div className={classNames.join(' ')}>
			<span className="m-accordion-teaser__title">{ title }</span>
			{children}
		</div>
	)
}

AccordionWithTeaserView.defaultProps = {
	title: '',
	children: null,
	isActive: false
}

if (__DEV__) {
	// Properties types validation
	AccordionWithTeaserView.propTypes = {
		title: propTypes.string.isRequired,
		children: commonPropTypes.reactChildren.isRequired,
		isActive: propTypes.bool.isRequired
	}
}

// Export component
export default AccordionWithTeaserView
