const DATA_MODIFIER_STAKEHOLDER_TEMPLATE_URL = '<b>URL:</b> <a href="%link" target="_blank">%url</a>'

const DATA_MODIFIER_STAKEHOLDER_TEMPLATE_ORGANIZATION = '<b>Organisation:</b> %organization'

/**
 * This class adds data that does not exist in the api already.
 * That data has to match the normalized state structure.
 */
class DataModifierStakeholderNormalized {
	/**
	 * Returns a default stakeholder element.
	 *
	 * @param obj
	 * @returns {{image: string, link: string, text: string, headline: string}}
	 */
	static getDefaultElement (obj = {}) {
		let stakeholder = {
			id: obj.personsId,
			lastChanged: obj.lastChanged,
			organization: obj.organisation,
			timestamp: obj.timestamp,
			title: obj.name,
			url: obj.url
		}

		return stakeholder
	}

	/**
	 * Add stakeholder elements.
	 *
	 * @param data
	 * @param dataApi
	 */
	static addElements (data, dataApi) {
		data.stakeholders = data.stakeholders ? data.stakeholders : {}

		/* This is a hack for a bug in the Glamus API: Convert a JS object to an array if necessary. */
		if (typeof(dataApi) === 'object' && !Array.isArray(dataApi)) {
			dataApi = Object.values(dataApi)
		}

		dataApi.map((person) => {
			data.stakeholders[person.personsId] = this.getDefaultElement(person)
		})
	}
}

export { DataModifierStakeholderNormalized }
