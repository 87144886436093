/* global __DEV__ */
import React, { Fragment } from 'react'
import propTypes from 'prop-types'

function DevAlertView (props) {

	let {
		openBasicModal,
		openDecisionModal,
		openMultipleModals,
		openModalsProgrammatically,
		openSameModalThrice
	} = props

	return (
		<div className='o-detail-view'>

			{/* DUMMY Text */}
			<h2 className="a-headline a-headline--opener">Text</h2>
			<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no se</p>
			<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no se</p>
			<hr />

			{/* Buttons that trigger test modals. */}
			<h2 className='a-headline a-headline--opener'>Modal buttons</h2>
			<p>
				<button className='a-button' onClick={openBasicModal}>Open basic Modal</button>
				<button className='a-button' onClick={openDecisionModal}>Open decision Modal</button>
				<button className='a-button' onClick={openMultipleModals}>Open multiple Modals</button>
				<button className='a-button' onClick={openModalsProgrammatically}>Open Modal after two seconds</button>
				<button className='a-button' onClick={openSameModalThrice}>Open same Modal thrice</button>
			</p>
		</div>
	)
}

if (__DEV__) {
	// Properties types validation
	DevAlertView.propTypes = {
		openBasicModal: propTypes.func.isRequired,
		openDecisionModal: propTypes.func.isRequired,
		openMultipleModals: propTypes.func.isRequired,
		openModalsProgrammatically: propTypes.func.isRequired,
		openSameModalThrice: propTypes.func.isRequired
	}
}

// Export component
export default DevAlertView
