/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToStakeholdersItemControllerComponent } from '../../utils/mappings'
import StakeholdersItemView from './views/stakeholders-item.view'
import i18n from 'i18next'
import NotFoundController from '../general/not-found.controller'
import routes from '../../utils/routes'
import ContentElementsController from '../content-elements/content-elements.controller'
import { withPageContentSections } from '../pages/page-content.controller'
import { compose } from 'redux/lib/redux'
import withTitle from '../header/withTitle.hoc'
import commonPropTypes from '../../utils/common-prop-types'
import setEntityViewed from '../general/set-entity-viewed.hoc'
import loadable from '../loadable/loadable.hoc'

class StakeholdersItemController extends Component {
	static getTitle (props) {
		return props.stakeholderItem ? props.stakeholderItem.title : i18n.t('TXT_STAKEHOLDER_DETAIL_TITLE.label')
	}

	render () {
		const { stakeholderItem } = this.props

		if (!stakeholderItem) {
			let links = [{ name: i18n.t('TXT_STAKEHOLDER_LIST_TITLE.label'), href: routes.stakeholdersList.build() }]
			return <NotFoundController links={links} />
		}

		return (
			<StakeholdersItemView stakeholderItem={stakeholderItem}>
				{stakeholderItem.contentElements.map((area, key) => {
					return <ContentElementsController key={key} contentElements={area} />
				})}
			</StakeholdersItemView>
		)
	}
}

if (__DEV__) {
	StakeholdersItemController.propTypes = {
		stakeholderItem: propTypes.object,

		...commonPropTypes.mixinFromReduxConnect
	}
}

export default compose(
	connect(mapAppStateToStakeholdersItemControllerComponent),
	withPageContentSections,
	withTitle(StakeholdersItemController.getTitle),
	loadable('stakeholders', 'stakeholderItem', 'id'),
	setEntityViewed('stakeholders', 'stakeholderItem')
)(StakeholdersItemController)
