import FilesPreparator from './files-preparator'
import FilesDownloader from './files-downloader'

export default class FMController {
	constructor (rawData) {
		this.filesPreparator = new FilesPreparator(rawData)
		this.filesDownloader = null
		this.cacheStorageStatus = null
	}

	prepareDownload () {
		this.filesPreparator.handleExtractFiles()
		this.filesPreparator.removeDynamicPdfs()
		this.filesDownloader = new FilesDownloader(
			this.filesPreparator.getStaticFiles(),
			this.filesPreparator.getDynamicFiles()
		)
	}

	download () {
		this.filesDownloader.prepareRequests()
		this.filesDownloader.triggerRequestGun()
	}
}
