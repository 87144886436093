import { ANALYTICS_MODULE_ACTIONS } from '../constants'
import AnalyticsSignalQueue from '../analytics-signal-queue'
import analyticsSignalProcessorBasic
	from '../signal-processors/analytics-signal-processor-basic'

/**
 * Basic analytics module. Works on it's own but does no tracking or output.
 * Signals will never be marked as processed.
 *
 * This is intended to be extended by other module implementations, e.g. by
 * setting a custom signalProcessor.
 */
export default class AnalyticsModuleBasic {
	/**
	 * Initialize module settings.
	 */
	constructor () {
		/**
		 *
		 * @type {AnalyticsSignalQueue}
		 */
		this.queue = null
		this.initialized = false
		this.processing = false
		this.active = true

		this.signalProcessor = analyticsSignalProcessorBasic
	}

	/**
	 * Creates an AnalyticsSignalQueue and links it to the module instance by
	 * saving a reference for module->queue communication and passing a dispatch
	 * callback for queue->module communication.
	 *
	 * @param {boolean} withStorage Flag: store queue data in local storage
	 * @param {string} storageName  optional: a unique storageName for the queue
	 */
	initQueue (withStorage, storageName) {
		this.queue = new AnalyticsSignalQueue(
			this.active ? this.dispatch.bind(this) : () => {},
			!!withStorage,
			storageName || ''
		)
	}

	/**
	 * A handler for messages from the queue.
	 *
	 * @param {number} action
	 * @param {Object} [data]
	 */
	dispatch (action, data) {
		switch (action) {
			case ANALYTICS_MODULE_ACTIONS.QUEUE_INITIALIZED:
				this.queue = data
				break
			case ANALYTICS_MODULE_ACTIONS.QUEUE_UPDATED:
				this.process()
				break
		}
	}

	/**
	 * Initializes the module and kicks off queue processing.
	 * This implementation is basically just a stub.
	 * It can be overwritten in extending classes to do advanced initialization.
	 */
	initialize () {
		this.initialized = true
		this.process()
	}

	/**
	 * Triggers queue processing and handles queue initialization and processing
	 * flags.
	 */
	process () {
		// Initialize the module, if not done already.
		if (!this.initialized) {
			this.initialize()
			// Processing will get started after initialization again.
			return
		}

		// If we are already processing we are fine.
		// We do not want to process in parallel.
		if (this.processing) {
			return
		}

		// Do the actual queue processing.
		this._process()
	}

	/**
	 * Processes the queue.
	 *
	 * @private
	 */
	_process () {
		// We only need to process the queue if it has signals.
		if (this.queue.length === 0) {
			// Free the module for successive processing triggers again.
			this.processing = false

			return
		}

		// Prevent parallel processing by setting the processing flag.
		this.processing = true

		// Get the next signal from the queue.
		let signal = this.queue.getCurrent()

		// Process the signal via the given signal processor.
		// Processors may run async code (e.g. network requests).
		this.signalProcessor(signal)
			.then(() => {
				// If the signal got successfully processed, remove it from the
				// queue.
				this.queue.removeCurrent()

				// Process the next item.
				setTimeout(this._process.bind(this), 10)
			})
			.catch((/* e */) => {
				// Process the next item.
				setTimeout(this._process.bind(this), 1000)
			})
	}

	/**
	 * PROXY FUNCTION for this.queue.toggleEnabled()
	 *
	 * @param {boolean} enabled
	 */
	toggleEnabled (enabled) {
		this.queue.toggleEnabled(enabled)
	}

	/**
	 * PROXY FUNCTION for this.queue.setEnabledCallback()
	 *
	 * @param {function} func
	 */
	setEnabledCallback (func) {
		this.queue.setEnabledCallback(func)
	}

	/**
	 * PROXY FUNCTION for this.queue.setDeleteQueueIfNotEnabled()
	 *
	 * @param {boolean} deleteQueueIfNotEnabled
	 */
	setDeleteQueueIfNotEnabled (deleteQueueIfNotEnabled) {
		this.queue.setDeleteQueueIfNotEnabled(deleteQueueIfNotEnabled)
	}

	/**
	 * PROXY FUNCTION for this.queue.pushSignal()
	 *
	 * @param {string} name
	 * @param {Object} data
	 */
	pushSignal (name, data) {
		this.queue.pushSignal(name, data)
	}
}
