/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'
import commonPropTypes from '../../../utils/common-prop-types'

function PressReleasesItemView (props) {
	// Assign all properties to variables for convenience
	let { children, tags } = props

	return (
		<div className={'o-detail-view o-detail-view--no-tab'}>
			{tags.length > 0 &&
				<p>
					Tags: { tags.map(tag => tag.title).join(', ') }
				</p>
			}
			{children}
		</div>
	)
}

if (__DEV__) {
	// Properties types validation
	PressReleasesItemView.propTypes = {
		// title: propTypes.string,
		tags: propTypes.array.isRequired,
		children: commonPropTypes.reactChildren
	}
}

// Export component
export default PressReleasesItemView
