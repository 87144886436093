/* global __DEV__ */
import React, { Component, Fragment } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToGenericControllerComponent } from '../../utils/mappings'
import HelpButtonView from './views/help-button.view'
import { createModal } from '../../actions/modal.actions'

class HelpButtonController extends Component {
	openModal (e) {
		e.preventDefault()

		this.props.dispatch(createModal(
			'Fehlerbehandlung',
			'Hier können Sie zur Fehlerbehandlungsseite wechseln, die es Ihnen ermöglicht alte, gegebenenfalls fehlerhafte Daten zu löschen.',
			function () {
				window.location.href = '/update-' + (new Date().getTime()) + '.html'
			},
			function () {},
			'Weiter',
			'Abbruch'
		))
	}

	render () {
		return (
			<HelpButtonView clickHandler={this.openModal.bind(this)} />
		)
	}
}

if (__DEV__) {
	HelpButtonController.propTypes = {
		dispatch: propTypes.func.isRequired,
		bar: propTypes.string
	}
}

export default connect(
	(state, ownProps) => mapAppStateToGenericControllerComponent(state, ownProps)
)(HelpButtonController)
