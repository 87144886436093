/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToCookieNoticeControllerComponent } from '../../utils/mappings'
import CookieNoticeView from './views/cookie-notice.view'
import {
	acceptCookieNotice,
	declineCookieNotice,
	syncCookieNoticeState
} from '../../actions/basic.actions'

class CookieNoticeController extends Component {
	constructor (props) {
		super()
		props.dispatch(syncCookieNoticeState())
	}

	_handleAccept (e) {
		e.preventDefault()
		this.props.dispatch(acceptCookieNotice())
	}

	_handleClose (e) {
		e.preventDefault()
		this.props.dispatch(declineCookieNotice())
	}

	render () {
		let { closed } = this.props

		return closed
			? null
			: (
				<CookieNoticeView
					onAccept={this._handleAccept.bind(this)}
					onClose={this._handleClose.bind(this)}
				/>
			)
	}
}

if (__DEV__) {
	CookieNoticeController.propTypes = {
		dispatch: propTypes.func.isRequired,
		closed: propTypes.bool
	}
}

export default connect(
	(state, ownProps) => mapAppStateToCookieNoticeControllerComponent(state, ownProps)
)(CookieNoticeController)
