/* global __DEV__ */
import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import StakeholdersListController from './stakeholders-list.controller'
import StakeholdersItemController from './stakeholders-item.controller'
import routes from '../../utils/routes'
import NotFoundController from '../general/not-found.controller'

class StakeholdersIndex extends Component {
	render () {
		return (
			<Switch>
				<Route exact path={routes.stakeholdersList.path}	component={StakeholdersListController} />
				<Route exact path={routes.stakeholdersDetail.path}	component={StakeholdersItemController} />
				<Route component={NotFoundController} />
			</Switch>
		)
	}
}

if (__DEV__) {
	StakeholdersIndex.propTypes = {}
}

export default StakeholdersIndex
