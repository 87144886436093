/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'

function LogoutButtonView (props) {
	let { onClick } = props

	return (
		<button data-cy={'logout'} onClick={onClick}>Logout</button>
	)
}

if (__DEV__) {
	// Properties types validation
	LogoutButtonView.propTypes = {
		onClick: propTypes.func.isRequired
	}
}

// Export component
export default LogoutButtonView
