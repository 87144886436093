/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { mapAppStateToGenericControllerComponent } from '../../utils/mappings'
import NotFoundView from './views/not-found.view'
import routes from '../../utils/routes'
import i18n from 'i18next'
import { withPageContentSections } from '../pages/page-content.controller'

class NotFoundController extends Component {
	render () {
		let { links, location, hideDefaultLinks } = this.props

		links = links.slice()

		if (!hideDefaultLinks) {
			links.push({
				name: i18n.t('TXT_PROJECT_HOME_TITLE.label'),
				href: routes.root.build()
			})
		}

		return (
			<NotFoundView links={links} pathname={location.pathname} />
		)
	}
}

NotFoundController.defaultProps = {
	links: [],
	hideDefaultLinks: false
}

if (__DEV__) {
	NotFoundController.propTypes = {
		location: propTypes.object.isRequired,
		dispatch: propTypes.func.isRequired,
		links: NotFoundView.propTypes.links,
		hideDefaultLinks: propTypes.bool
	}
}

export default withRouter(connect(
	(state, ownProps) => mapAppStateToGenericControllerComponent(state, ownProps)
)(withPageContentSections(NotFoundController)))
