/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'
import SideMenuController from '../side-menu/side-menu.controller'

function HeaderView (props) {
	// Assign all properties to variables for convenience
	const { title, onMenuButtonClick, backButton } = props
	return (
		<header className="m-header">
			<title>{ title }</title>
			<h1 data-cy={'main-header'} className="a-headline a-headline--h1 align-center">{ title }</h1>
			<button data-cy={'menu'} className="m-header__menu-trigger" onClick={onMenuButtonClick}>Menu</button>
			<SideMenuController/>
			{ backButton }
		</header>
	)
}

if (__DEV__) {
	// Properties types validation
	HeaderView.propTypes = {
		title: propTypes.string,
		onMenuButtonClick: propTypes.func.isRequired,
		backButton: propTypes.object
	}
}

// Export component
export default HeaderView
