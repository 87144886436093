/* global __DEV__ */
import React, { Fragment } from 'react'
import LinkAll from '../link-all'
import propTypes from 'prop-types'
import i18n from 'i18next'

function NotFoundView (props) {
	const { links, pathname } = props

	return (
		<div className={'o-detail-view o-detail-view--no-tab'}>
			<h1 data-cy={'main-header'} className="a-headline a-headline--h1">{i18n.t('TXT_404_MESSAGE.label')}</h1>
			<p>
				<strong>{pathname}</strong><br />
				{i18n.t('TXT_404_TEXT.label')}
			</p>
			{links.length > 0 && (
				<Fragment>
					<h2 className="a-headline a-headline--h2">{i18n.t('TXT_404_ALTERNATIVES.label')}</h2>
					{
						links.map((link, index) => {
							return <li key={index}><LinkAll to={link.href}>{link.name}</LinkAll></li>
						})
					}
				</Fragment>
			)}
		</div>
	)
}

NotFoundView.defaultProps = {
	link: '',
	linkName: '',
	pathname: ''
}

if (__DEV__) {
	NotFoundView.propTypes = {
		pathname: propTypes.string,
		links: propTypes.arrayOf(
			propTypes.shape({
				name: propTypes.string.isRequired,
				href: propTypes.string.isRequired
			})
		)
	}
}

// Export component
export default NotFoundView
