import PageController from '../components/pages/page.controller'
import routes from './routes'
import Logger from './logger'
import { CONTENT_TYPE_TOPIC, CONTENT_TYPE_NEWS, CONTENT_TYPE_PRESSRELEASE } from '../constants/names'

export function mapAppStateToAppComponent (state /*, ownProps */) {
	return {
		authData: state.loginProcess.authData,
		rememberPath: state.loginProcess.rememberPath,
		dataLoading: state.basic.dataLoading,
		online: state.basic.online,
		...state.basic
	}
}

export function mapAppStateToCategoriesComponent (state /*, ownProps */) {
	return {
		categories: state.content.categories,
		updatedCategoryTopicsQuantity: state.content.updatesInformation.categories.topicQuantities
	}
}

export function mapAppStateToNewsListControllerComponent (state /*, ownProps */) {
	return {
		news: state.content.news,
		// This will trigger a rerender when an update results in a change in
		// updated items count - e.g. to render update indicators without
		// reload/navigation.
		updatesQuantity: state.content.updatesInformation.news.quantity
	}
}

export function mapAppStateToRegistrationControllerComponent (state /*, ownProps */) {
	return {
		authDataSaveProgressStatus: state.loginProcess.authDataSaveProgressStatus
	}
}

export function mapAppStateToConfirmationControllerComponent (state /*, ownProps */) {
	return {
		authDataSaveProgressStatus: state.loginProcess.authDataSaveProgressStatus
	}
}

function inReadingList (id, type, readingList) {
	if (Object.keys(readingList).indexOf(type) === -1) {
		return false
	}
	return readingList[type].indexOf(id) > -1
}

export function mapAppStateToCategoryTopicsListControllerComponent (state, ownProps) {
	let category = null
	let topics = []

	if (state.content.categories[ownProps.match.params.id]) {
		// React doesn't see changes in props if using just assignment
		category = { ...state.content.categories[ownProps.match.params.id] }
		topics = category.topics.map((topicId) => {
			let topic = state.content.topics[topicId] || null
			if (topic === null) {
				Logger.errorOnce('MAPPINGS', 'Unkown topic ID "' + topicId + '"')
			}
			return topic
		})
	}

	return {
		category,
		topics
	}
}

export function mapAppStateToSideMenuControllerComponent (state /*, ownProps */) {
	return {
		isOpen: state.basic.sideMenuIsOpen,
		categoriesUpdatesQuantity: state.content.updatesInformation.topics.quantity,
		newsUpdatesQuantity: state.content.updatesInformation.news.quantity,
		pressReleasesUpdatesQuantity: state.content.updatesInformation.pressReleases.quantity,
		stakeholdersUpdatesQuantity: state.content.updatesInformation.stakeholders.quantity,
		readingListUpdatesQuantity: state.content.readingListCount,
		messageQueueListUpdatesQuantity: state.basic.messageQueue.length
	}
}

export function mapAppStateToHeaderControllerComponent (state /*, ownProps */) {
	return {
		title: state.basic.title,
		sideMenuIsOpen: state.basic.sideMenuIsOpen
	}
}

export function mapAppStateToNewsItemControllerComponent (state, ownProps) {
	let tmpMode = false

	let newsItems = state.basic.singleView && tmpMode ? state.content._tmp.data.news : state.content.news

	let newsId = null

	switch (true) {
		case state.basic.singleView:
			newsId = String(state.content._tmp.objectInformation.id)
			break

		case 'shortcutId' in ownProps:
			newsId = String(ownProps.shortcutId)
			break

		default:
			newsId = ownProps.match.params.id
	}

	let news = newsItems[newsId]

	return {
		id: newsId,
		newsItem: news,
		inReadingList: inReadingList(newsId, 'news', state.content.readingList),
		activeSectionKey: state.basic.activeSectionMenuTab
	}
}

export function mapAppStateToTopicControllerComponent (state, ownProps) {
	let tmpMode = false

	let topics = state.basic.singleView && tmpMode ? state.content._tmp.data.topics : state.content.topics
	let categories = state.basic.singleView && tmpMode ? state.content._tmp.data.categories : state.content.categories

	let topicId = null
	let categoryId = null

	switch (true) {
		case state.basic.singleView:
			topicId = String(state.content._tmp.objectInformation.id)
			break

		case 'shortcutId' in ownProps:
			topicId = String(ownProps.shortcutId)
			break

		default:
			topicId = ownProps.match.params.topicId
			categoryId = ownProps.match.params.id
	}

	let topic = topics[topicId]
	let category = categories[categoryId]

	return {
		category,
		topic,
		topicId,
		inReadingList: inReadingList(topicId, 'topics', state.content.readingList),
		activeSectionKey: state.basic.activeSectionMenuTab
	}
}

export function mapAppStateToPressReleasesItemController (state, ownProps) {
	let tmpMode = false

	let pressReleaseId = null

	switch (true) {
		case state.basic.singleView:
			pressReleaseId = String(state.content._tmp.objectInformation.id)
			break

		case 'shortcutId' in ownProps:
			pressReleaseId = String(ownProps.shortcutId)
			break

		default:
			pressReleaseId = ownProps.match.params.id
	}

	let pressRelease = getPressRelease(state, pressReleaseId, tmpMode)
	let pressReleaseTags = getPressReleaseTags(state, pressReleaseId, tmpMode)

	return {
		id: pressReleaseId,
		pressRelease: pressRelease,
		tags: pressReleaseTags,
		inReadingList: inReadingList(pressReleaseId, 'pressReleases', state.content.readingList)
	}
}

// pressReleases selector (selector is a redux pattern)
// TODO move close to relevant reducer as soon as refactoring is done
// TODO build reselector (https://www.npmjs.com/package/reselect)
function getPressReleases (state, tmpMode = false) {
	return tmpMode ? state.content._tmp.data.pressReleases : state.content.pressReleases
}

export function mapAppStateToPressReleasesListController (state /* , ownProps */) {
	return {
		pressReleases: getPressReleases(state),
		// This will trigger a rerender when an update results in a change in
		// updated items count - e.g. to render update indicators without
		// reload/navigation.
		updatesQuantity: state.content.updatesInformation.pressReleases.quantity
	}
}

// pressRelease selector (selector is a redux pattern)
// TODO move close to relevant reducer as soon as refactoring is done
// TODO build reselector (https://www.npmjs.com/package/reselect)
function getPressRelease (state, id, tmpMode = false) {
	const pressReleases = getPressReleases(state, tmpMode)
	return pressReleases[id] || null
}

// pressRelease tags selector (selector is a redux pattern)
// TODO move close to relevant reducer as soon as refactoring is done
// TODO build reselector (https://www.npmjs.com/package/reselect)
function getPressReleaseTags (state, id, tmpMode = false) {
	const pressRelease = getPressRelease(state, id, tmpMode)
	const tags = state.content.tags
	if (!pressRelease) {
		return []
	}
	if (pressRelease.isDummy) {
		return []
	}
	return pressRelease.tags
		.map((tagId) => {
			return tags[tagId] || null
		})
		.filter(tagId => tagId !== null)
}

export function mapAppStateToStakeholdersListController (state /* , ownProps */) {
	return {
		stakeholders: state.content.stakeholders,
		// This will trigger a rerender when an update results in a change in
		// updated items count - e.g. to render update indicators without
		// reload/navigation.
		updatesQuantity: state.content.updatesInformation.stakeholders.quantity
	}
}

export function mapAppStateToStakeholdersItemControllerComponent (state, ownProps) {
	const id = ownProps.match.params.id
	return {
		id,
		stakeholderItem: state.content.stakeholders[id]
	}
}

export function mapAppStateToReadingListController (state /*, ownProps */) {
	let readingList = {}
	Object.keys(state.content.readingList).forEach((itemType) => {
		readingList[itemType] = {}
		state.content.readingList[itemType].forEach((itemId) => {
			let item = null

			if (state.content[itemType] && state.content[itemType][itemId]) {
				item = readingList[itemType][itemId] = state.content[itemType][itemId]
			}

			readingList[itemType][itemId] = item
		})
	})
	return {
		readingListCount: state.content.readingListCount,
		readingList: readingList
	}
}

export function mapAppStateToMessageQueueControllerComponent (state /*, ownProps */) {
	return {
		messageQueue: state.basic.messageQueue
	}
}

export function mapAppStateToSearchControllerComponent (state /*, ownProps */) {
	return {
		searchRequest: state.content.search.request,
		searchResult: state.content.search.result,
		isOnline: state.content.search.isOnline
	}
}

export function mapAppStateToSearchInputControllerComponent (state /*, ownProps */) {
	return {
		content: state.content,
		searchRequest: state.content.search.request
	}
}

function getActiveAccordionTabIndex (state, ownProps) {
	let activeTabIndex = ownProps.initiallyClosed ? -1 : 0

	if (ownProps.accordionKey && typeof state.accordion.activeTabIndex[ownProps.accordionKey] !== 'undefined') {
		activeTabIndex = state.accordion.activeTabIndex[ownProps.accordionKey]
	}

	return activeTabIndex
}

export function mapAppStateToAccordionWithTeaserControllerComponent (state, ownProps) {
	const isActive = getActiveAccordionTabIndex(state, ownProps) !== -1

	return {
		isActive
	}
}

export function mapAppStateToAccordionControllerComponent (state, ownProps) {
	const activeTabIndex = getActiveAccordionTabIndex(state, ownProps)

	return {
		activeTabIndex
	}
}

export function mapAppStateToArticleSendControllerComponent (state, ownProps) {
	let props = {
		apiMessageType: '',
		apiMessage: '',
		apiCode: 0,
		emailTo: state.basic.articleSend.formFields.emailTo,
		emailSubject: state.basic.articleSend.formFields.emailSubject,
		emailMessage: state.basic.articleSend.formFields.emailMessage,
		messageQueue: state.basic.messageQueue
	}

	if (state.basic.articleSend.success.status) {
		props.apiMessageType = 'SUCCESS'
		props.apiMessage = state.basic.articleSend.success.message
		props.apiCode = state.basic.articleSend.success.code
	}

	if (state.basic.articleSend.error.status) {
		props.apiMessageType = 'ERROR'
		props.apiMessage = state.basic.articleSend.error.message
		props.apiCode = state.basic.articleSend.error.code
	}

	/* Page-dependent state elements. */
	switch (ownProps.match.path) {
		/* The delivery of individual items: /categories/[:id]/[:topicId]/send, e.g. */
		case routes.categoriesTopicSend.path:
			props.ids = ownProps.match.params.topicId
			break

		/* The delivery of individual items: /news/[:id]/send, e.g. */
		case routes.newsDetailSend.path:
		case routes.pressReleasesDetailSend.path:
			props.ids = ownProps.match.params.id
			break

		/* The delivery of the entire reading list. */
		case routes.readingListSend.path:
			props.ids = Object.keys(state.content.readingList)
				.map((key) => {
					return state.content.readingList[key]
				})
				.reduce(
					(a, b) => { return a.concat(b) },
					[]
				)
			break
	}

	return props
}

/**
 * Mapper for SettingsIndexController.
 *
 * @param state
 * @return {{}}
 */
export function mapAppStateToSettingControllerComponent (state) {
	let props = {
		apiMessageType: '',
		apiMessage: '',
		apiCode: 0,

		emails: state.basic.settings.formFields.emails.slice(0),

		showLoader: state.basic.layer.loader,
		showOffline: state.basic.layer.offline
	}

	if (state.basic.settings.success.status) {
		props.apiMessageType = 'SUCCESS'
		props.apiMessage = state.basic.settings.success.message
		props.apiCode = state.basic.settings.success.code

		/* to avoid an endless loop */
		state.basic.settings.success.status = false
		state.basic.settings.success.message = ''
		state.basic.settings.success.code = -1
	}

	if (state.basic.settings.error.status) {
		props.apiMessageType = 'ERROR'
		props.apiMessage = state.basic.settings.error.message
		props.apiCode = state.basic.settings.error.code

		/* to avoid an endless loop */
		state.basic.settings.error.status = false
		state.basic.settings.error.message = ''
		state.basic.settings.error.code = -1
	}

	return props
}

/**
 * Mapper for BackButtonController.
 *
 * This is needed for triggering rerender because React doesn't see history as
 * an actual new prop. So we read it's values here and pass it as new props.
 *
 * @param state
 * @param {object} ownProps
 * @param {object} ownProps.history passed by withRouter(...)
 * @param {object} ownProps.history.localHistory
 * @return {{}}
 */
export function mapAppStateToBackButtonControllerComponent (state, ownProps) {
	let canGoBack = false
	const localHistory = ownProps.history.localHistory
	if (localHistory) {
		canGoBack = localHistory.canGoBack()
	}
	return {
		canGoBack,
		localHistory: localHistory
	}
}

/**
 * Mapper for ModalController
 *
 * @param state
 * @return {{}}
 */
export function mapAppStateToModalControllerComponent (state /*, ownProps */) {
	let modal = state.modal.modals.length ? state.modal.modals[0] : null
	return {
		modal: modal
	}
}

/**
 * Mapper for PagesController
 *
 * @param state
 * @param props
 * @return {{}}
 */
export function mapAppStateToPagesControllerComponent (state, props) {
	return {
		prev: state.localHistory.lastAction === 'POP',
		scrollTopBody: state.basic.nextScrollPosition.scrollTopBody
	}
}

/**
 * Mapper for SliderController
 *
 * @param state
 * @param props
 * @return {{}}
 */
export function mapAppStateToSliderControllerComponent (state, props) {
	return {
		pageTransitionState: PageController.getTransitionStateByStateAndKey(state, props.location.pathname)
	}
}

/**
 * Mapper for SectionMenuController
 *
 * @param state
 * @param props
 * @return {{}}
 */
export function mapAppStateToSectionMenuControllerComponent (state, props) {
	return {
		activeSectionKey: state.basic.activeSectionMenuTab
	}
}

/**
 * Mapper for UpdateButtonController
 *
 * @param state
 * @param props
 * @return {{}}
 */
export function mapAppStateToUpdateButtonControllerComponent (state, props) {
	return {}
}

/**
 * Mapper for NewsSliderController
 * @param state
 * @param props
 * @returns {{sliderElements: shim}}
 */
export function mapAppStateToNewsSliderControllerComponent (state, props) {
	return {
		sliderElements: state.content.sliderElements
	}
}

/**
 * Mapper for HOC loadable()
 *
 * @param state
 * @param ownProps
 * @param entityProp
 * @return {{loadableInfo: {isLoading: boolean, isDummy: boolean}}}
 */
export function mapAppStateToLoadableHOC (state, ownProps, entityProp) {
	const entity = ownProps[entityProp]
	const isDummy = entity && entity.isDummy
	const isLoading = entity && entity.isLoading
	const loadableInfo = {
		isDummy: !!isDummy,
		isLoading: !!isLoading
	}

	return {
		loadableInfo
	}
}

export function mapAppStateToDevExamplesControllerComponent (state /*, ownProps */) {
	return {
		cookieNotice: { ...state.basic.cookieNotice }
	}
}

/**
 * Mapper for CookieNoticeController
 *
 * @param state
 * @return {{}}
 */
export function mapAppStateToCookieNoticeControllerComponent (state /*, ownProps */) {
	return {
		closed: state.basic.cookieNotice.closed
	}
}

/**
 * Mapper for LinkListController.
 *
 * @param state
 * @return {{topics: object}}
 */
export function mapAppStateToLinkListControllerComponent (state /*, ownProps */) {
	return {
		topics: state.content.topics
	}
}

/**
 * Mapper for PreviewSwitchController.
 *
 * @param state
 * @param ownProps
 * @return {{}}
 */
export function mapAppStateToShortcutSwitchControllerComponent (state, ownProps) {
	let id = ownProps.match.params.id
	let types = {
		'topics': CONTENT_TYPE_TOPIC,
		'news': CONTENT_TYPE_NEWS,
		'pressReleases': CONTENT_TYPE_PRESSRELEASE
	}

	for (let type in types) {
		for (let itemId in state.content[type]) {
			if (id === itemId) {
				return {
					objectInformation: {
						id: parseInt(itemId),
						type: types[type]
					}
				}
			}
		}
	}

	return {
		objectInformation: null
	}
}

/**
 * Mapper for PreviewSwitchController.
 *
 * @param state
 * @return {{}}
 */
export function mapAppStateToPreviewSwitchControllerComponent (state) {
	return {
		objectInformation: '_tmp' in state.content ? state.content._tmp.objectInformation : {}
	}
}

/**
 * Dummy Mapper for Controllers without data.
 *
 * @param state
 * @return {{}}
 */
export function mapAppStateToGenericControllerComponent (state /*, ownProps */) {
	return {}
}
