/* global __DEV__ */
import React from 'react'
import propTypes from 'prop-types'
import StakeholdersListItemView from './stakeholders-list-item.view'
import { asSequence } from 'sequency'
import StakeholdersAkkordeonView from './list-akkordeon.view'

function StakeholdersListView (props) {
	// Assign all properties to variables for convenience
	const { stakeholdersOrganised } = props

	// Create StakeholdersAkkordeonView for each Organisation
	const accordeons =
		Object.entries(stakeholdersOrganised)
			.map((o, index) => <StakeholdersAkkordeonView key={ index } name={ o[0] } items={ o[1] }/>)

	// Display all akkordeons
	return (
		<div className="container o-list-view">
			<div className="m-list-item-wrapper">
				{ accordeons }
			</div>
		</div>
	)
}

if (__DEV__) {
	// Properties types validation
	StakeholdersListView.propTypes = {
		stakeholdersOrganised: propTypes.object.isRequired
	}
}

// Export component
export default StakeholdersListView
