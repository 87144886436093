/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToPressReleasesListController } from '../../utils/mappings'
import routes from '../../utils/routes'
import PressReleasesListView from './views/press-releases-list.view'
import PressReleasesListItemView from './views/press-releases-list-item.view'
import DF from '../../utils/dateFormat'
import { withPageContentSections } from '../pages/page-content.controller'
import { compose } from 'redux/lib/redux'
import withTitle from '../header/withTitle.hoc'
import commonPropTypes from '../../utils/common-prop-types'

class PressReleasesListController extends Component {
	render () {
		const { pressReleases } = this.props

		const listItems = Object.keys(pressReleases).map((id /* , key */) => {
			const pressRelease = pressReleases[id]
			const link = routes.pressReleasesDetail.build(id)
			const classes = pressRelease.isUpdated ? 'topic-updated' : ''

			return (
				<PressReleasesListItemView
					key={id}
					id={id}
					link={link}
					title={pressRelease.title}
					date={DF.fromTimestamp(pressRelease.timestamp ? pressRelease.timestamp : pressRelease.tstamp).toJJJJMMDD()}
					classes={classes}
				/>
			)
		})

		return (
			<PressReleasesListView>
				{listItems}
			</PressReleasesListView>
		)
	}
}

PressReleasesListController.defaultProps = {
	pressReleases: {}
}

if (__DEV__) {
	PressReleasesListController.propTypes = {
		pressReleases: propTypes.object,

		...commonPropTypes.mixinFromReduxConnect
	}
}

export default compose(
	connect(mapAppStateToPressReleasesListController),
	withPageContentSections,
	withTitle(null, 'TXT_PRESS_RELEASES_LIST_TITLE.label')
)(PressReleasesListController)
