import SWFeature from './sw.feature'

function updateRootState (object) {
	window.rootComponent.setState(object)
}

async function serviceWorkerInstalled () {
	if (!SWFeature.checkSupport()) {
		return false
	}
	return (await navigator.serviceWorker.getRegistrations())
		.filter(r => r.active.scriptURL.includes('service-worker.js'))
		.length > 0
}

async function getServiceWorkerRegistration () {
	return (await navigator.serviceWorker.getRegistrations())
		.filter(r => r.active.scriptURL.includes('service-worker.js'))[0]
}

// Update symbols will work only for the PWA app with caching on main request enabled
export {
	updateRootState,
	serviceWorkerInstalled,
	getServiceWorkerRegistration
}
