/* global __DEV__ */
import React from 'react'
import commonPropTypes from '../../../utils/common-prop-types'
import propTypes from 'prop-types'
import i18n from '../../../i18n'

export function MessageQueueListView (props) {
	const {
		children,
		messageQueueListLink,
		handleClearMessageQueueList
	} = props

	let classNamesSend = 'a-button a-button--center'
	let classNamesClear = 'a-button a-button--center a-button--small a-button--light'

	if (messageQueueListLink === '') {
		classNamesSend += ' a-button--inactive'
		classNamesClear += ' a-button--inactive'
	}

	return (
		<div className="container o-list-view o-list-view--reading-list">
			<div className="m-list-item-wrapper">
				{ children }
			</div>

			<div className={'m-action-button-wrapper'}>
				<a data-cy={'reading-list-send'} className={classNamesSend} onClick={messageQueueListLink}>
					{i18n.t('TXT_MESSAGE_QUEUE_LIST_SEND.label')}
				</a>
				<a data-cy={'reading-list-clear'} className={classNamesClear} onClick={handleClearMessageQueueList}>
					{i18n.t('TXT_MESSAGE_QUEUE_LIST_CLEAR.label')}
				</a>
			</div>
		</div>
	)
}

if (__DEV__) {
	MessageQueueListView.propTypes = {
		children: commonPropTypes.reactChildren,
		messageQueueListLink: propTypes.func,
		handleClearMessageQueueList: propTypes.func
	}
}

export default MessageQueueListView
