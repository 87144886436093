import Operation from './../Operation'
import { MESSAGE_QUE_OPERATION } from '../../../constants/names'
import MessageQueReaction from '../../connection-status-reactions/MessageQueReaction'

export default class MessageQueOperation extends Operation {
	/**
	 * @param {function} dispatch
	 */
	constructor (dispatch) {
		super(MESSAGE_QUE_OPERATION)
		this.reaction = new MessageQueReaction(dispatch)
	}

	execute () {
		this.reaction.react(true)
	}
}
