/* global __DEV__ */
import React from 'react'
import commonPropTypes from '../../../utils/common-prop-types'

function PressReleasesListView (props) {
	// Assign all properties to variables for convenience
	const { children } = props

	return (
		<div className="o-list-view">
			<div className="m-list-item-wrapper">
				{ children }
			</div>
		</div>
	)
}

PressReleasesListView.defaultProps = {
	children: []
}

if (__DEV__) {
	// Properties types validation
	PressReleasesListView.propTypes = {
		children: commonPropTypes.reactChildren
	}
}

// Export component
export default PressReleasesListView
