import Logger from '../logger'

class DaemonsService {
	constructor () {
		this.daemons = []
	}

	/**
	 * Register daemon in daemons service.
	 * @param {DaemonBase} daemon
	 */
	registerDaemon (daemon) {
		let existed = this.get(daemon.name)
		if (existed !== undefined) {
			console.warn('Daemon already registered', daemon)
			return
		}
		this.daemons.push(daemon)
		Logger.logOnce('Daemons Service', 'Daemon successfully registered, daemons:', this.daemons)
	}

	get (name) {
		return this.daemons.find(d => d.name === name)
	}
}

export let daemonsService = new DaemonsService()
