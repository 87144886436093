/* global __DEV__ */
import commonPropTypes from '../../../utils/common-prop-types'
import React from 'react'

function HomeView ({ children }) {
	return (
		<div className={'o-home'}>
			{children}
		</div>
	)
}

HomeView.defaultProps = {
	bar: 'bar'
}

if (__DEV__) {
	// Properties types validation
	HomeView.propTypes = {
		children: commonPropTypes.reactChildren
	}
}

// Export component
export default HomeView
