/* global __FEATURE_ANALYTICS_CONSOLE__ */
/* global __FEATURE_ANALYTICS_GOOGLE__ */
import analyticsModuleGoogle from './modules/analytics-module-google'
import analyticsModuleJSConsole from './modules/analytics-module-js-console'

// Basic signal types.
const ANALYTICS_SIGNALS = {
	GENERIC: 'generic',
	PAGE_VIEW: 'pageview'
}

/**
 * Registered analytics modules.
 *
 * @type {Array}
 */
const analyticsModules = []

if (__FEATURE_ANALYTICS_CONSOLE__) {
	analyticsModules.push(analyticsModuleJSConsole)
}

if (__FEATURE_ANALYTICS_GOOGLE__) {
	analyticsModules.push(analyticsModuleGoogle)
}

/**
 * Pushes a signal to all registered analytics modules.
 *
 * @param {AnalyticsSignal} signal
 * @param {Object} [data]
 */
function pushSignal (signal, data) {
	analyticsModules.forEach((analyticsModule) => {
		analyticsModule.pushSignal(signal, data)
	})
}

/**
 * Helper function to push pageviews from the local history.
 *
 * @param {Object} location
 */
function handleHistory (location /*, action */) {
	analytics.trackPageVisit(location.pathname)
}

/**
 * Flag: We are already listening to the history.
 * Used to prevent multiple history listeners and therefore duplicate pageviews.
 *
 * @type {boolean}
 */
let listeningToHistory = false

/**
 * Main analytics object used to track data.
 *
 * @type {{trackPageVisit(*, *=): void, listenToHistory(*): void, track(*): void}}
 */
const analytics = {
	/**
	 * Tracks a generic message (aka generic signal).
	 * Registered analytics modules will process that signal according to their
	 * implementation.
	 *
	 * @param {string} message
	 */
	track (message) {
		pushSignal(ANALYTICS_SIGNALS.GENERIC, { message })
	},

	/**
	 * Tracks a pageview (aka pageview signal).
	 * Registered analytics modules will process that signal according to their
	 * implementation.
	 *
	 * @param {string} pathname
	 * @param {string} title
	 */
	trackPageVisit (pathname, title) {
		pushSignal(ANALYTICS_SIGNALS.PAGE_VIEW, {
			hostname: document.location.hostname,
			pathname,
			title: title || ''
		})
	},
	/**
	 * Registers a listener that handles history locations changes and tracks
	 * them as pageviews.
	 *
	 * @param {Object} history
	 * @param {function} history.listen
	 */
	listenToHistory (history) {
		// If we are already listening we do not register a second handler.
		if (listeningToHistory) {
			return
		}
		listeningToHistory = true

		// TODO This is a first shot only.
		//  At this point we do not have rendered any content yet.
		//  That way we are not able to determine the title of the page to be
		//  rendered. And we do not know if the user actually did see that page
		//  to be rendered or if he maybe left before.

		// TODO Should we support tracking of previous pages via
		//  localHistory? Or more generally: A passed array with page data
		//  that could be generated from local history?

		// This tracks the current location as a pageview.
		analytics.trackPageVisit(document.location.pathname)
		// Listen to future location changes and track them as pageviews.
		history.listen(handleHistory)
	}
}

export default analytics
export {
	ANALYTICS_SIGNALS
}
