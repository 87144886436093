/* global __DEV__ */
/* global __FEATURE__LOCAL_HISTORY__LOGGING__ */
import React, { Component, Fragment } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import App from './App'
import { Provider } from 'react-redux'
import store, { dynamicStore } from './store'
import { history } from './utils/browser-history'
import LocalHistory from './utils/browser-history-local'
import analytics from './analytics/analytics'
import browserHistoryLocalListenerScrollPosition
	from './utils/browser-history-local-listener-scrollPosition'
import ConnectionStatusController from './utils/connection-status-controller'
import analyticsModuleGoogle from './analytics/modules/analytics-module-google'
import CookieNotice from './utils/cookie-notice'
import { ReadingList } from './reading-list/reading-list'
import ReadingListProcessor from './reading-list/reading-list-processor'
import routes from './utils/routes'
import analyticsModuleJSConsole from './analytics/modules/analytics-module-js-console'
import ApplicationFeature from './utils/application-feature'
import { createModal } from './actions/modal.actions'

// Load and use google analytics only after cookies have been accepted.
analyticsModuleGoogle.setEnabledCallback(() => CookieNotice.accepted)
// Always load and use js-console analytics.
analyticsModuleJSConsole.toggleEnabled(true)

// Location tracking
analytics.listenToHistory(history)

// Easy handling for local history
const localHistory = new LocalHistory(history, dynamicStore, __DEV__ && __FEATURE__LOCAL_HISTORY__LOGGING__)
localHistory.registerListener(
	browserHistoryLocalListenerScrollPosition.onBeforeUpdate,
	browserHistoryLocalListenerScrollPosition.onAfterUpdate
)
localHistory.init()

ReadingList.injectDefaultReadingListProcessorFactory(() => { return ReadingListProcessor.instance })

// Connection status event listener
ConnectionStatusController.addEventListeners()

// Sets a callback for notification about missing features.
ApplicationFeature.setFuncCreateModal(function (title, text, confirmationCallback) {
	// Show a modal.
	store.dispatch(createModal(
		title,
		text,
		confirmationCallback
	))
})

class Root extends Component {
	render () {
		return (
			<Fragment>
				<Provider store={ store }>
					{/*
					We do not use BrowserRouter here because we want to be able
					to navigate through history.push() too.
					Instead we inject our global history instance to the
					standard router.
					BrowserHistory does the exact same thing. (creating a
					history instance and wrapping Router) So no functionality is
					lost by using Router with our own history instead.
					*/}
					<Router history={history}>
						{/*
						If we are on the single view route we need to inform the app
						about it to trigger a custom auth and data preparation
						process.
						*/}
						<Switch>
							<Route path={routes.singleView.path} render={() => { return <App matchedSingleViewRoute={true} /> }} />
							<Route path={routes.cmsPreview.path} render={() => {
								return <App matchedSingleViewRoute={true} matchedCmsPreviewRoute={true} />
							}} />
							<Route component={App} />
						</Switch>
					</Router>
				</Provider>
				{/* X-Browser-Tests look this up to check if the app renderes correctly. */}
				<span id='test__bs__render' />
			</Fragment>
		)
	}
}

export default Root
