class DataLayerResponse {
	/**
	 * @param {bool} supported Determines if that feature is supported by the data layer implementation.
	 * @param {bool} success Determines if the data layer implementation was able to handle the request properly.
	 */
	constructor (supported, success) {
		this.supported = supported
		this.success = success
	}
}

class DataLayerResponseBase extends DataLayerResponse {
	/**
	 * @param {bool} supported
	 * @param {bool} success
	 * @param {Object} data
	 */
	constructor (supported, success, data) {
		super(supported, success)
		this.rawData = data
	}
}

class DataLayerResponseSendArticle extends DataLayerResponse {
	/**
	 * @param {bool} supported
	 * @param {bool} success
	 * @param {string} code
	 * @param {string} message
	 */
	constructor (supported, success, code, message) {
		super(supported, success)
		this.code = code
		this.message = message
	}
}

class DataLayerResponseSearchContent extends DataLayerResponse {
	/**
	 * @param {bool} supported
	 * @param {bool} success
	 * @param {DataLayerSearchResult[]} results
	 */
	constructor (supported, success, results) {
		super(supported, success)
		this.results = results
	}
}

class DataLayerResponseLoginCheckAuth extends DataLayerResponse {
	/**
	 * @param {bool} supported
	 * @param {bool} success
	 * @param {bool} loggedIn
	 * @param {bool} offline
	 * @param {string} message
	 */
	constructor (supported, success, loggedIn, offline, message) {
		super(supported, success)
		this.loggedIn = loggedIn
		this.offline = offline
		this.message = message
	}
}

class DataLayerResponseLoginRegister extends DataLayerResponse {
}

class DataLayerResponseLoginBasic extends DataLayerResponse {
	/**
	 * @param {bool} supported
	 * @param {bool} success
	 * @param {string} token
	 */
	constructor (supported, success, token) {
		super(supported, success)
		this.token = token
	}
}

class DataLayerResponseLoginConfirm extends DataLayerResponse {
	/**
	 * @param {bool} supported
	 * @param {bool} success
	 * @param {string} token
	 */
	constructor (supported, success, token) {
		super(supported, success)
		this.token = token
	}
}

export {
	DataLayerResponseBase,
	DataLayerResponseSendArticle,
	DataLayerResponseSearchContent,
	DataLayerResponseLoginCheckAuth,
	DataLayerResponseLoginRegister,
	DataLayerResponseLoginBasic,
	DataLayerResponseLoginConfirm
}
