import { Exception } from '../utils/errors'
import appEnv from '../utils/app-env'

class DataLayerProxy {
	constructor (dataLayerImplementation) {
		this.dataLayerImplementation = dataLayerImplementation
	}

	static checkPropertyType (property, type, name) {
		let passes = true
		let error = ''
		switch (type) {
			case 'function':
				passes = typeof property === 'function'
				break
			case 'string':
				passes = typeof property === 'string'
				break
			case 'boolean':
				passes = typeof property === 'boolean'
				break
			default:
				passes = false
				error = 'unknown property type: ' + type
		}

		if (!passes) {
			if (error === '') {
				error = 'data layer implemenation lacks property "' + name + '" of type ' + type
			}
			console.warn('Loaded data layer implementation from ' + appEnv.dataLayerPath)
			/*
			 * --- DEBUG NOTE START ---
			 *
			 * If you are encountering this exception you most likely have run
			 * into one of these two errors:
			 *
			 * 1) You did not specify a data layer implementation in your
			 *    project configuration.
			 *
			 *    To fix that:
			 *    - If you have no
			 *        src/config/app-environment-local.js
			 *      yet, copy it from
			 *        src/config/app-environment-local.dist.js
			 *    - If your
			 *        src/config/app-environment-local.js
			 *      has no
			 *        appEnv.dataLayerPath = '...'
			 *      yet, add it and specify a data layer implementation path.
			 *      (starting from src, without leading dot and slash)
			 *    - Find data layer implementations in
			 *        src/data-layer/implementations
			 *      or any custom location where you put some custom
			 *      implementation.
			 *    - Example path:
			 *        'src/data-layer/implementations/pa-app-api/index.js'
			 *
			 * 2) The data implementation you chose is not compatible with the
			 *    current app version.
			 *
			 *    To fix that:
			 *    - Check
			 *        src/data-layer/dataLayer.js
			 *      and compare the definitions there against the data layer
			 *      implementation that you are running with.
			 *
			 * --- DEBUG NOTE END ---
			 */
			throw new Exception('DataLayerInterface: ' + error)
		}

		return passes
	}

	getProperty (name, type) {
		let property = this.dataLayerImplementation[name]
		if (!DataLayerProxy.checkPropertyType(property, type, name)) {
			return null
		}
		return property
	}

	getFunction (name) {
		return this.getProperty(name, 'function')
	}

	getString (name) {
		return this.getProperty(name, 'string')
	}

	getBoolean (name) {
		return this.getProperty(name, 'boolean')
	}
}

export default DataLayerProxy
