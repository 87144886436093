import dataLayer from '../../data-layer/dataLayer'
import { Exception } from '../errors'
import { asSequence } from 'sequency'
import Logger from '../logger'

export default class ContentUpdateController {
	/**
	 * Sets current timestamp to updateResponseBase.timestamp
	 * @param updateResponseBase
	 */
	static injectTimestamp (updateResponseBase) {
		updateResponseBase.timestamp = Math.round((new Date()).getTime() / 1000)
	}

	static injectHasUpdates (updateResponseBase) {
		try {
			updateResponseBase.hasUpdates = asSequence(Object.values(updateResponseBase.rawData))
				.any(obj => Object.values(obj).length > 0)
		} catch (e) {
			console.warn('UPDATE CONTROLLER :: injectHasUpdates :: ' +
				'There are some non-object element in updateResponseBase.rawData. ' +
				'Ignored and returned True')
		}
	}

	static requireSupport (updateResponseBase) {
		if (updateResponseBase.supported === false) {
			throw new Exception('Content update not supported')
		}
	}

	static requireSuccess (updateResponseBase) {
		if (updateResponseBase.success === false) {
			throw new Exception('Content update controller cant get update')
		}
	}

	/**
	 * Tries to get updateResponseBase from dataLayer
	 * @param lastUpdateDate
	 */
	static async getDataLayerResponse (lastUpdateDate) {
		try {
			return await dataLayer.getDataUpdates(lastUpdateDate)
		} catch (e) {
			console.error(e)
			this.requireSuccess({ success: false })
		}
	}

	static fromUnixTimestamp (timestamp) {
		//return moment.unix(timestamp).format('YYYY-MM-DD HH:mm:ss')

		function toTimeString (x) {
			return x < 10 ? '0' + x : x
		}

		// Timestamp to ms
		timestamp *= 1000

		let date = new Date(timestamp)

		let DD = toTimeString(date.getDate())
		let MM = toTimeString(date.getMonth() + 1)
		let YYYY = date.getFullYear()
		let hh = toTimeString(date.getHours())
		let mm = toTimeString(date.getMinutes())
		let ss = toTimeString(date.getSeconds())

		return `${YYYY}-${MM}-${DD} ${hh}:${mm}:${ss}`
	}

	/**
	 * Gets updateResponseBase with injected timestamp or throws exceptions
	 * @param lastUpdateTimestamp
	 * @throws update not supported
	 * @throws cant get update
	 * @returns {Promise<Object>}
	 */
	static async handle (lastUpdateTimestamp) {
		let dateSince = this.fromUnixTimestamp(lastUpdateTimestamp)
		Logger.logOnce('Content Update Controller', 'Will try to get updates since:', dateSince)
		let updateResponseBase = await this.getDataLayerResponse(dateSince)
		this.requireSupport(updateResponseBase)
		this.requireSuccess(updateResponseBase)
		this.injectTimestamp(updateResponseBase)
		this.injectHasUpdates(updateResponseBase)
		return updateResponseBase
	}
}
