/* global __DEV__ */
import React, { Component, Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'
import RegistrationController from './registration.controller'
import RegistrationSimpleController from './registration-simple.controller'
import ConfirmationController from './confirmation.controller'
import routes from '../../utils/routes'
import dataLayer from '../../data-layer/dataLayer'
import {
	LOGIN_PROCESS_PA_APP,
	LOGIN_PROCESS_SIMPLE
} from '../../constants/names'
import CookieNoticeController from '../cookie-notice/cookie-notice.controller'

let DynamicRegistrationController = null

switch (dataLayer.loginProcess) {
	case LOGIN_PROCESS_PA_APP:
		DynamicRegistrationController = RegistrationController
		break
	case LOGIN_PROCESS_SIMPLE:
	default:
		DynamicRegistrationController = RegistrationSimpleController
}

class LoginIndex extends Component {
	render () {
		return (
			<Fragment>
				<Switch>
					<Route
						exact
						path={routes.loginConfirmation.build()}
						render={ (props) =>
							<ConfirmationController { ...props }/>
						}
					/>
					<Route
						render={ (props) => <DynamicRegistrationController { ...props }/> }
					/>
				</Switch>
				<CookieNoticeController/>
			</Fragment>
		)
	}
}

if (__DEV__) {
	LoginIndex.propTypes = {}
}

export default LoginIndex
