/* global localStorage */
import {
	DataLayerResponseLoginBasic, DataLayerResponseLoginCheckAuth
} from '../../../lib/DataLayerResponse'
import { CreateGlamusLoginModel } from '../lib/models'
import { Exception, LoginError } from '../../../../utils/errors'
import i18n from '../../../../i18n'
import { LoginProcess } from '../../../../utils/login-process'

const loginBasic = async function (username, password) {
	let success = true
	let token = ''

	let GlamusLogin = CreateGlamusLoginModel(username, password)

	let loginResult

	try {
		loginResult = await GlamusLogin.request()
	} catch (e) {
		throw new Exception(i18n.t('ERROR_LOGIN_REGISTRATION_GENERAL.label'))
	}

	/*
	 * accessGranted: true
	 * message: null
	 * token: "17c6df3daef0f05c8cc107c8eaae6cdf"
	 * username: "message-cockpit-test"
	 * validUntil: "2019-05-29"
	 */
	let data = loginResult.data

	if (!data.accessGranted) {
		success = false
		throw new LoginError(107, data.message)
	}

	token = data.token

	return new DataLayerResponseLoginBasic(
		false,
		success,
		token
	)
}

const loginCheckAuth = function () {
	let success = true
	let loggedIn = false
	let message = ''
	let offline = false

	/* authData does not exist -> login check failed */
	if (localStorage.getItem('authData') === null) {
		return new DataLayerResponseLoginCheckAuth(
			true,
			success,
			loggedIn,
			offline,
			message
		)
	}

	/* get auth data object from local storage */
	let authData = LoginProcess.getLocalAuthData()

	if (authData === null || !authData.token || !authData.username) {
		/* we do not have some valid auth datas -> force logout */
		LoginProcess.logout(true)

		return new DataLayerResponseLoginCheckAuth(
			true,
			success,
			loggedIn,
			offline,
			message
		)
	}

	loggedIn = true

	return new DataLayerResponseLoginCheckAuth(
		true,
		success,
		loggedIn,
		offline,
		message
	)
}

const loginRegister = function () {
}

const loginConfirm = function () {
}

const paAppLoginService = {
	loginBasic,
	loginCheckAuth,
	loginRegister,
	loginConfirm
}

export default paAppLoginService
