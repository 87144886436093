/* global alert */
import StorageManager from 'js-storage-manager'
import { QUEUE_NAMESPACE, MESSAGE_QUEUE_NAMESPACE, DAEMON_MESSAGE_QUE_NAME } from '../constants/names'
import * as types from '../constants/action-types'
import { daemonsService } from '../utils/daemon/DaemonsService'
import { messageQueDaemon } from '../utils/daemon/daemons/MessageQueDaemon'

export function syncMessageQueue () {
	let sm = new StorageManager(QUEUE_NAMESPACE)
	let qm = sm.initQueue(MESSAGE_QUEUE_NAMESPACE)
	let messages = qm.getAll()

	if (messages.length > 0) {
		messageQueDaemon.start()
	} else {
		messageQueDaemon.stop()
	}

	return {
		type: types.MESSAGE_QUEUE_SYNC,
		messages
	}
}

export function deleteMessageQueue () {
	let sm = new StorageManager(QUEUE_NAMESPACE)
	let messages = []

	/* delete message queue */
	sm.set(MESSAGE_QUEUE_NAMESPACE, messages)

	return {
		type: types.MESSAGE_QUEUE_SYNC,
		messages
	}
}

export function deleteMessageQueueItem (key) {
	let sm = new StorageManager(QUEUE_NAMESPACE)
	let messages = sm.get(MESSAGE_QUEUE_NAMESPACE)

	/* delete message with index (key) */
	messages.splice(key, 1)
	sm.set(MESSAGE_QUEUE_NAMESPACE, messages)

	return {
		type: types.MESSAGE_QUEUE_SYNC,
		messages
	}
}
