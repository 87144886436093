import { setNextScrollPosition } from '../actions/basic.actions'

const browserHistoryLocalListenerScrollPosition = {
	onBeforeUpdate: function (lastLocationEntry, dispatch) {
		if (lastLocationEntry) {
			lastLocationEntry.data.scrollTopWindow = window.scrollTop
			lastLocationEntry.data.scrollTopBody = document.body.scrollTop
		}
	},

	onAfterUpdate: function (currentLocationEntry, dispatch) {
		dispatch(
			setNextScrollPosition(
				currentLocationEntry.data.scrollTopWindow || 0,
				currentLocationEntry.data.scrollTopBody || 0
			)
		)
	}
}

export default browserHistoryLocalListenerScrollPosition
