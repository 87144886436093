/**
 * This class adds data that does not exist in the api already.
 * That data has to match the normalized state structure.
 */
import { DataModifierArticleDetailNormalized } from './data-modifier-article-detail-normalized'
import { APP_CONTENT_TYPE_ARTICLES } from '../../../../constants/names'

class DataModifierCategoryNormalized {
	/**
	 * Get default category.
	 *
	 * @param categoryApi
	 * @param sorting
	 * @returns {{image, topics: Array, sorting: number, image_bg, id: *, title: string}}
	 */
	static getDefaultElement (categoryApi, sorting = 0) {
		/* TODO: change strategy
		 *
		 * teaserImages: Array(2)
		 * 	 0: {width: 480, height: 380, url: "https://www.vfa.de/static/generated/35375-kategorie-01.jpg"}
		 *   1: {width: 640, height: 420, url: "https://www.vfa.de/static/generated/35376-kategorie-01.jpg"}
		 *	 length: 2
		 */
		let backgroundImage = require('../../../../assets/dummy-images/studie.jpg')
		if ('teaserImages' in categoryApi && Array.isArray(categoryApi.teaserImages) && categoryApi.teaserImages.length > 0) {
			let width = 0

			/* search for the largest picture */
			categoryApi.teaserImages.forEach((teaserImage) => {
				if (teaserImage.width > width) {
					backgroundImage = teaserImage.url
					width = teaserImage.width
				}
			})
		}

		return {
			id: categoryApi.categoryId,
			title: 'title' in categoryApi ? categoryApi.title : '',
			sorting: sorting,
			topics: [],
			image: backgroundImage,
			image_bg: backgroundImage
		}
	}

	/**
	 * Modify categories.
	 *
	 * @param data
	 * @param dataApi
	 */
	static addElements (data, dataApi) {
		data.categories = {}

		let sorting = 0

		/* This is a hack for a bug in the Glamus API: Convert a JS object to an array if necessary. */
		if (typeof(dataApi) === 'object' && !Array.isArray(dataApi)) {
			dataApi = Object.values(dataApi)
		}

		dataApi.forEach(function (categoryApi) {
			sorting++

			let category = DataModifierCategoryNormalized.getDefaultElement(categoryApi, sorting)

			if ('articleIds' in categoryApi) {
				category.topics = []

				categoryApi['articleIds'].forEach(function (articleId) {
					category.topics.push(DataModifierArticleDetailNormalized.getId({ id: articleId }, APP_CONTENT_TYPE_ARTICLES))
				})
			}

			data.categories[categoryApi.categoryId] = category
		})
	}
}

export { DataModifierCategoryNormalized }
