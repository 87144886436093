/* global __DEV__ */
/* global __FEATURE__CONSOLE_GROUP_COLLAPSED__ */

const fnNone = function () {}
const myConsole = window.console || {}

/**
 * Returns the first console function that is found by given names or a dummy.
 * The console functions will be bound to the console object.
 *
 * Why do we need to bind to console?
 * Console functions used to need console as a context.
 * This changed in some browsers already but not all browsers have adapted.
 * (e.g. IE)
 * @see https://www.tjvantoll.com/2015/12/29/console-error-bind/
 * @see https://bugs.webkit.org/show_bug.cgi?id=20141
 * @see https://bugs.chromium.org/p/chromium/issues/detail?id=167911
 *
 */
function getBoundConsoleFunc (...names) {
	let func = fnNone

	names.find((name) => {
		if (!myConsole[name]) {
			return false
		}

		// We can not rely on console functions being bindable. (e.g. IE 9)
		// @see https://stackoverflow.com/a/14233684
		try {
			func = myConsole[name].bind(myConsole)
		} catch (e) {
			// Create a wrapper function instead that accesses the console
			// function.
			func = function (...args) {
				// We can not rely on console functions being applyable.
				// (e.g. IE 9)
				// This is a problem because spreading into function calls gets
				// transpiled to use apply() instead.
				// Example:
				//   myConsole[name](...args)
				// gets transpiled to:
				//   myConsole[name].apply(myConsole, args);
				try {
					myConsole[name](...args)
				} catch (e) {
					// Log the arguments array directly instead.
					myConsole[name](args)
				}
			}
		}
		return true
	})

	return func
}

const LoggerConsole = {
	console: {
		log:	getBoundConsoleFunc('log'),
		error:	getBoundConsoleFunc('error'),
		group:	getBoundConsoleFunc('group', 'log'),
		groupCollapsed:	getBoundConsoleFunc('groupCollapsed', 'group', 'log'),
		groupEnd:	getBoundConsoleFunc('groupEnd')
	},
	log: (...args) => {
		LoggerConsole.console.log(...args)
	},
	error: (...args) => {
		LoggerConsole.console.error(...args)
	},
	group: (...args) => {
		__FEATURE__CONSOLE_GROUP_COLLAPSED__
			? LoggerConsole.console.groupCollapsed(...args)
			: LoggerConsole.console.group(...args)
	},
	groupEnd: () => {
		LoggerConsole.console.groupEnd()
	}
}

export default class Logger {
	constructor (componentName) {
		this.componentName = componentName.toUpperCase()
		this.mStart = this.componentName + ' ::: '
		this.groupDefined = false
	}

	startGroup () {
		if (__DEV__) {
			LoggerConsole.group(this.componentName)
			this.groupDefined = true
		}
	}

	endGroup () {
		if (__DEV__) {
			LoggerConsole.groupEnd()
			this.groupDefined = false
		}
	}

	/**
	 * Log function
	 * @param message
	 * @param arg
	 * @param args
	 */
	log (message, arg = '', ...args) {
		if (__DEV__) {
			if (this.groupDefined) {
				LoggerConsole.group(message)
			} else {
				LoggerConsole.group(this.mStart + message)
			}

			LoggerConsole.log('Arg:', arg)
			args.forEach((i) => {
				LoggerConsole.log('Other arg:', i)
			})
			LoggerConsole.groupEnd()
		}
	}

	/**
	 * Log function if no need in multiple log statements in one function
	 * @param componentName
	 * @param message
	 * @param arg
	 * @param args
	 */
	static logOnce (componentName, message, arg = '', ...args) {
		if (__DEV__) {
			// Build string
			LoggerConsole.group(componentName.toUpperCase() + ' ::: ' + message)
			LoggerConsole.log('Arg:', arg)
			args.forEach((i) => {
				LoggerConsole.log('Other arg:', i)
			})
			LoggerConsole.groupEnd()
		}
	}

	/**
	 * Error function if no need in multiple log statements in one function
	 * @param componentName
	 * @param message
	 * @param arg
	 * @param args
	 */
	static errorOnce (componentName, message, arg = '', ...args) {
		if (__DEV__) {
			// Build string
			LoggerConsole.group('%c' + componentName.toUpperCase() + ' ::: ' + message, 'color: red')
			LoggerConsole.error('Arg:', arg)
			args.forEach((i) => {
				LoggerConsole.error('Other arg:', i)
			})
			LoggerConsole.groupEnd()
		}
	}
}
