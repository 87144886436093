import { CONTENT_NAMES_WITH_FILES, FILES_PROPS } from '../../constants/names'

export default class FilesPreparator {
	constructor (rawData) {
		this.__rawData = rawData
		this.__dynamicFiles = []
		this.__staticFiles = []
	}

	__extractFilesIfTypeExist (cName) {
		if (this.__rawData.hasOwnProperty(cName)) {
			this.__extractFilesFromContentType(this.__rawData[cName])
		} else {
			console.warn('FILE MANAGER CONTROLLER', 'There are no such content type in rawData. Type:', cName)
		}
	}

	getDynamicFiles () {
		return this.__dynamicFiles
	}

	getStaticFiles () {
		return this.__staticFiles
	}

	// TODO: Here we can check is Static or is Dynamic file
	__appendFilesPathsToFiles (eRef) {
		if (eRef.image_bg !== undefined) {
			this.__staticFiles.push(eRef.image_bg)
		}

		if (eRef.image !== undefined) {
			this.__staticFiles.push(eRef.image)
		}

		if (eRef.files !== undefined) {
			eRef.files.map(file => this.__dynamicFiles.push(file.path))
		}
	}

	__extractFilesFromContentType (cRef) {
		Object.values(cRef).map(this.__appendFilesPathsToFiles.bind(this))
	}

	__isUrlToPdf (url) {
		return url.substring(url.length - 3) === 'pdf'
	}

	removeDynamicPdfs () {
		this.__dynamicFiles = this.__dynamicFiles.filter(url => !this.__isUrlToPdf(url))
	}

	handleExtractFiles () {
		try {
			CONTENT_NAMES_WITH_FILES.map(this.__extractFilesIfTypeExist.bind(this))
		} catch (e) {
			console.error(e)
		}
	}
}
