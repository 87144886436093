/* global __DEV__ */
import React, { Component, Fragment } from 'react'
import propTypes from 'prop-types'
import i18n from '../../../i18n'

class MessageQueueListItemView extends Component {
	/**
	 * Returns a notification about blocked message sending if applicable.
	 *
	 * @return {*}
	 */
	createBlockedMessageNotification () {
		const { blockedByPreviousError, error } = this.props

		/*
		 * If the message is not blocked, we do not need a warning.
		 */
		if (!blockedByPreviousError) {
			return null
		}

		/*
		 * If the message is erroneous on it's own, we won't state that it gets
		 * blocked by a previous one.
		 */
		if (error) {
			return null
		}

		return (
			<span className='email-notification email-notification--warning'>
				{i18n.t('TXT_MESSAGE_QUEUE_MESSAGE_BLOCKED_BY_PREVIOUS_ERROR.label')}
			</span>
		)
	}

	/**
	 * Returns a notification about failed message sending if applicable.
	 *
	 * @return {*}
	 */
	createSendingErrorNotification () {
		const { error, errorShowSkipButton, handleSkipMessageClick } = this.props
		/*
		 * If there is no error, we need no notification.
		 */
		if (!error) {
			return null
		}

		let skipButton = null
		if (errorShowSkipButton) {
			skipButton = (
				<button
					className='a-button a-button--small a-button--inline email-notification__button'
					onClick={handleSkipMessageClick}
				>
					{i18n.t('TXT_MESSAGE_QUEUE_REQUEUE_ERRONEOUS_MESSAGE.label')}
				</button>
			)
		}

		return (
			<span className='email-notification email-notification--error'>
				{i18n.t('TXT_MESSAGE_QUEUE_ERROR_MESSAGE_COULD_NOT_BE_SENT.label')}
				{skipButton}
			</span>
		)
	}
	/**
	 * Renders the component.
	 *
	 * return {*}
	 */
	render () {
		const { keyIndex, emailTo, emailSubject, emailMessage, handleRemove } = this.props

		return (
			<div className='a-list-item a-list-item--message-queue-list'>
				<span
					className='email-to'>An: {/* {keyIndex + 1}) */} {emailTo}</span>
				<span className='email-subject'>{emailSubject}</span>
				<span className='email-message'>{emailMessage.split('\n').map((item, key) => {
					return (
						<Fragment key={key}>{item}<br/></Fragment>
					)
				})}</span>
				{this.createSendingErrorNotification()}
				{this.createBlockedMessageNotification()}
				{/* <span className='ids'>{ids}</span> */}
				<button
					className={'email-delete'}
					data-cy="message-queue-list-item-delete"
					onClick={handleRemove.bind(this, keyIndex)}
				/>
				<div>&nbsp;</div>
			</div>
		)
	}
}

MessageQueueListItemView.defaultProps = {
	error: false,
	errorShowSkipButton: false,
	blockedByPreviousError: false
}

if (__DEV__) {
	MessageQueueListItemView.propTypes = {
		keyIndex: propTypes.number.isRequired,
		emailTo: propTypes.string.isRequired,
		emailSubject: propTypes.string.isRequired,
		emailMessage: propTypes.string.isRequired,
		ids: propTypes.string.isRequired,
		handleRemove: propTypes.func,
		error: propTypes.bool,
		errorShowSkipButton: propTypes.bool,
		blockedByPreviousError: propTypes.bool,
		handleSkipMessageClick: propTypes.func.isRequired
	}
}

export default MessageQueueListItemView
