import { CONTENT_STORAGE_NAMESPACE, CONTENT_OBJECT_KEY } from '../../constants/names'
import StorageManager from 'js-storage-manager'
import Logger from '../logger'

export default class ContentSaveController {
	constructor (store) {
		this.store = store
		let cS = new StorageManager(CONTENT_STORAGE_NAMESPACE)
		this.cS = cS
		this.content = null
	}

	__getContentFromStore () {
		return (this.store.getState()).content
	}

	__updateContent (content) {
		if (this.content !== content) {
			this.cS.set(CONTENT_OBJECT_KEY, content)
			this.content = content
			Logger.logOnce('Content Save Controller', 'Content in local storage saved', content)
		}
	}

	handleStorageUpdate () {
		this.__updateContent(this.__getContentFromStore())
	}

	subscribeStorageUpdateHandler () {
		this.store.subscribe(this.handleStorageUpdate.bind(this))
		Logger.logOnce('Content Save Controller', 'Subscribed on store updates', this.store)
	}
}
