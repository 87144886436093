import { ASSET_MANIFEST_URL, STATIC_CACHE_NAME, STATIC_FILES_ARRAY } from '../../../constants/names'

// This module is duplicated with adjusted imports from service worker
// TODO: Fix code duplication

export default class InstallationEvent {
	constructor () {
		this._staticCache = null
		this._urls = []
	}

	/**
	 * Gets static urls from assets-manifest json file.
	 * @param {String} assetManifestUrl - url of asset-manifest
	 * @param {Array} staticFilesArray - files to cache
	 * @returns {Promise<Object>} - returns urls ready to add to cache
	 */
	async getStaticUrls (assetManifestUrl, staticFilesArray) {
		let assetsResponse = await fetch(assetManifestUrl)
		let assets = await assetsResponse.json()
		let assetsArray = []
		for (let key in assets) {
			assetsArray.push(assets[key])
		}
		return { assetsArray, staticFilesArray }
	}

	async _openStaticCache () {
		this._staticCache = await caches.open(STATIC_CACHE_NAME)
	}

	async _cacheStatic () {
		this._staticCache.addAll(STATIC_FILES_ARRAY.map(url => new Request(url, { credentials: 'same-origin' })))
		this._staticCache.addAll(this._urls.map(url => new Request(url, { credentials: 'same-origin' })))
	}

	async handle (urls) {
		await this._openStaticCache()
		this._urls = urls
		await this._cacheStatic()
	}
}
