/* global __DEV__ */
import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import SearchController from './search.controller'
import routes from '../../utils/routes'
import NotFoundController from '../general/not-found.controller'

class SearchIndex extends Component {
	render () {
		// TODO : consider adding a SERP route like /search/<search term> that
		//  shows search results for that term.
		return (
			<Switch>
				<Route exact path={routes.search.path} component={SearchController}/>
				<Route component={NotFoundController} />
			</Switch>
		)
	}
}

if (__DEV__) {
	SearchIndex.propTypes = {}
}

export default SearchIndex
