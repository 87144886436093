import * as types from '../constants/action-types'

/**
 * Opens a new Modal.
 *
 * @param {string} title
 * @param {string} message
 * @param {object|null} actionConfirm
 * @param {object|null} actionCancel
 * @param {string} labelConfirm
 * @param {string} labelCancel
 * @return {{actionConfirm: object|null, labelCancel: string, actionCancel: object|null, type: string, title: string, message: string, labelConfirm: string}}
 */
export function createModal (title, message, actionConfirm = null, actionCancel = null, labelConfirm = '', labelCancel = '') {
	// Heads up!
	// This needs to be a thin action, because it will be passed to a callback
	// after creation. That callback will not fire immeadeatly, but after
	// interaction.
	// NO NESTED DISPATCHING HERE!
	return {
		type: types.MODAL_CREATE,
		title,
		message,
		actionConfirm,
		actionCancel,
		labelConfirm,
		labelCancel
	}
}

/**
 * Closes the current Modal.
 *
 * @return {{type: string}}
 */
export function closeModal () {
	return {
		type: types.MODAL_CLOSE
	}
}
