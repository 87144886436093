import Logger from '../logger'
import ContentUpdateController from './content-update-controller'
import UpdateMerger from './UpdateMerger'
import { injectDataUpdates, setTimestamp } from '../../actions/basic.actions'
import store from '../../store'

/**
 * Updater class as singleton. Is responsible for the update process.
 *
 * @author Björn Hempel <bjoern.hempel@ressourcenmangel.de>
 * @version 1.0 (2019-09-11)
 */
export default class Updater {
	/**
	 * Starts the update process (as singleton call Update.update()).
	 *
	 * @param dispatch
	 * @param content
	 * @param retrospectDays
	 * @param delayed
	 */
	static update (dispatch, content, retrospectDays, delayed) {
		Updater.__singletonReference.__update(dispatch, content, retrospectDays, delayed)
	}

	/**
	 * This method clears an already existing interval.
	 */
	static clearInterval () {
		Updater.__singletonReference.__clearInterval()
	}

	/**
	 * The constructor of this class.
	 */
	constructor () {
		Updater.__singletonReference = this
		Updater.__singletonReference.__setDispatch(null)
		Updater.__singletonReference.__setContent(null)
		Updater.__singletonReference.__setRetrospectDays(0)
		Updater.__singletonReference.__setIntervalTime(300)
		Updater.__singletonReference.__setIntervalDelay(10)
		Updater.__singletonReference.__setIntervalId(null)
		Updater.__singletonReference.__setDelayed(false)
	}

	/**
	 * Starts the update process (via instance window.updater).
	 *
	 * @param dispatch
	 * @param content
	 * @param retrospectDays
	 * @param delayed
	 * @returns {Promise<void>}
	 * @private
	 */
	async __update (/* dispatch, content, retrospectDays, delayed */) {
		let updateFunc = function () {
			this.__update()
		}

		/* collect all arguments and save the values to this class (according to its type) */
		for (let number in arguments) {
			let argument = arguments[number]
			switch (typeof argument) {
				case 'function':
					this.__setDispatch(argument)
					break

				case 'object':
					this.__setContent(argument)
					break

				case 'number':
					this.__setRetrospectDays(argument)
					break

				case 'boolean':
					this.__setDelayed(argument)
					break
			}
		}

		/* Check some conditions. */
		if (this.content === null || this.dispatch === null) {
			console.error('It is not possible to start the update process without having a dispatcher and the current content.')
			return
		}

		let l = new Logger('Data update action')
		l.startGroup()

		/* delayed update */
		if (this.delayed) {
			this.__setDelayed(false)
			this.__startTimeout(updateFunc.bind(this), this.delayTime)
			l.log('The update process is started with a delay.')
			l.endGroup()
			return
		}

		let contentUpdateBase = null

		/* Gets the update. */
		try {
			// Throws exception if success or supported is false
			// TODO: this.content.timestamp is not up-to-date -> use store.getState().content.timestamp instead (this is a hack!!)
			let timestamp = store.getState().content.timestamp

			l.log('Try to get updates from: ' + new Date((timestamp - this.retrospect) * 1000).toISOString())
			contentUpdateBase = await ContentUpdateController.handle(timestamp - this.retrospect) // Set parameter to 0 for test
		} catch (e) {
			console.error('ContentUpdateController.handle:', e)
			// TODO: Handle error for user
			l.endGroup()
			return
		}

		l.log('Received updates, contentUpdateBase:', contentUpdateBase)

		/* start updater interval */
		if (!this.intervalId) {
			this.__startInterval(updateFunc.bind(this))
		}

		/* Always set the timestamp (last update) */
		if (contentUpdateBase.timestamp) {
			this.dispatch(setTimestamp(contentUpdateBase.timestamp))
		}

		// Return if no updates
		if (contentUpdateBase.hasUpdates === false) {
			l.log('Has no updates')
			l.endGroup()
			return
		}

		// Merge updates into content
		try {
			let updateMerger = new UpdateMerger(contentUpdateBase.rawData, this.content)
			updateMerger.handleMerge()
		} catch (e) {
			console.warn(e)
			// TODO: Handle error for user
			l.endGroup()
			return
		}

		l.log('Merged updates into content, merged content:', this.content)
		l.endGroup()
		this.dispatch(injectDataUpdates(this.content))
	}

	/**
	 * Dispatch setter.
	 *
	 * @param dispatch
	 * @private
	 */
	__setDispatch (dispatch) {
		this.dispatch = dispatch
	}

	/**
	 * Content setter.
	 *
	 * @param content
	 * @private
	 */
	__setContent (content) {
		this.content = content
	}

	/**
	 * Retrospect days setter.
	 *
	 * @param retrospectDays
	 * @private
	 */
	__setRetrospectDays (retrospectDays) {
		let secondsDay = 86400
		this.retrospect = retrospectDays * secondsDay
	}

	/**
	 * Interval time setter.
	 *
	 * @param intervalTime
	 * @private
	 */
	__setIntervalTime (intervalTime) {
		this.intervalTime = intervalTime
	}

	/**
	 * Interval delay time setter.
	 *
	 * @param delayTime
	 * @private
	 */
	__setIntervalDelay(delayTime) {
		this.delayTime = delayTime
	}

	/**
	 * Interval id setter.
	 *
	 * @param intervalId
	 * @private
	 */
	__setIntervalId (intervalId) {
		this.intervalId = intervalId
	}

	/**
	 * Delayed setter.
	 *
	 * @param delayed
	 * @private
	 */
	__setDelayed (delayed) {
		this.delayed = delayed
	}

	/**
	 * This method starts an interval.
	 *
	 * @param func
	 * @param timeoutTime
	 * @private
	 */
	__startTimeout (func, timeoutTime) {
		let l = new Logger('Updater')
		l.startGroup()
		l.log(String('Start timeout %ss.').replace(/%s/, timeoutTime))
		l.endGroup()

		window.setTimeout(func, timeoutTime * 1000)
	}

	/**
	 * This method starts an interval.
	 *
	 * @param func
	 * @param intervalTime
	 * @private
	 */
	__startInterval (func, intervalTime) {
		if (this.intervalId) {
			this.__clearInterval()
		}

		let l = new Logger('Updater')
		l.startGroup()
		l.log(String('Start update interval %ss.').replace(/%s/, intervalTime || this.intervalTime))
		l.endGroup()

		this.intervalId = window.setInterval(func, this.intervalTime * 1000)
	}

	/**
	 * This method clears an already started interval.
	 *
	 * @private
	 */
	__clearInterval () {
		if (!this.intervalId) {
			return
		}

		let l = new Logger('Updater')
		l.startGroup()
		l.log('Clear update interval.')
		l.endGroup()

		window.clearInterval(this.intervalId)
	}
}

/* create singleton */
window.updater = new Updater()
