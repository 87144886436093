/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import connect from 'react-redux/lib/connect/connect'
import { mapAppStateToSearchInputControllerComponent } from '../../../utils/mappings'
import { doSearch } from '../../../actions/search.actions'
import { withRouter } from 'react-router'
import SearchInputViewMenu from './views/search-input-menu.view'
import SearchInputViewFull from './views/search-input-full.view'
import { openSideMenu } from '../../../actions/basic.actions'
import { SearchSuggestions } from '../../../utils/search'
import { SUGGESTION_AFTER } from '../../../constants/numbers'
import { history } from '../../../utils/browser-history'
import routes from '../../../utils/routes'

class SearchInputController extends Component {
	constructor (props) {
		super(props)
		this.state = {
			searchRequest: '',
			suggestions: [],
			suggestWord: ''
		}

		this.menuSearchButtonRef = React.createRef()
	}

	handleSearchChange (e) {
		this.setState({ searchRequest: e.target.value })
		if (e.target.value.length > 0 && e.target.value.length % SUGGESTION_AFTER === 0) {
			let suggestions =
				SearchSuggestions.buildSuggestions(this.props.content, e.target.value)
			this.setState({ suggestions })
		}
	}

	// noinspection JSMethodCanBeStatic
	handleEnterClickMenu (event) {
		if (event.keyCode === 13) {
			history.push(routes.search.build())
			this.handleSearchSubmit()
		}
	}

	// noinspection JSMethodCanBeStatic
	handleEnterClickPage (event) {
		if (event.keyCode === 13) {
			this.handleSearchSubmit()
		}
	}

	handleSearchSubmit () {
		if (this.props.callFromMenu) {
			this.props.dispatch(openSideMenu(false))
		}
		this.props.dispatch(doSearch(this.state.searchRequest, this.props.content))
	}

	componentDidMount () {
		this.setState({ searchRequest: this.props.searchRequest })
	}

	componentDidUpdate (prevProps, prevState, snapshot) {
		if (this.props.searchRequest !== prevProps.searchRequest) {
			this.setState({ searchRequest: this.props.searchRequest })
		}
	}

	render () {
		let callType = 'menu'
		if (!this.props.callFromMenu) {
			callType = 'full'
		}
		let suggestionsComponent = (
			<datalist className="m-search-box__suggestions" id={ 'search-word-suggestions-' + callType }>
				{ this.state.suggestions.map((suggestion, index) => {
					return (<option key={ index } value={ suggestion }/>)
				}) }
			</datalist>
		)
		if (this.props.callFromMenu) {
			return (
				<SearchInputViewMenu
					suggestionsComponent={ suggestionsComponent }
					searchRequest={ this.state.searchRequest }
					handleSearchChange={ this.handleSearchChange.bind(this) }
					handleSearchSubmit={ this.handleSearchSubmit.bind(this) }
					handleEnterClick={ this.handleEnterClickMenu.bind(this) }
				/>
			)
		} else {
			return (
				<SearchInputViewFull
					suggestionsComponent={ suggestionsComponent }
					searchRequest={ this.state.searchRequest }
					handleSearchChange={ this.handleSearchChange.bind(this) }
					handleSearchSubmit={ this.handleSearchSubmit.bind(this) }
					handleEnterClick={ this.handleEnterClickPage.bind(this) }
				/>
			)
		}
	}
}

if (__DEV__) {
	SearchInputController.propTypes = {
		callFromMenu: propTypes.bool,
		searchRequest: propTypes.string,
		content: propTypes.object,
		dispatch: propTypes.func.isRequired
	}
}

export default withRouter(connect(
	(state) => mapAppStateToSearchInputControllerComponent(state)
)(SearchInputController))
