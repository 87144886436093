/**
 * This is a workaround for our SliderController component that renders a
 * react-slick component.
 *
 * react-slick doesn't handle adaptiveHeight correctly for multiple sliders at
 * once.
 * @see https://github.com/akiran/react-slick/issues/1356
 *
 * Therefore we need to listen to image load events and trigger
 * adaptiveHeight handling manually. Since there is no API for that in
 * react-slick, we need to do it indirectly by triggering the window resize
 * event. The window resize event gets handled by react-slick and it's
 * adaptiveHeight handling.
 *
 * Limitations:
 * 1. Currently we only initialise image load listeners on slick callbacks.
 *    Should the content of a slide load an image on it's own - after
 *    initialisation of image load listeners - that image's load event won't
 *    be handled. The resize trigger workaround won't work for it.
 * 2. Triggering the window resize event may trigger event handlers of other
 *    components. It will do so definitely for other active react-slick
 *    components.
 *
 * TODO X-Browser-Testing
 * TODO Update react-slick and remove this HOC as soon as the issue got solved
 *      in react-slick.
 */

import React from 'react'
import hoistStatics from 'hoist-non-react-statics'
import propTypes from 'prop-types'

/**
 * Minimum delay between two triggers.
 *
 * @type {number}
 */
const MINIMUM_DELAY_FOR_ADAPTIVEHEIGHT_TRIGGER_MS = 500

/**
 * Minimum delay between two image load handler executions inside a HOC
 * instance.
 *
 * @type {number}
 */
const MINIMUM_DELAY_FOR_IMAGE_LOAD_HANDLING_MS = 20

/**
 * An helper array with all image urls that got marked as loaded by this HOC in
 * all it's instances.
 *
 * @type {Array}
 */
const loadedImageSources = []

/**
 * Flag: The global trigger is already scheduled.
 *
 * @type {boolean}
 */
let globalTriggerScheduled = false

/**
 * Triggers the adaptiveHeight calculation of react-slick by triggering a
 * window resize event.
 *
 * Do not call directly. Use the decoupled version instead.
 * (globallyTriggerAdaptiveHeightForSlick)
 */
function _doGloballyTriggerAdaptiveHeightForSlick () {
	// Mark trigger as not scheduled to allow succeeding issues.
	globalTriggerScheduled = false

	// Trigger window resize events.
	var resizeEvent = window.document.createEvent('UIEvents')
	resizeEvent.initUIEvent('resize', true, false, window, 0)
	window.dispatchEvent(resizeEvent)
}

/**
 * Decouples _doGloballyTriggerAdaptiveHeightForSlick to call once every
 * MINIMUM_DELAY_FOR_ADAPTIVEHEIGHT_TRIGGER_MS at maximum.
 *
 * We decouple this to prevent parallel calls by multiple sliders and quickly
 * succeeding calls by rerendered components.
 */
function globallyTriggerAdaptiveHeightForSlick () {
	// The trigger is already scheduled and will be executed soon.
	// Discard this call.
	if (globalTriggerScheduled) {
		return
	}

	// Mark trigger as scheduled to prevent multiple issues.
	globalTriggerScheduled = true
	// Schedule trigger.
	setTimeout(_doGloballyTriggerAdaptiveHeightForSlick, MINIMUM_DELAY_FOR_ADAPTIVEHEIGHT_TRIGGER_MS)
}

/**
 * Creates a composable function that creates a higher order component that
 * looks for img tags inside and triggers a window resize event when they are
 * first loaded.
 *
 * This outer method technically is not needed here. We keep it to have a
 * recurring HOC pattern that allows to pass additional parameters if needed.
 *
 * @return {function(*=)}
 */
var sliderAdaptiveHeightFix = function () {
	/**
	 * Composable function that creates the HOC.
	 */
	return function sliderAdaptiveHeightFix (WrappedComponent) {
		/**
		 * The actual HOC.
		 */
		class SliderAdaptiveHeightFix extends React.Component {
			/**
			 * Constructor.
			 * Creates refs, initialises helpers and binds callbacks.
			 *
			 * @param props
			 */
			constructor (props) {
				super(props)

				/**
				 * A react ref for our wrapperElement. The element is needed to
				 * search for other elements inside it. In our case these are
				 * img elements.
				 *
				 * @type {{current}}
				 */
				this.refWrapperElement = React.createRef()

				/**
				 * These are the img child elements that we currently have load
				 * event handlers attached to.
				 *
				 * @type {Array}
				 */
				this.imagesThatWeListenTo = []

				/**
				 * Flag: The handling for img load events is already triggered.
				 *
				 * @type {boolean}
				 */
				this.imageLoadHandlingScheduled = false

				/**
				 * This is an id string that contains a sorted list of all
				 * img urls that were marked as loaded when we issued the
				 * global trigger last.
				 *
				 * @type {Array}
				 */
				this.imagesThatWeTriggeredAdaptiveHeightForId = []

				// Provide bound methods for callbacks.
				this.handleImageLoaded = this._handleImageLoaded.bind(this)
				this.doHandleImageLoaded = this._doHandleImageLoaded.bind(this)
			}

			/**
			 * Component did mount.
			 * Update image load listeners to handle new images and issue
			 * global trigger to adapt height in case all images are known
			 * already, because there is still a race condition in that case.
			 */
			componentDidMount () {
				this.updateImageLoadListeners()
				this.triggerAdaptiveHeightForSlick()
			}

			/**
			 * Remove image load handlers to avoid memory leak.
			 */
			componentWillUnmount () {
				this.removeImageLoadListeners()
			}

			/**
			 * Returns all child img elements inside this component.
			 *
			 * @return {Array}
			 */
			getImages () {
				let images = []

				if (this.refWrapperElement.current) {
					images = Array.from(this.refWrapperElement.current.querySelectorAll('img'))
				}

				return images
			}

			/**
			 * Returns all child img elements inside this component that have
			 * not been marked as loaded yet.
			 *
			 * @return {Array}
			 */
			getNewImages () {
				return this.getImages()
					.filter(image => loadedImageSources.indexOf(image.src) === -1)
			}

			/**
			 * Returns all child img elements inside this component that have
			 * been marked as loaded already.
			 *
			 * @return {Array}
			 */
			getLoadedImages () {
				return this.getImages()
					.filter(image => loadedImageSources.indexOf(image.src) !== -1)
			}

			/**
			 * Handler for image load events.
			 *
			 * Do not use directly. Use the decoupled, bound version instead.
			 * (this.handleImageLoaded)
			 *
			 * @private
			 */
			_doHandleImageLoaded () {
				let images
				let newImageLoaded = false
				this.imageLoadHandlingScheduled = false

				// Get all images that have not been marked as loaded yet ...
				images = this.getNewImages()

				// ... and check if they are loaded now.
				// If so, set newImageLoaded to ...
				images.map(image => {
					if (image.complete) {
						loadedImageSources.push(image.src)
						newImageLoaded = true
					}
				})

				if (!newImageLoaded) {
					return
				}

				// ... issue the global trigger.
				this.triggerAdaptiveHeightForSlick()
			}

			// noinspection JSMethodCanBeStatic
			/**
			 * Decouples this._doHandleImageLoaded to call once every
			 * MINIMUM_DELAY_FOR_IMAGE_LOAD_HANDLING_MS at maximum.
			 *
			 * Do not use directly. Use the bound version instead.
			 * (this.handleImageLoaded)
			 *
			 * @private
			 */
			_handleImageLoaded () {
				// The handler is already scheduled and will be executed soon.
				// Discard this call.
				if (this.imageLoadHandlingScheduled) {
					return
				}

				// Mark handler as scheduled to prevent multiple issues.
				this.imageLoadHandlingScheduled = true
				// Schedule handler.
				setTimeout(this.doHandleImageLoaded, MINIMUM_DELAY_FOR_IMAGE_LOAD_HANDLING_MS)
			}

			// noinspection JSMethodCanBeStatic
			triggerAdaptiveHeightForSlick () {
				// Create the id string for all images that are currently
				// loaded.
				let loadedImagesId = this.getLoadedImages().map(image => image.src).sort().join(' ')

				// If that string matches the last one, we already triggered
				// the adaptive height fix. We can discard this call.
				if (loadedImagesId === this.imagesThatWeTriggeredAdaptiveHeightForId) {
					return
				}

				// Otherwise we store the new string to prevent unneccessary
				// subsequent executions ...
				this.imagesThatWeTriggeredAdaptiveHeightForId = loadedImagesId

				// ... and issue the global trigger.
				globallyTriggerAdaptiveHeightForSlick()
			}

			/**
			 * This removes all existing event listeners and adds new ones to
			 * all current images that are not marked as loaded yet.
			 */
			updateImageLoadListeners () {
				this.removeImageLoadListeners()
				this.addImageLoadListeners()
			}

			/**
			 * Removes all existing load event listeners to prevent memory leak.
			 */
			removeImageLoadListeners () {
				this.imagesThatWeListenTo.map(image => {
					if (!image) {
						return
					}
					image.removeEventListener('load', this.handleImageLoaded)
				})

				this.imagesThatWeListenTo = []
			}

			/**
			 * Adds load event listeners to every child img element that has
			 * a src url that has not been marked as loaded yet.
			 */
			addImageLoadListeners () {
				const images = this.getNewImages()

				this.imagesThatWeListenTo = images

				if (images.length === 0) {
					return
				}

				images.map(image => {
					image.addEventListener('load', this.handleImageLoaded)
				})
			}

			/**
			 * Callback for the slick slider. This needs to be executed
			 * everytime the slider (re-)initialises or loads new content.
			 * That way we can apply event listeners to new img elements.
			 */
			slickOnChange () {
				this.updateImageLoadListeners()
				this.triggerAdaptiveHeightForSlick()
			}

			/**
			 * Renders the HOC.
			 * This is our helper wrapperElement and the original
			 * WrappedComponent.
			 *
			 * @return {*}
			 */
			render () {
				const { wrappedComponentRef, ...passThroughProps } = this.props

				return (
					<div ref={this.refWrapperElement}>
						<WrappedComponent
							ref={wrappedComponentRef}
							{...passThroughProps}
							slickOnInit={this.slickOnChange.bind(this)}
							slickOnReInit={this.slickOnChange.bind(this)}
							slickOnLazyLoad={this.slickOnChange.bind(this)}
						/>
					</div>
				)
			}
		}

		// Add a display name to the HOC for more comfortable debugging.
		SliderAdaptiveHeightFix.displayName = 'sliderAdaptiveHeightFix(' + (WrappedComponent.displayName || WrappedComponent.name || 'Component') + ')'
		// Add the Wrapped component to allow external access to it.
		SliderAdaptiveHeightFix.WrappedComponent = WrappedComponent

		SliderAdaptiveHeightFix.propTypes = {
			wrappedComponentRef: propTypes.func
		}

		// Hoist statics of the WrappedComponent to make them accessible by
		// external code.
		hoistStatics(SliderAdaptiveHeightFix, WrappedComponent)

		return SliderAdaptiveHeightFix
	}
}

export default sliderAdaptiveHeightFix
