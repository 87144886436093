import { LoginProcess } from '../../../../utils/login-process'

/**
 * @method getLocalAuthData
 * @description Get local auth data as Object
 * @author Yahor Paromau <yahor.paromau@ressourcenmangel.de>
 * @returns {any}
 */
export function getLocalAuthData () {
	return LoginProcess.getLocalAuthData(false)
}

// https://github.com/uxitten/polyfill/blob/master/string.polyfill.js
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
if (!String.prototype.padStart) {
	String.prototype.padStart = function padStart (targetLength, padString) {
		targetLength = targetLength >> 0 // truncate if number, or convert non-number to 0;
		padString = String(typeof padString !== 'undefined' ? padString : ' ')
		if (this.length >= targetLength) {
			return String(this)
		} else {
			targetLength = targetLength - this.length
			if (targetLength > padString.length) {
				padString += padString.repeat(targetLength / padString.length) // append to original to ensure we are longer than needed
			}
			return padString.slice(0, targetLength) + String(this)
		}
	}
}

/**
 * Converts a timestamp to the Glamus API timestamp string format
 *
 * @param {number} ts
 * @return {string}
 */
export function timestampToGlamusDateTimeFormat (ts) {
	const date = new Date(ts)
	const fullYear = String(date.getFullYear()).padStart(4, '0')
	const month = String(date.getMonth() + 1).padStart(2, '0')
	const days = String(date.getDate()).padStart(2, '0')
	const hours = String(date.getHours()).padStart(2, '0')
	const minutes = String(date.getMinutes()).padStart(2, '0')
	const seconds = String(date.getSeconds()).padStart(2, '0')
	return `${fullYear}-${month}-${days} ${hours}:${minutes}:${seconds}`
}
