/* global __DEV__ */
import React, { Component, Fragment } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToSearchControllerComponent } from '../../utils/mappings'
import SearchListView from './views/search-list.view'
import SearchListEmptyView from './views/search-list-empty.view'
import SearchListItemView from './views/search-list-item.view'
import { GeneralUtils } from '../../utils/general.utils'
import { withPageContentSections } from '../pages/page-content.controller'
import { compose } from 'redux/lib/redux'
import withTitle from '../header/withTitle.hoc'
import commonPropTypes from '../../utils/common-prop-types'

class SearchController extends Component {
	/* componentWillUnmount () {
		this.props.dispatch(unsetSearch())
	} */

	render () {
		const { searchResult, searchRequest, isOnline } = this.props

		let numberSearchResults = Object.keys(searchResult).length

		if (numberSearchResults === 0 && searchRequest) {
			return (
				<SearchListEmptyView isOnline={isOnline} searchRequest={searchRequest} />
			)
		} else {
			return (
				<SearchListView isOnline={isOnline}>
					{ /* Iteration over content types */}
					{Object.keys(searchResult).map((type, keyIndex) => {
						return (
							<Fragment key={keyIndex}>
								{ /* Iteration over elements in content type */}
								{Object.keys(searchResult[type]).map((id, keyIndex) => {
									let item = searchResult[type][id]
									let linkTo = GeneralUtils.buildItemUrl(item, type)
									return (
										<SearchListItemView key={keyIndex} item={item} linkTo={linkTo}/>
									)
								})}
							</Fragment>
						)
					})}
				</SearchListView>
			)
		}
	}
}

if (__DEV__) {
	SearchController.propTypes = {
		searchRequest: propTypes.string,
		searchResult: propTypes.object,
		isOnline: propTypes.bool,

		...commonPropTypes.mixinFromReduxConnect
	}
}

export default compose(
	connect(mapAppStateToSearchControllerComponent),
	withPageContentSections,
	withTitle(null, 'TXT_SEARCH_TITLE.label')
)(SearchController)
