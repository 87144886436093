/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import {
	mapAppStateToNewsSliderControllerComponent
} from '../../utils/mappings'
import SliderController from '../general/slider/slider.controller'
import commonPropTypes from '../../utils/common-prop-types'

class NewsSliderController extends Component {
	_getSliderElementsFromProps () {
		return Object.values(this.props.sliderElements)
	}

	render () {
		// TODO load real news

		const sliderElements = this._getSliderElementsFromProps(this.props.sliderElements)

		return (
			<SliderController additionalClassName='m-slider--darken m-slider--extended-desktop-view' data={ sliderElements }/>
		)
	}
}

if (__DEV__) {
	NewsSliderController.propTypes = {
		dispatch: propTypes.func.isRequired,
		sliderElements: commonPropTypes.content.sliderElements.isRequired
	}
}

export default connect(
	(state, ownProps) => mapAppStateToNewsSliderControllerComponent(state, ownProps)
)(NewsSliderController)
