/* global __DEV__ */
import React, { Component } from 'react'
import commonPropTypes from '../../utils/common-prop-types'
import { setTransitionState } from '../../actions/transitions.actions'
import { TRANSITIONS } from '../../reducers/transitions.reducer'
import { connect } from 'react-redux'
import { mapAppStateToGenericControllerComponent } from '../../utils/mappings'
import propTypes from 'prop-types'

class PageController extends Component {
	constructor (props) {
		super(props)
		this.state = {}
	}

	static getDerivedStateFromProps ({ transitionState, transitionKey, dispatch, scrollTopBody }) {
		if (transitionState === TRANSITIONS.PAGE.STATES.ENTERING) {
			document.body.scrollTop = scrollTopBody
		}
		dispatch(setTransitionState(PageController.buildTransitionName(transitionKey), transitionState))
		return null
	}

	render () {
		let { children } = this.props

		return (
			<div className='page'>
				{ children }
			</div>
		)
	}
}

PageController.buildTransitionName = (transitionKey) => {
	return TRANSITIONS.PAGE.NAME + '__' + transitionKey
}

PageController.getTransitionStateByStateAndKey = (state, transitionKey) => {
	return state.transitions[PageController.buildTransitionName(transitionKey)]
}

if (__DEV__) {
	PageController.propTypes = {
		children: commonPropTypes.reactChildren,
		transitionState: propTypes.string,
		transitionKey: propTypes.string,
		scrollTopBody: propTypes.number.isRequired,

		dispatch: propTypes.func.isRequired
	}
}

export default connect(mapAppStateToGenericControllerComponent)(PageController)
