import React from 'react'

function AddAppToHomescreenView (/* props */) {
	return (
		<div className={'o-detail-view o-detail-view--no-tab'}>

			<h2 className="a-headline a-headline--h2">iOS - Safari</h2>
			<ol className="a-list">
				<li>Tippen Sie auf das Teilen-Symbol in der unteren Status Leiste</li>
				<li>In dieser Sektion finden Sie die Option »Zum Home-Bildschirm«.</li>
				<li>Definieren Sie in dem erscheinenden Dialog, wie die App auf dem Homescreen heißen soll und bestätigen Sie mit "Hinzufügen"</li>
			</ol>

			<h2 className="a-headline a-headline--h2">Android - Chrome</h2>
			<ol className="a-list">
				<li>Tippen Sie oben rechts auf die drei kleinen Punkte, um das Menü zu öffnen.</li>
				<li>Wählen Sie "Zum Startbildschirm hinzufügen" aus.</li>
				<li>Das Icon mit dem entsprechenden Webseiten-Link wird anschließend auf Ihrem Homescreen abgelegt.</li>
			</ol>
			<h2 className="a-headline a-headline--h2">Android - Firefox</h2>
			<ol className="a-list">
				<li>Tippen Sie oben rechts auf das Haus mit dem Plus.</li>
				<li>Wählen Sie "Zum Startbildschirm hinzufügen" aus.</li>
				<li>Bestätigen Sie die Auswahl durch Klick auf "Hinzufügen".</li>
				<li>Das Icon mit dem entsprechenden Webseiten-Link wird anschließend auf Ihrem Homescreen abgelegt.</li>
			</ol>

		</div>
	)
}

// Export component
export default AddAppToHomescreenView
