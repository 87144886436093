/* global __FEATURE__SERVICE_WORKER__ENABLED__ */

import ApplicationFeature from './application-feature'
import featureDetection from './feature-detection'
import i18n from '../i18n'

class SWFeature extends ApplicationFeature {
	constructor () {
		super(
			[
				featureDetection.hasServiceWorker,
				featureDetection.hasCacheApi,
				featureDetection.hasFetchApi
			],
			i18n.t('TXT_SW_UNSUPPORTED_MESSAGE.title'),
			i18n.t('TXT_SW_UNSUPPORTED_MESSAGE.text'),

			__FEATURE__SERVICE_WORKER__ENABLED__
		)
	}
}

SWFeature.featureName = 'service-worker'

export default SWFeature
