import i18next from 'i18next'

import translationEnGB from './locales/en_GB/translation.json'
import translationDeDE from './locales/de_DE/translation.json'

i18next
    .init({
        interpolation: {
            escapeValue: false
        },
        lng: 'de_DE',
        resources: {
            en_GB: {
                translation: translationEnGB
            },
            de_DE: {
                translation: translationDeDE
            }
        }
    })

export default i18next