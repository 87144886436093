import propTypes from 'prop-types'

class StateValidator {
	constructor (store, propTypes) {
		this.unsubscribe = null
		this.store = store
		this.propTypes = null

		this.setPropTypes(propTypes)
	}

	setPropTypes (propTypes) {
		this.propTypes = propTypes
	}

	validate (state, location) {
		state = state || this.store.getState()
		location = location || 'state'
		propTypes.checkPropTypes(this.propTypes, state, location, 'State')
	}

	listen () {
		this.unsubscribe && this.unsubscribe()
		this.unsubscribe = this.store.subscribe(this.validate.bind(this))
	}

	unlisten () {
		this.unsubscribe && this.unsubscribe()
		this.unsubscribe = null
	}
}

export default StateValidator
