/* global __DEV__ */
import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import PrivacyController from './privacy.controller'
import routes from '../../utils/routes'
import NotFoundController from '../general/not-found.controller'

class PrivacyIndex extends Component {
	render () {
		return (
			<Switch>
				<Route exact path={routes.privacy.path} component={PrivacyController}/>
				<Route component={NotFoundController} />
			</Switch>
		)
	}
}

if (__DEV__) {
	PrivacyIndex.propTypes = {}
}

export default PrivacyIndex
