/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { mapAppStateToGenericControllerComponent } from '../../utils/mappings'
import ContentElementImageView from './views/content-element-image.view'

class ContentElementImageController extends Component {
	render () {
		let { contentElement } = this.props

		return (
			<ContentElementImageView
				caption={contentElement.caption}
				images={contentElement.images}
				title={contentElement.title}
			/>
		)
	}
}

const imagesPropType = __DEV__ ? propTypes.arrayOf(
	propTypes.shape({
		url: propTypes.string.isRequired,
		width: propTypes.number,
		height: propTypes.number
	})
) : null

const imageInfoPropType = __DEV__ ? propTypes.shape({
	caption: propTypes.string,
	title: propTypes.string.isRequired,
	images: imagesPropType.isRequired
}) : null

if (__DEV__) {
	ContentElementImageController.propTypes = {
		contentElement: imageInfoPropType.isRequired
	}
}

export {
	imageInfoPropType,
	imagesPropType
}

export default connect(
	(state, ownProps) => mapAppStateToGenericControllerComponent(state, ownProps)
)(ContentElementImageController)
