import { OFFLINE_BODY_CLASS, ONLINE_BODY_CLASS } from '../constants/names'

export class BodyModifier {
	static getBodyConnectionClass () {
		if (document.body.classList.contains(ONLINE_BODY_CLASS)) {
			return ONLINE_BODY_CLASS
		}
		if (document.body.classList.contains(OFFLINE_BODY_CLASS)) {
			return OFFLINE_BODY_CLASS
		}
		return null
	}

	static connectionStatusToClass (isOnline) {
		return isOnline ? ONLINE_BODY_CLASS : OFFLINE_BODY_CLASS
	}

	static addBodyClass (bodyClass) {
		document.body.classList.add(bodyClass)
	}

	static removeBodyClass (oldBodyClass) {
		document.body.classList.remove(oldBodyClass)
	}

	static replaceBodyClass (oldBodyClass, newBodyClass) {
		BodyModifier.removeBodyClass(oldBodyClass)
		BodyModifier.addBodyClass(newBodyClass)
	}

	static syncConnectionStatus (isOnline) {
		let oldConnectionClass = BodyModifier.getBodyConnectionClass()
		let newConnectionClass = BodyModifier.connectionStatusToClass(isOnline)
		if (oldConnectionClass == null) {
			BodyModifier.addBodyClass(newConnectionClass)
		} else {
			BodyModifier.replaceBodyClass(oldConnectionClass, newConnectionClass)
		}
	}
}
