import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import NewsListController from './news-list.controller'
import NewsItemController from './news-item.controller'
import ArticleSendController from '../general/article-send.controller'
import routes from '../../utils/routes'
import NotFoundController from '../general/not-found.controller'

class NewsIndex extends Component {
	render () {
		return (
			<Switch>
				<Route exact path={routes.newsList.path}		component={NewsListController}/>
				<Route exact path={routes.newsDetail.path}		component={NewsItemController}/>
				<Route exact path={routes.newsDetailSend.path}	component={ArticleSendController}/>
				<Route component={NotFoundController} />
			</Switch>
		)
	}
}
export default NewsIndex
