/* global __DEV__ */
import React, { Component } from 'react'
import { LoginProcess } from '../../utils/login-process.js'
import RegistrationView from './views/registration.view'
import { Redirect } from 'react-router-dom'
import propTypes from 'prop-types'
import LoadingView from './views/loading.view'
import connect from 'react-redux/lib/connect/connect'
import { mapAppStateToRegistrationControllerComponent } from '../../utils/mappings'
import { setAuthDataSaveProgressStatus, setLoginError } from '../../actions/login-process.actions'
import i18n from 'i18next'
import '../../i18n'
import appEnv from '../../utils/app-env'
import routes from '../../utils/routes'
import { LOGIN_PROCESS } from '../../reducers/login-process.reducer'
import analytics from "../../analytics/analytics";

class RegistrationController extends Component {
	constructor (props) {
		super(props)
		this.state = {
			nameAndSurname: appEnv.devBuildOnly.registration.defaultNameAndSurname,
			email: appEnv.devBuildOnly.registration.defaultEmail,
			dprChecked: false,
			dprLayerOpened: false
		}
	}

	logError (message) {
		this.props.dispatch(setLoginError(true, message))
		this.props.dispatch(setAuthDataSaveProgressStatus(LOGIN_PROCESS.AUTH_DATA_SAVE_PROGRESS_STATUS.FALSE))
	}

	async handleRegistrationSubmit (e) {
		e.preventDefault()

		/* the dpr checkbox was not confirmed -> stop here */
		if (!this.state.dprChecked) {
			this.logError(i18n.t('TXT_LOGIN_MISSING_DPR_CONFIRMATION.label'))
			return
		}

		LoginProcess.generateUuid()
		this.props.dispatch(setAuthDataSaveProgressStatus(LOGIN_PROCESS.AUTH_DATA_SAVE_PROGRESS_STATUS.IN_PROGRESS))
		try {
			await LoginProcess.register(this.state.nameAndSurname, this.state.email)
			// Registration successful. Reset error status.
			this.props.dispatch(setLoginError(false, ''))
			this.props.dispatch(setAuthDataSaveProgressStatus(LOGIN_PROCESS.AUTH_DATA_SAVE_PROGRESS_STATUS.AUTH_DATA_SAVED))
		} catch (e) {
			// Registration failed. Set error.
			this.logError(e.message)
			console.error(e)
		}
	}

	handleNameAndSurnameChange (e) {
		this.setState({ nameAndSurname: e.target.value })
	}

	handleEmailChange (e) {
		this.setState({ email: e.target.value })
	}

	handleOpenDpr (e) {
		this.setState({ dprLayerOpened: true })
		// Custom Tracking
		analytics.track('open Privacy Layer')
	}

	handleCloseDpr (e) {
		this.setState({ dprLayerOpened: false })
	}

	handleSwitchDpr (e) {
		this.setState({ dprChecked: !this.state.dprChecked })
	}

	render () {
		const { authDataSaveProgressStatus } = this.props
		let { nameAndSurname, email, dprChecked, dprLayerOpened } = this.state

		if (authDataSaveProgressStatus === LOGIN_PROCESS.AUTH_DATA_SAVE_PROGRESS_STATUS.IN_PROGRESS) {
			return (<LoadingView/>)
		}

		if (authDataSaveProgressStatus === LOGIN_PROCESS.AUTH_DATA_SAVE_PROGRESS_STATUS.AUTH_DATA_SAVED) {
			return (
				<Redirect to={routes.loginConfirmation.build()}/>
			)
		}

		return (
			<RegistrationView
				email={email}
				nameAndSurname={nameAndSurname}
				handleEmailChange={this.handleEmailChange.bind(this)}
				handleNameAndSurnameChange={this.handleNameAndSurnameChange.bind(this)}
				handleRegistrationSubmit={this.handleRegistrationSubmit.bind(this)}
				handleOpenDpr={this.handleOpenDpr.bind(this)}
				handleCloseDpr={this.handleCloseDpr.bind(this)}
				handleSwitchDpr={this.handleSwitchDpr.bind(this)}
				dprChecked={dprChecked}
				dprLayerOpen={dprLayerOpened}
			/>
		)
	}
}

if (__DEV__) {
	RegistrationController.propTypes = {
		dispatch: propTypes.func.isRequired,
		authDataSaveProgressStatus: propTypes.string
	}
}

export default connect(mapAppStateToRegistrationControllerComponent)(RegistrationController)
