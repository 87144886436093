import dataLayerImplementation from '__DATA_LAYER__'
import DataLayerProxy from './dataLayerProxy'

/**
 * @file This file loads the currently active data layer implementation and checks if it fulfills the expected "interface" via DataLayerProxy.
 */

/**
 * Creates a DataLayerProxy for the active data layer implementation.
 * @type {DataLayerProxy}
 */
const dataLayerProxy = new DataLayerProxy(dataLayerImplementation)

/**
 * @param {DataLayerAuthData} authData
 * @param {bool} isInitial A flag that determines if a user logs in for the first time or not.
 * @return {DataLayerResponseBase}
 */
const getType = dataLayerProxy.getFunction('getType')

/**
 * @param {DataLayerAuthData} authData
 * @param {bool} isInitial A flag that determines if a user logs in for the first time or not.
 * @return {DataLayerResponseBase}
 */
const getArticlePreview = dataLayerProxy.getFunction('getArticlePreview')

/**
 * @param {DataLayerAuthData} authData
 * @param {bool} isInitial A flag that determines if a user logs in for the first time or not.
 * @return {DataLayerResponseBase}
 */
const getNewsPreview = dataLayerProxy.getFunction('getNewsPreview')

/**
 * @param {DataLayerAuthData} authData
 * @param {bool} isInitial A flag that determines if a user logs in for the first time or not.
 * @return {DataLayerResponseBase}
 */
const getPressReleasePreview = dataLayerProxy.getFunction('getPressReleasePreview')

/**
 * @param {DataLayerAuthData} authData
 * @param {bool} isInitial A flag that determines if a user logs in for the first time or not.
 * @return {DataLayerResponseBase}
 */
const getDataFull = dataLayerProxy.getFunction('getDataFull')

/**
 * @param {DataLayerAuthData} authData
 * @param {Object} content The current state.content. TODO refactor this -> We should only return updates here and do the merging in app logic.
 * @return {DataLayerResponseBase}
 */
const getDataUpdates = dataLayerProxy.getFunction('getDataUpdates')

/**
 * @param {DataLayerAuthData} authData
 * @return {DataLayerResponseBase}
 */
const getReadingList = dataLayerProxy.getFunction('getReadingList')

/**
 * @param {DataLayerAuthData} authData
 * @param {string} id
 * @return {DataLayerResponseBase}
 */
const addRLItem = dataLayerProxy.getFunction('addRLItem')

/**
 * @param {DataLayerAuthData} authData
 * @param {string} id
 * @param {number} ts last known timestamp of that reading list item
 * @return {DataLayerResponseBase}
 */
const removeRLItem = dataLayerProxy.getFunction('removeRLItem')

/**
 * @param {DataLayerAuthData} authData
 * @param {string} emailTo
 * @param {string} emailSubject
 * @param {string} emailMessage
 * @param {string|string[]} ids
 * @return {DataLayerResponseSendArticle}
 */
const sendArticle = dataLayerProxy.getFunction('sendArticle')

/**
 * @var {boolean}
 */
const articleSendHasSubject = dataLayerProxy.getBoolean('articleSendHasSubject')

/**
 * @param {DataLayerAuthData} authData
 * @param {string} searchTerm
 * @return {DataLayerResponseSearchContent}
 */
const searchContent = dataLayerProxy.getFunction('searchContent')

/**
 * @param {DataLayerAuthData} authData
 * @return {DataLayerResponseLoginCheckAuth}
 */
const loginCheckAuth = dataLayerProxy.getFunction('loginCheckAuth')

/**
 * @param {string} nameAndSurname
 * @param {string} email
 * @param {Object} deviceParams
 * @return {DataLayerResponseLoginRegister}
 */
const loginRegister = dataLayerProxy.getFunction('loginRegister')

/**
 * @param {string} username
 * @param {string} password
 * @param {Object} deviceParams
 * @return {DataLayerResponseLoginBasic}
 */
const loginBasic = dataLayerProxy.getFunction('loginBasic')

/**
 * @param {string} email
 * @param {string} activationCode
 * @param {Object} deviceParams
 * @return {DataLayerResponseLoginConfirm}
 */
const loginConfirm = dataLayerProxy.getFunction('loginConfirm')

/**
 * @var {string}
 */
const loginProcess = dataLayerProxy.getString('loginProcess')

/**
 * HEADS-UP: This is the data layer proxy.
 *           The configuration under ./src/config defines which data layer is
 *           used. The relevant configuration property is `dataLayerPath`.
 *
 * @type {Object}
 */
const dataLayer = {
	getType,
	getArticlePreview,
	getNewsPreview,
	getPressReleasePreview,
	getDataFull,
	getDataUpdates,
	getReadingList,
	addRLItem,
	removeRLItem,
	sendArticle,
	articleSendHasSubject,
	searchContent,
	loginCheckAuth,
	loginRegister,
	loginBasic,
	loginConfirm,
	loginProcess
}

export default dataLayer
