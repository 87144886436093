import Reaction from './Reaction'
import { doSendMessageQueue } from '../../actions/article-send.actions'
import Logger from '../logger'

export default class MessageQueReaction extends Reaction {
	react (isOnline) {
		if (isOnline) {
			this.dispatch(doSendMessageQueue())
			Logger.logOnce('Message Que Reaction', 'Reaction executed, message que sent')
		}
	}
}
