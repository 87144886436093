import StorageManager from 'js-storage-manager'

const STORAGE_NAME_COOKIE_NOTICE = 'vfa-cookies'
const STORAGE_KEY_COOKIE_NOTICE_ACCEPTANCE = 'acceptance'

const COOKIE_NOTICE_ACCEPTANCE_UNSET	= null
const COOKIE_NOTICE_ACCEPTANCE_ACCEPTED	= true
const COOKIE_NOTICE_ACCEPTANCE_DECLINED	= false

const COOKIE_NOTICE_VISIBILITY_OPEN		= true
const COOKIE_NOTICE_VISIBILITY_CLOSED	= false

const smCookieNotice = new StorageManager(STORAGE_NAME_COOKIE_NOTICE)

class CookieNotice {
	/**
	 * Writes information to local storage: cookies accepted
	 */
	static unset () {
		smCookieNotice.set(STORAGE_KEY_COOKIE_NOTICE_ACCEPTANCE, COOKIE_NOTICE_ACCEPTANCE_UNSET)
	}

	/**
	 * Writes information to local storage: cookies declined
	 */
	static accept () {
		smCookieNotice.set(STORAGE_KEY_COOKIE_NOTICE_ACCEPTANCE, COOKIE_NOTICE_ACCEPTANCE_ACCEPTED)
	}

	/**
	 * Writes information to local storage: cookie acceptance unset
	 */
	static decline () {
		smCookieNotice.set(STORAGE_KEY_COOKIE_NOTICE_ACCEPTANCE, COOKIE_NOTICE_ACCEPTANCE_DECLINED)
	}

	/**
	 * Reads cookie acceptance from local storage and derives visibility for notice
	 * @return {{acceptance: null|boolean, visibility: boolean}}
	 */
	static getState () {
		const acceptance = smCookieNotice.get(STORAGE_KEY_COOKIE_NOTICE_ACCEPTANCE)
		let visibility

		switch (acceptance) {
			case COOKIE_NOTICE_ACCEPTANCE_ACCEPTED:
			case COOKIE_NOTICE_ACCEPTANCE_DECLINED:
				visibility = COOKIE_NOTICE_VISIBILITY_CLOSED
				break
			case COOKIE_NOTICE_ACCEPTANCE_UNSET:
			default:
				visibility = COOKIE_NOTICE_VISIBILITY_OPEN
				break
		}

		return {
			acceptance,
			visibility
		}
	}

	/**
	 * Flag: cookies got accepted
	 *
	 * @return {boolean}
	 */
	static get accepted () {
		const state = CookieNotice.getState()
		return state.acceptance === COOKIE_NOTICE_ACCEPTANCE_ACCEPTED
	}

	/**
	 * Flag: cookie notice can be hidden
	 *
	 * @return {boolean}
	 */
	static get closed () {
		const state = CookieNotice.getState()
		return state.visibility === COOKIE_NOTICE_VISIBILITY_CLOSED
	}
}

export default CookieNotice
