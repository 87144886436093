/* global __DEV__ */
import React from 'react'
import { Link } from 'react-router-dom'
import propTypes from 'prop-types'

function CategoryListItemView (props) {
	const { category, keyIndex, stylesObject, link, updatesQuantity } = props

	return (
		<div key={keyIndex} className='m-teaser' style={stylesObject}>
			<Link to={link}>
				<span className="m-teaser__title">{category.title}</span>
				<span className={'category-updates-quantity'}>{updatesQuantity || ''}</span>
			</Link>

		</div>
	)
}

if (__DEV__) {
	CategoryListItemView.propTypes = {
		category: propTypes.object,
		keyIndex: propTypes.number,
		stylesObject: propTypes.object,
		link: propTypes.string,
		updatesQuantity: propTypes.number.isRequired
	}
}

export default CategoryListItemView
