import Reaction from './Reaction'
import Logger from '../logger'
import { syncReadingList } from '../../actions/reading-list.actions'

/**
 * Reaction to online status change for ReadingList.
 */
export default class ReadingListReaction extends Reaction {
	/**
	 * Reaction handler.
	 *
	 * @param {boolean} isOnline
	 */
	react (isOnline) {
		if (isOnline) {
			this._triggerReadingListSync()
		}
	}

	/**
	 * Triggers synchronisation of the reading list.
	 *
	 * @private
	 */
	_triggerReadingListSync () {
		this.dispatch(syncReadingList())
		Logger.logOnce('ReadingListReaction', 'Reading List Sync triggered.')
	}
}
