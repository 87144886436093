/* global __DEV__ */
import React from 'react'
import i18n from 'i18next'
import '../../../i18n'

export function ReadingListEmptyView (props) {
	return (
		<div className="container o-list-view o-list-view--reading-list">
			<div className="m-list-item-wrapper">
				<p>{i18n.t('TXT_READING_LIST_EMPTY_LIST.label')}</p>
			</div>
		</div>
	)
}

if (__DEV__) {
	ReadingListEmptyView.propTypes = {}
}

export default ReadingListEmptyView
