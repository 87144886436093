import React from 'react'
import DevMoleculesView from './views/dev-molecules.view'
import { compose } from 'redux/lib/redux'
import withTitle from '../header/withTitle.hoc'

function DevMoleculesController (/* props */) {
	return (
		<DevMoleculesView/>
	)
}

export default compose(
	withTitle(null, 'TXT_DEV_MOLECULES_TITLE.label')
)(DevMoleculesController)
