import React, { Fragment } from 'react'
import hoistStatics from 'hoist-non-react-statics'
import propTypes from 'prop-types'
import { loadEntity } from '../../actions/content.actions'
import { connect } from 'react-redux'
import { mapAppStateToLoadableHOC } from './../../utils/mappings'
import LoadableDummyLoaderView from './views/loadable-dummy-loader.view'
import LoadableLoaderView from './views/loadable-loader.view'
import { compose } from 'redux/lib/redux'

var loadable = function (contentKey, entityProp, idProp = 'id', hideStaticsForDummy = ['renderContentHeader', 'renderContentFooter']) {
	return function loadable (WrappedComponent) {
		class Loadable extends React.Component {
			constructor (props) {
				super(props)
				props.dispatch(loadEntity(contentKey, props[idProp]))
			}

			render () {
				const { wrappedComponentRef, loadableInfo, ...passThroughProps } = this.props
				let loader = null

				if (loadableInfo.isDummy) {
					loader = <LoadableDummyLoaderView/>
				} else if (loadableInfo.isLoading) {
					loader = <LoadableLoaderView/>
				}

				return (
					<Fragment>
						{loader}
						{!loadableInfo.isDummy && <WrappedComponent ref={wrappedComponentRef} {...passThroughProps}/>}
					</Fragment>
				)
			}
		}

		Loadable.displayName = 'loadable(' + (WrappedComponent.displayName || WrappedComponent.name || 'Component') + ')'
		Loadable.WrappedComponent = WrappedComponent
		Loadable.propTypes = {
			wrappedComponentRef: propTypes.func,
			loadableInfo: propTypes.exact({
				isDummy: propTypes.bool.isRequired,
				isLoading: propTypes.bool.isRequired
			}).isRequired,

			dispatch: propTypes.func.isRequired
		}

		hoistStatics(Loadable, WrappedComponent)

		hideStaticsForDummy.forEach((funcName) => {
			Loadable[funcName] = (props) => {
				const entity = props[entityProp]
				const func = WrappedComponent[funcName]
				if (!entity || entity.isDummy) {
					return null
				}

				return func ? func(props) : null
			}
		})

		return compose(
			connect((state, ownProps) => mapAppStateToLoadableHOC(state, ownProps, entityProp))
		)(Loadable)
	}
}

export default loadable
