import { Exception } from '../errors'
import { asSequence } from 'sequency'

export default class UpdateMerger {
	constructor (updates, content) {
		this.__requireParams(updates, content)
		this.updates = updates
		this.content = content
	}

	__requireParams (updates, content) {
		if (updates === undefined || updates === null) {
			throw new Exception('UPDATE MERGER :: Updates must not be undefined or null!')
		}
		if (content === undefined || content === null) {
			throw new Exception('UPDATE MERGER :: Content must not be undefined or null!')
		}
	}

	__requireContentType (type) {
		if (!this.content.hasOwnProperty(type)) {
			throw new Exception('DATA UPDATE MERGE :: ' + 'There are content type in update, that is not implemented yet: ' + type)
		}
	}

	__requireTopicCategory (categoryId) {
		if (categoryId === null || categoryId === undefined) {
			throw new Exception('UPDATE MERGER :: Category of topic is undefined or null!')
		}
		if (!this.content.categories.hasOwnProperty(categoryId)) {
			throw new Exception('DATA UPDATE MERGE :: ' + 'There are no such category in content.categories: ' + categoryId)
		}
	}

	mergeObjects (target, source) {
		// Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
		for (let key of Object.keys(source)) {
			if (source[key] instanceof Object) {
				Object.assign(source[key], this.mergeObjects(target[key], source[key]))
			}
		}

		// Join `target` and modified `source
		Object.assign(target || {}, source)

		return target
	}

	/**
	 * Merges item of some content type into content
	 * @param updatedItem
	 * @param this.content
	 * @param updatedItemId
	 * @param contentType
	 */
	mergeItem (updatedItem, updatedItemId, contentType) {
		this.__requireContentType(contentType)

		let itemExists = contentType in this.content && updatedItemId in this.content[contentType]

		if (itemExists) {
			this.content[contentType][updatedItemId] = this.mergeObjects(this.content[contentType][updatedItemId], updatedItem)
		} else {
			this.content[contentType][updatedItemId] = updatedItem
		}
	}

	__increaseUpdateQuantity (type) {
		this.content.updatesInformation[type].quantity += 1
	}

	__topicSpecificIncreaseUpdateQuantity (topic, updatedItemId) {
		let categoriesId = topic.category
		let topicQuantities = this.content.updatesInformation.categories.topicQuantities
		categoriesId.forEach(id => {
			this.__requireTopicCategory(id)
			if (!topicQuantities.hasOwnProperty(id)) {
				topicQuantities[id] = 0
			}
			this.content.updatesInformation.categories.topicQuantities[id] += 1
		})
	}

	__isNullOrUndefined (x) {
		return x === null || x === undefined
	}

	__handleTopicCategoryAbsent (topic) {
		// Return if topic category is defined
		if (!this.__isNullOrUndefined(topic.category)) {
			return
		}

		// Check current content has category of this topic
		let categories = asSequence(Object.values(this.content.categories))
			.filter(c => c.topics.includes(topic.id))
			.map(c => c.id)
			.toList()

		// If null -> check updates has category of this topic
		if ((categories === null || categories.length === 0) && this.updates.hasOwnProperty('categories')) {
			categories = asSequence(Object.values(this.content.categories))
				.filter(c => c.topics.includes(topic.id))
				.map(c => c.id)
				.toList()
		}

		// Category of topic not found => warn, this topic is not accessible
		if (categories.length === 0) {
			console.warn('DATA UPDATE MERGE :: ' + 'This topic is not assigned to any category', topic)
		}

		// If some error and categories is null or undefined
		if (this.__isNullOrUndefined(categories)) {
			console.warn(topic)
			throw new Exception('DATA UPDATE MERGE :: ' + 'Error in searching of topic categories')
		}

		topic.category = categories
	}

	/**
	 * Sets isUpdated flags and updatesQuantities
	 * @param updatedItemId
	 * @param updatedItem
	 * @param contentType
	 */
	setUpdateMarker (updatedItem, updatedItemId, contentType) {
		/* some allowed type elements */
		let allowedContentTypes = ['topics', 'news', 'pressReleases']

		/* if we do have an element that is not allowed → cancel */
		if (allowedContentTypes.indexOf(contentType) < 0) {
			return
		}

		/* element was already increased → cancel */
		if (this.content[contentType][updatedItemId].isUpdated) {
			return
		}

		// Topic update
		if (contentType === 'topics') {
			// TODO: Temporary until category null fix
			this.__handleTopicCategoryAbsent(updatedItem)
			this.__topicSpecificIncreaseUpdateQuantity(updatedItem, updatedItemId)
		}

		this.content[contentType][updatedItemId].isUpdated = true
		this.__increaseUpdateQuantity(contentType)
	}

	/**
	 * Merges this.updates into this.content
	 */
	handleMerge () {
		for (let update in this.updates) {
			// Go through items of type
			for (let updatedItemId in this.updates[update]) {
				let updatedItem = this.updates[update][updatedItemId]

				this.mergeItem(updatedItem, updatedItemId, update)
				this.setUpdateMarker(updatedItem, updatedItemId, update)
			}
		}
	}
}
