import { DataModifierArticleDetailNormalized } from './data-modifier-article-detail-normalized'
import { DataModifierCategoryNormalized } from './data-modifier-category-normalized'
import { DataModifierSliderNormalized } from './data-modifier-slider-normalized'
import { DataModifierStakeholderNormalized } from './data-modifier-stakeholder-normalized'
import {
	APP_CONTENT_TYPE_NEWS,
	APP_CONTENT_TYPE_PRESS_RELEASES
} from '../../../../constants/names'

/**
 * This class adds data that does not exist in the api already.
 * That data has to match the normalized state structure.
 */
class DataModifierArticleAllNormalized {

	/**
	 * Transform api data to internal data structure.
	 *
	 * @param data
	 * @param dataApi
	 */
	static addGroups (data, dataApi) {
		/* add categories */
		if ('category' in dataApi) {
			DataModifierCategoryNormalized.addElements(data, dataApi.category)
		}
		if ('categories' in dataApi) {
			DataModifierCategoryNormalized.addElements(data, dataApi.categories)
		}

		/* add articles */
		if ('article' in dataApi) {
			DataModifierArticleDetailNormalized.addElements(data, dataApi.article)
		}

		/* add news */
		if ('news' in dataApi) {
			DataModifierArticleDetailNormalized.addElements(data, dataApi.news, APP_CONTENT_TYPE_NEWS)
		}

		/* add press releases */
		if ('pressrelease' in dataApi) {
			DataModifierArticleDetailNormalized.addElements(data, dataApi.pressrelease, APP_CONTENT_TYPE_PRESS_RELEASES)
		}

		/* add news slider */
		if ('slider' in dataApi) {
			DataModifierSliderNormalized.addElements(data, dataApi.slider)
		}

		/* add stakeholder */
		if ('person' in dataApi) {
			DataModifierStakeholderNormalized.addElements(data, dataApi.person)
		}

		/* fix category assignments */
		if ('categories' in data) {
			DataModifierArticleAllNormalized.fixCategoryAssignments(data)
		}
	}

	/**
	 * Fixes the category assignments within all topic elements.
	 *
	 * @param data
	 */
	static fixCategoryAssignments (data) {
		for (let categoryId in data.categories) {
			let category = data.categories[categoryId]

			category.topics.forEach((topicId) => {
				if (topicId in data.topics) {
					if (data.topics[topicId].category === null) {
						data.topics[topicId].category = []
					}

					data.topics[topicId].category.push(categoryId)
				}
			})
		}
	}
}

export { DataModifierArticleAllNormalized }
