import * as types from '../constants/action-types'
import { LoginProcess } from '../utils/login-process'
import { history } from '../utils/browser-history'
import { dataPreparation, openSideMenu } from './basic.actions'
import routes from '../utils/routes'
import { createModal } from './modal.actions'
import { LOGIN_PROCESS } from '../reducers/login-process.reducer'
import i18n from '../i18n'

/**
 * Writes error status to state and opens a modal if an error occurred.
 *
 * @param status
 * @param errorMessage
 * @return {Function}
 */
export function setLoginError (status, errorMessage) {
	return function (dispatch) {
		dispatch(writeLoginError(status, errorMessage))
		if (status) {
			dispatch(createModal(
				i18n.t('ERROR_LOGIN_REGISTRATION_ERROR_HEADING.label'),
				errorMessage
				// We do not reset error state on confirmation.
				// Maybe another component wants to render based on that.
				// Instead the login process should reset on successful login.
			))
		}
	}
}

/**
 * Writes error status to state.
 *
 * TODO
 *   With the switch from login specific AlertController to ModalController,
 *   this action does not trigger anything anymore.
 *   Can we remove it or should we keep error state for other purpose?
 *
 * @param status
 * @param errorMessage
 * @return {{type: string, error: {errorMessage: *, status: *}}}
 */
function writeLoginError (status, errorMessage) {
	return {
		type: types.SET_LOGIN_ERROR,
		error: { status, errorMessage }
	}
}

/**
 * @description Adds auth data to global store
 * @param authData
 * @returns {{type: string, authData: *}}
 */
export function addAuthData (authData) {
	return {
		type: types.ADD_AUTH_DATA,
		authData
	}
}

/**
 * @description Remembers the given path
 * @param path
 * @returns {{type: string, path: *}}
 */
export function setRememberPath (path) {
	return {
		type: types.REMEMBER_PATH,
		path
	}
}

/**
 * @description Handles logout
 * @returns {Function}
 */
export function logout () {
	return async function (dispatch) {
		// Close side menu, just in case we logout while side-menu is open.
		dispatch(openSideMenu(false))
		LoginProcess.logout()
		dispatch(addAuthData({ loggedIn: false }))
		dispatch(setAuthDataSaveProgressStatus(LOGIN_PROCESS.AUTH_DATA_SAVE_PROGRESS_STATUS.FALSE))
	}
}

/**
 * @description Submits confirmation code
 * @param activationCode
 * @returns {Function}
 */
export function confirmationSubmit (activationCode) {
	return async function (dispatch) {
		try {
			// Login mounted yet
			let authData = await LoginProcess.confirm(activationCode)

			// Confirmation successful. Reset error status.
			dispatch(setLoginError(false, ''))
			dispatch(addAuthData(authData))
			// Login unmounted

			// Go to start page
			history.push(routes.root.build())
			// Reset local history. After Leaving LoginIndex we are not able and
			// don't want to go back there. (unless logging out of course)
			history.localHistory && history.localHistory.reset()

			// Load App
			dispatch(dataPreparation())
		} catch (e) {
			console.error(e)
			// Confirmation failed. Set error.
			dispatch(setLoginError(true, e.message))
			dispatch(setAuthDataSaveProgressStatus(LOGIN_PROCESS.AUTH_DATA_SAVE_PROGRESS_STATUS.FALSE))
		}
	}
}

/**
 * @description Submits login credentials
 * @param {string} username
 * @param {string} password
 * @returns {Function}
 */
export function loginSubmit (username, password) {
	return async function (dispatch) {
		try {
			username = username.trim()

			let authData = await LoginProcess.login(username, password)

			// Registration successful. Reset error status.
			dispatch(setLoginError(false, ''))
			dispatch(setAuthDataSaveProgressStatus(LOGIN_PROCESS.AUTH_DATA_SAVE_PROGRESS_STATUS.AUTH_DATA_SAVED))
			dispatch(addAuthData(authData))

			// Load App
			dispatch(dataPreparation())

			// Go to start page
			history.push(routes.root.build())
			// Reset local history. After Leaving LoginIndex we are not able and
			// don't want to go back there. (unless logging out of course)
			history.localHistory && history.localHistory.reset()
		} catch (e) {
			// Registration failed. Set error.
			dispatch(setLoginError(true, e.message))
			dispatch(setAuthDataSaveProgressStatus(LOGIN_PROCESS.AUTH_DATA_SAVE_PROGRESS_STATUS.FALSE))
			console.error(e)
		}
	}
}

/**
 * @description Cancels confirmation
 * @returns {Function}
 */
export function confirmationCancel () {
	return async function (dispatch) {
		await dispatch(logout())

		history.push(routes.root.build())
	}
}

/**
 * @description Sets progress of saving auth data. Is needed to
 * control loading in Registration process
 * @param {string} progressStatus
 * @returns {{type: string, progressStatus: string}}
 */
export function setAuthDataSaveProgressStatus (progressStatus) {
	return {
		type: types.SET_AUTH_DATA_SAVE_PROGRESS_STATUS,
		progressStatus
	}
}
