/* global __DEV__ */
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import AddAppToHomescreenController from './addAppToHomescreen.controller'
import routes from '../../utils/routes'
import NotFoundController from '../general/not-found.controller'

function AddAppToHomescreenIndex (/* props */) {
	return (
		<Switch>
			<Route exact path={routes.addAppToHomescreen.path} component={AddAppToHomescreenController}/>
			<Route component={NotFoundController} />
		</Switch>
	)
}

if (__DEV__) {
	AddAppToHomescreenIndex.propTypes = {}
}

export default AddAppToHomescreenIndex
