import NotFoundController from '../components/general/not-found.controller'
import { CONTENT_TYPE_NEWS, CONTENT_TYPE_PRESSRELEASE, CONTENT_TYPE_TOPIC } from '../constants/names'
import TopicController from '../components/categories/category/topic/topic.controller'
import NewsItemController from '../components/news/news-item.controller'
import PressReleasesItemController from '../components/press-releases/press-releases-item.controller'

export default class ControllerUtils {
	/**
	 * This is a replacement for a controller factory.
	 *
	 * @param objectInformation
	 * @return {*}
	 */
	static getController (objectInformation) {
		if (objectInformation === null) {
			return NotFoundController
		}

		switch (objectInformation.type) {
			case CONTENT_TYPE_TOPIC:
				return TopicController

			case CONTENT_TYPE_NEWS:
				return NewsItemController

			case CONTENT_TYPE_PRESSRELEASE:
				return PressReleasesItemController

			default:
				return NotFoundController
		}
	}
}
