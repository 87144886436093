/* global __DEV__ */
import React, { Component, Fragment } from 'react'
import MainIndex from './components/main'
import HeaderController from './components/header/header.controller'
import LoginIndex from './components/login'
import propTypes from 'prop-types'
import { LoginProcess } from './utils/login-process.js'
import { Exception } from './utils/errors'
import { connect } from 'react-redux'
import { mapAppStateToAppComponent } from './utils/mappings'
import { withRouter } from 'react-router'
import {
	dataPreparation,
	dataPreparationPreview, setCmsPreview,
	setLoading,
	setSingleView,
	setSingleViewError
} from './actions/basic.actions'
import { addAuthData, setRememberPath } from './actions/login-process.actions'
import { history } from './utils/browser-history'
import routes from './utils/routes'
import ModalController from './components/general/modal.controller'
import registerServiceWorker, { updateServiceWorker } from './registerServiceWorker'
import { serviceWorkerInstalled } from './utils/sw-helpers'
import CookieNoticeController from './components/cookie-notice/cookie-notice.controller'
import FullscreenloaderView from './components/general/views/fullscreenloader.view'
import i18n from './i18n'
import { BodyModifier } from './utils/body-modifier'
import { PREVIEW_ROUTE_TOKEN_NAME } from './constants/names'
import NotFoundController from './components/general/not-found.controller'
import { SINGLE_VIEW_BODY_CLASS } from './constants/names'
import HelpButtonController from './components/login/help-button.controller'

// TODO: Make page titles logic

class App extends Component {
	/**
	 * @method handleLoginFlow
	 * @description Checks authorisation and depending on the proceeds further as registered user or
	 * not reg. user
	 * @returns {Promise<void>}
	 */
	async handleLoginFlow (reinitialize = false) {
		const { dispatch, match, matchedSingleViewRoute, matchedCmsPreviewRoute } = this.props

		const authData = await LoginProcess.checkAuthorisation()

		// If user requested a single view link
		if (matchedSingleViewRoute) {
			const singleViewToken = match.params[PREVIEW_ROUTE_TOKEN_NAME]
			dispatch(setSingleView(true))

			if (matchedCmsPreviewRoute) {
				dispatch(setCmsPreview(true))
			}

			if (authData.loggedIn) {
				dispatch(addAuthData(authData))
			}

			dispatch(setLoading(false))
			if (authData.loggedIn) {
				dispatch(dataPreparation(singleViewToken))
			} else {
				dispatch(dataPreparationPreview(singleViewToken))
			}
			return
		} else {
			dispatch(setSingleView(false))
			dispatch(setSingleViewError(false))
		}

		// If user is logged In
		if (authData.loggedIn) {
			dispatch(addAuthData(authData))
			if (!reinitialize) {
				dispatch(setLoading(false))
			}
			dispatch(dataPreparation())
			return
		}

		// If user is not logged in
		if (authData.loggedIn === false) {
			/* maybe remember path if we are not logged in and we do have one */
			dispatch(setRememberPath(history.location.pathname))
			history.replace(routes.loginRegistration.build())
			dispatch(addAuthData(authData))
			dispatch(setLoading(false))
			return
		}

		// If error on authorisation check
		if (authData.loggedIn === undefined) {
			throw new Exception('LoggedIn is undefined')
		}
	}

	async handleServiceWorkerRegistration () {
		if (!(await serviceWorkerInstalled())) {
			// SW is not installed. Install it.
			registerServiceWorker()
		} else {
			// SW is already installed.
			// Update it, if a new version is available.
			updateServiceWorker()
		}
	}

	/**
	 * Application has been started.
	 *
	 * @returns {Promise<void>}
	 */
	async componentDidMount () {
		this.handleServiceWorkerRegistration()
		await this.handleLoginFlow()
	}

	/**
	 * A component has been changed (page switch, etc.).
	 *
	 * @returns {Promise<void>}
	 */
	async componentDidUpdate () {
		const { matchedSingleViewRoute, singleView, dispatch } = this.props

		/* Perform some maintenance tasks when the singleView status changes. */
		if (matchedSingleViewRoute !== singleView) {
			dispatch(setLoading(true))
			await this.handleLoginFlow(false)
		}
	}

	render () {
		/*
		*
		* Process:
		* 1) Return App_Loading, while checking authorisation
		*   1.a) LoggedIn -> go to dataLoading ( 3) )
		*   1.b) Not LoggedIn -> go to <LoginIndex> ( 2) )
		* 2) Registration -> <LoginLoading> -> Confirmation -> <LoginLoading>
		*   2.a) Confirmation Success -> go to dataLoading ( 3) )
		*   2.b) Confirmation Fail -> error -> error handling in <LoginIndex>
		* 3) Data Loading ( function dataPreparation() )
		*    3.a) Data Loading success -> go to 4.a)
		*    3.b) TODO: Data Loading error -> go to 4.b)
		* 4) Return
		*    4.a) Content
		*    4.b) Data loading Error
		*
		* */

		const { dispatch, loading, authData, dataLoading, matchedSingleViewRoute, online, singleView, singleViewError, cmsPreview, rememberPath } = this.props

		BodyModifier.syncConnectionStatus(online)

		if (singleViewError) {
			return <NotFoundController hideDefaultLinks={!authData.loggedIn}/>
		}

		if (loading || matchedSingleViewRoute !== singleView) {
			return <FullscreenloaderView message={ i18n.t('TXT_GENERAL_APP_LOADING_INIT') }/>
		}

		/* set or remove singleView class */
		if (!authData.loggedIn && singleView || cmsPreview) {
			BodyModifier.addBodyClass(SINGLE_VIEW_BODY_CLASS)
		} else {
			BodyModifier.removeBodyClass(SINGLE_VIEW_BODY_CLASS)
		}

		if (!authData.loggedIn && !singleView) {
			return (
				<Fragment>
					<HelpButtonController />
					<LoginIndex/>
					<ModalController/>
				</Fragment>
			)
		}

		if (dataLoading) {
			return <FullscreenloaderView message={ i18n.t('TXT_GENERAL_APP_LOADING_DATA') }/>
		}

		/* redirect to path if given before login */
		if (rememberPath !== null) {
			dispatch(setRememberPath(null))
			history.push(rememberPath)
		}

		return (
			<Fragment>
				<HeaderController/>
				<MainIndex/>
				<ModalController/>
				<CookieNoticeController/>
			</Fragment>
		)
	}
}

App.defaultProps = {
	matchedSingleViewRoute: false,
	matchedCmsPreviewRoute: false
}

if (__DEV__) {
	App.propTypes = {
		history: propTypes.object,
		match: propTypes.object.isRequired,
		matchedSingleViewRoute: propTypes.bool,
		matchedCmsPreviewRoute: propTypes.bool,
		ApiInstance: propTypes.func,
		dispatch: propTypes.func.isRequired,
		authData: propTypes.object.isRequired,
		title: propTypes.string.isRequired,
		loading: propTypes.bool.isRequired,
		dataLoading: propTypes.bool,
		online: propTypes.bool,
		cmsPreview: propTypes.bool.isRequired,
		singleView: propTypes.bool.isRequired,
		singleViewError: propTypes.bool.isRequired
	}
}

export default withRouter(connect(mapAppStateToAppComponent)(App))
