import store from '../store'
import routes from './routes'

export class GeneralUtils {
	// Builds item url
	static buildItemUrl (item, type) {
		let url = ''
		switch (type) {
			case 'topics':
				url += routes.categoriesTopic.build(item.category, item.id)
				break
			case 'news':
				url += routes.newsDetail.build(item.id)
				break
			case 'pressReleases':
				url += routes.pressReleasesDetail.build(item.id)
				break
			case 'stakeholders':
				url += routes.stakeholdersDetail.build(item.id)
				break
			default:
				console.error('Item type ' + type + ' is not handled by buildItemUrl function', item)
				url += 'item-type-not-handled'
		}
		return url
	}

	static getTopicCategoryById (id) {
		return store.getState().content.topics[id].category
	}
}
