import React from 'react'
import HomeView from './views/home.view'
import { withPageContentSections } from '../pages/page-content.controller'
import NewsSliderController from '../news/news-slider.controller'
import { CategoryListControllerWithoutPageSections } from '../categories/category-list.controller'
import { compose } from 'redux/lib/redux'
import withTitle from '../header/withTitle.hoc'

function HomeController (/* props */) {
	return (
		<HomeView>

			<NewsSliderController/>
			<CategoryListControllerWithoutPageSections />

		</HomeView>
	)
}

export default compose(
	withPageContentSections,
	withTitle(null, 'TXT_PROJECT_HOME_TITLE.label')
)(HomeController)
