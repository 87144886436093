/* global __DEV__ */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'

class SideMenuElementView extends Component {
	render () {
		const { classNames, link, name, onClick, updatesQuantity, dataCy } = this.props

		let updatesIndicator = updatesQuantity > 0 && <span data-cy={dataCy + '-quantity'} className={'category-updates-quantity'}>{updatesQuantity || ''}</span>

		return (
			<li className="m-menu__element" data-cy={dataCy}>
				<Link data-cy={dataCy + '-link'} className={classNames} onClick={onClick} to={link}> {name} {updatesIndicator} </Link>
			</li>
		)
	}
}

if (__DEV__) {
	SideMenuElementView.propTypes = {
		classNames: propTypes.array,
		onClick: propTypes.func.isRequired,
		link: propTypes.string,
		name: propTypes.string,
		updatesQuantity: propTypes.number,
		isMeta: propTypes.bool,
		dataCy: propTypes.string
	}
}

export default SideMenuElementView
