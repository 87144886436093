/* global __DEV__ */
/* eslint-disable no-fallthrough */
import * as types from '../constants/action-types'
import propTypes from 'prop-types'
import commonPropTypes from '../utils/common-prop-types'
import Logger from '../utils/logger'

const name = 'basic'

/**
 * We use a function here to prevent passing an object reference.
 *
 * Otherwise this could happen:
 * 1. Use initialState reference for initialisation. state.foo is now a
 *    reference to initialState.foo if foo is an object or array.
 * 2. Some action sets state.foo.bar.
 *    This is where the problem occurs: initialState.foo.bar now has been
 *    changed.
 * 3. Some other action wants to reset state.foo by assigning initialState.foo.
 *    But instead of having the actual initial value of state.foo.bar it now has
 *    the value that got set in 2.)
 *
 * @return {Object}
 */
const getInitialState = () => {
	return {
		title: '',
		loading: true,
		dataLoading: true,
		layer: {
			offline: false,
			loader: false
		},
		articleSend: {
			error: {
				status: false,
				code: -1,
				message: ''
			},
			success: {
				status: false,
				code: -1,
				message: ''
			},
			formFields: {
				emailTo: '',
				emailSubject: '',
				emailMessage: ''
			}
		},
		sideMenuIsOpen: false,
		activeSectionMenuTab: '',
		nextScrollPosition: {
			scrollTopWindow: 0,
			scrollTopBody: 0
		},
		online: true,
		singleView: false,
		singleViewError: false,
		settings: {
			error: {
				status: false,
				code: -1,
				message: ''
			},
			success: {
				status: false,
				code: -1,
				message: ''
			},
			formFields: {
				emails: []
			}
		},
		cmsPreview: false,
		messageQueue: [],
		cookieNotice: {
			accepted: false,
			closed: false
		}
	}
}

const reducer = function (state = getInitialState(), action) {
	let newState = { ...state }
	switch (action.type) {
		case types.SET_LOADING:
			newState.loading = action.loading
			if (action.dataLoading !== undefined) {
				newState.dataLoading = action.dataLoading
			}
			return newState

		case types.SET_SINGLE_VIEW:
			newState.singleView = action.singleView
			return newState

		case types.SET_CMS_PREVIEW:
			newState.cmsPreview = action.cmsPreview
			return newState

		case types.SET_SINGLE_VIEW_ERROR:
			newState.singleViewError = action.singleViewError
			return newState

		case types.SET_TITLE:
			newState.title = action.title
			return newState

		case types.DATA_REQUEST_FAIL:
			newState.error = {
				status: true,
				type: 'DATA_REQUEST_ERROR',
				message: 'Failed to request data'
			}
			return newState

		case types.OPEN_SIDE_MENU:
			newState.sideMenuIsOpen = action.isOpen
			return newState

		case types.SEND_ARTICLE_ERROR:
			newState.articleSend.error = {
				status: true,
				message: action.message,
				code: action.code
			}
			return newState

		case types.SEND_ARTICLE_SUCCESS:
			newState.articleSend.success = {
				status: true,
				message: action.message,
				code: action.code
			}
			return newState

		case types.SEND_ARTICLE_RESET:
			let initialState = getInitialState()
			newState.articleSend.error = initialState.articleSend.error
			newState.articleSend.success = initialState.articleSend.success
			newState.articleSend.formFields = initialState.articleSend.formFields
			return newState

		case types.SEND_ARTICLE_UPDATE_FORM_FIELD:
			newState.articleSend.formFields[action.name] = action.value
			return newState

		case types.SECTIONMENU_SET_ACTIVE_TAB:
			newState.activeSectionMenuTab = action.sectionKey
			return newState

		case types.NEXT_SCROLL_POSITION:
			newState.nextScrollPosition.scrollTopWindow = action.scrollTopWindow
			newState.nextScrollPosition.scrollTopBody = action.scrollTopBody
			return newState

		case types.ENABLE_LAYER_LOADER:
			newState.layer.loader = true
			return newState

		case types.DISABLE_LAYER_LOADER:
			newState.layer.loader = false
			return newState

		case types.ENABLE_LAYER_OFFLINE:
			newState.layer.offline = true
			return newState

		case types.DISABLE_LAYER_OFFLINE:
			newState.layer.offline = false
			return newState

		case types.SET_ONLINE:
			newState.online = action.online
			return newState

		case types.MESSAGE_QUEUE_SYNC:
			newState.messageQueue = action.messages
			return newState

		case types.SETTINGS_UPDATE_CHECKBOX_FIELD:
			newState.settings.formFields.emails[parseInt(action.value)].active = !!action.checked
			return newState

		case types.SETTINGS_SET_EMAIL_CHECKBOX_FIELDS:
			newState.settings.formFields.emails = action.values
			return newState

		case types.SETTINGS_SET_STATUS:
			if (action.code === 200) {
				newState.settings.success = {
					code: action.code,
					message: action.message,
					status: true
				}
			} else {
				newState.settings.error = {
					code: action.code,
					message: action.message,
					status: true
				}
			}

			return newState

		case types.DEBUG_LOG:
			if (__DEV__) {
				Logger.logOnce('BASIC.REDUCER DEBUG_LOG', ...action.args)
			}
			return state

		case types.SYNC_COOKIE_NOTICE_STATE:
			newState.cookieNotice.accepted = action.accepted
			newState.cookieNotice.closed = action.closed
			return newState

		default:
			return state
	}
}

const reducerPropTypes = __DEV__ && propTypes.exact({
	title: propTypes.string.isRequired,
	loading: propTypes.bool.isRequired,
	dataLoading: propTypes.bool.isRequired,
	layer: propTypes.exact({
		offline: propTypes.bool.isRequired,
		loader: propTypes.bool.isRequired
	}).isRequired,
	articleSend: propTypes.exact({
		error: propTypes.exact({
			status: propTypes.bool.isRequired,
			code: propTypes.number.isRequired,
			message: propTypes.string.isRequired
		}).isRequired,
		success: propTypes.exact({
			status: propTypes.bool.isRequired,
			code: propTypes.number.isRequired,
			message: propTypes.string.isRequired
		}).isRequired,
		formFields: propTypes.exact({
			emailTo: propTypes.string.isRequired,
			emailSubject: propTypes.string.isRequired,
			emailMessage: propTypes.string.isRequired
		}).isRequired
	}).isRequired,
	settings: propTypes.exact({
		error: propTypes.exact({
			status: propTypes.bool.isRequired,
			code: propTypes.number.isRequired,
			message: propTypes.string.isRequired
		}).isRequired,
		success: propTypes.exact({
			status: propTypes.bool.isRequired,
			code: propTypes.number.isRequired,
			message: propTypes.string.isRequired
		}).isRequired,
		formFields: propTypes.exact({
			emails: propTypes.array.isRequired
		}).isRequired
	}).isRequired,
	sideMenuIsOpen: propTypes.bool.isRequired,
	activeSectionMenuTab: propTypes.string.isRequired,
	nextScrollPosition: propTypes.exact({
		scrollTopWindow: propTypes.number.isRequired,
		scrollTopBody: propTypes.number.isRequired
	}).isRequired,
	online: propTypes.bool.isRequired,
	singleView: propTypes.bool.isRequired,
	singleViewError: propTypes.bool.isRequired,
	cmsPreview: propTypes.bool.isRequired,
	messageQueue: propTypes.array.isRequired,
	cookieNotice: commonPropTypes.cookieNotice.isRequired
}).isRequired

const basicReducerDefinition = {
	name,
	/**
	 * We want to be able to use reducerDefinition.initialState without
	 * polluting initial state.
	 *
	 * @return {Object}
	 */
	get initialState () {
		return getInitialState()
	},
	reducer,
	reducerPropTypes
}

export default basicReducerDefinition
