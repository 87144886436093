import store from '../store'
import { setOnline } from '../actions/basic.actions'

export default class ConnectionStatusController {
	static onConnectionStatusChange () {
		store.dispatch(setOnline(navigator.onLine))
	}

	static addEventListeners () {
		// Connection status event listener
		window.addEventListener('online', ConnectionStatusController.onConnectionStatusChange)
		window.addEventListener('offline', ConnectionStatusController.onConnectionStatusChange)
	}
}