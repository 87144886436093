/* global __DEV__ */
import React from 'react'
import SlickSlider from 'react-slick'
import propTypes from 'prop-types'
import SlideView from './slide.view'
import commonPropTypes from '../../../../utils/common-prop-types'

function SliderView ({ children, additionalClassName, data, settings, showPlaceholder }) {
	let classNames = ['m-slider', additionalClassName].join(' ')

	if (data.length === 0) {
		// noinspection JSConstructorReturnsPrimitive
		return null
	}

	return (
		<div className={classNames}>
			{
				showPlaceholder
					? <SlideView {...data[0]} />
					: (
						<SlickSlider {...settings}>
							{data.map((item, key) => (<SlideView {...item} key={key} />))}
							{children}
						</SlickSlider>
					)
			}
		</div>
	)
}

SliderView.defaultProps = {
	settings: {},
	additionalClassname: '',
	data: [],
	showPlaceholder: false
}

if (__DEV__) {
	SliderView.propTypes = {
		children: commonPropTypes.reactChildren,
		settings: propTypes.object,
		additionalClassName: propTypes.string,
		data: propTypes.arrayOf(
			propTypes.shape(
				(({ image, link, headline, text }) => ({ image, link, headline, text }))(SlideView.propTypes)
			)
		),
		showPlaceholder: propTypes.bool
	}
}

export default SliderView
